import React, { useEffect } from "react";  
import { useState } from "react";
import { Call, Contact, Groups, Messages, More, Online } from "./Svgs"; 
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
{/* 
   { 
         label:"Notification",
       svg:Groups, 
       
       active:false
     },{ 
         label:"Security", 
         svg:Contact, 
         
         active:false
      }, 
      { 
         label:"Account", 
         svg:More, 
         
         active:false
       }  , */}
export default function AdminChatSidebar({setCurrentSelect}){     
    let {state}=useLocation()  
     let defaulroutes=[{ 
      label:"Profile", 
      svg:Messages, 
      active:true
      },{ 
         label:"Purchased Plans", 
         svg:Online, 
         active:false
      },
      { 
          label:"Heir Data", 
          svg:Call , 
          
         active:false
      }, 
       { 
         label:"Reference Data", 
         svg:More, 
         
         active:false
       }, 
      
      
     ]
   const [list,setList]=useState([]) 
   useEffect(()=>{  
      if(state?.user?.role === "customer"){ 
        setList(defaulroutes)
      } 
      else{
           setList([{ 
              label:"Profile", 
              svg:Messages, 
              active:true
              }])     
      
     }
  },[])
     return(   
     
         <div className="flex text-[16px] font-poppins  w-full flex-wrap flex-row items-enter  justify-center"> 
                 
                <div className="w-full">
                { 
                 list?.map((item,index)=>{  
                     let Svg=item.svg
                    return(  
                           <div onClick={()=>{   
                               setCurrentSelect(item?.label)  
                               let list1=[] 
                               let currentactive=0;
                             for(let i=0;i<list.length;i++){ 
                                list[i].active=false 
                                 list1.push(list[i]) 
                                 if(list[i].label === item?.label){ 
                                    currentactive=i
                                 }
                             }
                            list1[currentactive].active=true  
                            setList(prev=>prev=list)
                            //setRefresh(prev=>!prev)
                           }} className={`flex cursor-pointer text-center p-2 items-center w-[70%] transition-colors duration-500 ease-in-out  rounded-r-2xl m-2 ml-0 font-medium flex-wrap flex-row justify-between ${item.active ? "bg-white":"bg-[#000000] border border-[#F1AA21]"}`}>  
                           {/*  <div className={`fill-[black] ${item.active ? "fill-[white]":""} flex fex-wrap flex-row justify-end w-[40%]`}>
                            <Svg/> 
                             </div>  */}
                        <h1 className={`${item.active ? "text-black":"text-[#F1AA21]"} ml-10 w-[100%] text-left`}>{item.label}</h1> 
                        </div>
                    )
                 })
                 }
                </div>
               {/* <div className="w-[100%] text-white flex flex-wrap flex-row  mt-10 justify-center"> 
                    <Button label="Save" className="mr-4 bg-main-color w-[80px]  p-1 pr-2 pl-2"/> 
                    <Button label="Cancel" className="bg-[#AFAFAF] p-1 pr-2 w-[80px] pl-2"/>   
                  
                 </div>       
                 */}
                  
            </div>  
           
   
     )
}