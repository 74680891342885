import React, { useEffect } from "react";
import "./css/customer_list.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {  useState } from "react"; 
import  Axios  from "axios";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline"; 
import { Dialog } from "primereact/dialog";    
import PageLoader from "../common/PageLoader";
import { Button } from "primereact/button";
import AddNewCustomer from "./components/add_new_customer";
import ViewAllCustomers from "./components/view_all_customers";
import Loader from "../../../../../Common/Components";
import UpdateUser from "../../../../common/UpdateUser/UpdateUser";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../../../../Components/Loader/Loader";
import CommonLoaderBlue from "../../../../../Components/Loader/LoaderBlue";
export default function CustomerList({usersData,activeDialog,setActiveDialog,setUserData,token,setRenderTime,setRefreshOnActive,loader,setShowLoader}) {
  const [selectedRows, setSelectedRows] = useState(0);    
  let navigate=useNavigate()
  const [activeLoader,setActiveLoader]=useState(false)
  const [selectedId,setSelectedId]=useState()  
  const [deleteDialog,setDeleteDialog]=useState(false)
  const [userData,setUsersData]=useState()
  const [editDialogVisibility,setEditDialogVisibility]=useState(false)
  const customers = usersData.customers  ? usersData.customers : []
  const [viewAllCustomers,setViewAllCustomers]=useState(false)
  const [addNewCustomerVisibility, setAddNewCustomerVisibility] =
    useState(false);
    const tableActions = (rowData) => {
      return (
        <div className="w-full  flex flex-wrap items-center flex-row justify-around">
          <div>
            <EyeIcon onClick={()=>{ 
             navigate("/Profile",{state:{user:{id:rowData?.userId,simpleone:rowData?.id,role:"customer"}}})
          }} className="w-[16px] cursor-pointer   " />
          </div>
          <div>
            <PencilIcon className="w-[16px] cursor-pointer   " onClick={()=>{setUsersData(rowData); setEditDialogVisibility(prev=>!prev)}} />
          </div>
          <div>
            <TrashIcon               onClick={()=>{  setUsersData(rowData); setDeleteDialog(prev=>!prev)}} className="w-[16px] cursor-pointer " />
          </div>   
          <div onClick={()=>{  
             setSelectedId(rowData)
             setActiveDialog(true)
          }}>
             <i className="pi cursor-pointer pi-ban text-[14px] mt-[8px]"/>
          </div>
        </div>
      );
    }; 
     useEffect(()=>{ 
         if(userData !== undefined){ 
           
         }
     },[])
  return (
    <div className="overflow-x-auto">
   {/*   <Button
        onClick={() => {
          setAddNewCustomerVisibility(true);
        }}
        className="bg-submit-button text-white top-[30px] absolute  right-[20px] font-poppins border-none mt-[59px] pl-4 pr-4 pt-2 pb-2"
      >
        <PlusIcon className="w-[15px] inline mt-[-2px] mr-[5px]" />
        Add New Customer
      </Button> 
      */}
      <DataTable
        value={customers}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        size="small"
       
        resizableColumns
        //paginator
      //  rows={10}
        //rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Customers Not Found"}
        
        style={{ backgroundColor: 'white' ,minWidth:"1100px"}}
        className=" relative w-[99%] pl-10 pr-4 pt-10 ml-2 rounded-lg border border-[#E6E9EE] shadow-sm   font-poppins  shadow-md"
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column header={<span className="text-black text-[20px]">Customers</span>} body={(rowdata)=>{ 
          return( 
               <div className="flex flex-wrap flex-row justify-left">  
                
            
                <div className="rounded-[50%] bg-seller-avatar overflow-hidden w-[40px] h-[40px] self-center border border-[#E6E9EE] shadow-sm"> 
                <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}  className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]" src={rowdata.image}   alt="Customer image"/>
            
                  </div> 
              <p className="ml-5">{rowdata.name}</p> 
              </div>
          )
        }} field="name"></Column>
        <Column header="City"  field="city.name"></Column>
        <Column header="Id" field="userId"></Column>
        <Column header="Mobile No" field="phone"></Column>
        <Column header="Country" field="country.name"></Column> 
        
        <Column header="Qty" field="customerPlan.qty"></Column> 
        <Column header="Price" field="customerPlan.price"></Column> 
        <Column header={<Button onClick={()=>{setViewAllCustomers(prev=>!prev)}}  className={`border-r rounded-l-2xl  absolute right-[0]   mt-[-25px] text-white bg-black p-2 self-right justify-content-right  ${customers.length === 0 ? "hidden":"block"}`} label="View All"></Button>} field="action" body={tableActions}></Column>
      </DataTable>
      <Dialog
        header="Add New Customer"
        className="font-poppins"
        visible={addNewCustomerVisibility}
        style={{ width: "80vw" }}
        onHide={() => setAddNewCustomerVisibility(false)}
      >   
        <AddNewCustomer />
      </Dialog> 
      <Dialog 
         visible={editDialogVisibility} 
          onHide={()=>{ 
            setEditDialogVisibility(false)
          }}
        ><UpdateUser setRefreshOnActive={setRefreshOnActive} setEditDialogVisibility={ setEditDialogVisibility} token={token} userData={userData}/> </Dialog>
      <div> 
        {
        viewAllCustomers ? <ViewAllCustomers setViewAllCustomers={setViewAllCustomers}  setRenderTime={setRenderTime} setRefreshOnActive={setRefreshOnActive} allcustomers={customers}/>:undefined }</div> 
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setActiveDialog((prev) => !prev);
          }}
          visible={activeDialog}
        >   
        
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To {selectedId?.isActive === false ? "Activate":"De-Activate"} the Customer? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes" 
                loading={activeLoader} 
                disabled={activeLoader}
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/Users/UpdateStatus`,{ 
                        id:selectedId?.id, 
                        active:!(selectedId?.isActive)
                    }, 
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => {  
                      setRenderTime(prev=>{  
                        if(prev === 1){
                        prev=prev+1 
                        }
                      })
                        setActiveLoader(false)    
                         
                       // setUserData({})
                        setRefreshOnActive(prev=>!prev)   
                      //  setShowLoader(true) 
                       //  setUserData({})
                        setActiveDialog(prev=>!prev)
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch(() => { 
                      
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setActiveDialog((prev) => !prev);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div> 
        </Dialog>    
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setDeleteDialog(false);
          }}
          visible={deleteDialog}
        >   
         
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete the Customer? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes" 
                loading={activeLoader} 
                disabled={activeLoader}
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Users/Delete/${userData?.id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => {  
                      
                      setRenderTime(prev=>{  
                        if(prev === 1){
                        prev=prev+1 
                        }
                      })
                      setActiveLoader(false)    
                        
                        setRefreshOnActive(prev=>!prev)   
                        setDeleteDialog(prev=>!prev) 
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch((err) => { 
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div> 
        </Dialog> 
    </div>
  );
}


