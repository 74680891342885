import React from "react";
import { useState } from "react";
import { Call, Contact, Groups, Messages, More, Online } from "./Svgs";

export default function AdminChatSidebar({ setCurrentActive }) { 
  const [list,setList] =useState([
    {
      label: "Messages",
      svg: Messages, 
      active:true,
    },
    
    {
      label: "Contacts",
      svg: Contact,
    },
    
  ])
  {/*
  {
      label: "Online",
      svg: Online,
    },
    {
      label: "Groups",
      svg: Groups,
    },
    {
     label: "Calls",
     svg: Call,
   },
   {
     label: "More",
     svg: More,
   },*/
  }
  const [refresh, setRefresh] = useState(false);

  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  return (
    <div className="flex  max-h-[62vh] pt-4 flex-wrap flex-row justify-center overflow-auto">
      <div className="flex relative flex-wrap flex-row justify-center">
        <div className="w-[90px] h-[90px] relative overflow-hidden  rounded-full border border-main-color border-[4px] bg-white ">
          <img
            onLoad={(event) => {
              const { naturalWidth, naturalHeight } = event.target;
              if (naturalWidth > naturalHeight) {
                event.target.style = "width:auto;height:100%";
              } else {
                event.target.style = "width:100%;height:auto";
              }
            }}
            className="w-full absolute h-auto"
            src={parseLoginRes?.userInfo?.image}
          />
        </div>
        <i className=" text-[10px]  z-100 absolute text-white w-[20px] h-[20px] ml-[50px]  mt-[70px] rounded-full pl-[5px] pr-[2px] pt-[5px] bg-gradient-to-r from-[#4AB1FF] to-[#3363FF]  pi pi-check" />{" "}
        <h1 className="w-full text-center font-medium">
          {parseLoginRes?.userInfo?.name
            ? parseLoginRes?.userInfo?.name
            : "Anonymus"}
        </h1>
      </div>
      <div className="w-full">
        {list.map((item, index) => {
          let Svg = item.svg;
          return (
            <div
              onClick={() => { 
                 let newlist=[]
                for (let i = 0; i < list.length; i++) {
                  if(list[i].label === item?.label){
                  list[i].active = true;  
                  } 
                  else{ 
                    list[i].active=false
                  }
                      newlist.push(list[i])
                } 
                setList(newlist)
                setCurrentActive(item.label);
                setRefresh((prev) => !prev);
              }}
              className={`flex cursor-pointer text-center p-2 items-center  transition-colors duration-500 ease-in-out  rounded-2xl m-2 font-medium flex-wrap flex-row justify-between ${
                item.active ? "bg-black" : "bg-white"
              }`}
            >
              <div
                className={`fill-[black] ${
                  item.active ? "fill-[white]" : ""
                } flex fex-wrap flex-row justify-end w-[40%]`}
              >
                <Svg />
              </div>
              <h1
                className={`${
                  item.active ? "text-white" : "text-[black]"
                } w-[53%] text-left`}
              >
                {item.label}
              </h1>
            </div>
          );
        })}
      </div>
    </div>
  );
}
