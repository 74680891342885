import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import "./business_flow.css"; 
import Axios from "axios";   
import Loader from "../../../../Customers/Utils";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";
/*const data = [
  [
    {
      v: "Bob2",
      f: '<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 3</p><img style="width:100%;transform:rotate(90deg);height:auto;border:solid orange 5px;border-radius:50%" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Mike",
    "VP",
  ],
  [
    {
      v: "Bob2",
      f: '<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 3</p><img style="width:100%;transform:rotate(90deg);height:auto;border:solid orange 5px;border-radius:50%" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Mike",
    "VP",
  ],
  [
    {
      v: "Mike",
      f: '<div><p style="position:absolute;margin-top:85px;width:80px;margin-left:5px;z-index:99;background:black;color:white">Admin</p><img style="width:100%;transform:rotate(90deg);height:auto;border:solid orange 5px;border-radius:50%" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Mike",
    "VP",
  ],
  [
    {
      v: "Alice",
      f: '<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 1</p><img  style="width:100%;transform:rotate(90deg);border:solid orange 5px;height:auto;border-radius:50%" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Mike",
    "VP",
  ],
  [
    {
      v: "Bob",
      f: '<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 2</p><img style="width:100%;border-radius:190px;border:solid orange 5px;transform:rotate(90deg);height:auto;" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Mike",
    "VP",
  ],
  [
    {
      v: "Bob4",
      f: '<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 4</p><img style="width:100%;border-radius:190px;border:solid orange 5px;transform:rotate(90deg);height:auto;" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>',
    },
    "Bob",
    "VP",
  ],
  ["Alice", "Mike", ""],
  ["Bob", "Mike", ""],
  ["Bob2", "Mike", ""],
["Bob4", "Alice", ""],      
];*/

export default function BusinessFlow() {  
  const [allCustomers,setAllCustomers]=useState([])  
  const [showLoader,setShowLoader]=useState(true)
  const [data,setData]=useState([])
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;    
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/MarketerCustomers`, {
      headers: { Authorization: `Bearer ${token}` },
     
    })
      .then((res) => { 
        setAllCustomers(res?.data);   
        let orignalcustomerarray=res?.data
        let dataforchart=[ 
         [{ 
            v:"Admin", 
            f:'<div><p style="position:absolute;transform:rotate(90deg);margin-top:35px;width:80px;margin-left:-30px;z-index:99;background:black;color:white">Customer 3</p><img style="width:100%;transform:rotate(90deg);height:auto;border:solid orange 5px;border-radius:50%" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /></div>'
          
          },"Admin","vp" ], 
          [{ 
            v:"Admin", 
            f:`'<div style="transform:rotate(90deg)"><p style="position:absolute;margin-top:90px;width:90px;margin-left:0px;z-index:99;background:black;color:white">Admin</p><div style="display:flex ;flex-wrap:wrap; flex-direction:row; justify-content:center;width:90px;height:90px;overflow:hidden;border-radius:50%;border:solid orange 5px;"><img  style="transform:translateX(-50%);margin-left:100%; width:150px;height:auto" src="https://myportfolio-c6742.web.app/static/media/myimg.952d45668ee2bc8edd03.png" /> /></div></div>` 
               
          },"Admin","vp" ]
        ]  
        
       
        let splitArrays = [];
let chunkSize = Math.ceil(orignalcustomerarray.length / 3); // Calculate the size of each smaller array
for (let i = 0; i < orignalcustomerarray.length; i += chunkSize) {
    splitArrays.push(orignalcustomerarray.slice(i, i + chunkSize));
}     
for(let i=0;i<splitArrays.length;i++){    
  let currentparent="Admin"
   for(let j=0;j<splitArrays[i].length;j++){ 
          let arr=[ 
             { 
              v:splitArrays[i][j]?.id, 
              f:`'<div style="transform:rotate(90deg)"><p style="position:absolute;margin-top:80px;width:110px;margin-left:-10px;z-index:99;background:black;color:white">${splitArrays[i][j]?.name}</p><div style="display:flex ;flex-wrap:wrap; flex-direction:row; justify-content:center;width:90px;height:90px;overflow:hidden;border-radius:50%;border:solid orange 5px;"><img  style="width:150px;height:auto"  src="${splitArrays[i][j]?.image}" /></div></div>` 
               
             }  ,
             currentparent, 
             "vp",
          ]     
          dataforchart.push(arr) 
          
          currentparent=splitArrays[i][j]?.id
   }
}  
 setData(dataforchart)  
setShowLoader(false)

})    
        
  },[])
  return (
    <div className="  p-2 rounded-2xl   border border-[#E6E9EE] mt-4 flex flex-wrap justify-between flex-row  rounded-2xl shadow-sm w-[calc(100vw-320px)] ">
      {showLoader ?<div className="flex flex-row flex-wrap justify-center items-center w-full  mt-[40px] mb-[40px]"> <CommonLoaderBlue/></div> :<div
        className="ml-[140px]"
        style={{ transform: "rotate(270deg)",zIndex:"-99"}}
      >
        <Chart
          height="500px"
          chartType="OrgChart"
          data={data}
          options={{
            allowHtml: true,
            fontName: "Poppins",
            fontWeight: "medium",  
            compactRows:false,
            nodeClass: "org-chart-node", 
          
          }}
        /> 
      </div>  
    
}
    </div>
  );
}
