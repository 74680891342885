import React ,{useState,useEffect} from "react";  
import { Chart } from 'react-google-charts';   
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import Loader from "../../../../../../Components";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";


export default function BusinessFlow() {    
   const [showLoader,setShowLoader]=useState(true)
  const [data,setData] =useState([
    ['Month', 'PKR'],
    ['January', 0],
    ['February', 0],
    ['March', 0],
    ['April', 0],
  ])
 const [months,setMonths]=useState(4) 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token=parseLoginRes.token  
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Expense/Graph`,
      { headers: { Authorization: `Bearer ${token}` },params:{ 
         NoOfMonths:months 
          
      } }
    )
      .then((res) => {
      let resdata=res?.data;  
      setData(resdata)
     // setData2(resdata?.data) 
     setShowLoader(false)
        })
      .catch(() => {
      setShowLoader(false)
      });
  }, [months]);
   return (  
     <div className="  p-2 rounded-2xl   border border-[#E6E9EE] mt-4 flex flex-wrap justify-between flex-row  rounded-2xl shadow-sm w-[calc(100vw-320px)] "> 
     <div className="flex w-full flex-wrap justify-between items-center flex-row">
    <h1 className="business-flow-header font-bold self-center font-poppins font-semibold text-[20px] p-4  w-[25%]" >Business Flow </h1>  
    <Dropdown  
     value={months} 
      optionValue="value" 
      optionLabel="label"    
      placeholder="6 Months"
      onChange={(e)=>{ 
        setMonths(e.value)
      }}
      options={[{label:"1",value:1},{label:"2",value:2},{label:"3",value:3},{label:"4",value:4},{label:"5",value:5},{label:"6",value:6},{label:"7",value:7}]}
    className="p-2 w-[30%] font-poppins my-dropdown border text-[#AFAFAF] rounded-md border-[#E6E9EE] text-[#AFAFAF]"/> 
      
   
     </div>
    <div 
    className="self-center w-[calc(100vw-300px)] " style={{width:"100%",height:"80%"}}>
    { showLoader ?     <div className="flex flex-wrap flex-row mt-10 mb-10 items-center justify-center"><CommonLoaderBlue/></div>
     :<Chart  
           
        height="500px"
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
         
          vAxis: { gridlines: {
            color: 'transparent',
          },},     
          areaOpacity: 0.3, // Adjust the opacity of the area under the line
         
          hAxis:{gridlines: { count: 0 }      ,      baselineColor: 'transparent', // Set baseline color to be transparent
        },
          legend: 'none',
          curveType: 'function', // Use 'function' for a smooth line
          colors: ['#FF5733'], // Specify the custom line color here
          fontName: 'Poppins, sans-serif', // Set the desired font-family
     
        }}
      />    
}
      </div>
     </div>
   )
}