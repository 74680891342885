import React,{useState} from "react"; 
import {  MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { ArrowDown, CloseNewSale, DoubleArrow } from "../../../../../../Common/Svg";  
import Loader from "../../../../../../Components";
import { Dialog } from "primereact/dialog";
import AllUpcomingSells from "./dialogs/All_Upcoming_selles";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";
 let data=[{ 
  label:"Order Revenue", 
  date:"Apr 2,24", 
  amount:"550,000" , 
  color:"#2DD683", 
  plusamount:true
 },{ 
  label:"Withdrawal Initiated", 
  date:"Mar 5,24", 
  amount:"25000", 
  color:"#E74A51", 
  plusamount:false
 },{ 
  label:"Order Revenue", 
  date:"Jun 3,24", 
  amount:"10,50000", 
  color:"#2DD683", 
  plusamount:true

 }]   
 function formatDate(dateString) {
  const date = new Date(dateString);

  // Format the date to "MMM DD, YY" format
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "short",
    day: "2-digit",
  });

  return formattedDate;
}
export default function TransactionHistory({dashboardData,loader}) { 
  const [allUpcoming,setAllUpcoming]=useState(false)   
   
  return (  
    <div className="shadow-sm mt-4 m-5  h-[352px] width910:w-[100%] width500:w-[90%] width1130:m-0 width1130:mt-0 width1130:w-[34.5%]   p-2 rounded-2xl   border border-[#E6E9EE]">   
     <div className="flex flex-wrap justify-between ">
   <h1  className="font-poppins font-bold pl-0 p-4 text-transaction-header  font-semibold text-[20px]">History Transactions</h1> 
    <p onClick={()=>{ 
     setAllUpcoming(true)
    }}  className="font-poppins cursor-pointer pr-0 p-4 text-[12px] text-transactiondetails-viewall text-[16px] font-poppins">View All</p>  
   </div> 
   <div className="flex flex-wrap justify-around flex-row w-[100%]"> 
               {   loader ? <div className="mt-[90px]"> <CommonLoaderBlue/> </div>:
                (dashboardData?.salesHistories)?.map(item=>{  
                
                 let Svg=CloseNewSale
                 return(
                   <div className="font-poppins w-[100%] flex flex-wrap flex-row items-center justify-between ">   
                         <div className="  w-[170px]  flex flex-wrap flex-row justify-left self-center rounded-2xl  pl-[5px]  pr-[5px] ">   
                                   { item.type === 2  ?  
                                   
                                 <div className=" self-center w-[30px] h-[30px] bg-transaction-icon-inner rounded-[50%] p-[7px] "> <Svg/> </div>  
                              : 
                                 
                                 <div className=" self-center w-[30px] h-[30px] bg-transaction-icon-inner rounded-[50%] p-[7px] "><Svg/></div>  }
                                      <div className="text-left ml-[5px]  w-[100px]">  
                        <h1 className="font-poppins font-medium ">{item.title}</h1>   
                         <p className="text-transfer-next">{formatDate(item.createdAt)}</p>
                        </div>
                               </div>  
                                <div className="w-[50px] pt-2 text-transfer-next text-center h-[50px] border-l-[1px] border-r-[1px]"> {item?.userId}</div>
                      
                        <h1 className={`  w-[180px] self-center font-poppins text-right h-[40px]   font-poppins font-medium text-[20px] ${item?.type === 2  || item?.title === "New Sale" ? "text-[#2DD683]" : item?.title === "Withdrawal Rent" ? "#E74A51":"#E74A51"}`} >{item.title === "New Sale" ? "+":"-"} {item.amount}</h1> 
                   </div> 
                 )
})
               } 
                
          </div> 
          <Dialog visible={allUpcoming} onHide={()=>{ 
                  setAllUpcoming(false)
               }}  headerClassName="font-poppins font-medium" header="Monthly Return Sales"> 
                      <AllUpcomingSells/>
                    
                    </Dialog>
    </div>
  )
}