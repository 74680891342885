import React from "react" 
export default function Wallet({dashboardData}){ 
     return( 
        <div className="mt-2 border border-solid width500:mt-[0px] border-1 border-wallet-border rounded-2xl flex flex-wrap flex-row justify-around "> 
          <div className="bg-wallet-top w-[100%]  p-5 rounded-t-2xl flex flex-wrap justify-around"> 
             <div className="font-poppins">  
                <p className="font-medium">Your Balance</p> 
                 <h1 className="font-semibold font-poppins text-[32px] mt-4">PKR {dashboardData?.balance ? parseFloat(dashboardData?.balance).toFixed(2):"0"}</h1>
                </div>   
                 

          </div>    
           <div className="w-[100%] p-5 rounded-t-md flex flex-wrap justify-around"> 
           <div className="font-poppins">  
                <p className="text-stat-color font-medium">Currency</p> 
                 <h1 className="font-semibold text-[20px] mt-4">PKR/ PK Rupees</h1>
                </div>   
                 <div className="flex flex-col flex-wrap justify-center items-center">    
                      <p className="font-poppins p-1 rounded text-stat-color font-medium ">status</p> 
                      <p className="text-grey font-poppins mt-1 rounded p-1 text-wallet-active font-semibold text-[20px]" >Active</p>
                    </div> 
           </div>
         
         </div>
     )
}