import React,{useState} from "react"; 
import AddCities from "./Add_City/Add_City";
import CitiesList from "./Cities_List/Cities_list";
export default function Cities(){  
     const [refresh,setRefresh]=useState(false)  
     
     const [refreshwhenAddCity,setRefreshWhenAddCity]=useState(false)
     return( 
         
        <div className="p-4  mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  max-h-[calc(100vh-200px)] profilesidebar overflow-y-auto width1280:mt-[40px]"> 
          <AddCities setRefresh={setRefresh} setRefreshWhenAddCity={setRefreshWhenAddCity}/> 
           <CitiesList refresh={refresh} refreshwhenAddCity={refreshwhenAddCity}/>
          </div>
     )
}