import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import {
  Moon,
  Notification,
  Pakflag,
  Search,
  Setting,
  Sun,
  User,
  
} from "../Svg"; 
import { Link, useNavigate } from "react-router-dom";
import BranchForm from "./Forms/branch_form/branch_form";
import EmployeeForm from "./Forms/employee_form/employee_form";
import CustomerForm from "./Forms/customer_form/customer_form";
import MarketerForm from "./Forms/marketer_form/marketer_form";
export default function AppBar({setRefreshMenu,setFormToOpen,formToOpen,setLoggedIn,setRefreshUser,setRefreshUserFromAdd}) { 
  const navigate=useNavigate() 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  const [menuOpen, setMenuOpen] = useState(false);
  const [showUserMenu,setShowUserMenu]=useState(false)    
  const [showinDropDown,setShowInDropdow]=useState([])
  const  [items,setItems] = useState( [
    {
      label: "Overview",
      active:window.location.pathname === "/" ? true:false, 
      navigator:"/"
    },
    {
      label: "Customers", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },
    {
      label: "Marketers", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },
    {
      label: "Employees", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },
    {
      label: "Employees", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },
    {
      label: "Admins", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },      
    {
      label: "Accountants", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },   
    {
      label: "Managers", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
    },
    {
      label: "Users", 
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
      SubifutureMenu: [
        {
          label: "Customers",
          navigator: "/Customer-List",
        },
        {
          label: "Add Customers",
  
          navigator: "/Add-Customer ",
        },
        {
          label: "Marketers",
          navigator: "/Marketer-List ",
        },
        {
          label: "Add Marketer",
          navigator: "/Add-Marketer ",
        },
        {
          label: "Employees",
          navigator: "/User-List ",
        },
        {
          label: "Add Employee",
          navigator: "/Add-User ",
        },
      ],
    },
    {
      label: "Add Expenses",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
    },
    {
      label: "Expense List",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
    },
    {
      label: "Total Expense",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
    },
    {
      label: "Total Earnings",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
    },
    {
      label: "Total Rents",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
    },
    {
      label: "Expenses",
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
      subifMenu: [
        {
          label: "List Expenses",
          navigator: "/Purchases-List",
        },
        {
          label: "Add Expense",
  
          navigator: "/Add-Purchase",
        },
      ],
    },
    {
      label: "Add City",
       navigator:"/Cities",
            active:window.location.pathname === "/Cities" ? true:false, 
    },
    {
      label: "Cities List",
      navigator:"/Cities",
           active:window.location.pathname === "/Cities" ? true:false, 
  
    },
    {
      label: "Cities",
       navigator:"/Cities",
            active:window.location.pathname === "/Cities" ? true:false, 
      subifMenu: [
        {
          label: "List Branches",
          navigator: "/Branches-List",
          
        },
        {
          label: "Add Branch",
  
          navigator: "/Add-Branch",
          
        },
      ],
    },
    {
      label: "Add Plans", 
      navigator:"/Plans-Offers",
      active:window.location.pathname === "/Plans-Offers" ? true:false, 
    },
    {
      label: "Add Offers", 
      navigator:"/Plans-Offers",
      active:window.location.pathname === "/Plans-Offers" ? true:false, 
    },
    {
      label: "Plans & Offers", 
      navigator:"/Plans-Offers",
      active:window.location.pathname === "/Plans-Offers" ? true:false, 
      subifMenu: [
        {
          label: "List Plans",
          navigator: "/Plans-List",
          
        },
        {
          label: "Add Plan",
  
          navigator: "/Add-Plan",
          
        },
      ],
    },
    {
      label: "Employee Salary",
      navigator:"/Monthly-Pay",
      active:window.location.pathname === "/Monthly-Pay" ? true:false, 
    },
    {
      label: "Customer Rent",
      navigator:"/Monthly-Pay",
      active:window.location.pathname === "/Monthly-Pay" ? true:false, 
    },
    {
      label: "Pay Rent",
      navigator:"/Monthly-Pay",
      active:window.location.pathname === "/Monthly-Pay" ? true:false, 
    },
    {
      label: "Monthly Pay",
      navigator:"/Monthly-Pay",
      active:window.location.pathname === "/Monthly-Pay" ? true:false, 
      subifMenu: [
        {
          label: "List Invoices",
          navigator: "/Salaries-List",
          
        },
        {
          label: "Add Invoice",
  
          navigator: "/Add-Salary",
          
        },
      ],
    },
    {
      label: "Contacts", 
      navigator:"/Chat",
      active:window.location.pathname === "/Chat" ? true:false, 
    },
    {
      label: "Messages", 
      navigator:"/Chat",
      active:window.location.pathname === "/Chat" ? true:false, 
    },
    {
      label: "Chat", 
      navigator:"/Chat",
      active:window.location.pathname === "/Chat" ? true:false, 
      subifMenu: [
        {
          label: "Connected People",
          navigator: "/Chats",
          
        },
       
      ],
    }, 
    {
      label: "Add Faqs",   
        navigator:"/Support",
      active:window.location.pathname === "/Support" ? true:false, 
    }, 
    {
      label: "Faqs List",   
        navigator:"/Support",
      active:window.location.pathname === "/Support" ? true:false, 
    }, 
    {
      label: "Contact List",   
        navigator:"/Support",
      active:window.location.pathname === "/Support" ? true:false, 
    }, 
    {
      label: "Support",   
        navigator:"/Support",
      active:window.location.pathname === "/Support" ? true:false, 
      subifMenu: [
        {
          label: "Connected People",
          navigator: "/Chats",
          
        },
       
      ],
    },
  ]) 
  useEffect(()=>{
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let role = parseLoginRes?.role;   
    if(role === "Admin") { 
      let newitems=[]
      for(let i=0;i<items.length;i++){  
        
         
        newitems.push(items[i])
         
     }
      setItems(newitems)
    } 
    else if(role === "Marketer"){ 
       let newitems=[]
        for(let i=0;i<items.length;i++){  
          
           if(items[i].label === "Users"){  
            items[i].label = "Network" 
             items[i].navigator="Network"
                newitems.push(items[i])
           } 
           else if((items[i].label === "Plans & Offers") || (items[i].label === "Overview") || (items[i].label === "Support") || (items[i].label === "Chat")){ 
          newitems.push(items[i])
           }
       }
        setItems(newitems)
    }
  },[])
  const [searchValue,setSearchValue]=useState()
  return (
    <div className="relative">
      <div className="flex flex-wrap flex-row z-50   p-4 pr-7 pt-0 shadow-sm  justify-between   bg-white fixed top:0 w-[calc(100vw-300px)] items-center z-40 ">
        <div className="w-[250px] ">
          <h1
            onClick={() => {
              setMenuOpen((prev) => !prev);
            }}
            className="mt-4 p-10 pt-2 pb-2 font-medium cursor-pointer  bg-custom-createaccount-gradient text-white h-[40px] rounded-full   text-center "
          >
            {" "}
            <PlusIcon className="w-[20px] mt-[-2px]  p-0  bg-white rounded-full text-main-color inline  text-green-500" />{" "}
            Create Account
          </h1>
          <div
            className={` rounded-lg mt-2  absolute w-[250px] z-40 p-2  text-[#AFAFAF] border border-[rgb(229, 231, 235)] font-poppins font-medium  bg-white ${
              menuOpen ? "block" : "hidden"
            }`}
          >
            <h1
              className="cursor-pointer  mt-1 "
              onClick={() => {
                setFormToOpen("customer");
                setMenuOpen((prev) => !prev);
              }}
            >
              {" "}
              <PlusIcon className="w-[20px] mt-[-2px]  p-0  bg-white rounded-full  inline " />{" "}
              Customer
            </h1>
          {parseLoginRes?.role === "Admin" ? <h1
              onClick={() => {
                setFormToOpen("employee");
                setMenuOpen((prev) => !prev);
              }}
              className="cursor-pointer mt-1 "
            >
              <PlusIcon className="w-[20px] mt-[-2px]  p-0  bg-white rounded-full  inline " />{" "}
              Employee
            </h1>  :undefined  
             }
         {parseLoginRes?.role === "Admin" ?   <h1
              className="cursor-pointer mt-1"
              onClick={() => {
                setFormToOpen("marketer");
                setMenuOpen((prev) => !prev);
              }}
            >
              <PlusIcon className="w-[20px] mt-[-2px]  p-0  bg-white rounded-full  inline " />{" "}
              Marketer
            </h1>:undefined  
             }
{parseLoginRes?.role === "Admin" ?
            <h1
              className="cursor-pointer mt-1"
              onClick={() => {
                setFormToOpen("branch");
                setMenuOpen((prev) => !prev);
              }}
            >
              <PlusIcon className="w-[20px] mt-[-2px]  p-0  bg-white rounded-full  inline " />{" "}
              Branch
            </h1> :undefined}
          </div>
        </div>
        <div className=" mt-4 rounded-full bg-category-search w-[300px]  items-center h-[40px]">
          <div className="flex-wrap flex flex-row justify-between h-[40px]">
           
            <input
              placeholder="Search Here" 
              value={searchValue} 
              onChange={(e)=>{ 
          setSearchValue(e.target.value)  
          if(e.target.value !== ""){
          const filtered = items.filter((page) =>
            page.label.toLowerCase().includes(e.target.value.toLowerCase())
          ); 
       setShowInDropdow(filtered) 
        } 
        else{ 
          setShowInDropdow([])
        }
          //setFilteredPages(filtered);
            
              }}
              className="border-none bg-transparent bg-red-500 pl-3 pr-1 font-medium w-[calc(100%-120px)] opacity-[0.6] font-poppins focus:outline-none "
            />
             <div className="bg-custom-gradient text-white w-[100px] pl-10 pr-10  r rounded-r-2xl p-2 pt-2 right-0">
              <Search />
            </div>
        
            { 
              showinDropDown.length > 0 ?   <div
              className={` rounded-lg mt-[50px]  absolute w-[300px] z-40 p-2 overflow-y-auto max-h-[50vh] text-[#AFAFAF] border border-[rgb(229, 231, 235)] font-poppins font-medium  bg-white `}
            >     
            {
            showinDropDown.map(item=>{   
            return(
              <h1
            
            className="  pt-1 pb-1 cursor-pointer  text-black opacity-[0.6] h-[40px] rounded-full   text-left "
          >
          <Link to={item.navigator} onClick={()=>{setShowInDropdow([]);setFormToOpen("");setSearchValue("");setRefreshMenu(prev=>!prev)}}>    {item?.label}</Link>
          
          </h1>) }) 
}
             </div>:undefined
            }
          </div>  

        </div>
        {/*<div className="text-white mt-4 flex flex-wrap w-[300px] items-center  flex-row justify-between ml-4 p-1">
          <div>
            <div className="mr-2 inline opacity-[0.4]">
              <Sun />
            </div>
            <div className="ml-2 inline opacity-[0.4]">
              <Moon />
            </div>
          </div>
          <div className="opacity-[0.4]">
            <Pakflag />
          </div>
          <div className="opacity-[0.4]">
            <Notification />
          </div>
          <div className="opacity-[0.4] ">
            <Setting />
          </div>
        </div>  */}
        <div onClick={()=>{ 
          setShowUserMenu(prev=>!prev)
        }} className="mt-4 cursor-pointer ">
           <div className="bg-[#D9D9D9] w-[40px] overflow-hidden h-[40px] relative opacity-[1] flex flex-wrap flex-row justify-center items-center rounded-full ">
           
          <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      src={parseLoginRes?.userInfo?.image}
                      alt="branchimg"
                    />
                  
           </div>
          <div className={`flex ${showUserMenu ? "flex":"hidden"}   font-poppins font-medium flex-wrap flex-row absolute ml-[-100px] mt-[10px] justify-between`}> 
            <div className="text-white w-[150px] bg-main-color rounded-md p-4 "> 
              <h1 className="text-center cursor-pointer" onClick={()=>{ 
                localStorage.clear() 
                 setLoggedIn(false)
              }} >Logout</h1>
            </div>
        </div>
        </div>   
      
      </div>
      {formToOpen === "branch" ? (
        <BranchForm setFormToOpen={setFormToOpen} setRefreshUserFromAdd={setRefreshUserFromAdd} setRefreshUser={setRefreshUser} />
      ) : formToOpen === "employee" ? (
        <EmployeeForm setFormToOpen={setFormToOpen} setRefreshUserFromAdd={setRefreshUserFromAdd} setRefreshUser={setRefreshUser} />
      ) : formToOpen === "customer" ? (
        <CustomerForm setFormToOpen={setFormToOpen} setRefreshUserFromAdd={setRefreshUserFromAdd} setRefreshUser={setRefreshUser} />
      ) : formToOpen === "marketer" ? (
        <MarketerForm setFormToOpen={setFormToOpen} setRefreshUserFromAdd={setRefreshUserFromAdd} setRefreshUser={setRefreshUser} />
      ) : undefined}
    </div>
  );
}
