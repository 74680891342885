import React from "react" 
import Employee from "./Employee/Employee"
import Rent from "./Rent/Rent"
export default function MonthlyPay(){ 
     return(  
        <div className=" p-4 font-poppins font-medium  mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  width1280:mt-[40px]"> 
           <div className="flex flex-wrap flex-row justify-between"> 
            <div className="w-[49%]  max-h-[calc(100vh-200px)] profilesidebar overflow-y-auto">  
               <Employee/>
                </div> 
                <div className="w-[49%]  max-h-[calc(100vh-200px)] profilesidebar overflow-y-auto">  
                <Rent/>
                </div> 
           </div>
          </div>
     )
}