import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import React, { useState,useEffect, useRef } from "react";     
import Loader from "../../Cities/Components";  
import { DataTable } from "primereact/datatable"; 
import { Column } from "primereact/column"; 
import { Button } from "primereact/button";
import { Copy, Printer } from "../Svgs";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function Employee(){     
    const dataRef=useRef()
    const toast=useRef()  
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    let token = parseLoginRes.token;    
    const [payingDialog,setPayingDialog]=useState(false)   
    const [payingLoader,setPayingLoader]=useState()
    const [branchesData,setBranchesData]=useState([])     
    const [selectedRow,setSelectedRow]=useState()
    const [renderHere,setRenderHere]=useState(1)   
    const [loader,setLoader]=useState(true)           
    const [payApiLoader,setPayApiLoader]=useState(false)
    const [yearloader,setYearLoader]=useState(false) 
    const [monthloader,setMonthLoader]=useState(false)   
    const tableActions = (rowData) => {
      return (
        <div className="w-full  flex flex-wrap items-center flex-row justify-around">
           <Button  label={`${rowData?.isPaid ? "PAYED":"PAY"}`}  onClick={()=>{  
                    if(rowData?.isPaid){ 

                    } 
                    else{
                    setSelectedRow(rowData)
                     setPayingDialog(prev=>!prev) 
                      
                    } 
               
                }} className={`${rowData.isPaid ? "bg-[#D6D7DB] text-[#ffffff] cursor":"bg-[#2DD683] text-white cursor-pointer"} rounded-full pl-2 pr-2`}
               
             />
          <div >
            <Copy/>
            
            </div> 
            <div >
             <Printer />
             
            </div> 
            
          
        </div>
      );
    };
     const [currentYear,setCurrentYear]=useState((new Date()).getFullYear())   
      const [currentMonth,setCurrentMonth]=useState((new Date()).getMonth()+1)  
       const [currentBranch,setCurrentBranch]=useState() 
       const monthOptions = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];
    function generateYearObjects() {
      const currentYear = new Date().getFullYear();
      const years = [];
  
      // Loop from current year to 2010
      for (let year = currentYear; year >= 2010; year--) {
          years.push({ label: year.toString(), value: year });
      }
  
      return years;
  }
    const [salariesList,setSalariesList]=useState([])
    useEffect(() => {
    
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Branches/List`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
           // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            
               
            setBranchesData(res?.data);   
             
          })
          .catch(() => {
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
        
         
     
           
      }, []);  
       useEffect(()=>{   
         if(renderHere === 1){ 
          setLoader(true)
         }
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Salaries/List`, {
          headers: { Authorization: `Bearer ${token}` }, 
          params:{ 
              Year:currentYear, 
              Month:currentMonth, 

          }
        })
          .then((res) => { 
          setRenderHere(prev=>{ 
            prev=prev+1
          })
           // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            
                setYearLoader(false) 
                setMonthLoader(false)
            setSalariesList(res?.data);   
            setLoader(false)
          })
          .catch(() => { 
            setLoader(false)
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
       },[currentMonth,currentYear])
     return(  
        <div className=" w-full  font-poppins text-[#AFAFAF] bg-[#EEEEEE] font-medium border-[1px] border-[#EEEEEE] rounded-lg overflow-hidden">
         <div className="bg-[#F1AA21] pb-2 flex items-center pt-2 pr-1 gap-1 flex-wrap flex-row justify-between">
         <h1  className="  text-[19px] text-black pl-[2px]  font-semibold">Employee Salary</h1>   
         <div className="flex items-center gap-1  flex-wrap flex-row justify-between"> <Dropdown  optionLabel="label"
              optionValue="value"  onChange={(e)=>{ 
                setCurrentYear(e.value)
              }} options={generateYearObjects()} value={currentYear}  className=" h-[40px] pl-1 pr-1  w-[110px] my-dropdown bg-white text-black " placeholder="month"/> 
        
           <Dropdown  optionLabel="label"
              optionValue="value"  onChange={(e)=>{ 
                setCurrentMonth(e.value)
              }} options={monthOptions} value={currentMonth}  className=" h-[40px] pl-1 pr-1  w-[100px] my-dropdown bg-white text-black " placeholder="month"/> 
          <Dropdown  optionLabel="name"
              optionValue="id"  onChange={(e)=>{ 
                setCurrentBranch(e.value)
              }} options={branchesData} value={currentBranch}  className=" h-[40px] pl-1 pr-1  w-[100px] my-dropdown bg-white text-black " placeholder="Branch"/>    
           </div>
          </div>  
           <div className="overflow-x-auto">
           <DataTable
        value={salariesList}
        onSelectionChange={(e) => { /*setSelectedRows(e.value) */ }}
        size="small"
       
        resizableColumns
        //paginator
      //  rows={10}
        //rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Salaries Not Found"}
         
        style={{ backgroundColor: '#EEEEEE' }} 

        className=" relative w-full mt-4 rounded-lg   bg-[#EEEEEE] font-poppins  "
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column  body={(rowdata) => {
            return (
              <div className="flex flex-wrap w-[300px] flex-row items-center justify-left">
                <div className="rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[35px] self-center border border-[#E6E9EE] shadow-sm">  
                <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }} className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]" src={rowdata?.employee?.image}   alt="branch image"/>
            
                 </div>
                <p className="ml-5 font-medium ">{rowdata?.employee?.name}</p>
              </div>
            );
          }}  headerClassName="text-main-color min-w-[170px]  bg-[#EEEEEE]  border-b-[1px]  border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium" header="Name"  field="Name" className="  bg-[#EEEEEE]  border-r border-[#D6D7DB]"></Column>
        <Column headerClassName="text-main-color   bg-[#EEEEEE]   border-b-[1px] border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium" header="Salary" className="  bg-[#EEEEEE] border-r border-[#D6D7DB]"  field="salary"></Column>
      
        <Column  headerClassName="text-main-color   bg-[#EEEEEE]    border-b-[1px] border-t-[1px] border-main-color  border-r border-r-[#D6D7DB] font-medium"  header="Account" className="  bg-[#EEEEEE]  border-r border-[#D6D7DB]" field="employee.bankAccountNumber"></Column>
        <Column headerClassName="text-main-color min-w-[150px]   bg-[#EEEEEE]  border-b-[1px] border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium " className="  bg-[#EEEEEE]  border-r border-[#D6D7DB]" header="Actions" field="action" body={tableActions}></Column>
      </DataTable>
           </div>  
           <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setPayingDialog(false);
          }}
          visible={payingDialog}
        >   
    
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Pay the Salary? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button 
               loading={payingLoader} 
               disabled={payApiLoader}
                label="Yes"
                onClick={() => {   
                  setPayingLoader(true)
                  Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Salaries/Pay`,{id:selectedRow?.id}, {
                    headers: { Authorization: `Bearer ${token}` },
                  }).then(()=>{   
              
              setPayApiLoader(prev=>!prev) 
              setPayingLoader(true)
               setPayingDialog(prev=>!prev) 
                  }).catch(err=>{ 
               setPayingLoader(false)
                  }) 
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setPayingDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div>  
        </Dialog>   
         <Toast ref={toast}  />  
           <div ref={dataRef}> 
             <div> 
             </div>
           </div>
          </div>
     )
} 
