import React from "react"; 
import ContactUs from "./ContactUs/ContactUs";
import Faqs from "./Faqs/Faqs";
export default function Support(){ 
     return( 
        
          <div className="p-4  mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  width1280:mt-[40px]">
            <div className="flex flex-wrap flex-row flex-wrap justify-around"> 
                  <ContactUs/> 
                   <Faqs/>
            </div>
     </div>
     )
}