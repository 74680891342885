import React, { useState } from "react";
import AdminChatSidebar from "./SideBar/ProfileSidebar";
import ProfileImg from "./Profileimg/ProfileImg";
import UpdateProfile from "./Screens/profile";
import PurchasedPlans from "./Screens/PurchasedPlans";
import HeirData from "./Screens/HeirData";
import ReferenceData from "./Screens/ReferenceData";
import { useLocation } from "react-router-dom";
export default function Profile(){      
    const {state}=useLocation() 
       const [currentSelect,setCurrentSelect]=useState("Profile")
     return( 
        <div className="p-4 font-poppins   mt-[0px] mt-[10px] width716:mt-[60px] width910:mt-[10px]  width1280:mt-[10px]">  
        <ProfileImg/>
        <div className="flex flex-wrap flex-row mt-4 justify-between"> 
           <div className="w-[100%] bg-[#000000] profilesidebar rounded-2xl max-h-[calc(100vh-350px)] pt-10 pb-10 flex flex-wrap flex-row justify-center items-center  relative overflow-y-auto lg:w-[30%]"> 
                    <AdminChatSidebar setCurrentSelect={setCurrentSelect}/>
            </div> 
            <div className="w-[100%] md:w-[100%] profilesidebar rounded-2xl max-h-[calc(100vh-350px)] overflow-y-auto lg:w-[68%]">  
                    { 
                    currentSelect === "Profile" ? 
                  <UpdateProfile/> :currentSelect === "Purchased Plans" ? <PurchasedPlans/>:currentSelect === "Heir Data" ? <HeirData/>:currentSelect === "Reference Data" ? <ReferenceData/> :""
                    }
            </div>
        </div>
      </div>
     )
}