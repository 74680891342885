import React, { useState } from "react"; 
import Stats from "./Components/Stat/stats";
import AllExpenses from "./Components/All_Expenses/all_expenses/all_expenses";
import ExpenseFlow from "./Components/Expense_Flow/Expense_flow";
import AddExpense from "./Components/Add_Expense/Add_Expense";
import ExpenseList from "./Components/Expense_List/Expense_list";
export default function Expenses(){  
     const [refresh,setRefresh]=useState(false)     
     const [refreshwhenAddPurchase,setRefreshWhenAddPurchase]=useState(false)
    return(  
        <div className="maindashboard mt-[0px] mt-[140px] width716:mt-[-80px] width910:mt-[90px]  width1280:mt-[40px] "> 
        <div className="flex flex-wrap flex-row justify-around ">     
         <Stats/>  
         <AddExpense setRefresh={setRefresh} setRefreshWhenAddPurchase={setRefreshWhenAddPurchase}/>
          <AllExpenses/> 
          <ExpenseFlow/> 
          <ExpenseList refresh={refresh} refreshwhenAddPurchase={refreshwhenAddPurchase}/>
         </div> 
         </div>
    )
}