import React,{useEffect, useState} from "react";   
import Chart from 'react-google-charts';   
import moment from "moment";
import {  Statement } from "../../../../../../Common/Svg";      
import { Calendar } from "primereact/calendar";
import  Axios  from "axios";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";


const options = {
  pieHole: 0.6,
  pieSliceText: 'none', // Hide labels inside slices
  fontName: 'Poppins, sans-serif', // Set the desired font-family
     
  chartArea: {
    left: '10%', // Adjust as needed
    top: '10%', // Adjust as needed
    width: '80%',
    height: '80%', 
  },    
};



export default function AllExpenses() {   
  const [dateFilter,setDateFilter]=useState(new Date())  
  const [showLoader,setShowLoader]=useState(true) 
  const [data,setData]=useState([{ 
    label:"Daily", 
    amount:"0 PKR"
 },{ 
  label:"Weekly", 
  amount:"0 PKR"
 },{ 
  label:"Monthly", 
  amount:"0 PKR"
 }]  )
  const [dateCalendar,setDateCalendar]=useState(new Date())  
  const [data2,setData2]=useState([
    ['Task', 'Hours per Day'],
    ['Travelling', 0],
    ['Guest', 0],
    ['Bonus', 0],
  ])
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token=parseLoginRes.token  
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Expense/Chart`,
      { headers: { Authorization: `Bearer ${token}` },params:{ 
         Date:dateFilter 
          
      } }
    )
      .then((res) => {
      let resdata=res?.data; 
       setData([{label:"Daily",amount:`${resdata?.daily} PKR`},{label:"Weekly",amount:`${resdata?.weekly} PKR`},{label:"Monthly",amount:`${resdata?.monthly} PKR`}])
        setData2(resdata?.data)    
        setShowLoader(false)
        })
      .catch(() => {
        setShowLoader(false)
      });
  }, [dateFilter]);
   return (  
     <div className="shadow-sm mt-4 m-5  width910:w-[100%] width500:w-[90%] width1130:m-0 width1130:mt-4 width1130:w-[49%]     p-2 rounded-2xl   border border-[#E6E9EE] ">   
      <div className="flex flex-wrap justify-between items-center flex-row ">
    <h1 className="font-poppins  pl-0 p-4  font-semibold text-[20px]">All Expenses </h1>   
    <div onClick={()=>{ 
              Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Expense/Export`, {
                headers: { Authorization: `Bearer ${token}` },      
              
                  responseType: 'blob', // Set the response type to blob
                
              
              })
                .then((response) => { 
                   
                  const blob = new Blob([response.data]);
const url = window.URL.createObjectURL(blob);
const a = document.createElement('a');
a.href = url;
a.download = 'All_Expenses.xlsx';
document.body.appendChild(a);
a.click();
window.URL.revokeObjectURL(url);
                }).catch(err=>{ 

                })
           }} className="text-white bg-main-color  rounded-md font-poppins flex flex-wrap cursor-pointer flex-row justify-center items-center p-1 pr-2 border-[#E6E9EE]  "> 
        <div className="fill-[white] text-[white]">
       <Statement /> 
        </div>
    <h1    placeholder="Statment" > Statement</h1>
    </div>
  
    <Calendar dateFormat="dd/mm/yy" iconPos="left" showIcon  placeholder="6 Jan 2024" 
      onChange={(e)=>{   
        const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc();
    
        setDateCalendar(utcDate.toDate() )
        setDateFilter(utcDate.toDate() )
      }} value={dateCalendar}
    className="p-2 w-[30%] font-poppins border text-[#AFAFAF] rounded-md border-[#E6E9EE] text-[#AFAFAF]"/> 
       
     
    </div> 
     <div className="flex flex-wrap justify-between flex-row">  
              { 
              data.map(item=>( 
                <div> <p className="font-poppins text-expense-stat-label"> 
                  {item.label}
                </p>
                <h1 className="font-poppins font-bold text-expense-amount"> {item.amount} </h1> 
                </div>
              )) 
              }
     </div>   
      <div className="mt-4 font-poppins">  
         { 
          showLoader ? <div className="mt-[40px] mb-[40px] flex flex-wrap flex-row justify-center items-center"><CommonLoaderBlue/></div>:
      <Chart
        chartType="PieChart"
        width="100%"
        height="200px"
        data={data2}
        options={options}
      />   
         }
      </div>
     </div>
   )
}