import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SubMenuToggle } from "../../Svg";
export default function RenderMenuItem({setFormToOpen, setRefresh,item ,allitems}) { 
    const [showSubMenu,setShowSubMenu]=useState(false)
  let Svg = item.svg;
  return (  
    <> 
    { 
    item.subMenu ? 
    <p  
      onClick={()=>{  
        setShowSubMenu(prev=>!prev)  
        allitems.forEach(element => {
            element.active=false 
             
        }); 
         item.active=true 
         setRefresh(prev=>!prev)
      }}
     className="  bg-[#FFE7CA] mt-2 ml-[-40px] rounded-full  text-main-color text-[16px]  cursor-pointer font-poppins p-2 pl-[70px]">
      <span className="mr-2">
        <Svg />
      </span>

      {item.label}
      <span
        className={`absolute right-[30px] mt-2 text-main-color `}
      >
       {  item?.subMenu?<SubMenuToggle  showSubMenu={showSubMenu}/>:undefined }
      </span>
      </p> : <Link to={`${item.navigator}`}> 
      <p  
      onClick={()=>{ 
        setFormToOpen("")
        setShowSubMenu(prev=>!prev)   
        allitems.forEach(element => {
          element.active=false 
           
      }); 
       item.active=true 
       setRefresh(prev=>!prev)
      }}
     className={`  ${item?.active ? "bg-[#000000] text-white ":"bg-[#FFE7CA] text-main-color"} mt-2 ml-[-40px] rounded-full  text-main-color text-[16px]  cursor-pointer font-poppins p-2 pl-[70px]`}>
      <span className="mr-2">
        <Svg />
      </span>

      {item.label}
      <span
        className={`absolute right-[30px] mt-2 text-main-color `}
      >
       {  item?.subMenu?<SubMenuToggle  showSubMenu={showSubMenu}/>:undefined }
      </span>
      </p>
      </Link>

}
      <div
       
        className={`${!showSubMenu ? "hidden":"block"}  relative mt-2`}
      >
        {item?.subMenu?.map((item) => {
          let Svg = item.svg;
          return (
            <Link to={`${item?.navigator}`}>
              <p className="p-1 cursor-pointer text-menu_submenu font-poppins p-1 ">
                <span className="mr-4">
                  <Svg />
                </span>

                {item.label}
              </p>
            </Link>
          );
        })}
      </div>
    
    </>
  );
}
