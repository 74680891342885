import React, { useEffect, useState } from "react"; 
import Axios  from "axios";
import Loader from "../../Loaders";
import CommonLoaderBlue from "../../../../../Components/Loader/LoaderBlue";
const connectedUsers = [
  {
    img: "https://images.unsplash.com/photo-1537511446984-935f663eb1f4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Abdul Qadir",
    currentMessage:
      "aslam o alekom bro! kaisy hain ap main bahir ap ka wite kar raha hun",
    lastSeen: "2 Day Ago ", 
    active:true
  },
  {
    img: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Abdul Qadir",
    currentMessage:
      "aslam o alekom bro! kaisy hain ap main bahir ap ka wite kar raha hun",
    lastSeen: "3:55 PM",
  },
  {
    img: "https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Abdul Qadir",
    currentMessage:
      "aslam o alekom bro! kaisy hain ap main bahir ap ka wite kar raha hun",
    lastSeen: "2:55 PM",
  },
];
export default function ConnectedPeople({ setCurrentActive }) { 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token; 
  const [loaderShow,setLoaderShow]=useState(true)
  const [refresh, setRefresh] = useState(false);   
  const [connectedUsers,setConnectedUser]=useState([])
  useEffect(()=>{    
 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Conversations/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
      //setCitiesList(res?.data)    
      let data=res?.data    
        if(data?.length > 0){
      data[0].active=true
      setConnectedUser(data) 
    setCurrentActive(data[0])  
        }
         
    setLoaderShow(false) 
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{  
      setLoaderShow(false)
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
    
    })
   },[])

  return (
    <div className="w-full rounded-t-2xl border border-[#E6E9EE] overflow-hidden">
      <div className="w-full h-[90px] bg-[#D9D9D9]">
        <h1 className="text-[22px]     rounded-t-2xl font-semibold text-[black]  overflow-hidden pt-4 pb-4 pl-2 mr-0 bg-[#D9D9D9] ">
          Peoples
        </h1>
      </div>
      <div className="w-full max-h-[62vh] overflow-y-auto">      
        {  loaderShow ?     <div className="flex flex-wrap flex-row mt-10 mb-10 items-center justify-center"><CommonLoaderBlue/></div>
     
         :<>
        {connectedUsers.length > 0 ? <>{connectedUsers.map((item, index) => {
          return (
            <div
              onClick={() => {
                for (let i = 0; i < connectedUsers.length; i++) {
                  connectedUsers[i].active = false;
                }
                item.active = true;
                setCurrentActive(item);
                setRefresh((prev) => !prev);
              }}
              className={`flex flex-wrap cursor-pointer rounded-md m-2 transition-colors duration-500 ease-in-out  items-center flex-row justify-between ${
                item.active ? "bg-[black]" : "bg-[#F1AA21]"
              }`}
            >
              <div className="w-[90px] h-[90px]  overflow-hidden rounded-full">
                <img
                  src={item.image} 
                  onLoad={(event)=>{ 
                    const { naturalWidth, naturalHeight } = event.target; 
                     if(naturalWidth > naturalHeight){ 
                      event.target.style="width:auto;height:100%"  
                       
                     } 
                     else{ 
                      event.target.style="width:100%;height:auto"  
                   
                     }
          
                   }}
                  className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
                />{" "}  
                 
              </div>
              <div className="w-[calc(100%-110px)]"> 
               <div>
                <h1
                  className={`font-semibold inline ${
                    item.active ? "text-white" : "text-black"
                  }`}
                >
                  {item?.name}
                </h1>  
                <i className=" text-[10px]    ml-2 mt-[0px] text-white w-[20px] h-[20px] rounded-[25px] pt-[5px]  pl-[4px] pr-[4px] bg-gradient-to-r from-[#4AB1FF] to-[#3363FF] pi pi-check" />{" "}
          
                </div>
                <h1 className={` ${item.active ? "text-white" : "text-black"}`}>
                  {item?.lastMessage}
                </h1>
              </div>
            </div>
          );
        }) 
      }</>:<h1 className="font-poppins mt-[40px] text-main-color font-bold mb-[40px] w-full text-center ">No User Connected</h1>
          }  
         </>
     }
      </div>
    </div>
  );
}
