import React from "react"       
import { useFormik } from "formik" 
import * as Yup from 'yup';  
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext"; 
import { Dropdown } from 'primereact/dropdown';  
 const cityOptions=[{ 
  label:"Karachi",value:"Karachi"
 },{ 
  label:"Lahore",value:"Lahore"
 },{ 
  label:"Islamabad",value:"Islamabad"
 },{ 
  label:"Rawalpindi",value:"Rawalpindi"
 },{ 
  label:"Mianwali",value:"Mianwali"
 }] 
 const roleOptions=[{ 
  label:"Admin",value:"Admin"
 },{ 
  label:"Customer",value:"Customer"
 },{ 
  label:"Marketer",value:"Marketer"
 }] 
 const branchOptions=[{ 
  label:"B1",value:"b1"
 },{ 
  label:"B434",value:"B434"
 },{ 
  label:"B99",value:"B99"
 }]
export default function AddNewCustomer(){      
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name Is Required'),
        description: Yup.string().required('Description Is Required'),   
         role: Yup.string().required('Role Is Required'), 
         phone:  Yup.number().integer('Please enter a valid integer').required('Phone Is Required'), 
         address:Yup.string()
         .required("Email is required"),
        email: Yup.string()
        .required("Email is required")
        .email("Invalid email format")
        .max(255, "Email must be at most 255 characters"),
        city:Yup.string()
        .required("City is required"), 
        branch:Yup.string()
        .required("Branch is required"),
      
    });
    const formik=useFormik({ 
        initialValues:{ 
            name:"", 
            email:"", 
            phone:"", 
            role:"", 
            address:"", 
            description:"",  
            branch:"",
             city:""


        }, 
        validationSchema,
        onSubmit:(values=>{ 

        })
    })
     return( 
           <div >    
              <form onSubmit={formik.handleSubmit}  >  
               <div  className="flex flex-wrap justify-around flex-row"> 
                   <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                     <label className="block">Name <span className="text-red-500">*</span></label>
                   <InputText className="w-full  border  border-grey-500 rounded-lg p-2 mt-2" name="name" value={formik.values.name}  onChange={formik.handleChange}/>  
                   {formik.touched.name && formik.errors.name ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.name}</p>
            ) : null}
                    </div>   
                    <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">City <span className="text-red-500">*</span></label>
                      <Dropdown options={cityOptions} className="w-full   border  border-grey-500 rounded-lg p-0 mt-2" name="city" value={formik.values.city} onChange={formik.handleChange}/>  
                      {formik.touched.city && formik.errors.city ? (
                 <p className="mt-2 ml-1 text-red-500">{formik.errors.city}</p>
               ) : null}
                       </div>   
                       <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">Branch <span className="text-red-500">*</span></label>
                      <Dropdown options={branchOptions} className="w-full  border w-[35vw]  border-grey-500 rounded-lg p-0 mt-2" name="branch" value={formik.values.role} onChange={formik.handleChange}/>  
                      {formik.touched.branch && formik.errors.branch ? (
                 <p className="mt-2 ml-1 text-red-500">{formik.errors.branch}</p>
               ) : null}
                       </div>  
                       <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">Role <span className="text-red-500">*</span></label>
                      <Dropdown options={roleOptions} className=" border w-full  border-grey-500 rounded-lg p-0 mt-2" name="role" value={formik.values.role} onChange={formik.handleChange}/>  
                      {formik.touched.role && formik.errors.role ? (
                 <p className="mt-2 ml-1 text-red-500">{formik.errors.role}</p>
               ) : null}
                       </div>   
                    <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                     <label className="block">Descriptions <span className="text-red-500">*</span></label>
                   <InputText className="border w-full  border-grey-500 rounded-lg p-2 mt-2" name="description" value={formik.values.description} onChange={formik.handleChange}/>  
                   {formik.touched.description && formik.errors.description ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.description}</p>
            ) : null}
                    </div>   
                    
                    <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">Phone <span className="text-red-500">*</span></label>
                      <InputText keyfilter="int" className="  border w-full  border-grey-500 rounded-lg p-2 mt-2" name="phone" value={formik.values.phone} onChange={formik.handleChange}/>  
                      {formik.touched.phone && formik.errors.phone ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.phone}</p>
            ) : null}
                       </div>   
                       <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">Address <span className="text-red-500">*</span></label>
                      <InputText  className="  border w-full  border-grey-500 rounded-lg p-2 mt-2" name="address" value={formik.values.address} onChange={formik.handleChange}/>  
                      {formik.touched.address && formik.errors.address ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.address}</p>
            ) : null}
                       </div>   
                       <div className="mt-2 w-[100%] sm:w-[100%] md:w-[45%] lg:w-[23%]"> 
                        
                        <label className="block">Email <span className="text-red-500">*</span></label>
                      <InputText className="  border w-full  border-grey-500 rounded-lg p-2 mt-2" name="email" value={formik.values.email} onChange={formik.handleChange}/>  
                      {formik.touched.email && formik.errors.email ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.email}</p>
            ) : null}
                       </div>  

                            </div>
                        <div className="mt-4 pt-2 flex flex-wrap flex-row justify-left ">   
                       
                             <Button className="bg-submit-button text-white mt-[18px] p-4 pt-2 pb-2 rounded" label="Submit" type="Submit"/>
                             <Button className="bg-cancel-button  ml-10 text-white mt-[18px] p-4 pt-2 pb-2 rounded" label="Cancel" />
                      
                        </div>
                    </form>
           
             
           </div>
     )
}