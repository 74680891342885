import UsersDashboard from "./components/users_dashboard"; 
import React, { useEffect,useRef, useState } from "react"; 
import Axios  from "axios";
import { Toast } from "primereact/toast";
import UsersDetails from "./components/users_details";
import CustomerList from "./components/customer_list/customer_list";
export default function AllUsers({refreshUser,refreshUserFromAdd,setRefreshUserFromAdd}){  
  const toast=useRef() 
  const [activeDialog, setActiveDialog] = useState(false);
  const [usersData,setUsersData]=useState({})  
  const [loader,setShowLoader]=useState(true)  
  let parseLoginRes=JSON.parse(localStorage.getItem("userData")) 
  let token=parseLoginRes.token  
  const [renderTime,setRenderTime]=useState(1)
  const [refreshOnActive,setRefreshOnActive]=useState(false)  
   useEffect(()=>{    
     if(renderTime === 1){ 
          if(!refreshUserFromAdd){
     setShowLoader(true)  
          }
     }
     
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/Dashboard`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
        
        setUsersData(res?.data)      
        if(!refreshUserFromAdd){
          setShowLoader(false)  
               }
        setActiveDialog(false)
    }).catch(()=>{ 
      
    })   
    return ()=>{ 
      setRefreshUserFromAdd(false)
    }
   },[refreshOnActive,refreshUser])       
   //Refresh Customer List and Dashboard  
   
 
    return(  
        <> 
        <UsersDashboard usersData={usersData}/>  
        
         <UsersDetails setRenderTime={setRenderTime} setRefreshOnActive={setRefreshOnActive} usersData={usersData} loader={loader}/>   
         <div className="mt-4 customer-table relative">
         <CustomerList setRenderTime={setRenderTime} setActiveDialog={setActiveDialog} activeDialog={activeDialog} setRefreshOnActive={setRefreshOnActive} setUsersData={setUsersData} usersData={usersData} token={token} loader={loader}  /> 
         </div> 
         
         <Toast ref={toast}/>
        </>
    )
}