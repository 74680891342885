import React, { useState } from "react";
import { StarIcon } from "@heroicons/react/outline";
import { useRef } from "react";
import {Toast} from "primereact/toast"
import { useFormik } from "formik"
import * as Yup from 'yup';   
import Axios from "axios" 
import "./css/login.css"
import {
  Apple,
  Bizchamplg,
  Email,
  Facebook,
  Google,
  Hide,
  Instagram,
  Lock,
  Tiktok,
  Twitter,
  Youtube,
} from "../Svg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Loader from "../Components";
import CommonLoader from "../../Components/Loader/Loader";

export default function Login({setLoggedIn}) {  
   const [showPassword,setShowPassword]=useState(false) 
    const toast=useRef() 
    const [loader,setLoader]=useState(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email Is Required'),
    password: Yup.string().required('Password Is Required'),   
    
  
});
const formik=useFormik({ 
    initialValues:{ 
        email:"", 
        password:"", 
      


    }, 
    validationSchema,
    onSubmit:(values=>{   
      setLoader(true)
      Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Account/Login`,formik.values).then((res)=>{ 
        localStorage.setItem("userData",JSON.stringify(res?.data)) 
        setLoader(false)
        setLoggedIn(prev=>!prev)
      }).catch(()=>{  
        setLoader(false)
     })
    })
})
  return (
    <div className="w-full h-full flex flex-wrap flex-row justify-between  ">
      <div className="w-[49%] relative overflow-x-hidden">
        <img
          src="./login_screen/bgimg.jpg"
          className="h-[100vh] object-cover"
        />
        <div className="absolute top-0 h-[100vh]  w-[100%] bg-custom-gradient opacity-[0.8]"></div>
        <div className="flex flex-column absolute top-0 h-full justify-between flex-wrap items-center">
          <div className="w-full">
            <h1 className="font-poppins  font-bold text-center w-full">
              {" "}
              BizChamp PVT LTD
            </h1>
            <p className="text-center font-poppins">Success for everyone</p>
            <p className="text-center">
              {" "}
              <StarIcon className=" inline w-6 h-6 text-star-icon" />{" "}
              <StarIcon className=" inline w-6 h-6 text-star-icon" />{" "}
              <StarIcon className="  inline w-6 h-6 text-star-icon" />{" "}
              <StarIcon className="  inline w-6 h-6 text-star-icon" />{" "}
              <StarIcon className=" inline w-6 h-6 text-star-icon" />
            </p>
          </div>
          <div>
            <div className="w-full  ">
              <img
                src="./bixlogindetail.png"
                className="transform ml-[50%] translate-x-[-50%]  w-[25%] h-[25%]"
              />
            </div>
            <div className="w-full ">
              <p className="text-center font-poppins text-[black] mt-[5%] w-[80%] ml-[50%] transform translate-x-[-50%]">
                BizChamp is a Private Limited excelling in multi-business
                ventures. Our forte lies in navigating diverse industries,
                creating value, and generating revenue. We are the catalyst for
                success, BizChamp is your Partner in Multifaceted Prosperity.
              </p>
            </div>
            <div className="w-full items-center">
              <div className="w-[50%] mt-[5%] transform ml-[50%] translate-x-[-50%]  flex flex-wrap flex-row justify-center ">
                <Facebook />
                <Twitter />
                <Tiktok />
                <Youtube />
                <Instagram />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[51%] h-[100vh] relative overflow-hidden"> 
      <div className="transform  translate-x-[-50%] w-full ml-[40%]">
        <Bizchamplg /> </div>
        <div className="flex flex-row w-full  font-poppins   justify-center h-full items-center flex-wrap absolute top-0">
          <div className="w-full relative flex flex-wrap justify-center items-center flex-row">
            <h1 className=" text-[36px] w-[100%] text-center font-bold">
              LOGIN
            </h1> 
            <form  className="w-[70%] max-w-[450px] flex flex-wrap flex-row justify-center items-center" onSubmit={formik.handleSubmit}>
            <div className="mt-10   w-[90%] flex flex-wrap flex-row   justify-center">
              <div className="mt-[38px]">
                <Email />
              </div>   
            
              <div className="w-[calc(100%-40px)] ml-2">
                <div className="flex flex-wrap flex-row justify-between ">
                  <label className="block text-main-color">
                    Email <span>*</span>
                  </label>
                </div>
                <InputText
                  placeholder="Email"     
                      autocomplete="off"
                  className="    text-[rgba(0,0,0,.7)] font-medium w-full  bg-[#EEEEEE] rounded-full p-3 mt-2"
                  name="email" 
                  onChange={formik.handleChange}
                />
                 {formik.touched.email && formik.errors.email ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.email}</p>
            ) : null}
              </div>
            </div>
            <div className="mt-8   w-[90%] flex flex-wrap flex-row   justify-center">
              <div className="mt-[34px] ">
                <Lock />
              </div>
              <div className="w-[calc(100%-40px)] ml-2">
                <div className="flex flex-wrap flex-row justify-between ">
                  <label className="block text-main-color">
                    Password <span>*</span>
                  </label>
                  <label className="block text-main-color">
                    Forgot Password ?
                  </label>
                </div> 
                <div className="flex w-full flex-wrap bg-[#EEEEEE] rounded-full flex-row justify-between items-center">
                <InputText
                  placeholder="Password"
                  className=" font-medium text-[rgba(0,0,0,.7)] w-[calc(100%-35px)] bg-[#EEEEEE] rounded-full  p-3 "
                  name="password"  
                   type={showPassword ? "text":"password"}
                  onChange={formik.handleChange}
                />     
                 <span onClick={()=>{ 
                  setShowPassword(prev=>!prev)
                 }} className=" inline w-[25px]">
                  <i className={`text-[16px] mt-[8px] font-bold text-[rgba(0,0,0,.7)] ${showPassword ? "pi pi-eye":"pi pi-eye-slash"}`}  />
                 </span>  
                </div>
               
                     
                {formik.touched.password && formik.errors.password ? (
              <p className="mt-2 ml-1 text-red-500">{formik.errors.password}</p>
            ) : null}
              </div>    
              
            </div> 
           
            <div className=" w-[200px] rounded-full   flex flex-wrap flex-row bg-custom-gradient items-center justify-center mt-4"> 
               { 
                loader ? <div className="mt-[9px] mb-[8px]"> <CommonLoader /></div>:""
               } 
               { 
               !loader ?
              <Button 
             // onClick={()=>{setLoggedIn(prev=>!prev)}}
                className="bg-custom-gradient loginbutton font-poppins   p-3 text-center rounded-full   font-bold "
                label="Login" 
                type="submit"
              /> :undefined
               }
            </div> 
          
           
            </form>
          </div>
           
        </div>
      </div> 
       <Toast ref={toast}/>
    </div>
  );
}
