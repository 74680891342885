import "./App.css";
import { useState } from "react";
import moment from 'moment-timezone';
import Home from "./Admin/Screens/Home/home";
import CommonMenubar from "./Common/CommonMenuBar/Menubar";
import { Route, Routes} from "react-router-dom";
import ComingSoon from "./Admin/Screens/coming_soon/coming_soon";
import Login from "./Common/login/login"; 
import AppBar from "./Common/AppBar/AppBar";
import AllUsers from "./Admin/Screens/AllUsers/AllUsers";
import MarketerDashboard from "./Marketer/Screens/Home/home";
import PlanOffers from "./Admin/Screens/Plan_Offers/Plan_Offers";
import Expenses from "./Admin/Screens/Expenses/Expenses";
import Customers from "./Marketer/Screens/Customers/Customer";
import Cities from "./Admin/Screens/Cities/Cities";
import MonthlyPay from "./Admin/Screens/MonthlyPay/MonthlyPay";
import Support from "./Marketer/Screens/Support/Support";
import AdminSupport from "./Admin/Screens/Support/Support";
import Chat from "./Admin/Screens/Chat/MainChat";
import Profile from "./Admin/Screens/Profile/MainProfile";
function App() {
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let role = parseLoginRes?.role; 
  const [refreshUser,setRefreshUser]=useState(false)    
  const [refreshUserFromAdd,setRefreshUserFromAdd]=useState(false) 
  const [loggedIn, setLoggedIn] = useState(role !== undefined ? true : false);   
  
  const [formToOpen, setFormToOpen] = useState("");   
  const [refreshMenu,setRefreshMenu]=useState(false)    
  moment.tz.setDefault('Asia/Karachi');
  return (
    <div className="App">
      {!loggedIn ? (
        <Login setLoggedIn={setLoggedIn} />
      ) : (
        <>
          <div className="App-header w-full flex flex-wrap justify-between flex-row ">
            <div
              className={` transition-width duration-500 ease-in-out w-[300px] `}
            >
              <CommonMenubar  setFormToOpen={setFormToOpen} refreshMenu={refreshMenu} />
            </div>
            <div className="transition-width duration-500 w-[calc(100vw-300px)] ml-[300px] ease-in-out">
              <AppBar formToOpen={formToOpen} setFormToOpen={setFormToOpen} setRefreshMenu={setRefreshMenu} setLoggedIn={setLoggedIn} setRefreshUserFromAdd={setRefreshUserFromAdd}  setRefreshUser={setRefreshUser}/>
              <div className="mt-[60px] overflow-hidden">
                <Routes>
                  {role === "Admin" ? (  
                     <>
                    <Route path="/" element={<Home />} />  
                      
                  <Route path="Users" element={<AllUsers refreshUser={refreshUser} setRefreshUserFromAdd={setRefreshUserFromAdd} refreshUserFromAdd={refreshUserFromAdd} />} /> 
                  <Route path="Coming-Soon" element={<ComingSoon />} /> 
                  
                  <Route path="Expenses" element={<Expenses/>} /> 
                  
                  <Route path="Plans-Offers" element={<PlanOffers/>} />   
                   <Route path="Cities" element={<Cities/>}/> 
                     <Route path="Monthly-Pay" element={<MonthlyPay/>}/>  
                      
                   <Route path="/Support" element={<AdminSupport/>} />   
                    <Route path="/Chat"  element={<Chat/>}   /> 
                     <Route path="/Profile" element={<Profile/>} />
                    </>
                  ) : ( 
                    <>
                    <Route path="/" element={<MarketerDashboard />} />  
                    <Route path="/Network" element={<Customers  setRefreshUserFromAdd={setRefreshUserFromAdd} refreshUserFromAdd={refreshUserFromAdd} />}   />  
                    
                  <Route path="Coming-Soon" element={<ComingSoon />} /> 
                   <Route path="/Support" element={<Support/>} /> 
                  <Route path="Plans-Offers" element={<PlanOffers/>} />
                  <Route path="/Chat"  element={<Chat/>}   />
                
                    </>
                  )} 
                </Routes>
              </div>{" "}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
