import React from "react";      
import { useEffect,useState
 } from "react"; 
 import  Axios  from "axios"; 
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";

export default function ProfileImg(){   
 let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;     
  const [imgsrc,setimgsrc]=useState() 
  const [selectedFile,setSelectedFile]=useState()
  const {state}=useLocation()
  const [userData,setUserData]=useState()  
  const [imguploaddisabled,setImageUploadDisabled]=useState(false) 
   const formik=useFormik({ 
    initialValues:{ 
      BankName: "",
      CountryId: "",
      CityId: "",
      Address: "",
      Name: "",
      FatherName: "",
      BankAccountNumber: "",
      Email: "",
      Phone: "",
      WhatsappNumber: "",
      Dob: "",
      IdCardBack: "",
      IdCardFront: "",
      Cnic: "",
      Id:state?.user?.simpleone
    }
   })
  useEffect(()=>{ 
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Users/Info/${state.user.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {   
        let user=res?.data;  
        setUserData(user) 
        setimgsrc(user?.image)        
        formik.setFieldValue("Name",user?.name)  
        
        formik.setFieldValue("FatherName",user?.fatherName)  
        
        formik.setFieldValue("Cnic",user?.cnic)  
        
        formik.setFieldValue("CountryId",user?.country?.id)
        formik.setFieldValue("CityId",user?.city?.id)
        
        formik.setFieldValue("Address",user?.address)  
        
        formik.setFieldValue("BankName",user?.bankName)
        
        formik.setFieldValue("BankAccountNumber",user?.bankAccountNumber) 
        
        formik.setFieldValue("Email",user?.email)  
        
        formik.setFieldValue("Phone",user?.phone) 
        
        formik.setFieldValue("WhatsappNumber",user?.whatsappNumber)  
        
        formik.setFieldValue("Dob",user?.dob ? new Date(user?.dob):"")
        //setCitiesOptions(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  },[])
    return(   
         <div className="flex  relative border border-[#EEEEEE] rounded-2xl overflow-hidden flex-wrap flex-row justify-center ">
       <img src="./cover.png"  
       
         className=" h-auto w-full"/>  
        <i className="absolute pi pi-camera bg-main-color p-1 rounded-full text-white right-0 top-[40px]"/>
        <div className="flex mt-[-20px]  w-full flex-wrap flex-row justify-center items-center"> 
        <div className="relative ">  
           
        <div onClick={()=>{   
                if(!imguploaddisabled){
                setImageUploadDisabled(true)
                 let input=document.createElement("input") 
                 input.type="file" 
                 input.accept="Image/*" 
                 input.click() 
                 input.onchange=(e)=>{ 
                  setSelectedFile(e.target.files[0]) 
                  let reader=new FileReader() 
                  reader.readAsDataURL(e.target.files[0]) 
                  reader.onload=()=>{        
                    setimgsrc(reader.result)    
                    let formData=new FormData()        
                     const values=formik.values
                    for (const key in values) {
                      if (key === "Dob" && formik.values[key] !== "" && formik.values[key] !== null && formik.values[key] !==undefined ) {
                        let currentvalue = values[key];
                        let date = new Date(currentvalue);
                        let isoformat = date.toISOString();
                        formData.append(key, isoformat);
                      } else {
                           if(formik.values[key] !== "" && formik.values[key] !== null && formik.values[key] !==undefined )
                          formData.append(key, values[key]);
                
                      }
                    }
                    formData.append("Image",selectedFile)
                    Axios.post(
                      `${process.env.REACT_APP_BASE_URL}/api/Users/UpdateInfo`,formData,
                      { headers: { Authorization: `Bearer ${token}` } }
                    )
                      .then((res) => {  
                             setImageUploadDisabled(false)
                        }).catch(err=>{ 
                       setImageUploadDisabled(false)
                        })
                  }
                 } 
                }
            
              }} className="w-[180px] border relative border-main-color border-[8px] bg-[#E8EAEE80] h-[180px] rounded-full overflow-hidden ">  
              <img    onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }} className="w-[auto] h-[180] ml-[50%] mt-[50%] transform translate-x-[-50%] translate-y-[-50%]" src={imgsrc}/>
              
          </div>  
          <i className="absolute pi pi-camera bg-main-color p-1 rounded-full text-white right-[0px]  top-[60px]"/>
                  </div>
           <div className="ml-4 ">  
              
               <div> 
                 <h1 className="font-poppins font-semibold text-[24px]">{userData?.name}</h1>
            <p className="text-[#AFAFAF] font-poppins text-[20px]">Your account is ready, you can now apply for advice.</p> 
             </div>
            </div> 
              <div className="ml-4 flex  flex-wrap flex-row justify-left">   
           
                 <i onClick={()=>{ 
                    navigator.clipboard.writeText(`WhatsAppNumer : ${userData?.whatsappNumber} \nPhone : ${userData?.phone} \nName : ${userData?.name} \nUser Id : ${userData?.userId} `).then(() => {
                    })
                 }} className="pi p-2  cursor-pointer w-[37px] h-[37px] pt-[6px] pl-[6px] text-[24px] rounded-full bg-[#E8EAEE80]  text-[#AFAFAF] pi-copy ml-2"/>                 
               {  /*  <div className="relative"> 
                 <i className="pi p-2 w-[37px] h-[37px] pt-[6px] pl-[6px] text-[24px]  rounded-full bg-[#E8EAEE80]  text-[#AFAFAF] pi-comment ml-2"/>  
                   
                 <i className="pi bg-main-color text-[#F1AA21]  text-[8px] rounded-full bg-[#E8EAEE80]   pi-circle-fill right-0 absolute "/> 
                   </div>
                  
                 
                 <i className="pi  w-[37px] h-[37px] pt-[6px] pl-[6px] text-[24px]  text-[#CACED8] p-2 rounded-full bg-[#E8EAEE80] pi-share-alt ml-2"/>
              */}  </div> 
        </div>
         </div> 
    )
}