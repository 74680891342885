import React, { useEffect, useState ,useRef} from "react";
import {
  ChevronDownIcon,
  XIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid"; 
import moment from "moment"; 
import { Calendar } from "primereact/calendar";   
import { Button } from "primereact/button";
import Dropdown from "react-dropdown";
import "../css/customer_list.css";   
import html2pdf from 'html2pdf.js';
import { Dialog } from "primereact/dialog";
import { Search } from "../../../../../../Common/Svg";
import { Dropdown as DropdownPrime } from "primereact/dropdown";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { DateRange } from "../../../../../../Common/Svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Copy, Excel, Printer } from "../../../../../../Common/Svg";
import Axios from "axios"; 
import Loader from "../../../../../../Common/Components"; 
import PageLoader from "../../common/PageLoader";
import UpdateUser from "../../../../../common/UpdateUser/UpdateUser";
import { useNavigate } from "react-router-dom";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue"; 

export default function ViewAllCustomers({
  setViewAllCustomers, 
  setRefreshOnActive, 
  setRenderTime
}) { 
  const generatePDFInWorker = (htmlContent, options) => {
    return new Promise((resolve) => { 
    
        const worker = new Worker('/Worker.js');
        worker.postMessage({ htmlContent, options });
    worker.onerror=(error)=>{ 
    }
        worker.onmessage = (event) => { 
            if (event.data.status === 'done') {
                worker.terminate();
                resolve();
            }
        };   
    
    });
};
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;      
  let navigate=useNavigate()   
    const [startDate,setStartDate]=useState() 
    const [endDate,setEndDate]=useState()
  const [pdfSelect,setPdfSelect]=useState(false)   
  const rowClassName = (data, { rowIndex }) => {   
    
    return 'mt-[380px]'
    
    
  };
  let tableRef=useRef()
  const [printSelect,setPrintSelect]=useState(false)
  const dataRef=useRef()
  const [userData,setUsersData]=useState()
  const [editDialogVisibility,setEditDialogVisibility]=useState(false)
  const [activeLoader,setActiveLoader]=useState(false)
  const [activeDialog, setActiveDialog] = useState(false);
  const [selectedId,setSelectedId]=useState() 
  const [refreshOnActiveComp,setRefreshOnActiveComp]=useState(false)
  const [dropDowValue,setDropDownValue]=useState("All")
  const [filteredData,setFilteredData]=useState(null)    
  const [renderHere,setRenderHere]=useState(1)
  const [deleteDialog,setDeleteDialog]=useState(false)
  const [allcustomers,setAllCustomers]=useState([])
  const [searchCustomer, setSearchCustomer] = useState(""); 
  const [loader,setShowLoader]=useState(true)  

  useEffect(() => {   
      if(renderHere === 1){
    setShowLoader(true) 
      }
    setFilteredData(null) 
     if(searchCustomer === ""){
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        type: 4,  
        StartDate:startDate, 
         EndDate:endDate, 

      },
    })
      .then((res) => { 
        setAllCustomers(res?.data);
         
        if(dropDowValue === "active"){
          let marketers=[]
          for(let i=0;i<res?.data?.length;i++){    
              if((res?.data[i]?.isActive) === true)
              marketers.push(res?.data[i])
          }
             
          setFilteredData(marketers) 
      } 
      else if(dropDowValue === "inactive"){ 
        let marketers=[]
        for(let i=0;i<res?.data.length;i++){    
            if((res?.data[i].isActive) === false)
            marketers.push(res?.data[i])
        }
           
        setFilteredData(marketers) 
      } 
      else{    
       
         setFilteredData(res?.data)  
      }      
      setShowLoader(false);   
      })
      .catch((err) => { 
      }); 
    } 
    else{ 
      Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          type: 4, 
          Search:searchCustomer, 
          StartDate:startDate, 
          EndDate:endDate
        },
      })
        .then((res) => { 
          setAllCustomers(res?.data);
         
          if(dropDowValue === "active"){
            let marketers=[]
            for(let i=0;i<res?.data?.length;i++){    
                if((res?.data[i]?.isActive) === true)
                marketers.push(res?.data[i])
            }
               
            setFilteredData(marketers) 
        } 
        else if(dropDowValue === "inactive"){ 
          let marketers=[]
          for(let i=0;i<res?.data.length;i++){    
              if((res?.data[i].isActive) === false)
              marketers.push(res?.data[i])
          }
             
          setFilteredData(marketers) 
        } 
        else{    
         
           setFilteredData(res?.data)  
        }      
        setShowLoader(false);
        })
        .catch((err) => {}); 
    }
  }, [searchCustomer,refreshOnActiveComp,startDate,endDate]);   
 useEffect(()=>{  

  document.body.style.overflow = 'hidden';
return ()=>{  

  document.body.style.overflow = 'auto';
}
 },[])
  const [selectedRows, setSelectedRows] = useState(0);  
  const handleClick = async () => {
    if (!pdfSelect) { 
      document.querySelector("#loader").style.display="flex"
        setPdfSelect(prev => !prev);

        const form = dataRef.current;
        const options = {
            margin: 0.1,
            filename: 'Bizchamp_Customers.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 1 },
            pagebreak: { mode: 'avoid-all', before: '#page2el' },
            jsPDF: { unit: 'in', format: 'a4', compressPdf: true, orientation: 'portrait' }
        };
        html2pdf()
        .set(options)
        .from(form)
        .save()
        .then((pdfBuffer) => { 
       //await generatePDFInWorker(form, options);
        setPdfSelect(prev => !prev);   
        document.querySelector("#loader").style.display="none"
      
        })
    }
};

  const tableActions = (rowData) => {
    return (
      <div className="w-full  flex flex-wrap items-center flex-row justify-around">
        <div>
          <EyeIcon onClick={()=>{ 
             navigate("/Profile",{state:{user:{id:rowData?.userId,simpleone:rowData?.id,role:"customer"}}})
          }} className="w-[16px] cursor-pointer   " />
        </div>
        <div>
          <PencilIcon className="w-[16px] cursor-pointer   " onClick={()=>{setUsersData(rowData); setEditDialogVisibility(prev=>!prev)}} />
        </div>
        <div>
          <TrashIcon onClick={()=>{ 
             setUsersData(rowData); setDeleteDialog(prev=>!prev)}} className="w-[16px] cursor-pointer " />
        </div>   
        <div onClick={()=>{  
           setSelectedId(rowData)
           setActiveDialog(true)
        }}>
           <i className="pi cursor-pointer pi-ban text-[16px] mt-[5px]"/>
        </div>
      </div>
    );
  };
  return ( 
     <div> 
       <div   id="loader">
          <div className="spinner"></div>
        </div>
    <div className="all-customers fixed overflow-y-auto h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] w-[calc(100vw-320px)] border rounded-2xl border-[#E6E9EE] shadow-sm    ml-1  bg-white ">
        
 
        
      <div className="flex flex-wrap justify-between items-center rounded-t-2xl  flex-row bg-main-color p-4 w-full font-poppins font-semibold text-[20px]">
        <h1>Customers</h1>
        <div>
       
          <XIcon
            onClick={() => {  
              setRenderTime(prev=>{
                prev=prev+1
              })
              setRefreshOnActive(prev=>!prev)
              setViewAllCustomers((prev) => !prev);
            }}
            className="w-[16px] inline cursor-pointer ml-4 h-[16px]"
          />
        </div>
      </div>
      <div className="flex flex-wrap flex-row p-4 pt-0 justify-evenly">
        <div className="ml-[10px] active rounded-full bg-category-search w-[300px] mt-2 items-center h-[40px]">
          <div className="flex-wrap flex flex-row justify-between h-[40px]">
            <div className="bg-custom-gradient text-white w-[100px] pl-10 pr-10  r rounded-l-2xl p-2 pt-2 right-0">
              <Search />
            </div>
            <input
              placeholder="Search Here"
              value={searchCustomer}
              onChange={(e) => {  
                setRenderHere(prev=>{ 
                  prev=prev+1
               })
                setSearchCustomer(e.target.value);      
                 
              }}
              className="border-none bg-transparent font-medium opacity-[0.6] pl-4 w-[calc(100%-100px)] font-poppins focus:outline-none "
            />
          </div>
        </div>
        <Dropdown
          placeholder="Active"
          arrowClosed={
            <ChevronDownIcon className="w-[25px]  h-[25px] absolute mt-[-25px] ml-[110px]" />
          }
          arrowOpen={
            <ChevronUpIcon className="w-[25px]  h-[25px] absolute mt-[-25px] ml-[110px]" />
          }
          options={[ 
             {label:"All",value:"All"},
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ]}
          style={{ color: "grey" }} 
          value={dropDowValue} 
          onChange={(e)=>{       
               if(e.value === "active"){
                let marketers=[]
                for(let i=0;i<allcustomers.length;i++){    
                    if((allcustomers[i].isActive) === true)
                    marketers.push(allcustomers[i])
                }
                   
                setFilteredData(marketers) 
            } 
            else if(e.value === "inactive"){ 
              let marketers=[]
              for(let i=0;i<allcustomers.length;i++){    
                  if((allcustomers[i].isActive) === false)
                  marketers.push(allcustomers[i])
              }
                 
              setFilteredData(marketers) 
            } 
            else{    
             
               setFilteredData(allcustomers)  
            }   
            setDropDownValue(e.value)
          }}
          arrowClassName=""
          menuClassName=" font-poppins font-medium w-[150px]  cursor-pointer p-2 text-[16px] rounded mt-4 z-10 bg-[#2DD683]"
          className="z-20 bg-[#2DD683]   h-[40px] p-2  cursor-pointer font-poppins font-medium text-white  w-[150px]  mt-2 rounded-2xl"
        /> 
         <div className=" border opacity-[0.7]  rounded-2xl flex flex-wrap flex-row justify-center gap-1 items-center pt-[2px] pl-2 pr-2 border-[#E6E9EE] w-[250px]  mt-2">  
      
      <DateRange/> 
 
   <Calendar value={startDate} onChange={(e)=>{  
     const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
     // Update the state with the formatted date
  
 const utcDate = selectedDate.utc();
    setStartDate(utcDate.toDate())
   }} placeholder="6 May 2023" className="p-0 w-[calc(100%-30px)]"/> 
   </div> 
   <div className=" border opacity-[0.7] rounded-2xl pl-2  pt-[2px] flex flex-wrap gap-1 flex-row justify-center items-center pr-2 border-[#E6E9EE] w-[250px] mt-2 ">  
      
      <DateRange/> 
      <Calendar value={endDate} onChange={(e)=>{  
     const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
     // Update the state with the formatted date
  
 const utcDate = selectedDate.utc();
    setEndDate(utcDate.toDate())
   }} placeholder="6 May 2023" className="p-0 w-[calc(100%-30px)]"/> 
   </div>
        <div className="w-[100px] cursor-pointer pt-2 flex flex-wrap flex-row mt-2 justify-around">
        <div onClick={()=>{    
            document.querySelector("#loader").style.display="flex"
     
              Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/Export`, {
                headers: { Authorization: `Bearer ${token}` },      
              
                  responseType: 'blob', // Set the response type to blob
                
                params: {
                  type: 4, 
                  Search:searchCustomer
                },
              })
                .then((response) => { 
                
                  const blob = new Blob([response.data]);
const url = window.URL.createObjectURL(blob);
const a = document.createElement('a');
a.href = url;
a.download = 'Customers.xlsx';
document.body.appendChild(a);
a.click();
window.URL.revokeObjectURL(url); 
document.querySelector("#loader").style.display="none"
     
                }).catch(err=>{ 
                  document.querySelector("#loader").style.display="none"
     
                })
           }}>
          <Excel /> 
                 </div>     
          <div onClick={()=>{  
                     if(!printSelect){
                      document.querySelector("#loader").style.display="flex"
     
                      setPrintSelect(prev=>!prev)
                      
                   const form = dataRef.current;  
                   const opt=  {
                    margin: .1,
                    filename: 'Bizchamp_Customers.pdf',
                    image: { type: 'jpeg', quality:1 },
                    html2canvas: { scale: 1 },
                    
                  jsPDF: { unit: 'in', format: 'a4',compressPdf:true, orientation: 'portrait' } ,
                     pagebreak: { mode: 'avoid-all', before: '#page2el' },   
                   }
                      html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
                      const pdfUrl = URL.createObjectURL(pdfBlob);
                      const iframe = document.createElement('iframe');
                      iframe.style.display = 'none';
                      iframe.src = pdfUrl;  
            
                      document.body.appendChild(iframe); 
                      
                 
                setTimeout(() => {
                        iframe.contentWindow.print(); 
                            document.querySelector("#loader").style.display="none"
                      }, 1000);  
                     setPrintSelect(prev=>!prev)});   
      
                    }
                   }}    >
          <Printer />   
           </div>
          <div onClick={handleClick} className=" pt-[2px] cursor-pointer"> 
          <Copy />
           </div>
        </div>
      </div>
      <div  
            className={`overflow-x-auto`}>
        <DataTable
          value={filteredData !== null ? filteredData : allcustomers}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          size="small" 
          resizableColumns
          paginator
          rows={10}
          rowsPerPageOptions={[25, 50]} 
          emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Customers Not Found"}
          style={{ backgroundColor: "white", minWidth: `800px` }}
          className={`table-scroll relative w-[99%] p-4 ml-2"}  all-customers   font-poppins `}
        >
          {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
          <Column
            header="Name"
            field="name" 
            
            body={(rowdata) => {
              return (
                <div className="flex flex-wrap flex-row items-center justify-left">
                <div className="rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[40px] self-center border border-[#E6E9EE] shadow-sm">
                  <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}  className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]" src={rowdata.image}   alt="branch image"/>
            
                  </div> 
            
                  <p className="ml-5">{rowdata.name}</p>
                </div>
              );
            }}
          ></Column>
          <Column 
              
          
            header="City" field="city.name"></Column>
          <Column 
               
          
           header="Id" field="userId"></Column>
          <Column    
           header="Mobile No" field="phone"></Column>
          <Column    
            header="Country" field="country.name"></Column>

          <Column    
           header="Qty" field="customerPlan.Qty"></Column>
          <Column    
           header="Price" field="customerPlan.price"></Column>
    
           <Column    
           header="Actions" field="action" body={tableActions}></Column>   
        
        </DataTable>
      </div>  
      <Dialog 
         visible={editDialogVisibility} 
          onHide={()=>{ 
            setEditDialogVisibility(false)
          }}
        ><UpdateUser setEditDialogVisibility={ setEditDialogVisibility} token={token} setRefreshOnActiveComp={setRefreshOnActiveComp} userData={userData}/> </Dialog>
      <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setActiveDialog((prev) => !prev);
          }}
          visible={activeDialog}
        >   
         { 
          
          activeLoader ? <PageLoader/> :undefined}
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To {selectedId?.isActive === false ? "Activate":"De-Activate"} the Customer? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes"
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/Users/UpdateStatus`,{ 
                        id:selectedId?.id, 
                        active:!(selectedId?.isActive)
                    }, 
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => { 
                      setRenderHere(prev=>{ 
                        prev=prev+1
                     })
                        setActiveLoader(false)   
                        setRefreshOnActiveComp(prev=>!prev) 
                         setActiveDialog(false)
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch(() => { 
                      
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setActiveDialog((prev) => !prev);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div> 
        </Dialog>   
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setDeleteDialog(false);
          }}
          visible={deleteDialog}
        >   
         { 
          
          activeLoader ? <PageLoader/> :undefined}
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete the Customer? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes"
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Users/Delete/${userData?.id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => { 
                      setRenderHere(prev=>{ 
                        prev=prev+1
                     })
                        setActiveLoader(false)  
                        setRefreshOnActiveComp(prev=>!prev) 
                         setDeleteDialog(false)
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch(() => { 
                      
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div> 
        </Dialog>   
        <div style={{display:`${pdfSelect || printSelect ? "block":"none"}`}} ref={dataRef} className="  w-[745px]">  
        <DataTable
          value={filteredData !== null ? filteredData : allcustomers}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          size="small" 
          ref={tableRef}
          resizableColumns
          paginator={pdfSelect || printSelect ? false:true}
          rows={10}
          rowsPerPageOptions={[25, 50]}   
           rowClassName={rowClassName}
          emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Customers Not Found"}
          style={{ backgroundColor: "white"}}
          className={`table-scroll  w-full all-customers   font-poppins `}
        >
          {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
          <Column
            header="Name"
            field="name" 
             className=" mt-0 text-[12px]"
            headerClassName={`${pdfSelect || printSelect ? "bg-[white] text-[14px]":""}`}
            body={(rowdata,options) => { 
           
              return (<> <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} className={`  text-[12px]  `}>{rowdata.name}</p>  
            
                   
                  </>
              );
            }}
          ></Column>
          <Column 
          
          
              headerClassName={`${pdfSelect || printSelect ? " bg-[white] text-[14px]":""}`}
            className=" mt-0 text-[12px]" 
            body={(rowData,options)=>{ 
              return  <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} >{rowData?.city.name}</p>  
            }}
            header="City" field="city.name"></Column>
          <Column 
          
          
               headerClassName={`${pdfSelect || printSelect ? " bg-[white] text-[14px]":""}`}
           className=" mt-0 text-[12px]" 
           body={(rowData,options)=>{ 
            return  <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} >{rowData?.userId}</p>  
          }}
           header="Id" field="userId"></Column>
          <Column 
          
            className=" mt-0 text-[12px]"   headerClassName={`${pdfSelect || printSelect ? " bg-[white] text-[14px]":""}`}
           header="Mobile No"  
           body={(rowData,options)=>{ 
            return  <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} >{rowData?.phone}</p>  
          }}
            field="phone"></Column>
          <Column   className=" mt-0 text-[12px]"   headerClassName={`${pdfSelect || printSelect ? " bg-[white] text-[14px]":""}`}
            header="Country"    body={(rowData,options)=>{ 
              return  <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} >{rowData?.country.name}</p>  
            }} field="country.name"></Column>
{ /*
          <Column   className=" mt-0 text-[12px]"   headerClassName={`${pdfSelect || printSelect ? "bg-white  text-[14px":""}`}
           header="Qty" field="customerPlan.Qty"></Column>  
            */}
          <Column   className=" mt-0 text-[12px]"   headerClassName={`${pdfSelect || printSelect ? "  bg-[white] text-[14px]":""}`}
           header="Price"    body={(rowData,options)=>{ 
            return  <p  style={{marginTop:`${options.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options.rowIndex === 370 || options.rowIndex === 400 || options?.rowIndex === 430 || options.rowIndex === 460 || options?.rowIndex === 490 || options?.rowIndex === 520 || options.rowIndex === 550 || options?.rowIndex === 580 || options?.rowIndex === 610 || options?.rowIndex === 640 || options?.rowIndex === 670 || options?.rowIndex === 700  ? "30px":""} `}} >{rowData?.customerPlan.price}</p>  
          }} field="customerPlan.price"></Column>
          {  
          /*
            pdfSelect || printSelect ?  <Column 
            className=" mt-0 text-[12px]"   headerClassName={`${pdfSelect || printSelect ? " bg-[white] text-[14px]":""}`}
          header="Actions" field="action" body={tableActions}></Column>  :undefined
            
         */ }
        </DataTable>  

        </div>
    </div> 
     </div>
  );
}

