import React from "react"  
import { useFormik } from "formik" 
import { InputText } from "primereact/inputtext" 
import { Dropdown } from "primereact/dropdown"
import * as Yup from "yup"   
 import { useState,useEffect } from "react"
import Axios from "axios" 
import {InputMask} from "primereact/inputmask" 
import { Button } from "primereact/button"
import CommonLoader from "../../../Components/Loader/Loader"
export default function UpdateUser({token,branch,setEditDialogVisibility,userData,setRefreshOnActive,setRefreshOnActiveComp}){   
  const [countryOptions,setCountryOptions]=useState([])  
   const [loader,setLoader]=useState(false)
  const [citiesOption,setCitiesOptions]=useState([])   
   const [imgurl,setImageUrl]=useState(null)
    const validationSchema = Yup.object().shape({
    })     
   
    
    const formik = useFormik({
        initialValues: {
          Id: userData?.id,   
          Name:userData?.name, 
          Image:userData?.image,  
          FatherName:userData?.fatherName,
          Phone:userData?.phone,
          Address:userData?.address,
          Cnic:userData?.cnic, 
          WhatsappNumber:userData?.whatsappNumber, 
          CityId:userData?.city?.id, 
          CountryId:userData?.country?.id
        },validationSchema, onSubmit: (values) => {    
        
              if(imgurl !== null){      
                 const formData=new FormData() 
                 Object.keys(formik.values).map(item=>{  
                   if(formik.values[item] !== undefined  && formik.values[item] !== null){
                   formData.append(`${item}`,formik.values[item]) 
                   }
                 })  
                  setLoader(true)
                Axios.post(`${process.env.REACT_APP_BASE_URL}/api/${branch ?"Branches":"Users"}/${branch?"Update":"UpdateInfo"}`,formData,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
                  setLoader(false)    
                   setTimeout(() => {
                    setRefreshOnActive(prev=>!prev) 
                    setEditDialogVisibility(false)
                   }, 500);
               
              //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
                // setUsersData(res?.data)
             }).catch(()=>{  
              setLoader(false)
              // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
            
             }) 
              } 
              else{ 
              
              const formData=new FormData() 
              Object.keys(formik.values).map(item=>{  
                if(item === "Image"){ 

                } 
                else{ 
                  if(formik.values[item] !== undefined && formik.values[item] !== null){
                formData.append(`${item}`,formik.values[item])  
                  }
                }
              })  
              setLoader(true)
              Axios.post(`${process.env.REACT_APP_BASE_URL}/api/${branch ?"Branches":"Users"}/${branch?"Update":"UpdateInfo"}`,formData,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
                setLoader(false)    
                setTimeout(() => {
                   if(setRefreshOnActive){
                 setRefreshOnActive(prev=>!prev)  
                   } 
                   else{ 
                    
                 setRefreshOnActiveComp(prev=>!prev)  
                   }
                 setEditDialogVisibility(false)
                }, 500);
                //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
                  // setUsersData(res?.data)
               }).catch(()=>{ 
                // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
                setLoader(false)
               }) 
              }
        },

          }) 
        useEffect(()=>{
          Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
            setCitiesOptions(res?.data)
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
          // setUsersData(res?.data)
       }).catch(()=>{ 
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      
       })
     
      },[formik.values.CountryId])
      useEffect(()=>{ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
       
             setCountryOptions(res?.data)
        
        }).catch(()=>{ 
         
        })
       },[])
     return(  
       <form onSubmit={formik.handleSubmit}>  
       
        <div className="flex flex-wrap flex-row w-full  text-[#AFAFAF] pt-0 p-4 justify-between">
       
          <div className="flex flex-row flex-wrap justify-center w-full ">
        <div   onClick={()=>{ 
                 let create=document.createElement("input") 
                 create.type="file" 
                  create.onchange=(e)=>{ 
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = () => {
                      setImageUrl(reader.result);
                    };
                    formik.setFieldValue("Image", e.target.files[0]);
                   
                  } 
                  create.click()
               }} className="w-[200px] cursor-pointer h-[200px] rounded-full overflow-hidden  flex flex flex-wrap  justify-center"> 
                {userData?.image || imgurl !== null ? <img onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }} className="transform w-full translate-y-[-50%] mt-[50%] " src={`${imgurl === null ? userData?.image:imgurl}`}/> 
                :<h1 className=" transform w-full text-center font-poppins fornt-medium translate-y-[-50%] mt-[50%]">Upload Image</h1>
              }
        </div>    
        </div>
     
        <div className="w-[49%]">
          <InputText
            name="Name"
            value={formik.values.Name}
            onChange={formik.handleChange}
            className=" mt-4 border pl-2 h-[40px] p-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
            placeholder="Name"
          />
          {formik.touched.Name && formik.errors.Name ? (
            <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
              {formik.errors.Name}
            </p>
          ) : null}
        </div>     
        { 
         userData?.fatherName ?
        <div className="w-[49%]">
          <InputText
            name="FatherName"
            value={formik.values.FatherName}
            onChange={formik.handleChange}
            className=" mt-4 border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
            placeholder="Father Name"
          />

          {formik.touched.FatherName && formik.errors.FatherName ? (
            <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
              {formik.errors.FatherName}
            </p>
          ) : null}
        </div> :undefined
}
       { userData?.cnic ? <div className="w-[49%]">
          <InputMask
            mask="99999-9999999-9"
            name="Cnic"
            value={formik.values.Cnic}
            onChange={formik.handleChange}
            className="mt-4  p-2 border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
            placeholder="Cnic"
          />
          {formik.touched.Cnic && formik.errors.Cnic ? (
            <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
              {formik.errors.Cnic}
            </p>
          ) : null}
        </div>  
        :undefined
}
        <div className="w-[49%] mt-4 flex  z-50 flex-wrap flex-row justify-between">
          <div className="w-[49%]">
            <Dropdown
              className="w-full border my-dropdown font-poppins font-medium  rounded-md  "
              placeholder="Country"
              onChange={formik.handleChange}
              optionValue="id"
              optionLabel="name"
              name="CountryId"
              value={formik.values.CountryId}
              options={countryOptions}
            />

            {formik.touched.CountryId && formik.errors.CountryId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.CountryId}
              </p>
            ) : null}
          </div>
          <div className="w-[49%] ">
            <Dropdown
              className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
              name="CityId"
              placeholder="City"
              onChange={formik.handleChange}
              optionValue="id"
              optionLabel="name"
              value={formik.values.CityId}
              options={citiesOption}
            />
            {formik.touched.CityId && formik.errors.CityId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.CityId}
              </p>
            ) : null}
          </div>   
          
        </div>    
         { 
         userData?.Phone ?
        <div className="w-[49%]">
            <InputText
              name="Phone"
              value={formik.values.Phone}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.Phone && formik.errors.Phone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Phone}
              </p>
            ) : null}
          </div>   
          :undefined
}         {userData?.address ? <div className="w-[49%]">
            <InputText
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.Address && formik.errors.Address ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>  :undefined
}
         {userData?.WhatsappNumber ? <div className="w-[49%]">
            <InputText
              name="WhatsappNumber"
              value={formik.values.WhatsappNumber}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.WhatsappNumber && formik.errors.WhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>  :undefined
}
           <div className="flex flex-row flex-wrap justify-center w-full"> 
                   <Button  
            
            type="submit"
            disabled={loader}
            className="w-[30%] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loader ? "":"Update"}`}   
            iconPos="center"
            icon={loader? <CommonLoader/>:""}
          /> 
            </div>  
             
        </div> 
         </form>
     )
}