import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";  
export default function PageLoader(){  
     return( 
        <div className="flex flex-wrap bg-[rgba(255,255,255,.4)] fixed z-50 fixed w-[100vw] left-0 top-0 h-[100vh] mt-4 items-center justify-center "> 
        
          <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
      
         </div>
     )
     
}