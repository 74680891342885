import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import * as signalR from "@microsoft/signalr";
import { InputText } from "primereact/inputtext";
import Loader from "../../Loaders";
import CommonLoaderBlue from "../../../../../Components/Loader/LoaderBlue";
export default function CurrentConnected({ currentActiveUser, admin }) {
  let ref = useRef(null);
  const [loaderShow, setLoaderShow] = useState(true);
  const [messageToSend, setMessageToSend] = useState("");
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;
  const [messages, setMessages] = useState([]);
  const silentReload = () => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Conversations/Messages/${currentActiveUser?.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        //setCitiesList(res?.data)
        setMessages(res?.data);

        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  };
  useEffect(() => { 
    setMessages([])
    setLoaderShow(true);
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Conversations/Messages/${currentActiveUser?.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        //setCitiesList(res?.data)
        setMessages(res?.data);
        setLoaderShow(false);   
        setTimeout(()=>{ 
       if(ref !== null){
        try{
          ref.current.scrollTop = ref.current.scrollHeight;
          ref.current.scrollIntoView({ behavior: "smooth" });  
        } 
        catch(err){ 
          
        } 
       
       }
        },2000)
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        setLoaderShow(false);
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });  
      let interval = setInterval(() => {
        Axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/Conversations/Messages/${currentActiveUser?.id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
          .then((res) => {
            //setCitiesList(res?.data)
            setMessages(res?.data);
            setLoaderShow(false);
            //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
            // setUsersData(res?.data)
          })
          .catch(() => {
            setLoaderShow(false);
            // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
          });
      }, 4000);
      return () => {
        clearInterval(interval);
      };

  }, [currentActiveUser]);    
  

  {
    /* useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()  
      .withUrl(`${process.env.REACT_APP_BASE_URL}/chatHub`).build();   
       setInterval(()=>{ 
       },1000)
      connection.onclose(() => {
        // You can add additional logic here if needed
      });
    connection.on("ReceiveMessage", (data) => {    

       if((data.conversationId === currentActiveUser.id && data.senderId === currentActiveUser?.userId)){        
        const newData = data ? data : [];
        setMessages(prev => [...prev, newData]);     
       silentReload()
       } 
       else{ 
       }
      // Handle the received data here, for example, update state
    });

    // Start the connection
    connection.start()
      .then(() => {return} )
      .catch((err) => console.error("SignalR connection error: ", err));

    // Cleanup function to stop the connection when component unmounts
    return () => {
      connection.stop();
    };
  }, []);   */
  }

  function formatDateToCustomString(isoDateString) {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  return (
    <div className="w-full rounded-2xl font-poppins border bg-white border-[#E6E9EE] overflow-hidden">
      <div className="w-full    bg-[#D9D9D9] flex flex-wrap flex-row justify-between items-center">
        <div className="w-[70%]  bg-[#D9D9D9]  flex flex flex-wrap flex-row items-center items-center  justify-between p-2">
          <div className="w-[45px] h-[45px]  overflow-hidden rounded-full">
            <img
              src={currentActiveUser?.image}
              className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
            />{" "}
          </div>
          <div className="w-[calc(100%-60px)] ">
            <div>
              <h1 className={`inline font-semibold`}>
                {currentActiveUser?.name}
              </h1>{" "}
              <>
                {currentActiveUser?.name ? (
                  <i className=" text-[10px] absolute  text-white w-[20px] h-[20px] ml-2  rounded-full pt-[5px]  pl-[4px] pr-[4px] bg-gradient-to-r from-[#4AB1FF] to-[#3363FF] inline pi pi-check" />
                ) : undefined}
              </>
            </div>
            <h1>
              {currentActiveUser?.lastMessageTime
                ? `Last Seen ${
                    currentActiveUser?.lastMessageTime
                      ? formatDateToCustomString(
                          currentActiveUser?.lastMessageTime
                        )
                      : ""
                  }`
                : ""}
            </h1>
          </div>
        </div>
       {/* <div className="flex w-[30%] flex-row flex-wrap justify-around">
          <i className="pi pi-video" />
          <i className=" pi pi-phone" />
          <i className="pi pi-ellipsis-v" /> 
             
        </div> 
        */}
        <div
          ref={ref}
          className="w-full max-h-[62vh] overflow-y-auto overflow-x-hidden bg-white "
        >
          {loaderShow ? (
            <div className="flex flex-wrap flex-row mt-10  mb-10 items-center justify-center">
              <CommonLoaderBlue />
            </div>
          ) : (
            <>
              {messages?.map((item, index) => {
                return (
                  <div
                    className={`${
                      item?.senderId === currentActiveUser?.userId
                        ? "justify-left"
                        : "justify-end"
                    } flex flex-row flex-wrap w-[100%] m-2`}
                  >
                    {item?.senderId === currentActiveUser?.userId ? (
                      <>
                        <div className="w-[85%] flex flex-wrap flex-row  justify-between">
                          {index === 0 ? (
                            <div className="w-[45px] h-[45px]  overflow-hidden rounded-full">
                              <img
                                src={currentActiveUser?.image}
                                className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
                              />{" "}
                            </div>
                          ) : messages[index - 1]?.senderId ===
                            currentActiveUser?.userId ? (
                            <div className="w-[45px] h-[45px]  overflow-hidden rounded-full"></div>
                          ) : (
                            <div className="w-[45px] h-[45px]  overflow-hidden rounded-full">
                              <img
                                src={currentActiveUser?.image}
                                className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
                              />{" "}
                            </div>
                          )}

                          <div className="w-[calc(100%-60px)]">
                            <h1 className=" mt-2 p-3 rounded-r-full text-black bg-[#F1AA21]">
                              {item?.text}
                            </h1>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" flex flex-wrap flex-row w-[70%] justify-between mr-4">
                          <div className="w-[calc(100%-60px)]">
                            <h1 className=" mt-2 p-3 rounded-l-full rounded-t-full  text-white  bg-[black]">
                              {item?.text}
                            </h1>
                          </div>
                          {index === 0 ? (
                            <div className="w-[45px] h-[45px]  overflow-hidden rounded-full">
                              <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                                src={parseLoginRes?.userInfo?.image}
                                className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
                              />{" "}
                            </div>
                          ) : messages[index - 1]?.senderId ===
                            currentActiveUser?.userId ? (
                            <div className="w-[45px] h-[45px]  overflow-hidden rounded-full">
                              <img  
                                onLoad={(event)=>{ 
                                  const { naturalWidth, naturalHeight } = event.target; 
                                   if(naturalWidth > naturalHeight){ 
                                    event.target.style="width:auto;height:100%"  
                                     
                                   } 
                                   else{ 
                                    event.target.style="width:100%;height:auto"  
                                 
                                   }
                        
                                 }}
                                src={parseLoginRes?.userInfo?.image}
                                className="transform translate-x-[-50%] translate-y-[-50%] mt-[50%] ml-[50%] w-[full] h-auto"
                              />{" "}
                            </div>
                          ) : undefined}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          )}
          <div className="p-2 text-[#A6ADB5] flex border-t border-[#EEEEEE] flex-row flex-wrap justify-between items-center">
            {/*<i className="pi  pi-paperclip" />  */}
            <InputText
              value={messageToSend}
              onChange={(e) => {
                setMessageToSend(e.target.value);
              }}
              placeholder="Type a message"
              className="w-[calc(100%-60px)] p-1 h-[40px]"
            />
           {/* <i className="pi pi-microphone" />   */}
            <i
              className="pi pi-send cursor-pointer"
              onClick={() => {
                Axios.post(
                  `${process.env.REACT_APP_BASE_URL}/api/Conversations/Send`,
                  { conversationId: currentActiveUser.id, text: messageToSend },
                  { headers: { Authorization: `Bearer ${token}` } }
                )
                  .then((res) => {
                    const newData = res.data ? res.data : [];
                    setMessages((prev) => [...prev, newData]);
                    setMessageToSend("");
                  })
                  .catch((err) => {});
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
