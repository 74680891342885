import React from "react"; 
export const ExpenseSvg=()=>{ 
    return(  
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.13397 4C6.51887 3.33333 7.48113 3.33333 7.86603 4L10.4641 8.5C10.849 9.16667 10.3679 10 9.59808 10H4.40192C3.63212 10 3.151 9.16667 3.5359 8.5L6.13397 4Z" fill="white"/>
<path d="M7 10L7 19" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path opacity="0.5" d="M1 1L13 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
</svg>

    )
}