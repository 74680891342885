import Wallet from "./components/mainside/wallet/wallet"; 
import UpcomingRetailSelles from "./components/mainside/upcoming-retail-selles/upcoming-retail-selles";
import Quicktransfer from "./components/mainside/quick-transfer/quick-transfer";
import "./styles.css"    
import { useEffect, useState } from "react";
import Axios  from "axios";
import Stats from "./components/maindetail/stats/stats";
import BusinessFlow from "./components/maindetail/business_flow/business_flow";
import AllDetails from "./components/maindetail/all_details/all_details";
import AllExpenses from "./components/maindetail/all_expenses/all_expenses";
import TransactionHistory from "./components/maindetail/transaction_history/transaction_history";
function Home() {  
  const [dashboardData,setDashboardData]=useState("")  
  const [loader,setShowLoader]=useState(true)
  let parseLoginRes=JSON.parse(localStorage.getItem("userData")) 
  let token=parseLoginRes.token  
  useEffect(()=>{     
   Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Dashboards/Admin`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
          
     setDashboardData(res?.data) 
     setShowLoader(false)
   }).catch(()=>{ 
     
   })
  },[])  
  return (
    <div className="maindashboard mt-[0px] mt-[140px] width716:mt-[-80px] width910:mt-[90px]  width1280:mt-[20px] "> 
            <div className="flex flex-wrap flex-row justify-around ">  
            {/*
                 <div className="sidemenue  width910:w-[35%] width500:w-[100%]">      
                    <div className="mainside pt-4 width910:w-[90%]  width500:w-[90%]"> 
                    <Wallet/> 
                     <UpcomingRetailSelles/> 
                      <Quicktransfer/>
                     </div>
                   </div> */}
                  <div className="detailmenu  width910:w-[100%] width500:w-[100%] width500:mt-[220px]  width910:mt-[0px]  "> 
                        <div className="maindetail pt-4">   
                          <div className="flex flex-row  flex-wrap justify-around"> 
                            <div className="w-[100%]   pl-5 width1280:pl-0  width1280:w-[30%] ">
                            <Wallet dashboardData={dashboardData}/> 
                            </div> 
                             <div className="w-[100%] width1280:w-[67.5%] ">
                         <Stats dashboardData={dashboardData} />    
                         </div>  
                          </div>
                          <div className="flex flex-wrap justify-around mt-4 flex-row ">  
                          <div className="w-[49%] pl-5 width1280:pl-0 width1280:w-[30%]">
                             <Quicktransfer/> 
                             </div>
                             <div className="w-[49%] pr-5 width1280:pr-0 width1280:w-[33%]">
                             <UpcomingRetailSelles loader={loader} dashboardData={dashboardData}/>  
                              </div> 
                  
                             <div className="w-[100%] p-5 width1280:p-0 width1280:w-[33%]">
                            <AllDetails loader={loader}  dashboardData={dashboardData}/>  
                            </div>
                            <AllExpenses/> 
                            <TransactionHistory loader={loader} dashboardData={dashboardData}/> 
                            <BusinessFlow/> 
                          </div>
                          </div>
                  </div>

            </div>
    </div>
  );
}

export default Home;
