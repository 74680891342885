import React ,{useState,useEffect} from "react";  
import { Chart } from 'react-google-charts';   
import Axios from "axios";
import { Dropdown } from "primereact/dropdown"; 
import CommonLoaderBlue from "../../../../../Components/Loader/LoaderBlue";
export default function BusinessFlow() {    
   const [showLoader,setShowLoader]=useState(true)
  const [data,setData] =useState([
    ['Month', 'PKR'],
    ['January', 0],
    ['February', 3],
    ['March', 4],
    ['April', 5],
  ])
 const [months,setMonths]=useState(4) 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token=parseLoginRes.token  
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Expense/Graph`,
      { headers: { Authorization: `Bearer ${token}` },params:{ 
         NoOfMonths:months 
          
      } }
    )
      .then((res) => {
      let resdata=res?.data;  
      setData(resdata)
     // setData2(resdata?.data) 
     setShowLoader(false)
        })
      .catch(() => {
      setShowLoader(false)
      });
  }, [months]);
   return (  
    <div className="shadow-sm mt-4 m-5  width910:w-[100%] width500:w-[90%] width1130:m-0 width1130:mt-4 width1130:w-[49%]     p-2 rounded-2xl   border border-[#E6E9EE] ">   
    <div className="flex w-full flex-wrap justify-between items-center flex-row">
    <h1 className="business-flow-header font-bold self-center font-poppins font-semibold text-[20px] p-4  w-[25%]" >Business Flow </h1>  
    <Dropdown  
     value={months} 
      optionValue="value" 
      optionLabel="label"    
      placeholder="6 Months"
      onChange={(e)=>{ 
        setMonths(e.value)
      }}
      options={[{label:"1",value:1},{label:"2",value:2},{label:"3",value:3},{label:"4",value:4},{label:"5",value:5},{label:"6",value:6},{label:"7",value:7}]}
    className=" w-[100px] h-[40px] font-poppins my-dropdown border text-[#AFAFAF] rounded-md border-[#E6E9EE] text-[#AFAFAF]"/> 
      
   
     </div>
    <div 
    className="self-center w-[calc(100vw-300px)] " style={{width:"100%",height:"200px"}}>
    { showLoader ?  <div className="mt-[100px] flex flex-wrap flex-row justify-center items-center"><CommonLoaderBlue/></div>:<Chart  
           
     
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
         
          vAxis: { gridlines: {
            color: 'transparent',    
           
        
          }, 
          viewWindow: {
            min: 0, // Set minimum value for y-axis
             // Optional: Set maximum value for y-axis if needed
          },  
           minValue:0
        },     
          areaOpacity: 0.3, // Adjust the opacity of the area under the line
         
          hAxis:{gridlines: { count: 0 }      ,      baselineColor: 'transparent', // Set baseline color to be transparent
        },
          legend: 'none',
          curveType: 'function', // Use 'function' for a smooth line
          colors: ['#FF5733'], // Specify the custom line color here
          fontName: 'Poppins, sans-serif', // Set the desired font-family
     
        }}
      />    
}
      </div>
     </div>
   )
}