import React, { useState } from "react"  
import {Dialog} from "primereact/dialog"
import { BellIcon, PlusIcon } from '@heroicons/react/outline'; 
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";
import AllUpcomingSells from "./dialogs/All_Upcoming_selles";

 function formatDate(dateString) {
   const date = new Date(dateString);

   // Format the date to "MMM DD, YY" format
   const formattedDate = date.toLocaleDateString('en-US', {
     year: '2-digit',
     month: 'short',
     day: '2-digit'
   });
 
   return formattedDate;
 } 
export default function UpcomingRetailSelles({dashboardData,loader}){ 
   const [allUpcoming,setAllUpcoming]=useState(false)   
   
   return( 
        <div className=" p-2 rounded-2xl h-[352px]  pb-[20px] border border-[#E6E9EE]" >    
             <div className="mt-4 w-[100%] flex flex-wrap flex-row justify-between"> 
          <h1 className="font-poppins text-header font-semibold text-[20px]">Upcoming Retail Selles</h1>  
         
           <div className="border-notification-upcomingselles   w-[20px] h-[20px]  border border-solid border-1 rounded-full"> 
            <BellIcon className="text-notification-upcomingselles-icon"/>  
             <p className="absolute font-poppins mt-[-35px] text-notification rounded-[50%] w-[20px] h-[20px] p-1 ml-[8px] bg-notification text-center color-notification">{dashboardData?.totalUpcomingReturnSales}</p>
           </div>   
           { loader ? <div className="absolute left-[50%] mt-[135px]  transform translate-x-[-70%]"> <CommonLoaderBlue/> </div> :<>     
           <div className="flex flex-wrap relative justify-around mt-[5px]  flex-row w-[100%]"> 
                { 
                 (dashboardData?.returnSales)?.map(item=>( 
                    <div className="mt-4 font-poppins w-[100%] flex flex-wrap flex-row justify-around ">   
                          <div className="rounded-[50%] bg-seller-avatar w-[40px] h-[40px] self-center border border-[#E6E9EE] rounded-full overflow-hidden shadow-sm">   
                          <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }} className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]" src={item?.customer?.image}   alt="branch image"/>
            
                                </div>
                         <div className="text-left pl-1 w-[150px]">  
                         
                         <h1 className="font-medium text-[16px]">{item?.customer?.name}</h1>   
                          <p className="text-[#AFAFAF]">{formatDate(item?.createdAt)}</p>
                         </div>
                         <h1 className=" w-[140px] self-center font-poppins text-right text-seller-amount font-semibold"><PlusIcon className="w-[15px] inline mt-[-2px] mr-[5px]"/>{item.amount}</h1> 
                    </div>
                 ))
                } 
                   <p   onClick={()=>{ 
                     setAllUpcoming(prev=>!prev)
                   }} className="w-full cursor-pointer absolute mt-[230px]  font-poppins text-white text-center mt-4   bg-custom-gradient p-2 rounded-lg font-medium ">  
                View All
                </p>
           </div> 
           </>
}
          </div>   
   
                <Dialog visible={allUpcoming} onHide={()=>{ 
                   setAllUpcoming(false)
                }}  headerClassName="font-poppins font-medium" header="All UpComing Retail Sells"> 
                       <AllUpcomingSells/>
                     
                     </Dialog>
        </div>
     )
}