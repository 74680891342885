import React from "react";
export default function UsersDashboard({usersData}){   
const Dashboard=[{ 
  label:"Managers", 
  count:usersData.totalManagers
},{ 
    label:"Branches", 
    count:usersData.totalBranches
  },{ 
    label:"Admins", 
    count:usersData.totalAdmin,
  },{ 
    label:"Customers", 
    count:usersData.totalCustomers
  },{ 
    label:"Marketers", 
    count:usersData.totalMarketers
  },     
] 
     return( 
         <div className=" mt-[160px] width716:mt-[90px] width910:mt-[70px]  width1240:mt-[20px]  relative flex flex-wrap flex-row justify-left  lg:justify-evenly p-4 pl-0 "> 
          { 
           Dashboard.map(item=>(
            <div className="w-[200px] ml-5 lg:ml-0 mt-2 rounded-lg pl-4 pr-0 bg-custom-gradient items-center flex flex-wrap flex-row justify-between">  
               <h1 className="text-white font-semibold ">{item.label}</h1>
                 <h2 className="text-white bg-black pl-4 font-semibold  rounded-r-lg pr-4 pt-[6px] h-[40px]">{item.count}</h2>
            </div> 
           ))
          } 
         </div> 
       
         
     )
}