import React,{useEffect, useState} from "react";
import {
  Dash,
  Dashboard,
  People,
  Purchase,
  Reports,
} from "../Svg";
import RenderMenuItem from "./components/RenderMenuitems"; 
{/*{
    label: "Cities",
    svg: Reports, 
    
    active:false, 
    subMenu: [
      {
        label: "List Cities",
        navigator: "/Cities-List",
        svg: Dash,
      },
      {
        label: "Add City",

        navigator: "/Add-City",
        svg: Dash,
      },
    ],
  },
  {
    label: "Roles",
    svg: Reports, 
    
    active:false, 
    subMenu: [
      {
        label: "List Roles",
        navigator: "/Roles-List",
        svg: Dash,
      },
      {
        label: "Add Role",

        navigator: "/Add-Role",
        svg: Dash,
      },
    ],
  },           */}

export default function CommonMenubar({setFormToOpen,refreshMenu}) {            
  const  [items,setItems] = useState( [
    {
      label: "Overview",
      svg: Dashboard,    
      active:window.location.pathname === "/" ? true:false, 
      navigator:"/"
    },
    {
      label: "Users",
      svg: People,  
      active:window.location.pathname === "/Users" ? true:false, 
      navigator:"/Users",
      SubifutureMenu: [
        {
          label: "Customers",
          navigator: "/Customer-List",
          svg: Dash,
        },
        {
          label: "Add Customers",
  
          navigator: "/Add-Customer ",
          svg: Dash,
        },
        {
          label: "Marketers",
          navigator: "/Marketer-List ",
          svg: Dash,
        },
        {
          label: "Add Marketer",
          navigator: "/Add-Marketer ",
          svg: Dash,
        },
        {
          label: "Employees",
          navigator: "/User-List ",
          svg: Dash,
        },
        {
          label: "Add Employee",
          navigator: "/Add-User ",
          svg: Dash,
        },
      ],
    },
    {
      label: "Expenses",
      svg: Purchase, 
      navigator: "/Expenses",
      active:window.location.pathname === "/Expenses" ? true:false, 
      subifMenu: [
        {
          label: "List Expenses",
          navigator: "/Purchases-List",
          svg: Dash,
        },
        {
          label: "Add Expense",
  
          navigator: "/Add-Purchase",
          svg: Dash,
        },
      ],
    },
    {
      label: "Cities",
      svg: Reports,  
       navigator:"/Cities",
            active:window.location.pathname === "/Cities" ? true:false, 
      subifMenu: [
        {
          label: "List Branches",
          navigator: "/Branches-List",
          svg: Dash,
        },
        {
          label: "Add Branch",
  
          navigator: "/Add-Branch",
          svg: Dash,
        },
      ],
    },
    {
      label: "Plans & Offers",
      svg: Reports, 
      navigator:"/Plans-Offers",
      active:window.location.pathname === "/Plans-Offers" ? true:false, 
      subifMenu: [
        {
          label: "List Plans",
          navigator: "/Plans-List",
          svg: Dash,
        },
        {
          label: "Add Plan",
  
          navigator: "/Add-Plan",
          svg: Dash,
        },
      ],
    },
    {
      label: "Monthly Pay",
      svg: Reports, 
      navigator:"/Monthly-Pay",
      active:window.location.pathname === "/Monthly-Pay" ? true:false, 
      subifMenu: [
        {
          label: "List Invoices",
          navigator: "/Salaries-List",
          svg: Dash,
        },
        {
          label: "Add Invoice",
  
          navigator: "/Add-Salary",
          svg: Dash,
        },
      ],
    },
    {
      label: "Chat", 
      svg: Reports,  
      navigator:"/Chat",
      active:window.location.pathname === "/Chat" ? true:false, 
      subifMenu: [
        {
          label: "Connected People",
          navigator: "/Chats",
          svg: Dash,
        },
       
      ],
    }, 
    {
      label: "Support", 
      svg: Reports,  
        navigator:"/Support",
      active:window.location.pathname === "/Support" ? true:false, 
      subifMenu: [
        {
          label: "Connected People",
          navigator: "/Chats",
          svg: Dash,
        },
       
      ],
    },
  ])   
    useEffect(()=>{ 
     const filtered = items.filter((page) => {
      if(page.navigator === window.location.pathname){ 
        page.active=true
      return  page
      } 
      else{ 
          page.active=false 
          return page
      }}
      );  
      setItems(filtered)
    },[refreshMenu])
  useEffect(()=>{
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let role = parseLoginRes?.role;   
    if(role === "Admin") { 
      let newitems=[]
      for(let i=0;i<items.length;i++){  
        
         
        newitems.push(items[i])
         
     }
      setItems(newitems)
    } 
    else if(role === "Marketer"){ 
       let newitems=[]
        for(let i=0;i<items.length;i++){  
          
           if(items[i].label === "Users"){  
            items[i].label = "Network" 
             items[i].navigator="Network"
                newitems.push(items[i])
           } 
           else if((items[i].label === "Plans & Offers") || (items[i].label === "Overview") || (items[i].label === "Support") || (items[i].label === "Chat")){ 
          newitems.push(items[i])
           }
       }
        setItems(newitems)
    }
  },[])
  
  const [refresh,setRefresh]=useState(false)
  return (
    <div className="w-[300px]">
    
      <div
        className={`  fixed top-0 menubar-scrollbar   transition-left duration-300 left-0 ease-in-out z-20 bg-custom-gradient h-[100vh] p-2 card overflow-y-auto rounded shadow-md w-[inherit]`}
      >
        <div className="mt-4 flex flex-wrap justify-between flex-row items-center">
        <img
                src="./bixlogindetail.png"
                className="  w-[65%] h-[auto] ml-[50%] transform translate-x-[-50%]" 
                alt="BizChamp logo"
              />
        </div>
        <div className="mt-4 w-[80%]">
          {items.map((item) => (<RenderMenuItem setFormToOpen={setFormToOpen} setRefresh={setRefresh}  allitems={items} item={item}/>))}
        </div>
      </div>
    </div>
  );
}
