import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";  
import Axios  from "axios";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
export default function Contact(){   
    const [marketer,setMarketer]=useState(false)  
    let toast=useRef()
    const [customer,setCustomer]=useState(true)    
    let parseLoginRes = JSON.parse(localStorage.getItem("userData")); 
    let token = parseLoginRes.token; 
    const [pdfSelect,setPdfSelect]=useState(false)   
    const [userList,setUserList]=useState([])
    const [printSelect,setPrintSelect]=useState(false)
    const [loader,setLoader]=useState(true) 
     const [admins,setAdmins]=useState(false)
    const [employer,setEmployer]=useState(false)  
     useEffect(()=>{  
        
     setUserList([]) 
setLoader(true)
        
     if(marketer){ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              type: 6,
              Search: "",
            },
          })
            .then((res) => {    
              setLoader(false)  
              setUserList(res?.data);   }).catch(err=>{ 
             
              })
     } 
     else if(customer){ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              type: 4,
              Search: "",
            },
          })
            .then((res) => {      
                
              setLoader(false) 
              setUserList(res?.data);   }).catch(err=>{ 

              })
     } 
     else if(employer){ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              type: 5,
              Search: "",
            },
          })
            .then((res) => {  
                
              setLoader(false)     
              setUserList(res?.data);   }).catch(err=>{ 

              })
     }  
      else if(admins){ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Users/List`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            type: 1,
            Search: "",
          },
        })
          .then((res) => {  
              
            setLoader(false)     
            setUserList(res?.data);   }).catch(err=>{ 

            })
      }
     },[marketer,customer,employer,admins])
     return(  
        <div className="w-full rounded-t-2xl border p-4 border-[#E6E9EE] overflow-hidden"> 
          <div className="flex flex-wrap flex-row justify-left gap-4"> 
            <Button onClick={()=>{ 
                 setCustomer(true) 
                 setMarketer(false) 
                 setEmployer(false)
                 setAdmins(false)
            }} label="Customers" className={`${customer  ? "bg-main-color text-white":"border-main-color text-main-color"} p-1 pl-2 pr-2`}   /> 
            
            <Button  onClick={()=>{ 
                 setCustomer(false) 
                 setMarketer(true) 
                 setAdmins(false)
                 setEmployer(false)
            }} label="Marketers" className={`${marketer  ? "bg-main-color text-white":"border-main-color text-main-color"} p-1 pl-2 pr-2`}   />  
             { 
              JSON.parse(localStorage.getItem("userData")).role !== "Marketer" ? <Button  onClick={()=>{ 
                 setCustomer(false) 
                 setMarketer(false) 
                  setAdmins(false) 
                 setEmployer(true)
            }} label="Employee" className={`${employer  ? "bg-main-color text-white":"border-main-color text-main-color"} p-1 pl-2 pr-2`}   />  :   
            <Button  onClick={()=>{ 
              setCustomer(false) 
              setMarketer(false)   
               setEmployer(false) 
              setAdmins(true) 
         }} label="Admins" className={`${admins  ? "bg-main-color text-white":"border-main-color text-main-color"} p-1 pl-2 pr-2`}   />
          }
          </div>
         
         
        <div className="w-full max-h-[62vh] tablelist mt-4 overflow-y-auto">
         
         <DataTable  
     
     value={userList}
    // onSelectionChange={(e) => setSelectedRows(e.value)}
     size="small"
     resizableColumns
     paginator
     rows={10}
     rowsPerPageOptions={[25, 50]} 
     
     emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:`${customer && userList.length === 0  ? "Customers  Not Found":marketer && userList.length === 0 ? "Marketers Not Found": employer && userList.length === 0 ? "Employees Not Found":admins && userList.length === 0 ? "Admins Not Found":""}`}
     style={{ backgroundColor: "white", minWidth: "500px" }}
     className=" relative w-[99%] p-4 ml-2  all-customers  font-poppins "
   >
     {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
     <Column
       header="Name"  
       field="name"
       body={(rowdata) => {
         return (
           <div className="flex flex-wrap flex-row items-center justify-left">
             {  pdfSelect || printSelect ? "":  <div className={`rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[40px] self-center border border-[#E6E9EE] shadow-sm ${pdfSelect || printSelect ? "mt-[20px]":""}`}>  
             <img className={`transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%] `} src={rowdata.image}   alt="branch image"/>
         
              </div> 
       }
             <p className={` ${pdfSelect || printSelect ? "":"ml-5"} `}>{rowdata.name}</p>
           </div>
         );
       }}
     ></Column> 
      <Column header="Id" 
       headerClassName={`${pdfSelect || printSelect ? "text-[16px] mt-[-10px]  pb-6":""}`} field="userId"></Column>
       <Column header="Action"  body={(rowData)=>{ 
        return( 
             <Button label="Hi" onClick={()=>{ 
                Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Conversations/Initiate`,{ 
                    userId:rowData?.id, 
                    text:"Hi"
                }, { 
                     
                    headers: { Authorization: `Bearer ${token}` },
                  }).then(res=>{ 
                }).catch(err=>{ 
             
                  })
             }} className=" bg-main-color text-white rounded-full w-[40px] h-[40px] p-2"/>
        )
       }}></Column>
   </DataTable> 
         </div> 
         <Toast ref={toast} />
          </div>
     )
}