import React from "react";
import { ArrowUpIcon } from "@heroicons/react/solid";
import { ArrowDownIcon } from "@heroicons/react/solid";
const statstoshow = [
  
  {
    label: "Total Earning",
    amount: "123,320 PKR",
    background: "#FED142",
    buttonbg: "#D8FFEC",
    buttoncolor: "#2DD683",
    bgdecrease: "#FFCDCF",
    decreaseColor: "#E74A51",
  },
  {
    label: "Total Expense",
    amount: "123,320 PKR",
    background: "#FA8B3A",
    buttonbg: "#D8FFEC",
    buttoncolor: "#2DD683",
    bgdecrease: "#FFCDCF",
    decreaseColor: "#E74A51", 
    mt:9
  },
  {
    label: "Total Rent",
    amount: "123,320 PKR", 
    mt:9,
    background: "#E74A51",
    buttonbg: "#D8FFEC",
    buttoncolor: "#2DD683",
    bgdecrease: "#FFCDCF",
    decreaseColor: "#E74A51",
  },
];
export default function Stats() {
  return (
    <div className="flex flex-wrap flex-row   justify-around  w-[100%]">
      {statstoshow.map((item) => (
        <div
          className={`mt-2 w-[33%] h-[110px] items-center relative shadow-sm rounded-lg flex flex-wrap ${
            item.buttoncolor ? "justify-left" : "justify-left"
          } flex-row ${item?.mt?`mt-[13px]`:""}`}
        >
          <div
            style={{ background: `${item.background}` }}
            className={`bg-[${item.background}]  w-[10px] h-[100%] rounded-l-lg`}
          ></div>
          <div className="p-4 font-poppins font-medium w-[calc(100%-10px)] ">
            <p className="text-stat-color">{item.label}</p>
            <h1 className="font-semibold text-[20px]">{item.amount}</h1>
          </div>
          
        </div>
      ))}
    </div>
  );
}
