  import React, { useState, useEffect,useRef } from "react";
import * as Yup from "yup";  

import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown"; 
import { Button } from "primereact/button"; 
import {Toast} from "primereact/toast" 
import Axios from "axios";  
import CommonLoader from "../../../../../Components/Loader/Loader";
export default function AddExpense({setRefresh,setRefreshWhenAddPurchase}){  
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [expenseCategories,setExpenseCategories]=useState([])
  const [loaderShow,setShowLoader]=useState(false) 
  const [branchesData, setBranchesData] = useState([]);
  const toast=useRef(null)
    const validationSchema = Yup.object().shape({
        description:Yup.string().required("Description Is Required"),
         amount:Yup.string().required("Amount Is Required"), 
         expenseCategoryId:Yup.string().required("Category Is Required"),
          branchId:Yup.string().required("Branch Is Required"),
         
     
     
       })     
       useEffect(()=>{ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/ExpenseCategories/List`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            setExpenseCategories(res?.data);
          })
          .catch(() => {
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
      },[])
   const formik = useFormik({
       initialValues: {
         description:"", 
         amount:"", 
         expenseCategoryId:"", 
         branchId:"", 
        
       },
       validationSchema,
       onSubmit: (values,actions) => {  
        const dataTosend={...values,amount:parseInt(values.amount)}
        setShowLoader(true) 
         Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Expense/Create`,dataTosend,{
          headers: { Authorization: `Bearer ${token}` },
        }).then(res=>{  
          setRefreshWhenAddPurchase(true)
              setShowLoader(false)   
              
          setRefresh(prev=>!prev)
                actions.resetForm()
        }).catch(err=>{ 
          setShowLoader(false) 
       })  
     
       }})  
       useEffect(() => {
    
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Branches/List`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
           // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            
            let apiResponse=res?.data
            const modifiedResponse = apiResponse.map(item => {
              // Destructure the item object and omit the branch property
              const { city,country, ...rest } = item;
              return rest; // Return the modified object without the branch property
            });    
            setBranchesData(modifiedResponse);  
          })
          .catch(() => {
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
       
     
           
      }, []); 
     return( 
         <div className="font-poppins w-full mt-2 p-2 ">  
            <form onSubmit={formik.handleSubmit}>  
               <div className=" border-[1px]  border-[#E6E9EE] rounded-2xl p-2 flex flex-wrap flex-wrap justify-between items-center"> 
                 <h1 className="font-semibold">Add Expense</h1> 
                   
                 <div className="w-[27%] ">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.expenseCategoryId}
              name="expenseCategoryId"
              onChange={formik.handleChange}
              placeholder="Category"
              className="mt-2 border my-dropdown  border-[#EEEEEE]  rounded-md w-full"
              optionLabel="title"
              optionValue="id"
              options={expenseCategories}
            />
            {formik.touched.expenseCategoryId && formik.errors.expenseCategoryId ? (
              <p className="text-red-500 text-[12px]">{formik.errors.expenseCategoryId}</p>
            ) : undefined}
          </div>   
          <div className="w-[27%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.branchId}
              name="branchId"
              onChange={formik.handleChange}
              placeholder="Branch"
              className="mt-2 border my-dropdown  border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={branchesData}
            />
            {formik.touched.branchId && formik.errors.branchId ? (
              <p className="text-red-500 text-[12px]">{formik.errors.branchId}</p>
            ) : undefined}
          </div> 
          <div className="w-[27%] ">
            <InputText
              value={formik.values.amount}
              name="amount"
              onChange={formik.handleChange}
              placeholder="Amount"
              className="mt-2 border p-2 border-[#EEEEEE] text-[#AFAFAF]   rounded-md w-full"
            />
            {formik.touched.amount && formik.errors.amount ? (
              <p className="text-red-500 text-[12px]">{formik.errors.amount}</p>
            ) : undefined}
          </div>  
          <div className="w-[75%] ">
            <InputText
              value={formik.values.description}
              name="description"
              onChange={formik.handleChange}
              placeholder="Description"
              className="mt-2 border p-2 border-[#EEEEEE] text-[#AFAFAF]  rounded-md w-full"
            />
            {formik.touched.description && formik.errors.description ? (
              <p className="text-red-500 text-[12px]">{formik.errors.description}</p>
            ) : undefined}
          </div>        
          <Button 
            type="submit"
            disabled={loaderShow}
            className="w-[22%] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loaderShow ? "":"Submit"}`}   
            iconPos="center"
            icon={loaderShow ? <CommonLoader/>:""}
          />
               </div>
            
            </form> 
             <Toast ref={toast}/>
         </div>
     )
 }