import React, { useState, useEffect,useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../css/Plan_Offer.css"
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown"; 
import { Button } from "primereact/button"; 
import moment from "moment";
import {Toast} from "primereact/toast" 
import Axios from "axios"; 
import RenderSingleOffer from "./offer_components/Single_Offer_Render";
import CommonLoader from "../../../../Components/Loader/Loader";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";

export default function Offer({whenPlanAddRefreshOffer}) {    
  const toast=useRef()  
  const [refreshOnDelete,setRefreshOnDelete]=useState(false)
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [loader,setLoader]=useState(true)
  const [loaderShow,setShowLoader]=useState(false)
  const [provincesData, setprovincesData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [plansData, setPlansData] = useState([]);   
  const [allOffers,setAllOffers]=useState([])
  useEffect(() => {
    
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Offers/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        
         
        setAllOffers(res?.data);   
        setLoader(false)
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
   
  
       
  }, [loaderShow,refreshOnDelete]); 
  let role = parseLoginRes?.role;   
   
  useEffect(() => {
    
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Branches/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
       // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        
        let apiResponse=res?.data
        const modifiedResponse = apiResponse.map(item => {
          // Destructure the item object and omit the branch property
          const { city,country, ...rest } = item;
          return rest; // Return the modified object without the branch property
        });     
        const obj={name:"All",id:0} 
      
        modifiedResponse.push(obj)
        setBranchesData(modifiedResponse);  
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
   
 
       
  }, []); 
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Plans/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
       // setPlansData(res?.data);    
       let apiResponse=res?.data
        const modifiedResponse = apiResponse.map(item => {
          // Destructure the item object and omit the branch property
          const { branch,product, ...rest } = item;
          return rest; // Return the modified object without the branch property
        });  
        setPlansData(modifiedResponse)
      })
      .catch(() => {   
         setPlansData([])
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      })
  },[whenPlanAddRefreshOffer]) 
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/1`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        const obj={name:"All",id:0} 
      
        res?.data.push(obj)
        setCitiesData(res?.data); 
       
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  },[]) 
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Provinces/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        const obj={name:"All",id:0} 
      
        res?.data.push(obj)
        setprovincesData(res?.data);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  },[])
  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Date From Is Required"),
    endTime: Yup.string().required("Date To Is Required"),
    branchId: Yup.string().required("Branch Name Is Required"),
    name: Yup.string().required("Offer Is Required"),
    provinceId: Yup.string().required("Province Is Required"),
    cityId: Yup.string().required("City Is Required"),

    planId: Yup.string().required("Plan Is Required"),
    salesQty: Yup.string().required("Sales Quantity Is Required"),
    marketerReward: Yup.string().required("Reward Is Required"),
  });
  const formik = useFormik({
    initialValues: {
    
      startTime: "",
      endTime: "",
      branchId: 0,
      name: "",
      provinceId: 0,
      cityId: 0,
      planId: "",
      salesQty:"",
      marketerReward: "",
    },
    validationSchema,
    onSubmit: (values,{resetForm}) => { 
          setShowLoader(true)
      let startTime = new Date(formik.values.startTime);
      let isoformatstarttime = startTime.toISOString();
      let endTime = new Date(formik.values.endTime);
      let isoformatendtime = endTime.toISOString();
      const dataTosend = {
        ...values,
        startTime: isoformatstarttime,
        endTime: isoformatendtime, 
        salesQty:parseInt(formik.values.salesQty)
      };  
       Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Offers/Create`,dataTosend,{
        headers: { Authorization: `Bearer ${token}` },
      }).then(res=>{ 
            setShowLoader(false)  
            resetForm()
             //setLoader(true)
      }).catch(err=>{ 
        setShowLoader(false) 
      })
    
    },
  }); 

  return (
    <div className="w-full">   
      <div className="font-poppins text-[#AFAFAF] font-medium border-[2px] border-[#EEEEEE] rounded-[14px] overflow-hidden">
    <h1 className="font-semibold bg-[#EEEEEE] text-[22px] text-[#15223C]  p-3">
          { role === "Admin" ? "Add Offer":"Offers"}
        </h1> 
         {  
         role === "Admin" ?
        <form    
          className="flex p-2 flex-wrap flex-row justify-between"
          onSubmit={formik.handleSubmit}
        >
        
          <div className="w-[48%]">
            <Calendar dateFormat="dd/mm/yy"
              showIcon
              value={formik.values.startTime}
              iconPos="left"
              name="startTime"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.startTime=utcDate.toDate()
              }}
              placeholder="6 Jan 2024"
              className="mt-2 my-calendar border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.startTime && formik.errors.startTime ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.startTime}
              </p>
            ) : undefined}
          </div>
          <div className="w-[48%]">
            <Calendar dateFormat="dd/mm/yy"
              showIcon
              value={formik.values.endTime}
              iconPos="left"
              name="endTime"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.endTime=utcDate.toDate()
              }}
              placeholder="6 Jan 2024"
              className="mt-2 my-calendar border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.endTime && formik.errors.endTime ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.endTime}
              </p>
            ) : undefined}
          </div>
          <div className="w-[32%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.branchId}
              name="branchId"
              onChange={formik.handleChange}
              placeholder="Branch Name"
              className="my-dropdown mt-2 border   border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={branchesData}
            />
            {formik.touched.branchId && formik.errors.branchId ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.branchId}
              </p>
            ) : undefined}
          </div>
          <div className="w-[32%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.provinceId}
              name="provinceId"
              onChange={formik.handleChange}
              placeholder="Province"
              className="my-dropdown mt-2 border   border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={provincesData}
            />
            {formik.touched.provinceId && formik.errors.provinceId ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.provinceId}
              </p>
            ) : undefined}
          </div>
          <div className="w-[32%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.cityId}
              name="cityId"
              onChange={(e)=>{  
                formik.setFieldValue("cityId",e.value)
              }}
              placeholder="City"
              className="my-dropdown mt-2 border   border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={citiesData}
            />
            {formik.touched.cityId && formik.errors.cityId ? (
              <p className="text-red-500 text-[12px]">{formik.errors.cityId}</p>
            ) : undefined}
          </div>
          <div className="w-[100%]">
            <InputText
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              placeholder="Offer Name"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-red-500 text-[12px]">{formik.errors.name}</p>
            ) : undefined}
          </div>
          <div className="w-[70%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.planId}
              name="planId"
              onChange={formik.handleChange}
              placeholder="Plan"
              className="mt-2 border my-dropdown  border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={plansData}
            />
            {formik.touched.planId && formik.errors.planId ? (
              <p className="text-red-500 text-[12px]">{formik.errors.planId}</p>
            ) : undefined}
          </div>
          <div className="w-[28%]">
            <InputText
              value={formik.values.salesQty}
              name="salesQty"  
              
              keyfilter="pint"
              onChange={formik.handleChange}
              placeholder="Sales Qty"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.salesQty && formik.errors.salesQty ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.salesQty}
              </p>
            ) : undefined}
          </div>
          <div className="w-[100%]">
            <InputText
              value={formik.values.marketerReward}
              name="marketerReward"
              onChange={formik.handleChange}
              placeholder="Marketer's Reward"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.marketerReward && formik.errors.marketerReward ? (
              <p className="text-red-500 text-[12px]">
                {formik.errors.marketerReward}
              </p>
            ) : undefined}
          </div>
          <Button 
            type="submit"
            disabled={loaderShow}
            className="w-full text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loaderShow ? "":"Submit"}`}   
            iconPos="center"
            icon={loaderShow ? <CommonLoader/>:""}
          />
        </form> 
           :undefined}
      </div> 
      
      <div className="mt-2 w-full font-poppins text-[#AFAFAF] bg-[#EEEEEE] font-medium border-[2px] border-[#EEEEEE] p-2 rounded-[14px] overflow-hidden">
        { 
         loader ?<div className="flex flex-wrap flex-row items-center justify-center"><CommonLoaderBlue/></div> :
          allOffers.map((item, index) => {
         
          return (
            <div className="flex w-full mt-2 bg-custom-gradient rounded-md border-[2px] border-[#595A5C] flex-wrap flex-row justify-between pl-2 pt-2 pb-2 ">
              <RenderSingleOffer token={token} setLoader={setLoader} setRefreshOnDelete={setRefreshOnDelete} item={item}/>
            </div>
          );
        })}
      </div>   
         <Toast ref={toast}/>
    </div>
  );
}
