  import React, { useState, useEffect,useRef } from "react";
import * as Yup from "yup"; 
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown"; 
import { Button } from "primereact/button"; 
import {Toast} from "primereact/toast" 
import Axios from "axios";  
import { useFormik } from "formik";  
import CommonLoader from "../../../../Components/Loader/Loader";
export default function AddCities({setRefresh,setRefreshWhenAddCity}){  
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [loaderShow,setShowLoader]=useState(false)  
  const [countryOptions,setCountryOptions]=useState([]) 
  const toast=useRef(null)    
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
      
         setCountryOptions(res?.data)
       // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Countries Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetching Countries Data</h1> });
   
    })
   },[])
    const validationSchema = Yup.object().shape({
        countryId:Yup.string().required("Country Is Required"),
         name:Yup.string().required("City Name Is Required")
         
     
     
       })     
     
   const formik = useFormik({
       initialValues: {
         countryId:"", 
         name:"", 
     
         },
       validationSchema,
       onSubmit: (values,{resetForm}) => {   
        const dataTosend={...values,countryId:(formik.values.countryId).toString()}
       setShowLoader(true) 
         Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Cities/Add`,dataTosend,{
          headers: { Authorization: `Bearer ${token}` },
        }).then(res=>{  
             setRefreshWhenAddCity(true)
              setShowLoader(false)   
              resetForm()
         setRefresh(prev=>!prev)
        }).catch(err=>{  
          setShowLoader(false) 
            
        })  
     
       }})  
    
     return( 
         <div className="font-poppins w-full mt-2 p-2 ">  
        
            <form onSubmit={formik.handleSubmit}>  
               <div className=" border-[1px]  border-[#E6E9EE] rounded-2xl p-2 flex flex-wrap flex-wrap justify-between items-center"> 
                 <h1 className="font-semibold">Add City</h1> 
                   
                 <div className="w-[27%] ">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.countryId}
              name="countryId"
              onChange={formik.handleChange}
              placeholder="Country"
              className="mt-2 border my-dropdown  border-[#EEEEEE]  rounded-md w-full"
              optionValue="id" optionLabel="name"
              options={countryOptions}
            />
            {formik.touched.countryId && formik.errors.countryId ? (
              <p className="text-red-500 text-[12px]">{formik.errors.countryId}</p>
            ) : undefined}
          </div>   
        
          <div className="w-[27%] ">
            <InputText
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              placeholder="City Name"
              className="mt-2 border p-2 border-[#EEEEEE] text-[#AFAFAF]   rounded-md w-full"
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-red-500 text-[12px]">{formik.errors.name}</p>
            ) : undefined}
          </div>  
             
          <Button 
            type="submit"
            disabled={loaderShow}
            className="w-[22%] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loaderShow ? "":"Submit"}`}   
            iconPos="center"
            icon={loaderShow ? <CommonLoader/>:""}
          />
               </div>
            
            </form> 
             <Toast ref={toast}/>
         </div>
     )
 }