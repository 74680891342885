import React, { useState } from "react"; 
import ContactUs from "./ContactUs/ContactUs";
import Faqs from "./Faqs/Faqs";
import { Button } from "primereact/button";
export default function AdminSupport(){  
       const [contactUs,setContactUs]=useState(false)
       const [faqs,setFaqs]=useState(true)
     return( 
          
          <div className="p-4  mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  width1280:mt-[40px]"> 
          <div className="flex flex-wrap  flex-row justify-left gap-2"> 
          <Button onClick={()=>{
               setContactUs(true)
               setFaqs(false)
          }} label="Contact US" className={`${contactUs ? "bg-main-color text-white":"text-main-color border border-main-color"} p-1 w-[120px] pl-2 pr-2 font-poppins `}/>  
          
          <Button label="Faqs" onClick={()=>{
               setContactUs(false)
               setFaqs(true)
          }} className={`${faqs ? "bg-main-color text-white":"text-main-color border border-main-color"} p-1 pl-2 pr-2 w-[120px]  font-poppins `}/>
          </div>
            <div className="flex flex-wrap flex-row mt-[10px] flex-wrap justify-around">   
                   { 
                   contactUs ? 
                  <ContactUs/>  :   
                   <Faqs/>  
} 

                    
            </div>
     </div>
     )
}