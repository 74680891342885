import React, { useState } from "react";   
import ConnectedPeople from "./ConnectedPeople/ConnectedPeople";
import CurrentConnected from "./CurrentConnectMessages.js/CurrentConnectedMessages";
export default function Messages(){   
    const [currentActiveUser,setCurrentActive]=useState(null)   
    return( 
        <div className="flex flex-wrap flex-row justify-between"> 
     <div className="w-[49%]"> 
      <ConnectedPeople setCurrentActive={setCurrentActive}/>
     </div>  
     <div className="w-[49%]">  
      { 
      currentActiveUser !== null ?
   <CurrentConnected currentActiveUser={currentActiveUser}/> :undefined
      }
     </div> 
        </div>
    )
}