import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";  
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import CommonLoader from "../../../../Components/Loader/Loader";
export default function RenderSeperateHeir({ heirData,setFormToOpen,setRefreshUserFromAdd,setRefreshUser }) {
  const toast = useRef(); 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  const [showCnicFrontError, setShowCnicFrontError] = useState(false);
  const [showCnicBackError, setShowCnicBackError] = useState(false);
  const [showReceiptError, setShowReceiptError] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(false); 
  let {state}=useLocation()
  const [showCofirmation, setConfirmation] = useState(false);
  let token = parseLoginRes.token;
  const validationSchema = Yup.object().shape({
    cnic: Yup.string().required("Cnic Is Required"),
    countryId: Yup.string().required("Country Is Required"),
    cityId: Yup.string().required("City Is Required"),
    address: Yup.string().required("Address Is Required"),
    name: Yup.string().required("Name Is Required"),

    fatherName: Yup.string().required("Father Name Is Required"),
    phone: Yup.string().required("Contact Is Required"),
    whatsappNumber: Yup.string().required("Whatsapp Is Required"),
    dob: Yup.string().required("DOB Is Required"), 
  });
  const formik = useFormik({
    initialValues: {
         id:heirData?.id ? heirData?.id:0 , 
        countryId: heirData?.countryId,
        cityId: heirData?.cityId,
        address: heirData?.address,
        name: heirData?.name,
        fatherName:heirData?.fatherName,
        cnic: heirData?.cnic,
        phone: heirData?.phone,
        whatsappNumber: heirData?.whatsappNumber,
        dob: heirData?.dob ? new Date(heirData?.dob) :"", 
        userId:state?.user?.simpleone

    },
    validationSchema,
    onSubmit: (values) => {
        if (formik.values.IdCardBack !== "") {
          if (formik.values.IdCardFront !== "") {
              AddCustomer(values);
            
          } else {
            setShowCnicFrontError(true);

            if (formik.values.Receipt === "") {
              setShowReceiptError(true);
            }
          }
        } else {
          setShowCnicBackError(true);
          if (formik.values.IdCardFront === "") {
            setShowCnicFrontError(true);
          }
          if (formik.values.Receipt === "") {
            setShowReceiptError(true);
          }
        }
      
    }
  });  
  
  const [heirCitiesOption, setHierCitiesOptions] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.countryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setHierCitiesOptions(res?.data);
        //  toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.countryId]);
  function AddCustomer(values) {
    setShowPageLoader(true)
    const formData = {}
    for (const key in values) {
      if (key === "Dob" ) {
        let currentvalue = values[key];
        let date = new Date(currentvalue);
        let isoformat = date.toISOString();
        formData[key]=isoformat
      } else {
      
          formData[key]=values[key]

      }
    }
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/CustomerHeirs/CreateOrUpdate`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setShowPageLoader(false);
        // setCountryOptions(res?.data)
  
        // setUsersData(res?.data)
      })
      .catch((err) => {
        setShowPageLoader(false);
      
      });
  }
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCountryOptions(res?.data);

        // setUsersData(res?.data)
      })
      .catch(() => {
       
      });
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
    <div className="mt-4  border border-[#E6E9EE]  rounded-2xl pb-4 p-4 w-full flex flex-wrap felx-row justify-between">
     
       
        <div className="w-[45%]">
            <InputText
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className=" mt-4 border pl-2 h-[40px] p-2 w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.name}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <InputText
              name="fatherName"
              value={formik.values.fatherName}
              onChange={formik.handleChange}
              className=" mt-4 border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.fatherName && formik.errors.fatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.fatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <InputMask
              mask="99999-9999999-9"
              name="cnic"
              value={formik.values.cnic}
              onChange={formik.handleChange}
              className="mt-4  p-2 border pl-2 h-[40px]  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.cnic && formik.errors.cnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.cnic}
              </p>
            ) : null}
          </div>
          <div className="w-[45%] mt-4 flex  flex-wrap flex-row justify-between">
            <div className="w-[45%]">
              <Dropdown
                className="w-full border  my-dropdown font-poppins font-medium  rounded-md  "
                placeholder="Country"
                onChange={formik.handleChange}
                optionValue="id"
                optionLabel="name"
                name="countryId"
                value={formik.values.countryId}
                options={countryOptions}
              />

              {formik.touched.countryId && formik.errors.countryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.countryId}
                </p>
              ) : null}
            </div>
            <div className="w-[45%]">
              <Dropdown
                className="w-full border my-dropdown font-poppins font-medium  rounded-md  "
                placeholder="City"
                onChange={formik.handleChange}
                optionValue="id"
                optionLabel="name"
                name="cityId"
                value={formik.values.cityId}
                options={heirCitiesOption}
              />

              {formik.touched.cityId && formik.errors.cityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.cityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className="mt-4  border pl-2 h-[40px]  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.address && formik.errors.address ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.address}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.phone && formik.errors.phone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.phone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="whatsappNumber"
              value={formik.values.whatsappNumber}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.whatsappNumber &&
            formik.errors.whatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.whatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar dateFormat="dd/mm/yy"
              name="dob"
              value={formik.values.dob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.dob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="mt-4  border pl-2 h-[40px]  p-2  w-full  rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.dob && formik.errors.dob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.dob}
              </p>
            ) : null}
          </div>
        </div>
        <div className="w-full mt-4">
            <Button
            
            type="submit"
            disabled={showPageLoader}
            className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${showPageLoader ? "":"Submit"}`}   
            iconPos="center"
            icon={showPageLoader? <CommonLoader/>:""}
          /> 
             </div>
      <Toast className="z-50 mt-[80px]" ref={toast} />
    </form>
  );
}
