import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";  
 import Axios  from "axios"; 
 import moment from "moment";
import CommonLoaderBlue from "../../../../../../../Components/Loader/LoaderBlue";
export default function AllUpcomingSells(){  
     const [allSells,setAllSells]=useState([]) 
     let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
     let token = parseLoginRes.token;
      const [loader,setLoader]=useState(true)
     const [selectedRows,setSelectedRows]=useState()  
        useEffect(()=>{ 
            Axios.get(`${process.env.REACT_APP_BASE_URL}/api/SalesHistory/UpComingReturnSales`, {
                headers: { Authorization: `Bearer ${token}` },
               
              }).then(res=>{       
                setLoader(false)
setAllSells(res?.data)
              }).catch(err=>{ 

              })
      
        },[])
     return (<div className="overflow-x-auto">  
        <DataTable
          value={allSells}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          size="small"
          resizableColumns
        paginator
          rows={10}
          rowsPerPageOptions={[25, 50]}
          emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div> : "Upcoming Return Sales Not Found"}
          style={{ backgroundColor: "white", minWidth: "800px" }}
          className=" relative w-[99%] p-4 ml-2  all-customers  font-poppins "
        >
          {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
          <Column
            header="Name"
            field="name"
            body={(rowdata) => {
              return (
                <div className="flex flex-wrap flex-row items-center justify-left">
 <div className="rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[40px] self-center border border-[#E6E9EE] shadow-sm">
                    <img
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={rowdata?.customer?.image}
                      alt="branch image"
                    />
                  </div>     
            
                  <p className="ml-5">{rowdata?.customer?.name}</p>
                </div>
              );
            }}
          ></Column>
          <Column   
          header="Created Date" field="city.name" body={(rowData)=>{ 
         return  ( 
            <p>{moment(rowData?.createdAt).format('DD-MM-YYYY')}</p>
         )
          }}></Column>
          <Column   
          header="Id" field="id"></Column>
          <Column   
          header="End Date" field="endTime"  body={(rowData)=>{ 
            return  ( 
               <p>{moment(rowData?.endTime).format('DD-MM-YYYY')}</p>
            )
             }}></Column>
          
             </DataTable>
      </div>   
     )
}