import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../css/Plan_Offer.css";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button"; 

import Axios from "axios";
import { Toast } from "primereact/toast"; 
import moment from "moment";
import RenderSinglePlan from "./plan_components/Single_Plan_Render";
import CommonLoader from "../../../../Components/Loader/Loader";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";

export default function Plan({setWhenPlanAddRefreshOffer}) {
  const [loader, setLoader] = useState(true);
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;
  let toast = useRef(null);
  const [loaderShow, setShowLoader] = useState(false);
  const [productData, setProductData] = useState([]);
  const [AllPlans, setAllPlans] = useState([]);
  const [refreshOnDelete, setRefreshOnDelete] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Products/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });

        setProductData(res?.data);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, []);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Branches/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });

        let apiResponse = res?.data;
        const modifiedResponse = apiResponse.map((item) => {
          // Destructure the item object and omit the branch property
          const { city, country, ...rest } = item;
          return rest; // Return the modified object without the branch property
        });
        const obj = { name: "All", id: 0 };
        modifiedResponse.push(obj);
        setBranchesData(modifiedResponse);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, []);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Plans/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });

        setAllPlans(res?.data);
        setLoader(false);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, [loaderShow, refreshOnDelete]);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start Date Is Required"),
    endTime: Yup.string().required("End Time Is Required"),
    branchId: Yup.string().required("Branch Name Is Required"),
    name: Yup.string().required("Plan Name Is Required"),
    productId: Yup.string().required("Product Name Is Required"),
    totalSales: Yup.string().required("Sales Quantity Is Required"),
    rent: Yup.string().required("Rent Is Required"),  
     amount:Yup.string().required("Amount Is Required")
  });
  const formik = useFormik({
    initialValues: {
      startTime: "",
      endTime: "",
      branchId: 0,
      name: "",
      amount:"",
      productId: "",
      totalSales: "",
      rent: "",
    },
    validationSchema,
    onSubmit: (values,{resetForm}) => {
      setShowLoader(true);
      let startTime = new Date(formik.values.startTime);
      let isoformatstarttime = startTime.toISOString();
      let endTime = new Date(formik.values.endTime);
      let isoformatendtime = endTime.toISOString();
      const dataTosend = {
        ...values,
        startTime: isoformatstarttime,
        endTime: isoformatendtime,
        totalSales: parseInt(formik.values.totalSales),
        rent: parseInt(formik.values.rent),
      };
      Axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Plans/Create`,
        dataTosend,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {  
           setWhenPlanAddRefreshOffer(prev=>!prev)
          setShowLoader(false);  
           resetForm()
        
           
          
        })
        .catch((err) => {
          setShowLoader(false);
         
        });
    },
  });
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  let role = parseLoginRes?.role;   
  return (
    <div className="w-full">
      <div className="font-poppins text-[#AFAFAF] font-medium border-[2px] border-[#EEEEEE] rounded-[14px] overflow-hidden">
        <h1 className="font-semibold bg-[#EEEEEE] text-[22px] text-[#15223C]  p-3">
         {role === "Admin" ? "Add Plan":"Plans"}
        </h1> 
        { role === "Admin" ?
        <form
          className="flex p-2 flex-wrap flex-row justify-between"
          onSubmit={formik.handleSubmit}
        >
          <div className="w-[48%]">
            <Calendar dateFormat="dd/mm/yy"
              showIcon
              values={formik.values.startTime}
              iconPos="left"
              name="startTime"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.startTime=utcDate.toDate()
              }}
              placeholder="6 Jan 2024"
              className="mt-2 my-calendar border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.startTime && formik.errors.startTime ? (
              <p className="text-[12px] text-red-500">
                {formik.errors.startTime}
              </p>
            ) : undefined}
          </div>
          <div className="w-[48%]">
            <Calendar dateFormat="dd/mm/yy"
              showIcon
              values={formik.values.endTime}
              iconPos="left"
              name="endTime"
              onChange={(e)=>{  
                
                
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                formik.values.endTime=utcDate.toDate() 
              }}
              placeholder="6 Jan 2024"
              className="mt-2 my-calendar border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.endTime && formik.errors.endTime ? (
              <p className="text-[12px] text-red-500">
                {formik.errors.endTime}
              </p>
            ) : undefined}
          </div>
          <div className="w-[39%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.branchId}
              name="branchId"
              onChange={formik.handleChange}
              placeholder="Branch"
              className="my-dropdown mt-2 border   border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={branchesData}
            />
            {formik.touched.branchId && formik.errors.branchId ? (
              <p className="text-[12px] text-red-500">
                {formik.errors.branchId}
              </p>
            ) : undefined}
          </div>
          <div className="w-[58%]">
            <InputText
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              placeholder="Plan Name"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-[12px] text-red-500">{formik.errors.name}</p>
            ) : undefined}
          </div>
          <div className="w-[24%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.productId}
              name="productId"
              onChange={formik.handleChange}
              placeholder="Product"
              className="mt-2 border my-dropdown  border-[#EEEEEE]  rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={productData}
            />
            {formik.touched.productId && formik.errors.productId ? (
              <p className="text-[12px] text-red-500">
                {formik.errors.productId}
              </p>
            ) : undefined}
          </div>
          <div className="w-[24%]">
            <InputText
              values={formik.values.totalSales}
              name="totalSales"
              onChange={formik.handleChange}
              placeholder="Sales Qty"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.totalSales && formik.errors.totalSales ? (
              <p className="text-[12px] text-red-500">
                {formik.errors.totalSales}
              </p>
            ) : undefined}
          </div>
          <div className="w-[40%]">
            <InputText
              values={formik.values.rent}
              name="rent"
              onChange={formik.handleChange}
              placeholder="rent"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.rent && formik.errors.rent ? (
              <p className="text-[12px] text-red-500">{formik.errors.rent}</p>
            ) : undefined}
          </div>  '
          <div className="w-[100%]">
            <InputText
              value={formik.values.amount} 
              keyfilter="num"
              name="amount"              onChange={formik.handleChange}
              placeholder="Product Amount"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.amount && formik.errors.amount ? (
              <p className="text-red-500 text-[12px]">{formik.errors.amount}</p>
            ) : undefined}
          </div>
          <Button 
            type="submit"
            disabled={loaderShow}
            className="w-full text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loaderShow ? "":"Submit"}`}   
            iconPos="center"
            icon={loaderShow ? <CommonLoader/>:""}
          />
        </form>   :undefined}
      </div> 
    
      <div className="mt-2 w-full font-poppins text-[#AFAFAF] bg-[#EEEEEE] font-medium border-[2px] border-[#EEEEEE] p-2 rounded-[14px] overflow-hidden">
        {loader ? ( 
          <div className="flex flex-wrap flex-row items-center justify-center"><CommonLoaderBlue/></div>
        ) : (
          AllPlans.map((item, index) => {
            return (
              <div className="flex w-full mt-2 bg-custom-gradient rounded-md border-[2px] border-[#595A5C] flex-wrap flex-row justify-between">
                <RenderSinglePlan 
                
           setWhenPlanAddRefreshOffer={setWhenPlanAddRefreshOffer}
                  setRefreshOnDelete={setRefreshOnDelete}
                  item={item}
                  token={token}
                  setLoader={setLoader}
                />
              </div>
            );
          })
        )}
      </div> 
      <Toast ref={toast} />
    </div>
  );
}
