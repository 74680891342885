import React from "react";
import "./css/expense_list.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import Axios  from "axios";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,

} from "@heroicons/react/outline";
import { Button } from "primereact/button";
//import AddNewCustomer from "./components/add_new_customer";
//import ViewAllCustomers from "./components/view_all_customers"; 
import Loader from "../Components/index"
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function CitiesList({refresh,refreshwhenAddCity}) { 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;   
  const [deleteDialog,setDeleteDialog]=useState(false)    
  const [activeLoader,setActiveLoader]=useState(false) 
   const [updatedCtyName,setUpdatedCity]=useState() 
   const [editDialogVisibility,setEditDialog]=useState(false)
  const [cityData,setCityData]=useState()    
  const [selectedRows, setSelectedRows] = useState(0);   
  const [loader,setLoader]=useState(true) 
  const [viewAllCustomers,setViewAllCustomers]=useState(false)
  //const [addNewCustomerVisibility, setAddNewCustomerVisibility] 
  const [citiesList,setCitiesList]=useState([]) 
  const tableActions = (rowData) => {
    return (
      <div className="w-full  flex flex-wrap flex-row justify-around">
      
    
          <div  >
           <PencilIcon  onClick={()=>{  
            setUpdatedCity(rowData?.city)
             setCityData(rowData) 
             setEditDialog(true)
           }} className="w-[16px] cursor-pointer  " />
           
          </div> 
          <div >
           <TrashIcon onClick={()=>{ 
             setCityData(rowData) 
              setDeleteDialog(true)
           }} className="w-[16px] cursor-pointer   " />
          </div>
    
        
      </div>
    );
  };
    useEffect(()=>{     
     if(!refreshwhenAddCity){
      setLoader(true) 
     }
      Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/1`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
        setCitiesList(res?.data)  
        setLoader(false)
       //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
         // setUsersData(res?.data)
      }).catch(()=>{ 
       // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
     
      })
     },[refresh])         
      function RefreshCities(){ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/1`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
          setCitiesList(res?.data)  
          setLoader(false)
         //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
           // setUsersData(res?.data)
        }).catch(()=>{ 
         // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
       
        })
      }

  return (
    <div className="overflow-x-auto w-full mt-4">
   {/*   <Button
        onClick={() => {
          setAddNewCustomerVisibility(true);
        }}
        className="bg-submit-button text-white top-[30px] absolute  right-[20px] font-poppins border-none mt-[59px] pl-4 pr-4 pt-2 pb-2"
      >
        <PlusIcon className="w-[15px] inline mt-[-2px] mr-[5px]" />
        Add New Customer
      </Button> 
      */}
      <DataTable
        value={citiesList}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        size="small"
       
        resizableColumns
        paginator
      rows={10}
        rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Cities Not Found"}
         
        style={{ backgroundColor: 'white' ,minWidth:"650px"}}
        className=" relative w-[99%] pl-10 pr-4 pt-10 ml-2 rounded-lg border border-[#E6E9EE] shadow-sm   font-poppins  shadow-md"
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column headerClassName="border-b-[2px]  bg-white border-main-color" header={<span className="text-black font-semibold text-[20px]">Cities List</span>}  field="name" className="border-r border-[#D6D7DB]"></Column>
       
        <Column  headerClassName="text-main-color   bg-white border-b-[2px] border-main-color font-medium"  header="Country Name" className="border-r border-[#D6D7DB]" field="countryName"></Column>
        <Column headerClassName="text-main-color bg-white border-b-[2px] border-main-color font-medium" className="border-r border-[#D6D7DB]" field="action" body={tableActions}></Column>
      </DataTable>
      <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setDeleteDialog(false);
          }} 
          visible={deleteDialog}
        >   
       
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete the City? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes"  
                loading={activeLoader}       
                disabled={activeLoader}
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Cities/Delete/${cityData?.id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => { 
                      setActiveLoader(false)     
                      setDeleteDialog(false) 
                      RefreshCities()
                   //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
         
                     })
                    .catch(() => { 
                      
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div> 
        </Dialog>    
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Update City"
          onHide={() => {
            setEditDialog(false);
          }} 
        
          visible={editDialogVisibility}
        >   
       
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-left">   
                 <label className="w-full block ">City Name: </label>
                <InputText   name={updatedCtyName} onChange={(e)=>{setUpdatedCity(e.target.value)}} className="w-[100%] rounded mt-2 border border-[#AFAFAF] h-[40px] "/>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-left">
              <Button
                label="Update"  
                loading={activeLoader}       
                disabled={activeLoader}
                onClick={() => { 
                  setActiveLoader(true)
                  Axios.put(
                    `${process.env.REACT_APP_BASE_URL}/api/Cities/Update`,{name:updatedCtyName,cityId:cityData?.id,countryId:cityData?.countryId},
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => { 
                      setActiveLoader(false)     
                      setEditDialog(false) 
                      RefreshCities()
                   //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
         
                     })
                    .catch(() => { 
                      
                      setActiveLoader(false)
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="Cancel"
                onClick={() => {
                  setEditDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 ml-5"
              />
            </div>
          </div> 
        </Dialog> 
    </div>
  );
}


