import React, { useRef, useState,useEffect } from "react";    
import RenderSeperateReference from "./RenderSeperateReference";
import { useLocation } from "react-router-dom"; 
import Axios  from "axios";
import { DataTable } from "primereact/datatable";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
import { Column } from "primereact/column";
export default function ReferenceData(){    
const toast = useRef(); 
const {state}=useLocation() 
const [loader,setLoader]=useState(true) 
let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
let token = parseLoginRes.token;  
const [userproducts,setUserProduct]=useState([])  
const [pdfSelect,setPdfSelect]=useState(false)

const [printSelect,setPrintSelect]=useState(false)
useEffect(()=>{ 
Axios.get(
  `${process.env.REACT_APP_BASE_URL}/api/Users/CustomerMarketers`,
  {params:{UserId:state?.user?.simpleone}, headers: { Authorization: `Bearer ${token}` } }
)
  .then((res) => {   
     setUserProduct(res?.data) 
     setLoader(false)
    //setCitiesOptions(res?.data);
    //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
    // setUsersData(res?.data)
  })
  .catch(() => {
    // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
  });
},[]) 
     return(   
        <div className="overflow-auto">
         
           <DataTable   
       
       value={userproducts}
      // onSelectionChange={(e) => setSelectedRows(e.value)}
       size="small"
       resizableColumns
       paginator
       rows={10}
       rowsPerPageOptions={[25, 50]} 
       
       emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Refferal  Not Found"}
       style={{ backgroundColor: "white", minWidth: "500px" }}
       className=" relative w-[99%] p-4 ml-2  all-customers  font-poppins "
     >
       {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
       <Column
         header="Name"  
         field="name"
         body={(rowdata) => {
           return (
             <div className="flex flex-wrap flex-row items-center justify-left">
               {  pdfSelect || printSelect ? "":  <div className={`rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[40px] self-center border border-[#E6E9EE] shadow-sm ${pdfSelect || printSelect ? "mt-[20px]":""}`}>  
               <img className={`transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%] `} src={rowdata.image}   alt="branch image"/>
           
                </div> 
         }
               <p className={` ${pdfSelect || printSelect ? "":"ml-5"} `}>{rowdata.name}</p>
             </div>
           );
         }}
       ></Column> 
        <Column header="Id" 
         headerClassName={`${pdfSelect || printSelect ? "text-[16px] mt-[-10px]  pb-6":""}`} field="userId"></Column>
       <Column header="City" 
         headerClassName={`${pdfSelect || printSelect ? "text-[16px] mt-[-10px]  pb-6":""}`} field="city.name"></Column> 
       
       <Column header="Country" 
         headerClassName={`${pdfSelect || printSelect ? "text-[16px] mt-[-10px]  pb-6":""}`} field="country.name"></Column>
     </DataTable> 
           </div>
     )
}