import React, { useState, useEffect ,useRef} from "react";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar"; 
import moment from "moment";
import * as Yup from "yup"; 
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import {
  MinusIcon,
  XIcon,
  CheckIcon,
} from "@heroicons/react/solid";   
import html2pdf from 'html2pdf.js';
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Pdf, Printer } from "../../../Svg"; 
import PageLoader from "../../../Components/PageLoader";
import Axios from "axios";
import CommonLoader from "../../../../Components/Loader/Loader";
export default function EmployeeForm({setRefreshUserFromAdd, setFormToOpen ,setRefreshUser}) {
  const [showCofirmation, setConfirmation] = useState(false);
  const [imgUrl, setImageUrl] = useState(null);
  const [showImageError, setShowImageError] = useState(null);
  const [showPageLoader,setShowPageLoader]=useState(false)  
  let formref=useRef()
  const toast=useRef() 
  const validationSchema = Yup.object().shape({
    BranchId: Yup.string().required("BranchId Is Required"),
    Id: Yup.string().required("BranchId ID Is Required"),
    Name: Yup.string().required("Name Is Required"),
    FatherName: Yup.string().required("Father Name Is Required"),
    Email: Yup.string().required("Email Is Required"),
    Phone: Yup.string().required("Contact Is Required"),
    Cnic: Yup.string().required("Cnic Is Required"),
    WhatsappNumber: Yup.string().required("Whatsapp Number Is Required"),
    Address: Yup.string().required("Address Is Required"),
    BankAccountNumber: Yup.string().required("Account No Is Required"),
    BankName: Yup.string().required("Bank Name Is Required"),
    Dob: Yup.string().required("DOB Is Required"),
    Date: Yup.string().required("Date Is Required"),
    CountryId: Yup.string().required("Country Is Required"),
    CityId: Yup.string().required("City Is Required"),
    ApplyPosition: Yup.string().required("Position Is Required"),
    ApplyPositionSalary: Yup.string().required("Salary  Is Required"),
    StartTime: Yup.string().required("Time Is Required"),
    EndTime: Yup.string().required("Time Is Required"),
    StartDay: Yup.string().required("Day Is Required"),
    EndDay: Yup.string().required("Day Is Required"),
    JoiningDate: Yup.string().required("Joining Date Required"),
    InstitutionCountryId: Yup.string().required("Country Is Required"),
    InstitutionCityId: Yup.string().required("City Is Required"),
    Institution: Yup.string().required("Institution Is Required"),
    InstitutionName: Yup.string().required("Institution Name Is Required"),
    InstitutionAddress: Yup.string().required(
      "Institution Address Is Required"
    ),
    Year: Yup.string().required("Year Is Required"),
    DidGraduation: Yup.string().required("Graduation Field Is Required"),
    Degree: Yup.string().required("Degree/Diploma Is Required"),
    Experience: Yup.string().required("Experience Field Is Required"),
    Rank: Yup.string().required("Rank Is Required"),
    YearsOfService: Yup.string().required("Service Years Is Required"),
    InstitutionSkills: Yup.string().required("Skills Are Required"),
    RelatedDetails: Yup.string().required("Related Details Is Required"),
    Skills: Yup.string().required("Skills And Qualifications Is Required"),         
    employername1st:Yup.string().required("Name Is Required"),
      employercompanyname1st: Yup.string().required("Company Name Is Required"),
      employercontact1st: Yup.string().required("Phone No Is Required"),
      employerbusinesstype1st: Yup.string().required("Business Type Is Required"),
      employeraddress1st: Yup.string().required("Address Is Required"),
      employercountry1st: Yup.string().required("Country Is Required"),
      employercity1st: Yup.string().required("City Is Required"),
      employersalarypay1st:Yup.string().required("Salary Is Required"),
      employerreasonforleaving1st: Yup.string().required("Leaving Reason Is Required"),
      employerservicesduties1st: Yup.string().required("Services Is Required"),
      employername2nd: Yup.string().required("Name Is Required"),
      employercompanyname2nd:  Yup.string().required("Company Name Is Required"),
      employercontact2nd:  Yup.string().required("Phone No Is Required"),
      employerbusinesstype2nd: Yup.string().required("Business Type Is Required"),
      employeraddress2nd: Yup.string().required("Address Is Required"),
      employercountry2nd:  Yup.string().required("Country Is Required"),
      employercity2nd:  Yup.string().required("City Is Required"),
      employersalarypay2nd: Yup.string().required("Salary Is Required"),
      employerservicesduties2nd: Yup.string().required("Services Is Required"),
      employerreasonforleaving2nd: Yup.string().required("Leaving Reason Is Required"),
      referencename1st: Yup.string().required("Name Is Required"),
      referencecontact1st: Yup.string().required("Phone No Is Required"),
      referencecountry1st:  Yup.string().required("Country Is Required"),
      referencecity1st: Yup.string().required("City Is Required"),
      referenceaddress1st:  Yup.string().required("Address Is Required"),
      referenceoccupation1st:  Yup.string().required("Occupation Is Required"),
      referencerelationyear1st:  Yup.string().required("Relation Year Is Required"),
      referencename2nd: Yup.string().required("Name Is Required"),
      referencecontact2nd: Yup.string().required("Phone No Is Required"),
      referencecountry2nd:  Yup.string().required("Country Is Required"),
      referencecity2nd: Yup.string().required("City Is Required"),
      referenceaddress2nd:  Yup.string().required("Address Is Required"),
      referenceoccupation2nd:  Yup.string().required("Occupation Is Required"),
      referencerelationyear2nd:  Yup.string().required("Relation Year Is Required"),
  });
  const dayOptions = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];

  const timeOptions = [
    { label: "12:00 AM", value: "12:00 AM" },
    { label: "1:00 AM", value: "1:00 AM" },
    { label: "2:00 AM", value: "2:00 AM" },
    { label: "3:00 AM", value: "3:00 AM" },
    { label: "4:00 AM", value: "4:00 AM" },
    { label: "5:00 AM", value: "5:00 AM" },
    { label: "6:00 AM", value: "6:00 AM" },
    { label: "7:00 AM", value: "7:00 AM" },
    { label: "8:00 AM", value: "8:00 AM" },
    { label: "9:00 AM", value: "9:00 AM" },
    { label: "10:00 AM", value: "10:00 AM" },
    { label: "11:00 AM", value: "11:00 AM" },
    { label: "12:00 PM", value: "12:00 PM" },
    { label: "1:00 PM", value: "1:00 PM" },
    { label: "2:00 PM", value: "2:00 PM" },
    { label: "3:00 PM", value: "3:00 PM" },
    { label: "4:00 PM", value: "4:00 PM" },
    { label: "5:00 PM", value: "5:00 PM" },
    { label: "6:00 PM", value: "6:00 PM" },
    { label: "7:00 PM", value: "7:00 PM" },
    { label: "8:00 PM", value: "8:00 PM" },
    { label: "9:00 PM", value: "9:00 PM" },
    { label: "10:00 PM", value: "10:00 PM" },
    { label: "11:00 PM", value: "11:00 PM" },
  ];

  const formik = useFormik({
    initialValues: {
      BranchId: "",
      Id: "",
      Name: "",
      FatherName: "",
      Email: "",
      Phone: "",
      Image: "",
      Cnic: "",
      WhatsappNumber: "",
      Address: "",
      BankAccountNumber: "",
      BankName: "",
      Dob: "",
      Date: new Date(),
      CountryId: "",
      CityId: "",
      EverApplied: true,
      AppliedDate: "",
      AnyFriend: true,
      FriendId: "",
      FriendName: "",
      FriendStatus: "",
      Transportation: true,
      OverAge: true,
      AgeCertificate: true,
      Citizenship: true,
      AnyCrime: true,
      CrimeDetails: "",
      ApplyPosition: "",
      ApplyPositionSalary: "",
      TemporaryWork: true,
      PartTimeWork: true,
      FullTimeWork: true,
      TempStartDate: "",
      TempEndDate: "",
      StartTime: "",
      EndTime: "",
      StartDay: "",
      EndDay: "",
      AvailableForOverTime: true,
      JoiningDate: "",
      InstitutionCountryId: "",
      InstitutionCityId: "",
      Institution: "",
      InstitutionName: "",
      InstitutionAddress: "",
      Year: "",
      DidGraduation: "",
      Degree: "",
      Experience: "",
      Rank: "",
      YearsOfService: "",
      InstitutionSkills: "",
      RelatedDetails: "",
      Skills: "",
      CurrentlyEmployed: true,
      CurrentEmployementDetails: "",

      employername1st: "",
      employercompanyname1st: "",
      employercontact1st: "",
      employerbusinesstype1st: "",
      employeraddress1st: "",
      employercountry1st: "",
      employercity1st: "",
      employersalarypay1st: "",
      employerreasonforleaving1st: "",
      employerservicesduties1st: "",
      employername2nd: "",
      employercompanyname2nd: "",
      employercontact2nd: "",
      employerbusinesstype2nd: "",
      employeraddress2nd: "",
      employercountry2nd: "",
      employercity2nd: "",
      employersalarypay2nd: "",
      employerservicesduties2nd: "",
      employerreasonforleaving2nd: "",
      referencename1st: "",
      referencecontact1st: "",
      referencecountry1st: "",
      referencecity1st: "",
      referenceaddress1st: "",
      referenceoccupation1st: "",
      referencerelationyear1st: "",
      referencename2nd: "",
      referencecontact2nd: "",
      referencecountry2nd: "",
      referencecity2nd: "",
      referenceaddress2nd: "",
      referenceoccupation2nd: "",
      referencerelationyear2nd: "",
      IdCardBack: "",
      IdCardFront: "",
    },
    validationSchema,
    onSubmit: (values) => { 
      setShowPageLoader(true)
      const formData = new FormData();
      const employee1st = {};
      const employee2nd = {};
      const reference1st = {};
      const reference2nd = {};
      for (const key in values) {
        if (
          !key.includes("2") &&
          !key.includes("1") &&
          key !== "Dob" &&
          key !== "Date" &&
          key !== "JoiningDate" &&
          key !== "TempStartDate" &&
          key !== "TempEndDate" &&
          key !== "AppliedDate"
        ) { 
          if(key === "ApplyPositionSalary"){ 

            formData.append(key, parseInt(values[key])); 
          } 
          else{ 
            formData.append(key, values[key]); 
          }
        } else {
          if (
            key === "Dob" ||
            key === "Date"  ||
            key === "JoiningDate" ||
            key === "TempStartDate" ||
            key === "TempEndDate" ||
            key === "AppliedDate"
          ) {
            if (values[key] !== "") {
              let currentvalue = values[key];
              let date = new Date(currentvalue);
              let isoformat = date.toISOString();
              formData.append(key, isoformat);
            }
          } else {
            if (key.includes("1")) { 
              if (key.includes("reference")) {
                if (key === "referencename1st") {
                  reference1st.Name = values[key];
                } else if (key === "referenceoccupation1st") {
                  reference1st.Occupation = values[key];
                } else if (key === "referencerelationyear1st") {
                  reference1st.RelationYears = values[key];
                } else if (key === "referenceaddress1st") {
                  reference1st.Address = values[key];
                } else if (key === "referencecity1st") {
                  reference1st.CityId = values[key];
                } else if (key === "referencecountry1st") {
                  reference1st.CountryId = values[key];
                } else if (key === "referencecontact1st") {
                  reference1st.Phone = values[key];
                }
              } else {
                if (key === "employername1st") {
                  employee1st.EmployerName = values[key];
                } else if (key === "employercompanyname1st") {
                  employee1st.CompanyName = values[key];
                } else if (key === "employercontact1st") {
                  employee1st.PhoneNumber = values[key];
                } else if (key === "employerbusinesstype1st") {
                  employee1st.BusinessType = values[key];
                } else if (key === "employeraddress1st") {
                  employee1st.Address = values[key];
                } else if (key === "employercountry1st") {
                  employee1st.CountryId = values[key];
                } else if (key === "employercity1st") {
                  employee1st.CityId = values[key];
                } else if (key === "employersalarypay1st") {
                  employee1st.Salary = values[key];
                } else if (key === "employerreasonforleaving1st") {
                  employee1st.LeavingReason = values[key];
                } else if (key === "employerservicesduties1st") {
                  employee1st.Position = values[key];
                }
              }
            } else  {
              if (key.includes("reference")) {
                if (key === "referencename2nd") {
                  reference2nd.Name = values[key];
                } else if (key === "referenceoccupation2nd") {
                  reference2nd.Occupation = values[key];
                } else if (key === "referencerelationyear2nd") {
                  reference2nd.RelationYears = values[key];
                } else if (key === "referenceaddress2nd") {
                  reference2nd.Address = values[key];
                } else if (key === "referencecity2nd") {
                  reference2nd.CityId = values[key];
                } else if (key === "referencecountry2nd") {
                  reference2nd.CountryId = values[key];
                } else if (key === "referencecontact2nd") {
                  reference2nd.Phone = values[key];
                }
              } else {
                if (key === "employername2nd") {
                  employee2nd.EmployerName = values[key];
                } else if (key === "employercompanyname2nd") {
                  employee2nd.CompanyName = values[key];
                } else if (key === "employercontact2nd") {
                  employee2nd.PhoneNumber = values[key];
                } else if (key === "employerbusinesstype2nd") {
                  employee2nd.BusinessType = values[key];
                } else if (key === "employeraddress2nd") {
                  employee2nd.Address = values[key];
                } else if (key === "employercountry2nd") {
                  employee2nd.CountryId = values[key];
                } else if (key === "employercity2nd") {
                  employee2nd.CityId = values[key];
                } else if (key === "employersalarypay2nd") {
                  employee2nd.Salary = values[key];
                } else if (key === "employerreasonforleaving2nd") {
                  employee2nd.LeavingReason = values[key];
                } else if (key === "employerservicesduties2nd") {
                  employee2nd.Position = values[key];
                } 
              }
            }
          }
        }
      }
      let employmenthistory=[] 
      employmenthistory.push(employee1st) 
       
      employmenthistory.push(employee2nd)  
      let references=[] 
      references.push(reference1st) 
      
      references.push(reference2nd)
      formData.append("EmployementHistory",JSON.stringify(employmenthistory))  
      formData.append("References",JSON.stringify(references))  
     
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Users/AddEmployee`,formData,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
      setShowPageLoader(false) 
      setConfirmation(true)
        // setCountryOptions(res?.data)
       // setUsersData(res?.data)
    }).catch((err)=>{  
      setShowPageLoader(false) 
    })
      
    },
  });
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;
  const [branchesData, setBranchesData] = useState([]);
  const [citiesOption, setCitiesOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [institutionCities, setInstitutionCity] = useState([]);
  const [employer1stCities, setEmployer1stCities] = useState([]);
  useEffect(()=>{  

    document.body.style.overflow = 'hidden';
  return ()=>{  
  
    document.body.style.overflow = 'auto';
  }
   },[])
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCountryOptions(res?.data);
        //  toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Countries Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetching Countries Data</h1> });
      });
  }, []);
  const yearOptions = [
    { label: "2024", value: "2024" },
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
    { label: "2016", value: "2016" },
    { label: "2015", value: "2015" },
    { label: "2014", value: "2014" },
    { label: "2013", value: "2013" },
    { label: "2012", value: "2012" },
    { label: "2011", value: "2011" },
    { label: "2010", value: "2010" },
    { label: "2009", value: "2009" },
    { label: "2008", value: "2008" },
    { label: "2007", value: "2007" },
    { label: "2006", value: "2006" },
    { label: "2005", value: "2005" },
    { label: "2004", value: "2004" },
    { label: "2003", value: "2003" },
    { label: "2002", value: "2002" },
    { label: "2001", value: "2001" },
    { label: "2000", value: "2000" },
    { label: "1999", value: "1999" },
    { label: "1998", value: "1998" },
    { label: "1997", value: "1997" },
    { label: "1996", value: "1996" },
    { label: "1995", value: "1995" },
    { label: "1994", value: "1994" },
    { label: "1993", value: "1993" },
    { label: "1992", value: "1992" },
    { label: "1991", value: "1991" },
    { label: "1990", value: "1990" },
    { label: "1989", value: "1989" },
    { label: "1988", value: "1988" },
    { label: "1987", value: "1987" },
    { label: "1986", value: "1986" },
    { label: "1985", value: "1985" },
    { label: "1984", value: "1984" },
    { label: "1983", value: "1983" },
    { label: "1982", value: "1982" },
    { label: "1981", value: "1981" },
    { label: "1980", value: "1980" },
    { label: "1979", value: "1979" },
    { label: "1978", value: "1978" },
    { label: "1977", value: "1977" },
    { label: "1976", value: "1976" },
    { label: "1975", value: "1975" },
    { label: "1974", value: "1974" },
    { label: "1973", value: "1973" },
    { label: "1972", value: "1972" },
    { label: "1971", value: "1971" },
    { label: "1970", value: "1970" },
    { label: "1969", value: "1969" },
    { label: "1968", value: "1968" },
    { label: "1967", value: "1967" },
    { label: "1966", value: "1966" },
    { label: "1965", value: "1965" },
    { label: "1964", value: "1964" },
    { label: "1963", value: "1963" },
    { label: "1962", value: "1962" },
    { label: "1961", value: "1961" },
    { label: "1960", value: "1960" },
    { label: "1959", value: "1959" },
    { label: "1958", value: "1958" },
    { label: "1957", value: "1957" },
    { label: "1956", value: "1956" },
    { label: "1955", value: "1955" },
    { label: "1954", value: "1954" },
    { label: "1953", value: "1953" },
    { label: "1952", value: "1952" },
    { label: "1951", value: "1951" },
    { label: "1950", value: "1950" },
  ];
  const [showCnicFrontError, setShowCnicFrontError] = useState(false);
  const [showCnicBackError, setShowCnicBackError] = useState(false);
  const [cnicFrontUrl, setCnicFront] = useState(null);
  const [cnicBackUrl, setCnicBack] = useState(null); 
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Account/GetNewId`,{params:{IdType:5}, headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         //setManagerCitiesOptions(res?.data) 
   formik.setFieldValue("Id",res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[])
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setCitiesOptions(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.CountryId]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setEmployer1stCities(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.employercompanyname1st]);
  const [employer2ndCities, setEmployer2ndCities] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.employercountry2nd}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setEmployer2ndCities(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.employercountry2nd]);
  const [citiesReference1st, setCitiesReference1st] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.referencecountry1st}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setCitiesReference1st(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.referencecountry1st]);
  const [citiesReference2nd, setCitiesReference2nd] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.referencecountry2nd}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setCitiesReference2nd(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.referencecountry2nd]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.InstitutionCountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setInstitutionCity(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.InstitutionCountryId]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Branches/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });

        let apiResponse = res?.data;
        const modifiedResponse = apiResponse.map((item) => {
          // Destructure the item object and omit the branch property
          const { city, country, ...rest } = item;
          return rest; // Return the modified object without the branch property
        });
        setBranchesData(modifiedResponse);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, []);
  return (    
    <div> 
    <div   id="loader">
  <div className="spinner"></div>
</div>
    <div className="all-customers fixed overflow-y-auto  w-[calc(100vw-320px)] border rounded-2xl border-[#E6E9EE] shadow-sm   right-[0px] z-40 h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] bg-white ">
      
      {showCofirmation ? (
        <div className="fixed h-[230px] w-[400px] border border-[#EEEEEE]  bg-white top-[50%] left-[calc(50%+160px)] rounded-xl z-40 transform translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-wrap mt-[-20px] justify-center ">
            <div className="w-[50px] rounded-full p-3 h-[50px] bg-[#2DD683]">
              <CheckIcon className=" text-white w-[25px] h-[25px]" />
            </div>
          </div>
          <div className="flex flex-wrap justify-end flex-row mt-[-20px]">
            <XIcon
              onClick={() => { 
                setRefreshUserFromAdd(true)
                setConfirmation((prev) => !prev);    
                setRefreshUser(prev=>!prev) 
                setFormToOpen("")
              }}
              className="cursor-pointer mr-[20px] inline w-[25px] h-[25px]"
            />{" "}
          </div>
          <h1 className="w-full font-poppins text-[20px] text-center font-bold mt-2 ">
            Thank You !
          </h1>
          <p className="w-full font-poppins p-4 pl-10 pr-10 text-[16px] text-center font-medium  ">
            Your Details has been Successfully submitted. Thanks!
          </p>

          <div className="w-full rounded-b-xl h-[50px] mt-5 pt-[2px] bg-[#2DD683] items-center flex flex-wrap flex-row justify-center">
            <div   
             onClick={() => {      
              document.querySelector("#loader").style.display="flex"
     
              const form = formref.current;
              const options = {
                margin: 0.1,
                filename: 'Customer_Form.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'in', format: 'a4', compressPdf: true, orientation: 'portrait' }
            };
            html2pdf()
            .set(options)
            .from(form)
            .save()
            .then((pdfBuffer) => {   
            document.querySelector("#loader").style.display="none"
          
            })
            }} 
             className="w-[25px] cursor-pointer pt-[1px] h-[25px] mr-[10px]">
              <Pdf />
            </div>
            <div onClick={()=>{ 
               document.querySelector("#loader").style.display="flex"
       
          
               
            const form = formref.current;  
            const opt=  {
             margin: .1,
             image: { type: 'jpeg', quality:1 },
             html2canvas: { scale: 1 },
             jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } 
           } 
               html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
               const pdfUrl = URL.createObjectURL(pdfBlob);
               const iframe = document.createElement('iframe');
               iframe.style.display = 'none';
               iframe.src = pdfUrl;
               document.body.appendChild(iframe);
         setTimeout(() => {
                 iframe.contentWindow.print();   
                 document.querySelector("#loader").style.display="none"

               }, 1000)   
             
            })}} className="w-[25px] cursor-pointer h-[25px] ml-[10px] ">
              <Printer />
            </div>
          </div>
        </div>
      ) : undefined}
      <form  ref={formref} onSubmit={formik.handleSubmit} className="pb-[50px]">
        <div className="flex flex-wrap justify-between items-center rounded-t-2xl  flex-row bg-main-color p-4 w-full font-poppins font-semibold text-[20px]">
          <h1>Employee Form</h1>
          <div>
        
            <XIcon
              onClick={() => {
                setFormToOpen("");
              }}
              className="w-[16px] inline cursor-pointer ml-4 h-[16px]"
            />
          </div>
        </div>
        <div className=" p-10 flex relative bg-form-title flex-wrap items-center w-full flex-row justify-between">
        <div>
            <img src="./formlogo.png" className={`${showCofirmation ? "w-[130px]":""}`} alt="formlogo" />
          </div>
          <div>
           { 
               showCofirmation ?    <div className="flex flex-wrap ml-[-100px] items-center flex-row justify-left">   
                   <div className="border-l-[5px] border-main-color mt-3  h-[70px] w-[4px]"></div>
                   <h1 className="w-[50px] text-white mt-[-15px] font-medium font-poppins ml-[20px] text-[25px]">Employee Form</h1>
                </div> :    <h1 className={`font-poppins border-l-[5px]  border-main-color   w-[200px] font-medium  text-white  ${showCofirmation ? "text-[20px] text-center  w-[50px]   pl-[20px]":"text-[36px]  pl-[40px] line-height-[40px]  ml-[-200px]"}`}>Employee Form</h1>
         
             }
             </div>
          <div    onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setImageUrl(reader.result)
                }
              formik.setFieldValue("Image",e.target.files[0])  
              setShowImageError(false)
              }  
              input.click()
          }}>
             <div className={`absolute ${showCofirmation ? "mt-10 h-[130px] w-[120px] mt-[-30px] ml-[-110px] ":"mt-[-30px] ml-[-170px] h-[180px] w-[170px] "} cursor-pointer  bg-white  justify-center z-10 items-center overflow-hidden flex flex-wrap flex-row  rounded-2xl`}>
             
             {imgUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Pic 
              </h1>  
              :<img src={imgUrl}/>
}
            </div> 
            { showImageError ? (
              <p className={`font-poppins block font-medium mt-[155px] absolute ml-[-165px] text-red-500`}>
                Image Is Required
              </p>
            ) : null}
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-left   flex-row bg-main-color p-4">
          <div className="w-[150px]  font-poppins font-medium">
            <p className="mb-2">Employee ID</p>
         
              <InputText
              disabled
              name="Id"
              value={formik.values.Id}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="ID"
            />
            {formik.touched.Id && formik.errors.Id ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Id}
              </p>
            ) : null}
          </div>
          <div className="ml-5  w-[150px]">
            <p className=" mb-2 font-poppins font-medium ">Date</p>
          
            
             <Calendar dateFormat="dd/mm/yy"
              name="Date" 
              disabled
              value={formik.values.Date}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Date=utcDate.toDate()
              }}
              showIcon 
              
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px]   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Date"
            />
            {formik.touched.Date && formik.errors.Date ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Date}
              </p>
            ) : null}
          </div>   
          <div className="w-[150px] ml-5  font-poppins font-medium">
            <p className="mb-2">Branch ID</p>
         
              <InputText
              name="BranchId"
              value={formik.values.BranchId}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Branch"
            />
            {formik.touched.BranchId && formik.errors.BranchId ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.BranchId}
              </p>
            ) : null}
          </div>
          
        </div>

        <div className="mt-2 flex flex-wrap flex-row   justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
          <h1 className={`font-medium ${showCofirmation ? "mt-[-10px] text-[20px] m-3":""}`}>Personal Information</h1>
        </div>
        <div className="flex flex-wrap flex-row w-full p-4 justify-between">
          <div className="w-[49%]">
            <InputText
              name="Name"
              value={formik.values.Name}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] mt-2 border pl-2 h-[40px]  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.Name && formik.errors.Name ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Name}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="FatherName"
              value={formik.values.FatherName}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] mt-2 border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.FatherName && formik.errors.FatherName ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.FatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputMask
              mask="99999-9999999-9"
              name="Cnic"
              value={formik.values.Cnic}
              onChange={formik.handleChange}
              className="mt-2 text-[#AFAFAF]  border pl-2 h-[40px]  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.Cnic && formik.errors.Cnic ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Cnic}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]  flex   flex-wrap flex-row justify-between">
            <div className="w-[49%]">
               
                {  
            showCofirmation ?  
            <InputText
            name="CountryId"
            value={formik.values.CountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="CountryId"  placeholder="Country" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.CountryId} options={countryOptions}/>
         
   }

              {formik.touched.CountryId && formik.errors.CountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[49%] ">
            {  
            showCofirmation ?  
            <InputText
            name="CityId"
            value={formik.values.CityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="City"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="CityId"  placeholder="City" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.CityId} options={citiesOption}/>
         
   }
              {formik.touched.CityId && formik.errors.CityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              className="mt-2 text-[#AFAFAF] border pl-2 h-[40px]  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.Address && formik.errors.Address ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Address}
              </p>
            ) : null}
          </div>
          <div className="w-[25%]">
            <InputText
              name="BankName"
              value={formik.values.BankName}
              onChange={formik.handleChange}
              className=" mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Bank Name"
            />
            {formik.touched.BankName && formik.errors.BankName ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.BankName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="BankAccountNumber"
              value={formik.values.BankAccountNumber}
              onChange={formik.handleChange}
              className=" mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Account No"
            />
            {formik.touched.BankAccountNumber &&
            formik.errors.BankAccountNumber ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.BankAccountNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <InputText
              name="Email"
              value={formik.values.Email}
              onChange={formik.handleChange}
              className=" mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Email"
            />
            {formik.touched.Email && formik.errors.Email ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Email}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="Phone"
              value={formik.values.Phone}
              onChange={formik.handleChange}
              className=" mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.Phone && formik.errors.Phone ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Phone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="WhatsappNumber"
              value={formik.values.WhatsappNumber}
              onChange={formik.handleChange}
              className=" mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.WhatsappNumber && formik.errors.WhatsappNumber ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar dateFormat="dd/mm/yy"
              name="Dob"
              value={formik.values.Dob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Dob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="mt-2 text-[#AFAFAF] border pl-2 h-[40px]    w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.Dob && formik.errors.Dob ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Dob}
              </p>
            ) : null}
          </div>
          <div className="mt-8 flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium">
            <h1>Have you ever applied to / worked for BizChamp before?</h1>
          {!showCofirmation ?   <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.EverApplied
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              label="Yes"
              onClick={() => {
                formik.setFieldValue("EverApplied", true);
              }}
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.EverApplied
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("EverApplied", false);
              }}
              label="No"
            />     
            </> 
            :     
             <>    
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.EverApplied
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("EverApplied", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.EverApplied
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("EverApplied", false);
              }}
            >No</button>  
            </div>
           </>
              
              }
          </div>
          <div className="flex flex-wrap  flex-row items-center w-full justify-left text-[#595A5C] font-poppins font-medium">
           
           { !showCofirmation ?<> 
            <h1>If yes, please explain (include Date):</h1>
            <Calendar dateFormat="dd/mm/yy"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.AppliedDate=utcDate.toDate()
              }}
              placeholder="6 Feb 2024"
              className="p-1 ml-5 font-poppins  text-[#AFAFAF] border border-[#595A5C] w-[150px] rounded-full"
              value={formik.values.AppliedDate}
              showIcon
              iconPos="left"
            /> </>: <>
             <h1 className={`${showCofirmation ?  "mt-[-10px]":""}`}> If yes, please explain (include Date):</h1>
            <div className="ml-5 h-[25px] mt-1 rounded-full h-[25px] border pl-2 border-[#D6D7DB]   w-[150px]">
               
              <h1 className="mt-[-8px]">{formik.values.AppliedDate} Date</h1>
            {formik.touched.AppliedDate && formik.errors.AppliedDate ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.AppliedDate}
              </p>
            )  
             
            
              : null}
          </div>  
          </>   
           }
          </div>
          <div className=" flex flex-wrap flex-row  w-full justify-left text-[#595A5C] font-poppins font-medium">
           { !showCofirmation ? <><h1>
              {" "}
              Do you have any friends, relatives, or acquaintances working for
              BizChamp?
            </h1>
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.AnyFriend
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AnyFriend", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.AnyFriend
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AnyFriend", false);
              }}
              label="No"
            />  
             </>   
            : 
            <>          
            <h1>
              {" "}
              Do you have any friends, relatives, or acquaintances working for
              BizChamp?
            </h1>
            <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
             formik.values.AnyFriend
               ? "border-main-color text-main-color"
               : ""
           }`}>
          <button
           type="button"
            className="mt-[-8px] ml-[4px] absolute"
           onClick={() => {
             formik.setFieldValue("AnyFriend", true);
           }}
         >Yes</button>  
         </div>    
          <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
             !formik.values.AnyFriend
               ? "border-main-color text-main-color"
               : ""
           }`}>
         <button
           type="button"
            className="mt-[-8px] ml-[4px] absolute"
           onClick={() => {
             formik.setFieldValue("AnyFriend", false);
           }}
         >No</button>  
         </div>
        </>
              }
          </div>   
            
             
             
          <div className={` flex flex-wrap flex-row  ${showCofirmation ? "":"mt-2"}  w-full items-center justify-left text-[#595A5C] font-poppins font-medium`}>
          {!showCofirmation ?  <> <h1>If yes, state name & relationship:</h1>
            <div className="w-[25%]  ml-5">
              <InputText
                name="FriendId"
                value={formik.values.FriendId}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2    pt-1 pb-1  w-full rounded-full border-[#D6D7DB] font-poppins font-medium"
                placeholder="Relative's ID in Company"
              />
              {formik.touched.FriendId && formik.errors.FriendId ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.FriendId}
                </p>
              ) : null}
            </div>
            <div className="w-[18%] ml-5">
              <InputText
                name="FriendName"
                value={formik.values.FriendName}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2    pt-1 pb-1  w-full rounded-full border-[#D6D7DB] font-poppins font-medium"
                placeholder="Name"
              />
              {formik.touched.FriendName && formik.errors.FriendName ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.FriendName}
                </p>
              ) : null}
            </div>
            <div className="w-[18%] ml-5">
              <InputText
                name="FriendStatus"
                value={formik.values.FriendStatus}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2    pt-1 pb-1  w-full rounded-full border-[#D6D7DB] font-poppins font-medium"
                placeholder="Status"
              />
              {formik.touched.FriendStatus && formik.errors.FriendStatus ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.FriendStatus}
                </p>
              ) : null}
            </div>    
             </>
              :     
              <>
              <h1 className={`${showCofirmation ?  "mt-[-10px]":""}`}> If yes, state name & relationship:</h1>
             <div className="ml-5 h-[25px] mt-1 rounded-full border pl-2 border-[#D6D7DB]  w-[25%]  ">
                
               <h1 className="mt-[-8px]">{formik.values.FriendId} Date</h1>
             {formik.touched.AppliedDate && formik.errors.FriendId ? (
               <p className="font-poppins font-medium  ml-1 text-red-500">
                 {formik.errors.AppliedDate}
               </p>
             )  
              
             
               : null}
           </div>     
           <div className="ml-5 h-[25px] mt-1 rounded-full border pl-2 border-[#D6D7DB]  w-[18%]  ">
                
                <h1 className="mt-[-8px]">{formik.values.FriendName} Date</h1>
              {formik.touched.FriendName && formik.errors.FriendName ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.FriendName}
                </p>
              )  
               
              
                : null}
            </div> 
            <div className="ml-5 h-[25px] mt-1 rounded-full border pl-2 border-[#D6D7DB]  w-[18%]  ">
                
                <h1 className="mt-[-8px]">{formik.values.FriendStatus} Date</h1>
              {formik.touched.FriendStatus && formik.errors.FriendStatus ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.FriendStatus}
                </p>
              )  
               
              
                : null}
            </div> 
           </> 
               
                }
          </div>
          <div className={` flex flex-wrap flex-row w-full ${showCofirmation ? "" :"mt-2"} justify-left text-[#595A5C] font-poppins font-medium`}>
         
           {!showCofirmation ? <>  
            <h1>If hired, would you have transportation to/from work?</h1>
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C]   pl-2 pr-2 ${
                formik.values.Transportation
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("Transportation", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border  border-[#595A5C] pl-2 pr-2 ${
                !formik.values.Transportation
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("Transportation", false);
              }}
              label="No"
            /> 
              </>:  <>   

                   <h1>If hired, would you have transportation to/from work?</h1>  
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.Transportation
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("Transportation", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.Transportation
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("Transportation", false);
              }}
            >No</button>  
            </div>
           </>}
          </div>
          <div className={` w-full flex flex-wrap flex-row justify-left ${showCofirmation ? "":"mt-2"} text-[#595A5C] font-poppins font-medium`}>
            <h1>Are you over the age of 18?</h1>
          { !showCofirmation ? <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.OverAge ? "border-main-color text-main-color" : ""
              }`}
              onClick={() => {
                formik.setFieldValue("OverAge", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.OverAge
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("OverAge", false);
              }}
              label="No"
            /> 
             </>: 
               <>          
              
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.OverAge
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("OverAge", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.OverAge
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("OverAge", false);
              }}
            >No</button>  
            </div>
           </>
}
          </div>
          <div className={` w-full  flex flex-wrap flex-row ${showCofirmation ? "" :"mt-2"} justify-left text-[#595A5C] font-poppins font-medium `}>
            <h1>
              If you are under the age of 18, do you have an employment/age
              certificate?
            </h1>
           {!showCofirmation ? <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.AgeCertificate
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AgeCertificate", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.AgeCertificate
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AgeCertificate", false);
              }}
              label="No"
            />   
             </>:     
               <>          
              
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.AgeCertificate
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("AgeCertificate", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.AgeCertificate
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("AgeCertificate", false);
              }}
            >No</button>  
            </div>
           </>
           }
          </div>
          <div className={` flex w-full flex-wrap flex-row ${showCofirmation ? "":"mt-2"}  justify-left text-[#595A5C] font-poppins font-medium`}>
            <h1>
              If hired, would you be able to present evidence of your Pakistan
              citizenship?
            </h1> 
             
              {  
               !showCofirmation ? <>
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.Citizenship
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("Citizenship", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.Citizenship
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("Citizenship", false);
              }}
              label="No"
            /> 
              </>: 
                  <>          
              
                  <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                   formik.values.Citizenship
                     ? "border-main-color text-main-color"
                     : ""
                 }`}>
                <button
                 type="button"
                  className="mt-[-8px] ml-[4px] absolute"
                 onClick={() => {
                   formik.setFieldValue("Citizenship", true);
                 }}
               >Yes</button>  
               </div>    
                <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                   !formik.values.Citizenship
                     ? "border-main-color text-main-color"
                     : ""
                 }`}>
               <button
                 type="button"
                  className="mt-[-8px] ml-[4px] absolute"
                 onClick={() => {
                   formik.setFieldValue("Citizenship", false);
                 }}
               >No</button>  
               </div>
              </>  
            }
          </div>
          <div className={`w-full flex flex-wrap flex-row ${showCofirmation ?"":"mt-2"} justify-left text-[#595A5C] font-poppins font-medium`}>
            <h1>
              Have you been convicted of or pleaded no contest to a felony
              within the last five years?
            </h1> 
              { 
               !showCofirmation  ? <>
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.AnyCrime
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AnyCrime", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.AnyCrime
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AnyCrime", false);
              }}
              label="No"
            />  
             
               </>: 
                   <>          
              
                   <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                    formik.values.AnyCrime
                      ? "border-main-color text-main-color"
                      : ""
                  }`}>
                 <button
                  type="button"
                   className="mt-[-8px] ml-[4px] absolute"
                  onClick={() => {
                    formik.setFieldValue("AnyCrime", true);
                  }}
                >Yes</button>  
                </div>    
                 <div  className={`ml-2 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                    !formik.values.AnyCrime
                      ? "border-main-color text-main-color"
                      : ""
                  }`}>
                <button
                  type="button"
                   className="mt-[-8px] ml-[1px] absolute"
                  onClick={() => {
                    formik.setFieldValue("AnyCrime", false);
                  }}
                >No</button>  
                </div>
               </>
                 }
          </div>
          <div className="  w-full flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium">
            <h1>
              If yes, please describe the crime - state the nature of the
              crime(s), when and where convicted, and the disposition (final
              settlement) of the case:
            </h1>
          </div>
          <div className={`${showCofirmation ? "mt-8":""} w-full `}>
            <InputTextarea
              placeholder="Detail "
              draggable={false}
              name="CrimeDetails"
              value={formik.values.CrimeDetails}
              onChange={formik.handleChange}
              className="w-full text-[#595A5C] border  rounded font-poppins  border-[#D6D7DB]"
            />
          </div>
          <div className={` font-poppins items-center p-5 flex flex-wrap flex-row justify-evenly w-full ${showCofirmation ? "mt-8":""} bg-custom-gradient`}>
            <h1 className={`${showCofirmation ? "mt-[-20px]":""} text-[20px] font-medium `}>
              Position & Availability
            </h1>
            <div className="w-[18%] ">
            { !showCofirmation ? <Dropdown
                placeholder="Applying For"
                name="ApplyPosition"
                onChange={(e) => {
                  formik.setFieldValue("ApplyPosition", e.value);
                }}
                value={formik.values.ApplyPosition}
                className="rounded-full my-dropdown w-full p-0 font-poppins text-[#AFAFAF]"
                options={[
                  { label: "Hr", value: "Hr" },
                  { label: "Accountant", value: "Accountant" },
                ]}
              />   : 
              <InputText
              name="ApplyPosition"
              value={formik.values.ApplyPosition}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full    w-full   border-[#D6D7DB] font-poppins font-medium"
              placeholder="Apply Position"
            />
               
               
               }
              {formik.touched.ApplyPosition && formik.errors.ApplyPosition ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.ApplyPosition}
                </p>
              ) : null}
            </div>
            <div className="w-[18%] ml-5">
              <InputText
                name="ApplyPositionSalary"
                value={formik.values.ApplyPositionSalary}
                onChange={formik.handleChange}
                className={`  text-[#AFAFAF] border pl-2    ${showCofirmation ? "h-[40px]": "pt-1 pb-1"}  w-full rounded-full border-[#D6D7DB] font-poppins font-medium `}
                placeholder="Salary"
              />
              {formik.touched.ApplyPositionSalary &&
              formik.errors.ApplyPositionSalary ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.ApplyPositionSalary}
                </p>
              ) : null}
            </div>
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1 className={`font-medium ${showCofirmation ? "mt-[-10px] mb-3 text-[20px]":""}`} >Are You Applying For</h1>
          </div>
          <div className={`${ !showCofirmation ? "mt-2":""} w-full flex flex-wrap flex-row justify-left  text-[#595A5C] font-poppins font-medium`}>
            <h1>Temporary work – such as summer or holiday work?</h1>
              { !showCofirmation ? <>
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.TemporaryWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("TemporaryWork", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.TemporaryWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("TemporaryWork", false);
              }}
              label="No"
            />   
             </>: 
             <>    
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.EverApplied
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("TemporaryWork", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.TemporaryWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("TemporaryWork", false);
              }}
            >No</button>  
            </div>
           </>
}
          </div>
          <div className={`${ !showCofirmation ? "mt-2":""}   w-full  flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium`}>
            <h1>Regular part-time work?</h1>
           { !showCofirmation ? <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.PartTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("PartTimeWork", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.PartTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("PartTimeWork", false);
              }}
              label="No"
            /> 
              </>    
              :  
              <>    
              <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               formik.values.PartTimeWork
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
            <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("PartTimeWork", true);
             }}
           >Yes</button>  
           </div>    
            <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               !formik.values.PartTimeWork
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
           <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("PartTimeWork", false);
             }}
           >No</button>  
           </div>
          </>
               }
          </div>
          <div className={`${ !showCofirmation ? "mt-2":""}  w-full  flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium`}>
            <h1>Regular full-time work?</h1>
           {!showCofirmation ? <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.FullTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("FullTimeWork", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.FullTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("FullTimeWork", false);
              }}
              label="No"
            /> 
             </> 
              : 
              <>    
               <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                formik.values.FullTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
             <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("FullTimeWork", true);
              }}
            >Yes</button>  
            </div>    
             <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
                !formik.values.FullTimeWork
                  ? "border-main-color text-main-color"
                  : ""
              }`}>
            <button
              type="button"
               className="mt-[-8px] ml-[4px] absolute"
              onClick={() => {
                formik.setFieldValue("FullTimeWork", false);
              }}
            >No</button>  
            </div>
           </>}
          </div>
          <div className={` ${ !showCofirmation ? "mt-2":""}  w-full  flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium`}>
            <h1>
              If applying for temporary work, indicate your desired length of
              employment below:
            </h1>
          </div>
          <div className=" flex flex-row w-full  flex-wrap justify-center items-center font-poppins  text-[#15223C] font-medium">
          { !showCofirmation  ? <Calendar dateFormat="dd/mm/yy"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.TempStartDate=utcDate.toDate()
              }}
              placeholder="6 Feb 2024"
              className="p-1 ml-5 border font-poppins text-[#AFAFAF] border-[#E6E9EE] w-[150px] rounded-full"
              value={formik.values.TempStartDate}
              name="TempStartDate"
              showIcon
              iconPos="left"
            />    :  <InputText
            name="TempStartDate"
            value={formik.values.TempStartDate}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border pl-2 h-[40px] mt-2 rounded-full w-[150px]  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Apply Position"
          />
          }
            <h1 className={`ml-5 ${showCofirmation ? "mt-[-10px]":""}`}>To:</h1>
         {!showCofirmation ?   <Calendar dateFormat="dd/mm/yy"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.TempEndDate=utcDate.toDate()
              }}
              placeholder="6 Feb 2024"
              className="p-1 ml-5 font-poppins border text-[#AFAFAF] border-[#E6E9EE] w-[150px] rounded-full"
              value={formik.values.TempEndDate}
              showIcon
              name="TempEndDate"
              iconPos="left"
            />: 
            <InputText
            name="TempEndDate"
            value={formik.values.TempEndDate}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full    w-[150px] ml-5 mt-2  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Apply Position"
          /> }
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1 className={`font-medium ${showCofirmation ? "mt-[-10px] text-[20px] mb-2":""}`}>Days/Hours Availabe</h1>
          </div>
          <div className="mt-2 flex flex-row w-full  flex-wrap justify-center items-center font-poppins  text-[#15223C] font-medium">
            <div className="w-[18%] ">
             {!showCofirmation ? <Dropdown
                placeholder="Monday"
                name="StartDay"
                onChange={formik.handleChange}
                value={formik.values.StartDay}
                className="border  my-dropdown font-poppins text-[#AFAFAF] border-[#D6D7DB] rounded-full w-full"
                optionLabel="label"
                optionValue="value"
                options={dayOptions}
              /> : <InputText
              name="StartDay"
              value={formik.values.TempEndDate}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full     w-[150px]  mr-5 mt-2  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Apply Position"
            />
               
             }
              {formik.touched.StartDay && formik.errors.StartDay ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.StartDay}
                </p>
              ) : null}
            </div>
            <h1 className={` ${showCofirmation ? "mt-[-8px]   ml-[15px] w-[5%] text-center ":"ml-5 mr-5"}`}>To:</h1>
            <div className="w-[18%]  ">
             { !showCofirmation ? <Dropdown
                placeholder="Saturday"
                name="EndDay"
                onChange={formik.handleChange}
                value={formik.values.EndDay}
                className="border  my-dropdown font-poppins text-[#AFAFAF] border-[#D6D7DB] rounded-full w-full"
                optionLabel="label"
                optionValue="value"
                options={dayOptions}
              /> : <InputText
              name="EndDay"
              value={formik.values.EndDay}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full     w-[150px]  mr-5 mt-2  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Apply Position"
            />
             }
              {formik.touched.EndDay && formik.errors.EndDay ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.EndDay}
                </p>
              ) : null}
            </div>
            <div className="w-[18%] ml-5">
             { !showCofirmation ?  <Dropdown
                placeholder="9:00 AM"
                name="StartTime"
                options={timeOptions}
                onChange={formik.handleChange}
                className="border  my-dropdown  font-poppins text-[#AFAFAF] border-[#D6D7DB] rounded-full w-full"
                value={formik.values.StartTime}
              /> : <InputText
              name="StartTime"
              value={formik.values.StartTime}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full     w-[150px]  mr-5 mt-2  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Apply Position"
            />
             }
              {formik.touched.StartTime && formik.errors.StartTime ? (
                <p className="font-poppins   font-medium  ml-1 text-red-500">
                  {formik.errors.StartTime}
                </p>
              ) : null}
            </div>
            <div className="w-[18%]   ml-5">
           { !showCofirmation ?   <Dropdown
                placeholder="5:00 PM "
                name="EndTime"
                onChange={(e) => {
                  formik.setFieldValue("EndTime", e.value);
                }}
                value={formik.values.EndTime}
                options={timeOptions}
                className="border my-dropdown font-poppins text-[#AFAFAF] border-[#D6D7DB] rounded-full w-full"
              />   
              : 
              <InputText
              name="EndTime"
              value={formik.values.EndTime}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full     w-[150px]  mr-5 mt-2  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Apply Position"
            />  
              }
              {formik.touched.EndTime && formik.errors.EndTime ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.EndTime}
                </p>
              ) : null}
            </div>
          </div>
          <div className="mt-2 w-full  flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium">
            <h1>Are you available to work overtime?</h1>
           {!showCofirmation ? <> <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.AvailableForOverTime
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AvailableForOverTime", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.AvailableForOverTime
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("AvailableForOverTime", false);
              }}
              label="No"
            />   
             </>  :   
              <>    
              <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               formik.values.AvailableForOverTime
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
            <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("AvailableForOverTime", true);
             }}
           >Yes</button>  
           </div>    
            <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               !formik.values.AvailableForOverTime
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
           <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("AvailableForOverTime", false);
             }}
           >No</button>  
           </div>
          </>
}
          </div>
          <div className="mt-2 w-full  items-center flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium">
            <h1 className={`${showCofirmation ? "mt-[-15px]":""}`}>If hired, on what Date can you start working?</h1>
        {!showCofirmation ?     <Calendar dateFormat="dd/mm/yy"
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.JoiningDate=utcDate.toDate()
              }}
              placeholder="6 Feb 2024"
              name="JoiningDate"
              className="p-1 ml-5 border font-poppins text-[#AFAFAF] border-[#595A5C] w-[150px] rounded-full"
              value={formik.values.JoiningDate}
              showIcon
              iconPos="left"
            />  :  <InputText
            name="JoiningDate"
            value={formik.values.JoiningDate}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border pl-2 h-[40px] rounded-full     w-[150px]  ml-5   border-[#D6D7DB] font-poppins font-medium"
            placeholder="Joining Date"
          />  
             }
            {formik.touched.JoiningDate && formik.errors.JoiningDate ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.JoiningDate}
              </p>
            ) : null}
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1 className={`font-medium ${showCofirmation ? "mt-[-10px] mb-3 text-[20px]":""}`} >Education , Traning & Experience</h1>
          </div>
          <div className="w-[18%] z-40 mt-2 ">
            <InputText
              placeholder="Institution"
              name="Institution"
              className="  text-[#AFAFAF] border pl-2     h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              onChange={formik.handleChange}
              value={formik.values.Institution}
            />
            {formik.touched.Institution && formik.errors.Institution ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Institution}
              </p>
            ) : null}
          </div>
          <div className="w-[35%] mt-2">
            <InputText
              name="InstitutionName"
              value={formik.values.InstitutionName}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2      h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Institution Name"
            />
            {formik.touched.InstitutionName && formik.errors.InstitutionName ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.InstitutionName}
              </p>
            ) : null}
          </div>
          <div className="w-[20%]  mt-2 "> 
           { 
            showCofirmation ?     <InputText
            name="InstitutionCountryId"
            value={formik.values.InstitutionCountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />:
            <Dropdown
              className="w-full  my-dropdown  border font-poppins  font-medium  rounded-md "
              name="InstitutionCountryId"
              placeholder="Country"
              onChange={formik.handleChange}
              optionValue="id"
              optionLabel="name"
              value={formik.values.InstitutionCountryId}
              options={countryOptions}
            />
 
           }
            {formik.touched.InstitutionCountryId &&
            formik.errors.InstitutionCountryId ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.InstitutionCountryId}
              </p>
            ) : null}
          </div>
          <div className="w-[20%]  mt-2 ">
           { showCofirmation ?     <InputText
            name="InstitutionCityId"
            value={formik.values.InstitutionCityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />   :  <Dropdown
              className="w-full  my-dropdown border  font-poppins font-medium  rounded-md "
              name="InstitutionCityId"
              placeholder="City"
              onChange={formik.handleChange}
              optionValue="id"
              optionLabel="name"
              value={formik.values.InstitutionCityId}
              options={institutionCities}
            />  
             
             }

            {formik.touched.InstitutionCityId &&
            formik.errors.InstitutionCityId ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.InstitutionCityId}
              </p>
            ) : null}
          </div>
          <div className="w-full mt-2">
            <InputText
              name="InstitutionAddress"
              value={formik.values.InstitutionAddress}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Institution Address"
            />
            {formik.touched.InstitutionAddress &&
            formik.errors.InstitutionAddress ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.InstitutionAddress}
              </p>
            ) : null}
          </div>
          <div className="w-[18%] mt-2 ">
            { 
              showCofirmation ?       <InputText
              name="Year"
              value={formik.values.Year}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Year"
            />:
             <Dropdown
              placeholder="2024"
              name="Year"
              className="w-full  my-dropdown  border font-poppins  font-medium  rounded-md "
              onChange={formik.handleChange}
              value={formik.values.Year}
              options={yearOptions}
            /> 
            }
            {formik.touched.Year && formik.errors.Year ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Year}
              </p>
            ) : null}
          </div>
          <div className="w-[30%]  mt-2 ">
            {showCofirmation ? 
                   <InputText
                   name="DidGraduation"
                   value={formik.values.DidGraduation}
                   onChange={formik.handleChange}
                   className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                   placeholder="Did You Graduate"
                 />
             : <Dropdown
              placeholder="Did You Graduate?"
              name="DidGraduation"
              className="w-full  my-dropdown  border font-poppins  font-medium  rounded-md "
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
              optionLabel="label"
              optionValue="value"
              onChange={formik.handleChange}
              value={formik.values.DidGraduation}
            /> 
            }
            {formik.touched.DidGraduation && formik.errors.DidGraduation ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.DidGraduation}
              </p>
            ) : null}
          </div>
          <div className="w-[49%] mt-2">
            <InputText
              name="Degree"
              value={formik.values.Degree}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border  pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Degree/Diploma"
            />
            {formik.touched.Degree && formik.errors.Degree ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Degree}
              </p>
            ) : null}
          </div>
          <div className="w-[20%] z-40 mt-2 ">
           { showCofirmation ? 
                  <InputText
                  name="Experience"
                  value={formik.values.Experience}
                  onChange={formik.handleChange}
                  className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  placeholder="Experience"
                />
            :<Dropdown
              placeholder="Experience"
              name="Experience"
              className="w-full  my-dropdown  border font-poppins  font-medium  rounded-md "
              options={[
                {
                  label: "1",
                  value: "1",
                },
                {
                  label: "2",
                  value: "2",
                },
                {
                  label: "3",
                  value: "3",
                },
                {
                  label: "4",
                  value: "4",
                },
                {
                  label: "5",
                  value: "5",
                },
                {
                  label: "6",
                  value: "6",
                },
                {
                  label: "7",
                  value: "7",
                },
              ]}
              onChange={formik.handleChange}
              value={formik.values.Experience}
            />  
            }
            {formik.touched.Experience && formik.errors.Experience ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Experience}
              </p>
            ) : null}
          </div>
          <div className="w-[25%] mt-2">
            <InputText
              name="Rank"
              value={formik.values.Rank}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Rank"
            />
            {formik.touched.Rank && formik.errors.Rank ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Rank}
              </p>
            ) : null}
          </div>
          <div className="w-[25%] mt-2">
            <InputText
              name="YearsOfService"
              value={formik.values.YearsOfService}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Total Years Of Service"
            />
            {formik.touched.YearsOfService && formik.errors.YearsOfService ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.YearsOfService}
              </p>
            ) : null}
          </div>

          <div className="w-[25%] mt-2">
            <InputText
              name="InstitutionSkills"
              value={formik.values.InstitutionSkills}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Skills/Duties"
            />
            {formik.touched.InstitutionSkills &&
            formik.errors.InstitutionSkills ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.InstitutionSkills}
              </p>
            ) : null}
          </div>
          <div className="w-full mt-2">
            <InputTextarea
              name="RelatedDetails"
              value={formik.values.RelatedDetails}
              onChange={formik.handleChange}
              className={`  text-[#AFAFAF] border pl-2  pb-6   w-full rounded border-[#D6D7DB] font-poppins font-medium ${showCofirmation ? "pb-0 pt-0 min-h-[40px] ":""}`}
              placeholder="Related Details"
            />
            {formik.touched.RelatedDetails && formik.errors.RelatedDetails ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.RelatedDetails}
              </p>
            ) : null}
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1  className={`font-medium ${showCofirmation ? "mt-[-10px] mb-3 text-[20px]":""}`} >Skills & Qualifications</h1>
          </div>
          <div className="w-full mt-2">
            <InputTextarea
              name="Skills"
              value={formik.values.Skills}
              onChange={formik.handleChange}
              className={`  text-[#AFAFAF] border pl-2  pb-6   w-full rounded border-[#D6D7DB] font-poppins font-medium ${showCofirmation ? "pb-0 pt-0 min-h-[40px] ":""}`}
            
              placeholder="Related Details"
            />
            {formik.touched.Skills && formik.errors.Skills ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.Skills}
              </p>
            ) : null}
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1 className={`font-medium ${showCofirmation ? "mt-[-10px] mb-3 text-[20px]":""}`} >Employment History</h1>
          </div>

          <div className="mt-2 items-center  flex flex-wrap flex-row justify-left text-[#595A5C] font-poppins font-medium">
            <h1 className={`${showCofirmation ? "mt-[-10px]":""}`}>Are you currently employed?</h1>
             {  !showCofirmation ? <>  <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                formik.values.CurrentlyEmployed
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("CurrentlyEmployed", true);
              }}
              label="Yes"
            />{" "}
            <Button
              type="button"
              className={`ml-5 rounded border border-[#595A5C] pl-2 pr-2 ${
                !formik.values.CurrentlyEmployed
                  ? "border-main-color text-main-color"
                  : ""
              }`}
              onClick={() => {
                formik.setFieldValue("CurrentlyEmployed", false);
              }}
              label="No"
            />   
             </> 
                :  
                <>    
              <div  className={`ml-5 mt-2 rounded border h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               formik.values.CurrentlyEmployed
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
            <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("CurrentlyEmployed", true);
             }}
           >Yes</button>  
           </div>    
            <div  className={`ml-5 rounded border mt-2  h-[25px] w-[50px] border-[#595A5C] pl-2 pr-2 ${
               !formik.values.CurrentlyEmployed
                 ? "border-main-color text-main-color"
                 : ""
             }`}>
           <button
             type="button"
              className="mt-[-8px] ml-[4px] absolute"
             onClick={() => {
               formik.setFieldValue("CurrentlyEmployed", false);
             }}
           >No</button>  
           </div>
          </>
                  
                  }
          </div>
          <div className="w-[55%] mt-2">
            <InputText
              name="CurrentEmployementDetails"
              value={formik.values.CurrentEmployementDetails}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="If yes, enter details"
            />
            {formik.touched.CurrentEmployementDetails &&
            formik.errors.CurrentEmployementDetails ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.CurrentEmployementDetails}
              </p>
            ) : null}
          </div>
          <div className="w-full flex flex-wrap justify-between flex-row">
            <div className="mt-2 bg-custom-gradient flex flex-wrap justify-center items-center rounded text-white font-poppins w-[40px] h-[40px]">
              <h1 className={`${showCofirmation ? "h-[40px]":""}`}>01</h1>
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employername1st"
                value={formik.values.employername1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Name Of Employer"
              />
              {formik.touched.employername1st &&
              formik.errors.employername1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employername1st}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employercompanyname1st"
                value={formik.values.employercompanyname1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Company Name"
              />
              {formik.touched.employercompanyname1st &&
              formik.errors.employercompanyname1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercompanyname1st}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employercontact1st"
                value={formik.values.employercontact1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Mobile No"
              />
              {formik.touched.employercontact1st &&
              formik.errors.employercontact1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercontact1st}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employerbusinesstype1st"
                value={formik.values.employerbusinesstype1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Business Type"
              />
              {formik.touched.employerbusinesstype1st &&
              formik.errors.employerbusinesstype1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerbusinesstype1st}
                </p>
              ) : null}
            </div>
            <div className="w-[68%] mt-2">
              <InputText
                name="employeraddress1st"
                value={formik.values.employeraddress1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Address"
              />
              {formik.touched.employeraddress1st &&
              formik.errors.employeraddress1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employeraddress1st}
                </p>
              ) : null}
            </div>
            <div className="w-[24%]  mt-2 ">
            { showCofirmation ? <InputText
            name="employercountry1st"
            value={formik.values.employercountry1st}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />  :  <Dropdown
                placeholder="Country"
                name="employercountry1st"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
                onChange={formik.handleChange}
                value={formik.values.employercountry1st}
                options={countryOptions}
                optionLabel="name"
                optionValue="id"
              />  
               
               
            }
              {formik.touched.employercountry1st &&
              formik.errors.employercountry1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercountry1st}
                </p>
              ) : null}
            </div>
            <div className="w-[24%]  mt-2 "> 
              { 
              showCofirmation ? <InputText
              name="employercity1st"
              value={formik.values.employercity1st}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Country"
            /> :
              <Dropdown
                placeholder="City"
                className="w-full  my-dropdown border  font-poppins font-medium  rounded-md "
                name="employercity1st"
                optionLabel="name"
                optionValue="id"
                onChange={(e) => {
                  formik.setFieldValue("employercity1st", e.value);
                }}
                value={formik.values.employercity1st}
                options={employer1stCities}
              /> 
              }
              {formik.touched.employercity1st &&
              formik.errors.employercity1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercity1st}
                </p>
              ) : null}
            </div>
            <div className="w-[24%] mt-2">
              <InputText
                name="employersalarypay1st"
                value={formik.values.employersalarypay1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2   h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Salary Pay"
              />
              {formik.touched.employersalarypay1st &&
              formik.errors.employersalarypay1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employersalarypay1st}
                </p>
              ) : null}
            </div>
           
            <div className="w-[24%] mt-2">
              <InputText
                name="employerservicesduties1st"
                value={formik.values.employerservicesduties1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2   h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Position/Duties"
              />
              {formik.touched.employerservicesduties1st &&
              formik.errors.employerservicesduties1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerservicesduties1st}
                </p>
              ) : null}
            </div>
            <div className="w-full mt-2">
              <InputTextarea
                name="employerreasonforleaving1st"
                value={formik.values.employerreasonforleaving1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Reason For Leaving"
              />
              {formik.touched.employerreasonforleaving1st &&
              formik.errors.employerreasonforleaving1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerreasonforleaving1st}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between flex-row">
            <div className="mt-2 bg-custom-gradient flex flex-wrap justify-center items-center rounded text-white font-poppins w-[40px] h-[40px]">
              <h1 className={`${showCofirmation ? "h-[40px]":""}`}>02</h1>
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employername2nd"
                value={formik.values.employername2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Name Of Employer"
              />
              {formik.touched.employername2nd &&
              formik.errors.employername2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employername2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employercompanyname2nd"
                value={formik.values.employercompanyname2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Company Name"
              />
              {formik.touched.employercompanyname2nd &&
              formik.errors.employercompanyname2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercompanyname2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employercontact2nd"
                value={formik.values.employercontact2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Mobile No"
              />
              {formik.touched.employercontact2nd &&
              formik.errors.employercontact2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercontact2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="employerbusinesstype2nd"
                value={formik.values.employerbusinesstype2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Business Type"
              />
              {formik.touched.employerbusinesstype2nd &&
              formik.errors.employerbusinesstype2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerbusinesstype2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[68%] mt-2">
              <InputText
                name="employeraddress2nd"
                value={formik.values.employeraddress2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Address"
              />
              {formik.touched.employeraddress2nd &&
              formik.errors.employeraddress2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employeraddress2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[24%]  mt-2 ">
                {showCofirmation ? <InputText
              name="employercountry2nd"
              value={formik.values.employercountry2nd}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Country"
            /> :<Dropdown
                placeholder="Country"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
                name="employercountry2nd"
                onChange={formik.handleChange}
                value={formik.values.employercountry2nd}
                options={countryOptions}
                optionLabel="name"
                optionValue="id"
              /> 
                }
              {formik.touched.employercountry2nd &&
              formik.errors.employercountry2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercountry2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[24%]  mt-2 ">  
             { 
             showCofirmation ? 
             <InputText
             name="employercity2nd"
             value={formik.values.employercity2nd}
             onChange={formik.handleChange}
             className="text-[#AFAFAF] border h-[40px] pl-2    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
             placeholder="City"
           />  :
              <Dropdown
                placeholder="City"
                name="employercity2nd"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
                onChange={formik.handleChange}
                value={formik.values.employercity2nd}
                optionLabel="name"
                optionValue="id"
                options={employer2ndCities}
              /> 
             }
              {formik.touched.employercity2nd &&
              formik.errors.employercity2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employercity2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[24%] mt-2">
              <InputText
                name="employersalarypay2nd"
                value={formik.values.employersalarypay2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Salary Pay"
              />
              {formik.touched.employersalarypay2nd &&
              formik.errors.employersalarypay2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employersalarypay2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[24%] mt-2">
              <InputText
                name="employerservicesduties2nd"
                value={formik.values.employerservicesduties2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2   h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Position/Duties"
              />
              {formik.touched.employerservicesduties2nd &&
              formik.errors.employerservicesduties2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerservicesduties2nd}
                </p>
              ) : null}
            </div>
            <div className="w-full mt-2">
              <InputTextarea
                name="employerreasonforleaving2nd"
                value={formik.values.employerreasonforleaving2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Reason For Leaving"
              />
              {formik.touched.employerreasonforleaving2nd &&
              formik.errors.employerreasonforleaving2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.employerreasonforleaving2nd}
                </p>
              ) : null}
            </div>
          </div>
          <div className="mt-2 flex flex-wrap flex-row w-full    justify-center bg-[black] text-white font-poppins font-semibold text-[31px]">
            <h1  className={`font-medium ${showCofirmation ? "mt-[-10px] mb-3 text-[20px]":""}`}>References</h1>
          </div>
          <div className="w-full mt-2 ">
            <h1 className={`font-poppins font-medium text-[18px] text-[#595A5C] ${showCofirmation ? "mb-2":""} `}>
              List below two persons who have knowledge of your work performance
              within the last four years. Please include professional references
              only.
            </h1>
          </div>
          <div className="w-full flex flex-wrap justify-between flex-row">
            <div className="mt-2 bg-custom-gradient flex flex-wrap justify-center items-center rounded text-white font-poppins w-[40px] h-[40px]">
              <h1  className={`${showCofirmation ? "h-[40px]":"" }`}  >01</h1>
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="referencename1st"
                value={formik.values.referencename1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2  h-[40px]     w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Name"
              />
              {formik.touched.referencename1st &&
              formik.errors.referencename1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencename1st}
                </p>
              ) : null}
            </div>
            <div className="w-[20%] mt-2">
              <InputText
                name="referencecontact1st"
                value={formik.values.referencecontact1st}
                onChange={formik.handleChange} 
                className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Mobile No"
              />
              {formik.touched.referencecontact1st &&
              formik.errors.referencecontact1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecontact1st}
                </p>
              ) : null}
            </div>

            <div className="w-[20%]  mt-2 ">  
             { 
              showCofirmation ?    
              <InputText
              name="referencecountry1st"
              value={formik.values.referencecountry1st}
              onChange={formik.handleChange} 
              className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Mobile No"
            />   : 
              <Dropdown
                placeholder="Country"
                name="referencecountry1st"
                onChange={formik.handleChange}
                options={countryOptions}
                optionLabel="name"
                optionValue="id"
                value={formik.values.referencecountry1st}
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
              /> 
             }
              {formik.touched.referencecountry1st &&
              formik.errors.referencecountry1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecountry1st}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]  mt-2 "> 
             
            { 
              showCofirmation ?    
              <InputText
              name="referencecity1st"
              value={formik.values.referencecity1st}
              onChange={formik.handleChange} 
              className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Mobile No"
            />   : 
              <Dropdown
                placeholder="City"
                name="referencecity1st"
                onChange={formik.handleChange}
                value={formik.values.referencecity1st}
                options={citiesReference1st}
                optionLabel="name"
                optionValue="id"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
              />  
            }
              {formik.touched.referencecity1st &&
              formik.errors.referencecity1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecity1st}
                </p>
              ) : null}
            </div>
            <div className="w-[49%] mt-2">
              <InputText
                name="referenceaddress1st"
                value={formik.values.referenceaddress1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Address"
              />
              {formik.touched.referenceaddress1st &&
              formik.errors.referenceaddress1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referenceaddress1st}
                </p>
              ) : null}
            </div>
            <div className="w-[25%] mt-2">
              <InputText
                name="referenceoccupation1st"
                value={formik.values.referenceoccupation1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Occupation"
              />
              {formik.touched.referenceoccupation1st &&
              formik.errors.referenceoccupation1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referenceoccupation1st}
                </p>
              ) : null}
            </div>

            <div className="w-[25%] mt-2">
              <InputText
                name="referencerelationyear1st"
                value={formik.values.referencerelationyear1st}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Relation Years"
              />
              {formik.touched.referencerelationyear1st &&
              formik.errors.referencerelationyear1st ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencerelationyear1st}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between flex-row">
            <div className="mt-2 bg-custom-gradient flex flex-wrap justify-center items-center rounded text-white font-poppins w-[40px] h-[40px]">
              <h1 className={`${showCofirmation? "h-[40px]":""}`}>02</h1>
            </div>
            <div className="w-[30%] mt-2">
              <InputText
                name="referencename2nd"
                value={formik.values.referencename2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2    h-[40px]  w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Name"
              />
              {formik.touched.referencename2nd &&
              formik.errors.referencename2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencename2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[20%] mt-2">
              <InputText
                name="referencecontact2nd"
                value={formik.values.referencecontact2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2  h-[40px]     w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Mobile No"
              />
              {formik.touched.referencecontact2nd &&
              formik.errors.referencecontact2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecontact2nd}
                </p>
              ) : null}
            </div>

            <div className="w-[20%] mt-2 ">  
            { 
              showCofirmation ?    
              <InputText
              name="referencecountry2nd"
              value={formik.values.referencecountry2nd}
              onChange={formik.handleChange} 
              className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Mobile No"
            />   : 
              <Dropdown
                placeholder="Country"
                name="referencecountry2nd"
                onChange={formik.handleChange}
                value={formik.values.referencecountry2nd}
                options={countryOptions}
                optionLabel="name"
                optionValue="id"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
              /> 
            }
              {formik.touched.referencecountry2nd &&
              formik.errors.referencecountry2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecountry2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]  mt-2 ">  
            { 
              showCofirmation ?    
              <InputText
              name="referencecity2nd"
              value={formik.values.referencecity2nd}
              onChange={formik.handleChange} 
              className="  text-[#AFAFAF] border pl-2    h-[40px]   w-full rounded border-[#D6D7DB] font-poppins font-medium"
              placeholder="Mobile No"
            />   : 
              <Dropdown
                placeholder="City"
                name="referencecity2nd"
                onChange={(e) => {
                  formik.setFieldValue("referencecity2nd", e.value);
                }} 
                 
                value={formik.values.referencecity2nd}
                options={citiesReference2nd}
                optionLabel="name"
                optionValue="id"
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
              /> 
              }
              {formik.touched.referencecity2nd &&
              formik.errors.referencecity2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencecity2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[49%] mt-2">
              <InputText
                name="referenceaddress2nd"
                value={formik.values.referenceaddress2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Address"
              />
              {formik.touched.referenceaddress2nd &&
              formik.errors.referenceaddress2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referenceaddress2nd}
                </p>
              ) : null}
            </div>
            <div className="w-[25%] mt-2">
              <InputText
                name="referenceoccupation2nd"
                value={formik.values.referenceoccupation2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Occupation"
              />
              {formik.touched.referenceoccupation2nd &&
              formik.errors.referenceoccupation2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referenceoccupation2nd}
                </p>
              ) : null}
            </div>

            <div className="w-[25%] mt-2">
              <InputText
                name="referencerelationyear2nd"
                value={formik.values.referencerelationyear2nd}
                onChange={formik.handleChange}
                className="  text-[#AFAFAF] border pl-2     h-[40px] w-full rounded border-[#D6D7DB] font-poppins font-medium"
                placeholder="Relation Years"
              />
              {formik.touched.referencerelationyear2nd &&
              formik.errors.referencerelationyear2nd ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.referencerelationyear2nd}
                </p>
              ) : null}
            </div>
          </div>
          <div className="bg-custom-gradient w-full mt-[45px]">
            <h1 className={`bg-[#000000] text-white font-poppins absolute mt-[-31px] font-bold text-[31px] w-[300px] p-1 pl-10 font-medium ${showCofirmation ? "mt-[-20px]":""}`}>
            { showCofirmation ? <p className="mt-[-20px]  mb-2">Certifications </p>:"Certifications" 
}
            </h1>
            <h1 className={`p-4 ${showCofirmation ? "mt-4":"mt-10"} mb-5 font-poppins font-medium text-[18px]`}>
              I certify that the information contained in this application is
              true and complete. I understand that false information may be
              grounds for not hiring me or for immediate termination of
              employment if I am hired. I authorize the verification of any and
              all information listed above.
            </h1>
          </div>
          <div className="flex mt-10  ml-20 flex-wrap flex-row justify-left w-full">
            <h1 className="text-[18px] font-poppins text-[#595A5C]  font-medium ">
              Employee Signature:
            </h1>
            <div className=" w-[200px] border-b border-[#000000] border-b-[1px] ml-5 "></div>
          </div>
          <div className={` flex w-full flex-wrap flex-row bg-custom-gradient  justify-center  text-black font-poppins font-semibold text-[31px] ${showCofirmation? "mt-6":"mt-2"}`}>
            <h1 className={`font-bold ${showCofirmation ? "mt-[-10px] mb-3 text-[24px]":""}`}>For office use</h1>
          </div>
          <div className="mt-2 flex flex-wrap flex-row  w-full justify-left text-[#595A5C] font-poppins font-medium">
            <h1>Can he be hired as an employee?</h1>
          </div>
          <div className="w-full mt-2 font-poppins font-medium ">
            <InputTextarea
              placeholder=""
              className="w-full p-4 border border-[#D6D7DB] rounded"
              name="hiredasanemployee"
              onChange={formik.handleChange}
              value={formik.values.hiredasanemployee}
            />
            {formik.touched.hiredasanemployee &&
            formik.errors.hiredasanemployee ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.hiredasanemployee}
              </p>
            ) : null}
          </div>
          <div className="w-full flex flex-wrap items-center flex-row justify-left">
            <div className="w-[20%] z-40 mt-2 ">
              <InputText className="w-full p-4 border font-poppins font-medium  border-[#D6D7DB] rounded" />
              {formik.touched.hiringmanager && formik.errors.hiringmanager ? (
                <p className="font-poppins font-medium  ml-1 text-red-500">
                  {formik.errors.hiringmanager}
                </p>
              ) : null}
            </div>
            <h1 className="font-poppins mt-2 mt-10 ml-10 text-[18px] font-medium text-[#595A5C]">
              Hiring Manager Signature
            </h1>
            <div className=" w-[200px] border-b  mt-[30px]   border-[#000000] border-b-[1px] ml-5 "></div>
          </div>
          <div className="w-full mt-2">
            <InputTextarea
              className="w-full p-4 border font-poppins font-medium  border-[#D6D7DB] rounded"
              name="ownerdecision"
              onChange={formik.handleChange}
              value={formik.values.ownerdecision}
            />
            {formik.touched.ownerdecision && formik.errors.ownerdecision ? (
              <p className="font-poppins font-medium  ml-1 text-red-500">
                {formik.errors.ownerdecision}
              </p>
            ) : null}
          </div>
          <div className="flex  flex-wrap justify-between flex-row w-full   mt-2">
            <div className="w-[50%] flex flex-wrap justify-left flex-row">
              <h1 className="font-poppins w-[160px]  mt-2 mt-10  text-[18px] font-medium text-[#595A5C]">
                Owner Signature:{" "}
              </h1>
              <div className=" w-[calc(100%-220px)] border-b  mt-[30px]   border-[#000000] border-b-[1px] ml-5 "></div>
            </div>
            <div className="w-[50%] flex flex-wrap justify-left flex-row">
              <h1 className="font-poppins w-[160px] mt-2 mt-10  text-[18px] font-medium text-[#595A5C]">
                Company Stemp:{" "}
              </h1>
              <div className=" w-[calc(100%-220px)] border-b  mt-[30px]   border-[#000000] border-b-[1px] ml-5 "></div>
            </div>
          </div>
          <div className="flex flex-row w-full mt-4 flex-wrap justify-center ">
            <h1 className={`font-poppins font-medium text-black  ${showCofirmation ? "text-[20px]  ":"text-[31px] mt-4"}`}>
             {showCofirmation ? "":"Upload "}<span className="font-medium">Employee</span> ID CARD
            </h1>
          </div>
          <div className={`w-full ${showCofirmation ? "mt-10":"mt-10"}  flex flex-wrap flex-row justify-evenly`}>
          <div className="  flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[300px] h-[150px] ":"w-[400px] h-[200px]"}  rounded-lg overflow-hidden bg-[#EEEEEE] flex flex-wrap flex-row justify-center items-center`}   onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicFront(reader.result)
                }
              formik.setFieldValue("IdCardFront",e.target.files[0])         
               setShowCnicFrontError(false)
              }  
               input.click()
          }}>
             
              {cnicFrontUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Front
              </h1> 
              :<img src={cnicFrontUrl} className="h-[100%] w-auto"/>   
        }      
         </div>
           { showCnicFrontError ? (
              <p className="font-poppins block w-full font-medium  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
            
             </div>
            <div className=" flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[300px] h-[150px] ":"w-[400px] h-[200px]"}  rounded-lg bg-[#EEEEEE] flex flex-wrap flex-row overflow-hidden justify-center items-center`} onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicBack(reader.result)
                }  
              formik.setFieldValue("IdCardBack",e.target.files[0])  
              setShowCnicBackError(false)
              }  
              input.click()
          }}>
               {cnicBackUrl === null ?   <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Back
              </h1> 
              :<img src={cnicBackUrl} className="h-[100%] w-auto"/>   
        }   
            </div> 
            { showCnicBackError ? (
              <p className="font-poppins w-full block font-medium  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
          </div> 
          </div> 
           { !showCofirmation ?    
           <>
          <div className="flex flex-row w-full mt-2 flex-wrap justify-center ">
            <h1 className="font-poppins font-bold text-[31px] font-medium ">
              Upload <span>Other</span> Documents
            </h1>
          </div>
          <div className="flex flex-wrap text-[#D6D7DB] font-poppins font-medium mt-10 w-full justify-center flex-row ">
            <Button
              type="button"
              label="Upload  "
              className="bg-[#EEEEEE] p-1 pl-4 pr-4 ml-5 "
            />
            <Button
              type="button"
              label="Upload"
              className="bg-[#EEEEEE] p-1 pl-4 pr-4 ml-5 "
            />
            <Button
              type="button"
              label="Upload"
              className="bg-[#EEEEEE] p-1 pl-4 pr-4 ml-5"
            />
          </div>   
           </>
          :""      
          }
          {!showCofirmation ? 
          <div className="flex font-poppins mt-10  font-medium flex-row w-full flex-wrap justify-center ">
           
            <Button  
             onClick={() => {
              if (formik.values.Image !== "") {
                if (formik.values.IdCardBack !== "") {
                  if (formik.values.IdCardFront !== "") {
                    formik.handleSubmit();
                  } else {
                    setShowCnicFrontError(true);

                    formik.handleSubmit();
                  }
                } else {
                  setShowCnicBackError(true);
                  if (formik.values.IdCardFront === "") {
                    setShowCnicFrontError(true);
                  }

                  formik.handleSubmit();
                }
              } else {
                setShowImageError(true);
                if (formik.values.IdCardBack === "") {
                  setShowCnicBackError(true);
                }
                if (formik.values.IdCardFront === "") {
                  setShowCnicFrontError(true);
                }

                formik.handleSubmit();
              }
            }}
            type="button"
            disabled={showPageLoader}
            className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${showPageLoader ? "":"Submit"}`}   
            iconPos="center"
            icon={showPageLoader? <CommonLoader/>:""}
          /> 
           {/* <Button
              type="button"
              label="Draft"
              className="bg-[#D6D7DB] rounded-xl ml-5 w-[100px]"
            />*/}
          </div>    
           :""}
        </div>   
        
      </form> 
       <Toast className="z-50 mt-[80px]" ref={toast}/>
    </div>  
    </div>
  );
}
