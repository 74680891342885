import React from "react";
import { useState } from "react";
import { StarIcon } from "@heroicons/react/solid";
import ViewAllBranches from "./all_branches/view_all_branches";
import ViewAllAdmins from "./all_admins/view_all_admins";
import ViewAllMaketers from "./all_marketers/view_all_marketers";
import Loader from "../../../../Common/Components"; 
import ViewAllEmployees from "./employees/view_all_employees";
import ViewallAccountants from "./accountants/view_all_accountants";
import ViewallManagers from "./managers/view_all_managers";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function UsersDetails({ usersData, loader,setRenderTime,setRefreshOnActive }) {
  const branches = usersData?.branches ? usersData.branches : [];
  const admins = usersData.admins ? usersData.admins : [];
  const Employees = usersData.employees ? usersData.employees : [];
  const Marketers = usersData.marketers ? usersData.marketers : []; 
  const TruncateString = (text, maxLength = 15 ) => {
    if (text.length > maxLength) {
      return <span>{text.substring(0, maxLength - 3) + '...'}</span>;
    }
    return <span>{text}</span>;
  };
  const Managers = usersData.managers ? usersData.managers : [];
  // const Accountants = usersData.employees ? usersData.employees :[]
  const [showBranches, setViewAllBranches] = useState(false);
  const [showAdmins, setViewAllAdmins] = useState(false);
  const [showMarketers, setViewAllMarketers] = useState(false);
  const [showEmployees,setViewallEmployees]=useState(false)
  const [showAccountants,setViewallAccountants]=useState(false)
  const [showManagers,setViewallManagers]=useState(false)
  
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format the date to "MMM DD, YY" format
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "2-digit",
    });

    return formattedDate;
  }

  return (
    <>
      <div  className="flex flex-wrap w-full flex-row   justify-around justify-center items-start] ">
        <div className=" flex flex-wrap w-[90%]   width1130:w-[31%] width1000:w-[48%] width1240:w-[31%]  flex-row mt-2  justify-around rounded-2xl p-4 border  border-[#E6E9EE] shadow-sm ">
          <div className="flex flex-wrap font-poppins  h-[55px] font-medium flex-row w-full justify-between border-b pb-2 border-b-main-color ">
            <h1 className=" text-[20px] text-left  mt-2">Branches</h1>
            <h1
              className="text-main-color mt-2 cursor-pointer"
              onClick={() => {
                setViewAllBranches((prev) => !prev);
              }}
            >
              {" "}
              View All
            </h1>
          </div>
          {loader ? (
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {branches.map((item) => (
                <div className="mt-4 font-poppins w-full flex flex-wrap flex-row justify-between ">    
                <div className="flex flex-row flex-wrap justify-left">
                  <div className="rounded-[50%]  overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img  onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="branchimg"
                    />
                  </div>
                  <div className=" ml-2  text-left ">
                    <h1 className="font-medium text-[16px]">  {TruncateString(item?.name)}</h1>
                    <p className="text-[#AFAFAF]">
                      {formatDate(item.createdAt)}
                    </p>
                  </div> 
                  </div>
                  <h1 className=" w-[80px] self-center font-poppins text-right  font-medium text-[#AFAFAF]">
                    {item.details}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className=" flex flex-wrap w-[90%]   width1240:w-[31%] width1130:w-[31%] width1000:w-[48%]   flex-row mt-2 justify-around rounded-2xl p-4 border border-[#E6E9EE] shadow-sm  ">
          <div className="flex flex-wrap font-poppins h-[55px] font-medium flex-row w-full border-b pb-2 border-b-main-color justify-between ">
            <h1 className=" text-[20px] text-left  mt-2">Managers</h1>
            <h1 className="text-main-color mt-2 cursor-pointer" onClick={()=>{ 
              setViewallManagers(prev=>!prev)
            }}> View All</h1>
          </div>
          {loader ? (
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {Managers.map((item) => (
                <div className="mt-4 font-poppins w-full flex flex-wrap flex-row justify-between ">     
                 <div className="flex flex-wrap flex-row justify-left">
                 <div className="rounded-[50%]  overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="branchimg"
                    />
                  </div>
                 <div className="ml-2  text-left">
                    <h1 className="font-medium text-[16px] font-medium ">
                    {TruncateString(item?.name)}
                    </h1>
                    <p className="text-[#AFAFAF] ">
                      {formatDate(item.createdAt)}
                    </p>
                  </div> 
                   </div>
                  <h1 className=" w-[80px] self-center font-poppins text-right font-medium text-[#AFAFAF] ">
                    {item.details}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className=" flex flex-wrap w-[90%]   width1240:w-[31%] width1130:w-[31%] width1000:w-[48%]   flex-row mt-2 justify-around rounded-2xl p-4 border border-[#E6E9EE] shadow-sm  ">
          <div className="flex flex-wrap font-poppins font-medium h-[55px] flex-row w-full border-b pb-2 border-b-main-color justify-between ">
            <h1 className=" text-[20px] text-left  mt-2">Marketers</h1>
            <h1
              className="text-main-color mt-2 cursor-pointer"
              onClick={() => {
                setViewAllMarketers(true);
              }}
            >
              {" "}
              View All
            </h1>
          </div>
          {loader ? (
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {Marketers.map((item) => (
                <div className="mt-4 font-poppins w-full flex flex-wrap flex-row justify-between "> 
                 <div  className="flex flex-row flex-wrap justify-left"
                 >
                  <div className="rounded-[50%]  overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img  onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="marketerimg"
                    />
                  </div>
                  <div className=" ml-2  text-left">
                    <h1 className="font-medium text-left text-[16px]">
                      {TruncateString(item?.name)}
                    </h1>
                    <p className="text-[#AFAFAF] text-left">
                      {formatDate(item.createdAt)}
                    </p>
                  </div>     
                  </div>
                  <h1 className=" w-[80px]  self-center font-poppins text-right font-medium text-[#AFAFAF] ">
                    {[1, 2, 3, 4, 5].map((item2) => {
                      return (
                        <StarIcon
                          className={`inline w-[16px] h-[16px] ${
                            item2 <= item.stars ? "text-main-color" : ""
                          }`}
                        />
                      );
                    })}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className=" flex flex-wrap w-[90%]   width1240:w-[31%] width1130:w-[31%] width1000:w-[48%]   flex-row mt-2 justify-around rounded-2xl p-4 border border-[#E6E9EE] shadow-sm ">
          <div className="flex flex-wrap font-poppins h-[55px] font-medium flex-row w-full border-b pb-2 border-b-main-color justify-between ">
            <h1 className=" text-[20px] text-left  mt-2">Employees</h1>
            <h1 className="text-main-color mt-2 cursor-pointer" onClick={()=>{ 
              setViewallEmployees(prev=>!prev)
            }}> View All</h1>
          </div>
          {loader ? (
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {Employees.map((item) => (
                <div className="mt-4 font-poppins w-full flex flex-wrap flex-row justify-between "> 
                <div className="flex flex-row flex-wrap justify-left">
                  <div className="rounded-[50%]  overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img  onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="employeeimg"
                    />
                  </div>
                  <div className="text-left ml-2  ">
                    <h1 className="font-medium text-[16px]">{TruncateString(item?.name)}</h1>
                    <p className="text-[#AFAFAF]">
                      {formatDate(item.createdAt)}
                    </p>
                  </div> 
                   </div>
                  <h1 className=" w-[80px] self-center font-poppins text-right text-[#AFAFAF] font-medium">
                    {item.details}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className=" flex flex-wrap w-[90%]   width1240:w-[31%] width1130:w-[31%] width1000:w-[48%]   flex-row mt-2 justify-around rounded-2xl p-4 border border-[#E6E9EE] shadow-sm ">
          <div className="flex flex-wrap font-poppins h-[55px] font-medium flex-row w-full border-b pb-2 border-b-main-color justify-between ">
            <h1 className=" text-[20px] text-left  mt-2">Accountants</h1>
            <h1 className="text-main-color mt-2 cursor-pointer" onClick={()=>{ 
              setViewallAccountants(prev=>!prev)
            }}> View All</h1>
          </div>
          {loader ? ( 
            
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {Employees.map((item) => (
                <div className="mt-4 font-poppins  w-full flex flex-wrap flex-row justify-between "> 
                   <div className="flex flex-wrap flex-row justify-left">
                  <div className="rounded-[50%]   overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img  onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="accountantimg"
                    />
                  </div>
                  <div className=" ml-2 text-left  ">
                    <h1 className="font-medium text-[16px]">  {TruncateString(item?.name)}</h1>
                    <p className="text-[#AFAFAF]">
                      {formatDate(item.createdAt)}
                    </p>
                  </div> 
                   </div>
                  <h1 className=" w-[80px] self-center font-poppins text-right text-[#AFAFAF] font-medium">
                    {item.details}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex  flex-wrap w-[90%]   width1240:w-[31%] width1130:w-[31%] width1000:w-[48%]  mt-2  flex-row justify-around rounded-2xl p-4 border border-[#E6E9EE] shadow-sm ">
          <div className="flex flex-wrap font-poppins h-[55px] font-medium flex-row w-full border-b pb-2 border-b-main-color justify-between ">
            <h1 className=" text-[20px] text-left  mt-2">Admins</h1>
            <h1
              className="text-main-color mt-2 cursor-pointer"
              onClick={() => {
                setViewAllAdmins((prev) => !prev);
              }}
            >
              {" "}
              View All
            </h1>
          </div>
          {loader ? (
            <div className="mt-[40px]"><CommonLoaderBlue /></div>
          ) : (
            <div className="h-[190px] w-full">
              {admins.map((item) => (
                <div className="mt-4 font-poppins w-full flex flex-wrap flex-row justify-between "> 
                <div className="flex flex-row flex-wrap justify-left">
                <div className="rounded-[50%]  overflow-hidden bg-seller-avatar w-[40px] h-[40px]   border border-[#E6E9EE] shadow-sm">
                    <img onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}
                      className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]"
                      src={item.image}
                      alt="branchimg"
                    />
                  </div>
                   <div className="text-left ml-2  ">
                    <h1 className="font-medium text-[16px]">  {TruncateString(item?.name)}</h1>
                    <p className="text-[#AFAFAF]">
                      {formatDate(item.createdAt)}
                    </p>
                  </div> 
                    </div>
                  <h1 className=" w-[80px] self-center font-poppins text-right  text-[#AFAFAF] font-medium">
                    {item?.details}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div>
        {showBranches ? (
          <ViewAllBranches
            setViewAllBranches={setViewAllBranches}  
            setRenderTime={setRenderTime} 
            setRefreshOnActive={setRefreshOnActive}
            branches={branches}
          />
        ) : undefined}
      </div>
      <div>
        {showAdmins ? (
          <ViewAllAdmins setViewAllAdmins={setViewAllAdmins} admins={admins} 
          setRenderTime={setRenderTime} 
          setRefreshOnActive={setRefreshOnActive}  
          />    
          
        ) : undefined}
      </div>
      <div>
        {showMarketers ? (
          <ViewAllMaketers
            setViewAllMarketers={setViewAllMarketers}
            setRenderTime={setRenderTime} 
            setRefreshOnActive={setRefreshOnActive}
          />
        ) : undefined}
      </div> 
      <div>
        {showEmployees ? (
          <ViewAllEmployees
            setViewallEmployees={setViewallEmployees} 
            setRenderTime={setRenderTime} 
            setRefreshOnActive={setRefreshOnActive}
           
          />
        ) : undefined}
      </div> 
      <div>
        {showAccountants ? (
          <ViewallAccountants 
          setRenderTime={setRenderTime} 
          setRefreshOnActive={setRefreshOnActive}
            setViewallAccountants={setViewallAccountants}
           
          />
        ) : undefined}
      </div> 
      <div>
        {showManagers ? (
          <ViewallManagers 
          setRenderTime={setRenderTime} 
          setRefreshOnActive={setRefreshOnActive}
            setViewallManagers={setViewallManagers}
           
          />
        ) : undefined}
      </div>
    </>
  );
}
