import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import Axios from "axios";    
import Loader from "../../Utils";
import { render } from "@testing-library/react";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function Faqs(){   
    const [loaderShow,setLoaderShow]=useState(true)  
    const [faqsList,setFaqsList]=useState([])
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    let token = parseLoginRes.token;
   const [searchValue,setSearchValue]=useState("") 
    const [renderHere,setRenderHere]=useState(1)
  useEffect(()=>{   
     if(renderHere === 1){
    setLoaderShow(true) 
     }
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Faqs/List`, {
        headers: { Authorization: `Bearer ${token}` }, 
        params:{Search:searchValue} 
      })
        .then((res) => {  
          setRenderHere(prev=>{ 
            prev=prev=1} )
          //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
       setFaqsList(res?.data) 
       setLoaderShow(false)
        })
        .catch(() => {  

             setLoaderShow(false)
          //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
        });
  },[searchValue])

     return(  
   <div className="w-[49%] max-h-[calc(100vh-200px)] overflow-y-auto  relative bg-[#EEEEEE] p-2 rounded-md flex text-black  font-poppins font-normal flex-wrap flex-row profilesidebar justify-around"> 
   <h1 className="text-black font-semibold text-[22px] mt-4">Frequently Asked Question</h1>
   <div className="w-[calc(100%-20px)] absolute mt-[60px] ml-2 mr-2 flex flex-wrap flex-row justify-between"> 
           <InputText className="pl-2 h-[40px] w-[calc(100%-120px)] rounded-l-full" value={searchValue} onChange={(e)=>{ 
            setSearchValue(e.target.value)
           }} placeholder="Search"/> 
                <div className="h-[40px]"> 
                    <i className="pi pi-search w-[120px] h-[40px]   bg-custom-gradient rounded-r-full text-white pl-[50px] p-2"></i>
                </div>
            </div>  
      {loaderShow ? <div className="w-[100%] mt-4 "><CommonLoaderBlue/> </div> :  
           
        <div className="flex flex-wrap w-full flex-row  mt-[60px]">  
         
        { 
          faqsList?.map(item=>{ 
            return( 
                <div className="flex bg-[#F1AA21] p-2 w-full  mt-2 rounded-lg flex-wrap flex-row justify-between items-center " > 
                    <div className="w-[50px] h-[50px] border border-[4px] border-solid border-[black] rounded-full  flex flex-wrap flex-row justify-center items-center">  
                      <i className="pi pi-question fill-[black] text-[31px] text-[black] font-bold"></i>
                     </div> 
                     <div className="w-[calc(100%-100px)]">  
                         <h1 className="font-semibold">{item.question}</h1> 
                         <h1>{item.answer}</h1> 
                        </div>   
                   
                    </div>
            )
          })
          }     
          </div>}
   </div> 
     )
}