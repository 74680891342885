import React,{useState} from "react";
import Loader from "../../../../../../Components";
import CommonLoaderBlue from "../../../../../../Components/Loader/LoaderBlue";
export default function AllDetails({dashboardData,loader}) {  
    
  const AllDetailsPage = [
    {
      label: "Total Marketers",
      color: "#F7931DCC",
      number: `${dashboardData?.totalMarketers}`,
      numberColor: "#15223C",
    },
    {
      label: "Total Customers",
      color: "#F7931DCC",
      number: `${dashboardData?.totalCustomers}`,
      numberColor: "#15223C",
    },
    {
      label: "Active Plans",
      color: "#2DD683",
      number: `${dashboardData?.totalPlans}`, 
  
      numberColor: "#15223C",
    },
    {
      label: "Monthly Closed Sales",
      color: "#2094FF",
      number: `${dashboardData?.monthlyClosedSales}`,
  
      numberColor: "#15223C",
    },
    {
      label: "Monthly Returned Sales",
      color: "#E74A51",
      number: `${dashboardData?.monthlyReturnedSales}`,
      numberColor: "#15223C",
    },
  ];
  return (
    <div className="shadow-sm h-[352px] overflow-y-auto width910:w-[100%] width500:w-[100%] p-2 rounded-2xl    border border-[#E6E9EE]">
      <div className="flex flex-wrap justify-between items-center flex-row ">
        <h1 className="font-poppins font-bold pl-0 p-4  font-semibold text-[20px]">More Details</h1>
    
      </div>     
      { 
      loader ? <div className="mt-[90px] flex flex-wrap flex-row justify-center items-center"><CommonLoaderBlue/></div> :<>
      <div>
        {AllDetailsPage.map((item) => ( 
           <>
          <div className="flex flex-wrap justify-between font-medium flex-row mt-[-14px] ">
            <p className="pl-0 font-poppins" style={{ color: `${item.color}` }}>
              {item.label}
            </p>
            <p
              className="font-poppins "
              style={{ color: `${item.numberColor}` }}
            >
              {item.number}
            </p>
            <div className="w-[100%] mt-0">
              <hr className="w-[100%] h-[20px]" />
            </div>
          </div>  
           { item.label === "Active Plans"  ?  
              (dashboardData?.plans)?.map(item2=>( 
                <div className="flex flex-wrap justify-between font-medium flex-row mt-[-10px] ">
                <p className="pl-0 font-poppins" style={{ color: `#F7931DCC` }}>
                  {item2.name}
                </p>
                <p
                  className="font-poppins "
                  style={{ color: `#15223C` }}
                >
                  {item2.count}
                </p>
                <div className="w-[100%] mt-0">
                  <hr className="w-[100%] h-[20px]" />
                </div>
              </div>  
              ))
               :undefined
             }
           </>
        ))}
      </div>  
       </>
}
    </div>
  );
}
