import React, { useState } from "react";
import AdminChatSidebar from "./SideBar/ChatSidebar";
import Messages from "./Messages/Messages";
import Contact from "./Contacts/Contact";
export default function Chat(){  
      const [currentActive,setCurrentActive]=useState("Messages")
     return( 
        <div className="p-4 font-poppins   mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  width1280:mt-[40px]">
       <div className="flex flex-wrap flex-row justify-between"> 
           <div className="w-[100%] bg-[#D9D9D9] rounded-md lg:w-[30%]"> 
                    <AdminChatSidebar setCurrentActive={setCurrentActive}/>
            </div> {currentActive === "Messages" ? 
            <div className="w-[100%] md:w-[100%] lg:w-[68%]"> 
                  <Messages/>
            </div> :currentActive === "Contacts" ?  
            <div className="w-[100%] md:w-[100%] lg:w-[68%]">  
             <Contact/></div>:undefined 
            }
        </div>  
      </div>
     )
}