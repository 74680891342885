import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import Axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};
export default function RenderSinglePlan({
  setRefreshOnDelete,
  item,
  token,
  setLoader, 
  setWhenPlanAddRefreshOffer
}) { 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let role = parseLoginRes?.role;   
  const [singlePlanLoader, setSinglePlanLoader] = useState(false);
  const [singleActivationLoader, setSingleActivationLoader] = useState(false);
  let Svg = item.svg;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [active, setActive] = useState(item.isActive);

  return (
    <>
      
      <div className="  text-white rounded-r-2xl flex flex-wrap flex-row justify-center items-center w-[100px] p-1 bg-[#595A5C]">
        <img src="/PlanSvg.png" />
      </div>
      <div className="w-[calc(100%-110px)]  ">
        <div className=" flex flex-wrap flex-row p-1 justify-between items-center">
          <h1 className="text-[#000000] mt-1">{item.name}</h1>
         {role === "Admin" ? <Dropdown
            className="w-[100px mt-1] p-0 dropdownplan rounded-full pl-2  text-[12px]"
            placeholder="Active" 
            loading={singleActivationLoader}
            value={active}
            onChange={(e) => {
              setSingleActivationLoader(true);
              Axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/Plans/UpdateStatus`,
                { id: item.id, active: e.value },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
                .then((res) => {
                  setSingleActivationLoader(false);
                  setActive(e.value);
                  //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                })
                .catch(() => {
                  setSingleActivationLoader(false);
                  //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                });
            }}
            options={[
              {
                label: "Active",
                value: true,
              },
              {
                label: "InActive",
                value: false,
              },
            ]}
          />   
          :undefined}
          <h1 className="bg-[#595A5C] mt-1 pl-1 pr-1 text-[12px] rounded-full">
            Exp: {formatDate(item.endTime)}
          </h1>
        </div>
        <div className="">
          <div className=" flex flex-wrap flex-row p-1 pt-0 justify-between items-center">
            <h1 className="text-[#EEEEEE]  mt-1 w-[50px] ">
              {item?.branch?.name ? item?.branch?.name : "All"}
            </h1>
        <div className="w-[10px] h-[20px] border-r-2 mt-1 border-[white] "> 
        </div>
            <h1 className="text-[#EEEEEE] w-[100px]  mt-1 ">
              {item?.product?.name}
            </h1>
            <div className="w-[10px] h-[20px] border-r-2 mt-1 border-[white] "> 
            </div>
            <h1 className="text-[#EEEEEE]  mt-1 pr-2">
              Sales {item.totalSales}
            </h1>
          </div>
          <div className="flex flex-wrap flex-row items-center justify-between">
            <h1 className="text-[white] text-center w-[90%] text-[25px] font-semibold mt-1  pr-2">
              rent {item.rent}
            </h1>
           {role === "Admin" ? <div className="w-[10%]">
              <i
                onClick={() => {
                  setDeleteDialog((prev) => !prev);
                }}
                className=" pi cursor-pointer pi-trash text-white "
              ></i>
            </div>:undefined  
             }
          </div>
        </div>
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setDeleteDialog((prev) => !prev);
          }} 
          closeIcon={singlePlanLoader}
          visible={deleteDialog}
        >
          <div className="font-poppins text-medium text-[#AFAFAF]">
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete Plan? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes" 
                loading={singlePlanLoader}
                onClick={() => {
                  setSinglePlanLoader(true);
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Plans/Delete/${item.id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => { 
                      setWhenPlanAddRefreshOffer(prev=>!prev)
                      setSinglePlanLoader(false);
                      setRefreshOnDelete((prev) => !prev);
                      
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch(() => {
                      setSinglePlanLoader(false);
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog((prev) => !prev);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
