import React, { useRef, useState,useEffect } from "react";   
import RenderSeperatePurchasedPlans from "./RenderSeperatePurchasePlan";
import { Toast } from "primereact/toast";
import  Axios  from "axios";
import { useLocation } from "react-router-dom";
export default function PurchasedPlans(){    
   const toast = useRef(); 
   const {state}=useLocation()
   let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
   let token = parseLoginRes.token;  
const [userproducts,setUserProduct]=useState([]) 
useEffect(()=>{ 
   Axios.get(
     `${process.env.REACT_APP_BASE_URL}/api/CustomerPlans/GetAll`,
     {params:{UserId:state?.user?.simpleone}, headers: { Authorization: `Bearer ${token}` } }
   )
     .then((res) => {   
        setUserProduct(res?.data)
       //setCitiesOptions(res?.data);
       //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
     })
     .catch(() => {
       // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
     });
 },[]) 
     return(   
        <div>
        <div className="mt-4  border border-[#E6E9EE]  rounded-2xl pb-4 p-4 w-full flex flex-wrap felx-row justify-between">{ 

              userproducts .map(item=>{       
                 return(
                <  >
               <RenderSeperatePurchasedPlans planPurchased={item}/>
             </> 
                 )
        })
          } 
             <div className="mt-4 mb-4 flex flex-wrap w-full flex-row justify-center items-center"> 
                 <i onClick={()=>{ 
                  setUserProduct(prev=>[...prev,{new1:true}])
                 }} className="pi pi-plus cursor-pointer bg-main-color text-white rounded-full p-1"/>
              </div> 
           </div>    
             <Toast  ref={toast}/>
           </div>
     )
}