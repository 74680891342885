import React, { useRef } from "react";   
import { useFormik } from "formik"; 
import { InputText } from "primereact/inputtext";   
import * as Yup from "yup";   
import { Calendar } from "primereact/calendar";
import  Axios  from "axios"; 
import { useEffect,useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import CommonLoader from "../../../../Components/Loader/Loader";
export default function RenderSeperatePurchasedPlans({planPurchased}){    
  const toast=useRef() 
   const [proceed,setProceed]=useState(false) 
   const [showPageLoader,setShowPageLoader]=useState(false)
  let parseLoginRes = JSON.parse(localStorage.getItem("userData")); 
  const {state}=useLocation()
  let token = parseLoginRes.token;
    const validationSchema = Yup.object().shape({
        totalSalesQty: Yup.string().required("Qty Is Required"),
        amount: Yup.string().required("Amount Is Required"),     
         productId:Yup.string().required("Product Is Required"), 
         planId:Yup.string().required("Plan Is Required"),  
         endTime:Yup.string().required("End Time Is Required"),
         refId:Yup.string().required("Reference Is Required"), 

      });
      const formik = useFormik({
        initialValues: {
           startTime:new Date(planPurchased?.startTime),
            planId: planPurchased?.planId,
            id:planPurchased?.id ? planPurchased?.id :0 ,
            totalSalesQty: planPurchased?.totalSalesQty ? planPurchased?.totalSalesQty :1 ,
            amount: planPurchased?.amount,
            rent:planPurchased?.rent,  
            refId:planPurchased?.refId,
            liability: planPurchased?.liability,     
            userId:state?.user?.simpleone, 
            endTime:planPurchased?.endTime ? new Date(planPurchased?.endTime):"",
            productId:planPurchased?.productId ?planPurchased?.productId:0 ,
    
        },
        validationSchema,    
        onSubmit: (values) => {  
          setShowPageLoader(true) 
          Axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/CustomerPlans/CreateOrUpdate`,values,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
            .then((res) => {   
              setShowPageLoader(false) 
        
               
             }).catch(err=>{  
              setShowPageLoader(false) 
        
          
            })
        }   })    
        const [plansData, setPlansData] = useState([]);
        const [fetchedPlanData, setFetchedPlanData] = useState(null);  
        const [firsttimerender,setFirstRender]=useState(true)
        useEffect(() => {   
          
          if(!firsttimerender){
          Axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/Plans/Get/${formik.values.planId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
            .then((res) => { 
              setFetchedPlanData(res?.data);
              if (formik.values.totalSalesQty === 1) { 
                 formik.setFieldValue("startTime",res?.data?.startTime ? new Date(res?.data?.startTime):"") 
                 
                 formik.setFieldValue("endTime",res?.data?.endTime ? new Date(res?.data?.endTime):"")
                formik.setFieldValue("amount", res?.data?.amount ?  res?.data?.amount : 0)
                formik.setFieldValue("rent", res?.data?.rent);
      
                let startDate = new Date(res?.data?.startTime);
                let endDate = new Date(res?.data?.endTime);
                let totalMonths =
                  (endDate.getFullYear() - startDate.getFullYear()) * 12;
                totalMonths -= startDate.getMonth() + 1;
                totalMonths += endDate.getMonth() + 1;
                formik.setFieldValue("liability", totalMonths * res?.data?.rent);
              } else {   
                
                formik.setFieldValue("startTime",res?.data?.startTime ? new Date(res?.data?.startTime):"") 
                 
                formik.setFieldValue("endTime",res?.data?.endTime ? new Date(res?.data?.endTime):"")
                formik.setFieldValue("rent", res?.data?.rent * formik?.values?.totalSalesQty);
                formik.setFieldValue("amount", res?.data?.amount ?  res?.data?.amount * formik?.values?.totalSalesQty: 0)
              
                let startDate = new Date(res?.data?.startTime);
                let endDate = new Date(res?.data?.endTime);
                let totalMonths =
                  (endDate.getFullYear() - startDate.getFullYear()) * 12;
                totalMonths -= startDate.getMonth() + 1;
                totalMonths += endDate.getMonth() + 1;
                formik.setFieldValue(
                  "liability",
                  totalMonths * res?.data?.rent * formik?.values?.totalSalesQty
                );
              } 
            })
            .catch(() => {}); 
        }
          else{ 
          setFirstRender(false)
          }
        }, [formik.values.planId]);  
        
  const [productData, setProductData] = useState([]);
        useEffect(() => {
          Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Products/List`, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
      
              setProductData(res?.data);
            })
            .catch(() => {
              //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
            });
        }, []);
        useEffect(() => {
          if (formik.values.planId !== "" && fetchedPlanData !== null) {  
           
            formik.setFieldValue("rent", (fetchedPlanData?.rent * formik.values.totalSalesQty).toFixed(2));
            formik.setFieldValue("amount",(formik.values.totalSalesQty * fetchedPlanData?.amount).toFixed(2))
            let startDate = new Date(fetchedPlanData.startTime);
            let endDate = new Date(formik.values.endTime);
            let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12;
            totalMonths -= startDate.getMonth() + 1;
            totalMonths += endDate.getMonth() + 1;
            formik.setFieldValue(
              "liability",
              (totalMonths * fetchedPlanData?.rent * formik.values.totalSalesQty).toFixed(2)
            );
          }
        }, [formik.values.totalSalesQty,formik.values.endTime]);
        useEffect(() => {
          Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Plans/List`, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
              // setPlansData(res?.data);
              let apiResponse = res?.data;
              const modifiedResponse = apiResponse.map((item) => {
                // Destructure the item object and omit the branch property
                const { branch, product, ...rest } = item;
                return rest; // Return the modified object without the branch property
              });
              setPlansData(modifiedResponse);
            })
            .catch(() => {
              //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
            }); 
            
        }, []); 
        useEffect(() => {
          Axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/Users/Info/${formik.values.refId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
            .then((res) => {  
                setProceed(true)
            })
            .catch(() => {
              setProceed(false)
            });
        }, [formik.values.refId]);
     return(   
        <form onSubmit={formik.handleSubmit}>
        <div className="mt-4  border border-[#E6E9EE]  rounded-2xl pb-4 p-4 w-full flex flex-wrap felx-row justify-between">
           <div className="w-full flex flex-wrap flex-row justify-between">   
           <Calendar  
            disabled
             dateFormat="dd/mm/yy"
                onChange={(e)=>{const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc();
                 formik.values.endTime=utcDate.toDate() 
     } 
                }
                placeholder="6 Feb 2024"
                className="w-[24%] p-[10px] border  rounded-lg   text-[#AFAFAF]  font-poppins font-medium"
                value={formik.values.startTime}
                showIcon
                iconPos="left"
                name="Date"
              />
                    
           <div className="w-[24%]">
              <InputText
                name="refId"
                value={formik.values.refId}
                onChange={formik.handleChange}
                className="   border pl-2 h-[40px]  p-2  w-full rounded-lg border-[#D6D7DB] text-[#AFAFAF] font-poppins font-medium"
                placeholder="Refferal ID"
                
              />
            </div>   
          
            <div className="w-[24%]">
            <Dropdown
              showIcon
              iconPos="left"
              value={formik.values.productId}
              name="productId"
              onChange={formik.handleChange}
              placeholder="Product"
              className=" border my-dropdown w-[100%] border-[#EEEEEE]  text-[#AFAFAF] rounded-md w-full"
              optionLabel="name"
              optionValue="id"
              options={productData}
            />   
            </div>      
            
            <div className="w-[24%]">
            <Dropdown
            className="w-full  dropdownplan rounded-full p-3 pl-2  h-[40px] text-[12px]"
            placeholder="Active" 
            disabled
            value={fetchedPlanData?.isActive}
            onChange={(e) => {
             
            }}
            options={[
              {
                label: "Active",
                value: true,
              },
              {
                label: "InActive",
                value: false,
              },
            ]}
          />   

          </div>
           </div>
          
            <div className="w-[18%] mt-4  ">
              <Dropdown
                className="w-full my-dropdown border font-poppins font-medium  rounded-md  "
                placeholder="Plan"
                onChange={formik.handleChange}
                optionValue="id"
                optionLabel="name"
                name="planId"
                value={formik.values.planId}
                options={plansData}
              />

              {formik.touched.Plan && formik.errors.Plan ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.Plan}
                </p>
              ) : null}
            </div>
            <div className="  w-[24%]">
          
            
          <Calendar dateFormat="dd/mm/yy"
           name="endTime" 
           value={formik.values.endTime}
           onChange={(e)=>{ 
            const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
            // Update the state with the formatted date
         
        const utcDate = selectedDate.utc();
              formik.setFieldValue("endTime",utcDate.toDate())
           }}
           showIcon 
           
           iconPos="left"
           className=" text-[#AFAFAF] border pl-2 h-[40px] mt-4   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
           placeholder="End Time"
         />
         {formik.touched.endTime && formik.errors.endTime ? (
           <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
             {formik.errors.endTime}
           </p>
         ) : null}
       </div>
            <div className="w-[10%]">
              <InputText
                name="totalSalesQty"
                value={formik.values.totalSalesQty}
                keyfilter="num"
                onChange={formik.handleChange}
                className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] text-[#AFAFAF] font-poppins font-medium"
                placeholder="totalSalesQty"
              />
              {formik.touched.totalSalesQty && formik.errors.totalSalesQty ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.totalSalesQty}
                </p>
              ) : null}
            </div> 

            <div className="w-[20%]">
              <InputText
                name="amount"
                value={formik.values.amount}
                onChange={(e)=>{
                  formik.setFieldValue("amount",parseFloat((e.target.value)).toFixed(2)) }}   
                disabled
                keyfilter="num"
                className=" mt-4  border pl-2 h-[40px]  p-2  w-full text-[#AFAFAF] rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
                placeholder="Product Amount"
              />
              {formik.touched.amount && formik.errors.amount ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.amount}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]">
              <InputText
                name="rent"
                value={formik.values.rent}
                keyfilter="num"
                onChange={formik.handleChange}
                className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2  text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
                placeholder="Rent"
                disabled
              />
              {formik.touched.rent && formik.errors.rent ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.rent}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]">
              <InputText
                name="liability"
                value={formik.values.liability}
                onChange={formik.handleChange}
                className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 text-[#AFAFAF] border-[#D6D7DB] font-poppins font-medium"
                placeholder="Liability"
                disabled
              />
            </div>    
            <div className="w-full mt-4">
            <Button  
            
            type="submit"
            disabled={!proceed || showPageLoader}
            className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${showPageLoader ? "":"Submit"}`}   
            iconPos="center"
            icon={showPageLoader? <CommonLoader/>:""}
          /> 
             </div>
             </div>   
             <Toast  ref={toast}/> 
                
           </form>
     )
}