import React, { useState } from "react"; 
import Plan from "./Components/Plan";
import Offer from "./Components/Offer";
export default function PlanOffers(){   
    const [whenPlanAddRefreshOffer,setWhenPlanAddRefreshOffer]=useState(false)
     return(  
        <div className="p-4  mt-[0px] mt-[160px] width716:mt-[-60px] width910:mt-[90px]  width1280:mt-[40px]">
           <div className="flex flex-wrap flex-row justify-between"> 
              <div className="w-[100%] lg:w-[48%] max-h-[calc(100vh-200px)] profilesidebar overflow-y-auto"> 
                 <Plan setWhenPlanAddRefreshOffer={setWhenPlanAddRefreshOffer}/>
               </div> 
               <div className="w-[100%] md:w-[100%] lg:w-[48%] max-h-[calc(100vh-200px)] profilesidebar overflow-y-auto"> 
                <Offer whenPlanAddRefreshOffer={whenPlanAddRefreshOffer} />
               </div>
           </div>
         </div>
     )
}