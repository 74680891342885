import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown"; 
import { Pdf,Printer } from "../../../../Common/Svg";  
import moment from "moment";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
export default function UpdateProfile({ setFormToOpen,setRefreshUserFromAdd,setRefreshUser }) { 
  const toast = useRef(); 
  const {state}=useLocation()
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;
  const [showCnicFrontError, setShowCnicFrontError] = useState(false);
  const [showCnicBackError, setShowCnicBackError] = useState(false);
  const [cnicFrontUrl, setCnicFront] = useState(null);
  const [cnicBackUrl, setCnicBack] = useState(null);
  const [showReceiptError, setShowReceiptError] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [citiesOption, setCitiesOptions] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [showCofirmation, setConfirmation] = useState(false);
   {/* CountryId: Yup.string().required("Country Is Required"),
    CityId: Yup.string().required("City Is Required"),
    Address: Yup.string().required("Address Is Required"),
    Name: Yup.string().required("Name Is Required"),
    Cnic: Yup.string().required("Cnic Is Required"),
    FatherName: Yup.string().required("Father Name Is Required"),
    BankAccountNumber: Yup.string().required("Account No Is Required"),
    Email: Yup.string().required("Email Is Required"),
    Phone: Yup.string().required("Contact Is Required"),
    WhatsappNumber: Yup.string().required("Whatsapp Is Required"),
    Dob: Yup.string().required("DOB Is Required"),
    BankName: Yup.string().required("Bank Name Is Required"),*/}
  const validationSchema = Yup.object().shape({
  
  });
  const formik = useFormik({
    initialValues: {
      BankName: "",
      CountryId: "",
      CityId: "",
      Address: "",
      Name: "",
      FatherName: "",
      BankAccountNumber: "",
      Email: "",
      Phone: "",
      WhatsappNumber: "",
      Dob: "",
      IdCardBack: "",
      IdCardFront: "",
      Cnic: "",
      Id:state?.user?.simpleone

    },
    validationSchema,
    onSubmit: (values) => {
     
              AddCustomer(values);
            
      
    }
  });  
  useEffect(()=>{ 
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Users/Info/${state.user.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {   
        let user=res?.data; 
        formik.setFieldValue("Name",user?.name)  
        
        formik.setFieldValue("FatherName",user?.fatherName)  
        
        formik.setFieldValue("Cnic",user?.cnic)  
        
        formik.setFieldValue("CountryId",user?.country?.id)
        formik.setFieldValue("CityId",user?.city?.id)
        
        formik.setFieldValue("Address",user?.address)  
        
        formik.setFieldValue("BankName",user?.bankName)
        
        formik.setFieldValue("BankAccountNumber",user?.bankAccountNumber) 
        
        formik.setFieldValue("Email",user?.email)  
        
        formik.setFieldValue("Phone",user?.phone) 
        
        formik.setFieldValue("WhatsappNumber",user?.whatsappNumber)  
        
        formik.setFieldValue("Dob",user?.dob ? new Date(user?.dob):"")  
        setCnicFront(user?.idCardFront) 
        setCnicBack(user?.idCardBack)
        //setCitiesOptions(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  },[])
  function AddCustomer(values) {
    setShowPageLoader(true)
    const formData = new FormData();
    for (const key in values) {
      if (key === "Dob" && formik.values[key] !== "" && formik.values[key] !== null && formik.values[key] !==undefined ) {
        let currentvalue = values[key];
        let date = new Date(currentvalue);
        let isoformat = date.toISOString();
        formData.append(key, isoformat);
      } else {
           if(formik.values[key] !== "" && formik.values[key] !== null && formik.values[key] !==undefined )
          formData.append(key, values[key]);

      }
    }
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/Users/UpdateInfo`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setShowPageLoader(false);
        // setCountryOptions(res?.data)
       
        // setUsersData(res?.data)
      })
      .catch((err) => {
        setShowPageLoader(false);
        
      });
  }
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCountryOptions(res?.data);
      
        // setUsersData(res?.data)
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Info",
          detail: (
            <h1 className="font-poppins ">Error Fetching Countries Data</h1>
          ),
        });
      });
  }, []);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setCitiesOptions(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.CountryId]);

  return (
    <div className="all-customers  overflow-y-auto  w-[calc(100%)]   max-h-[calc(100vh-350px)] border rounded-2xl border-[#E6E9EE] shadow-sm   right-[0px] z-40 h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] bg-white ">
  
      {showCofirmation ? (
        <div className="fixed h-[230px] w-[400px] border border-[#EEEEEE]  bg-white top-[50%] left-[calc(50%+160px)] rounded-xl z-40 transform translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-wrap mt-[-20px] justify-center ">
            <div className="w-[50px] rounded-full p-3 h-[50px] bg-[#2DD683]">
              <CheckIcon className=" text-white w-[25px] h-[25px]" />
            </div>
          </div>
          <div className="flex cursor-pointer flex-wrap justify-end flex-row mt-[-20px]">
            <XIcon
              onClick={() => { 
                setRefreshUserFromAdd(true)
                setConfirmation((prev) => !prev);   
                setRefreshUser(prev=>!prev) 
                setFormToOpen("")
              }}
              className=" mr-[20px] inline w-[25px] h-[25px]"
            />{" "}
          </div>
          <h1 className="w-full font-poppins text-[20px] text-center font-bold mt-4 ">
            Thank You !
          </h1>
          <p className="w-full font-poppins p-4 pl-10 pr-10 text-[16px] text-center font-medium  ">
            Your Details has been Successfully submitted. Thanks!
          </p>

          <div className="w-full rounded-b-xl h-[50px] mt-5 pt-[2px] bg-[#2DD683] items-center flex flex-wrap flex-row justify-center">
            <div className="w-[25px] pt-[1px] h-[25px] mr-[10px]">
              <Pdf />
            </div>
            <div className="w-[25px] h-[25px] ml-[10px] ">
              <Printer />
            </div>
          </div>
        </div>
      ) : undefined}
      <form onSubmit={formik.handleSubmit} className="pb-[50px]">
       
     

       
        <div className="flex flex-wrap flex-row w-full  text-[#AFAFAF] pt-0 p-4 justify-between">
          <div className="w-[45%]">
            <InputText
              name="Name"
              value={formik.values.Name}
              onChange={formik.handleChange}
              className=" mt-4 border pl-2 h-[40px] p-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.Name && formik.errors.Name ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Name}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <InputText
              name="FatherName"
              value={formik.values.FatherName}
              onChange={formik.handleChange}
              className=" mt-4 border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.FatherName && formik.errors.FatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.FatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <InputMask
              mask="99999-9999999-9"
              name="Cnic"
              value={formik.values.Cnic}
              onChange={formik.handleChange}
              className="mt-4  p-2 border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.Cnic && formik.errors.Cnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Cnic}
              </p>
            ) : null}
          </div>
          <div className="w-[45%] mt-4 flex   flex-wrap flex-row justify-between">
            <div className="w-[45%]">
              <Dropdown
                className="w-full border my-dropdown font-poppins font-medium  rounded-md  "
                placeholder="Country"
                onChange={formik.handleChange}
                optionValue="id"
                optionLabel="name"
                name="CountryId"
                value={formik.values.CountryId}
                options={countryOptions}
              />

              {formik.touched.CountryId && formik.errors.CountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[45%] ">
              <Dropdown
                className="w-full  my-dropdown border font-poppins font-medium  rounded-md "
                name="CityId"
                placeholder="City"
                onChange={formik.handleChange}
                optionValue="id"
                optionLabel="name"
                value={formik.values.CityId}
                options={citiesOption}
              />
              {formik.touched.CityId && formik.errors.CityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              className="mt-4  border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.Address && formik.errors.Address ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Address}
              </p>
            ) : null}
          </div>
          <div className="w-[27%]">
            <InputText
              name="BankName"
              value={formik.values.BankName}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Bank Name"
            />
            {formik.touched.BankName && formik.errors.BankName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankName}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="BankAccountNumber"
              value={formik.values.BankAccountNumber}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Account No"
            />
            {formik.touched.BankAccountNumber &&
            formik.errors.BankAccountNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankAccountNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="Email"
              value={formik.values.Email}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Email"
            />
            {formik.touched.Email && formik.errors.Email ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Email}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="Phone"
              value={formik.values.Phone}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.Phone && formik.errors.Phone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Phone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="WhatsappNumber"
              value={formik.values.WhatsappNumber}
              onChange={formik.handleChange}
              className=" mt-4  border pl-2 h-[40px]  p-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.WhatsappNumber && formik.errors.WhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar dateFormat="dd/mm/yy"
              name="Dob"
              value={formik.values.Dob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.dob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="mt-4  border pl-2 h-[40px]  p-2  w-full  rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.Dob && formik.errors.Dob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Dob}
              </p>
            ) : null}
          </div>
          <div className="w-full mt-10  flex flex-wrap flex-row justify-evenly">
            <div className="w-[45%] cursor-pointer flex flex-wrap flex-row justify-center">
              <div
                className="w-[300px] h-[200px] rounded-lg bg-[#EEEEEE] flex flex-wrap overflow-hidden flex-row justify-center items-center"
                onClick={() => {
                  let input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = (e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = () => {
                      setCnicFront(reader.result);
                    };
                    formik.setFieldValue("IdCardFront", e.target.files[0]);
                    setShowCnicFrontError(false);
                  };
                  input.click();
                }}
              >
                {cnicFrontUrl === null ? (
                  <h1 className="text-[16px] font-poppins  font-semibold ">
                   ID CARD FRONT
                  </h1>
                ) : (
                  <img src={cnicFrontUrl} className="h-[100%] w-auto" />
                )}
              </div>
              {showCnicFrontError ? (
                <p className="font-poppins block w-full font-medium  ml-1 text-red-500">
                  Image Is Required
                </p>
              ) : null}
            </div>

            <div className="w-[45%] cursor-pointer flex flex-wrap flex-row justify-center">
              <div
                className="w-[300px] h-[200px] rounded-lg bg-[#EEEEEE] flex flex-wrap overflow-hidden flex-row justify-center items-center"
                onClick={() => {
                  let input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = (e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = () => {
                      setCnicBack(reader.result);
                    };
                    formik.setFieldValue("IdCardBack", e.target.files[0]);
                    setShowCnicBackError(false);
                  };
                  input.click();
                }}
              >
                {cnicBackUrl === null ? (
                  <h1 className="text-[16px] font-poppins  font-semibold ">
                   ID CARD BACK
                  </h1>
                ) : (
                  <img src={cnicBackUrl} className="h-[100%] w-auto" />
                )}
              </div>
              {showCnicBackError ? (
                <p className="font-poppins block font-medium  ml-1 text-red-500">
                  Image Is Required
                </p>
              ) : null}
            </div>
          </div>
         
        </div>  
        <Button loading={showPageLoader} disabled={showPageLoader}  label="Update"  className="bg-main-color text-white p-1 rounded-sm ml-4 pl-2 pr-2"/>
      </form>
      <Toast className="z-50 mt-[80px]" ref={toast} />
    </div>
  );
}
