import React, { useEffect, useState } from "react"    
import  Axios  from "axios" 
import { useFormik } from "formik" 
import * as Yup from "yup"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import CommonLoader from "../../../../../../Components/Loader/Loader"
export default function Quicktransfer(){   
   let parseLoginRes=JSON.parse(localStorage.getItem("userData")) 
let token=parseLoginRes.token 
  const [loading,setLoading]=useState(false)
const validationSchema = Yup.object().shape({   
   amount:Yup.number().required("Amount Is Required"),  
   customerId:Yup.string().required("Customer Id Is Required"), 
   months: Yup.array()
   .min(1, 'Month is required')
   .required('Month is required')
})     


const formik = useFormik({
    initialValues: {
      amount:0,  
      customerId:"", 
      months: []
    },validationSchema, onSubmit: (values) => { 
        let quicktransferobj={...values,amount:parseFloat(values.amount)} 
      setLoading(true)
      Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Rents/QuickTransfer`,quicktransferobj,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
             setLoading(false)
         //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
           // setUsersData(res?.data)
        }).catch(()=>{  
         setLoading(false)
         // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
       
        })    
    }})
    
    return (  
         <form onSubmit={formik.handleSubmit} className="mt4  p-2 rounded-2xl h-[350px]  border border-[#E6E9EE] "> 
            <h1 className=" mt-4 font-poppins text-quicktransfer-header font-semibold text-[20px]">Quick Transfer</h1>
            <MultiSelect name="months" value={formik.values.months} onChange={formik.handleChange} options={[{name:1,value:1},{name:2,value:2},{name:3,value:3},{name:4,value:4}]} optionLabel="name" 
    placeholder="Select Months"  className="border border-[rgba(0,0,0,.2)] rounded-md w-full mt-2 md:w-20rem" />
          {formik.touched.months && formik.errors.months ? (
            <p className="font-poppins  mt-1 ml-1 text-red-500">
              {formik.errors.months}
            </p>
          ) : null}
              <p className="font-poppins  font-medium text-[16px] mt-4 text-[#AFAFAF] ">Customer ID</p> 
               <InputText name="customerId"  className="font-poppins   opacity-[0.8] mt-2 w-full border-b-[1px] font-poppins" value={formik.values.customerId} onChange={formik.handleChange}   placeholder="Enter Customer ID"/>
               {formik.touched.customerId && formik.errors.customerId ? (
            <p className="font-poppins font-medium mt-1 ml-1 text-red-500">
              {formik.errors.customerId}
            </p>
          ) : null}
            <div className="flex flex-wrap flex-row mt-4  justify-around"> 
            <div className="flex w-[150px] gap-2 flex-wrap flex-row justify-center font-poppins p-2 rounded bg-send-amount font-semibold text-[20px]">  
             <InputText name="amount" keyfilter="num" onChange={formik.handleChange}  className="bg-send-amount  border-b font-poppins w-[60%]" /> 
            
              <p >PKR</p>
               </div>
                     <button  disabled={loading}  type="submit" className="font-poppins flex flex-wrap flex-row justify-center items-center text-center w-[130px] text-white bg-send-money p-2 rounded">{loading ? <CommonLoader/> : "Send Money"}</button>
              
            </div>   
            {formik.touched.amount && formik.errors.amount ? (
            <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
              {formik.errors.amount}
            </p>
          ) : null}
         </form>
    )
}