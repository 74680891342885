import React ,{useState} from "react";  
import { OfferSvg } from "../../assets";  
import  Axios from "axios";   
import { Button } from "primereact/button";
import PageLoader from "../../../../../Common/Components/PageLoader";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";  
const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };
export default function RenderSingleOffer({ setRefreshOnDelete,
  item,
  token,
  setLoader,}){               
    const [singlePlanLoader, setSinglePlanLoader] = useState(false);
    const [singleActivationLoader, setSingleActivationLoader] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    let role = parseLoginRes?.role;   
    const [active,setActive]=useState(item.isActive)
    return( 
         <> 
          

        <div className="  text-white rounded-full flex flex-wrap flex-row justify-center items-center h-[100px] w-[100px] p-1 bg-[#D07E29]">
        <OfferSvg />
      </div>
      <div className="w-[calc(100%-110px)]  ">
        <div className=" flex flex-wrap flex-row p-1 justify-between items-center">
          <h1 className="text-[#000000] mt-1">{item.name}</h1>
          { role === "Admin" ? <Dropdown 
             loading={singleActivationLoader}
            className="w-[100px mt-1] p-0 dropdownplan rounded-full pl-2  text-[12px]"
            placeholder="Active" 
            value={active}  
            onChange={(e)=>{ 
              setSingleActivationLoader(true);
              Axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/Offers/UpdateStatus`,
                { id: item.id, active: e.value },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
                .then((res) => {
                  setSingleActivationLoader(false);
                  setActive(e.value);
                  //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                })
                .catch(() => {
                  setSingleActivationLoader(false);
                  //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                });
            }}  
            options={[ 
              { 
                label:"Active", 
                value:true
              },{ 
                label:"InActive", 
                value:false
              }
            ]}
          /> 
           :undefined }
          <h1 className="bg-[#595A5C] mt-1 pl-1 pr-1 text-[12px] rounded-full">
            Exp: {formatDate(item.endTime)}
          </h1>
        </div>
        <div className="">
          <div className=" flex flex-wrap flex-row p-1 pt-0 justify-left items-center">
            <h1 className="text-[#EEEEEE] border-r-2 border-[white] mt-1 pr-2">
              {item.province?.name}
            </h1>

            <h1 className="ml-2 text-[#EEEEEE] border-r-2 border-[white] mt-1 pr-2">
              {item?.plan?.name}
            </h1>

            <h1 className=" ml-2 text-[#EEEEEE]  mt-1 pr-2">
              Sales {item.salesQty}
            </h1>  
           
          </div>
          <div className="flex flex-wrap flex-row items-center justify-between">
          <h1 className="ml-2 text-[#EEEEEE] text-left mt-1  pr-2">
            {item.marketerReward}
          </h1>    
        {role === "Admin" ? <div className="w-[10%]">
              <i
                onClick={() => {
                  setDeleteDialog((prev) => !prev);
                }}
                className=" pi cursor-pointer pi-trash text-white "
              ></i>
            </div> 
            :undefined 
             }
          <img  className="mt-[-20px] mr-[40px] w-[40px] h-[40px]" src="/offer.png"/>
           </div>
        </div>    
        <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation" 
          closeIcon={singlePlanLoader}
          onHide={() => {
            setDeleteDialog((prev) => !prev);
          }}
          visible={deleteDialog}
        >
          <div className="font-poppins text-medium text-[#AFAFAF]">
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete Plan? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes" 
                loading={singlePlanLoader}
                onClick={() => {
                  setSinglePlanLoader(true);
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Offers/Delete/${item.id}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                    .then((res) => {
                      setSinglePlanLoader(false);
                      setRefreshOnDelete((prev) => !prev);  
                      setDeleteDialog(false)
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                    })
                    .catch(() => {
                      setSinglePlanLoader(false);
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog((prev) => !prev);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div>
        </Dialog>
      </div> </>
    )
}