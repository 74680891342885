import React from "react";
import "./css/expense_list.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog"; 
import Axios  from "axios";
import {
  EyeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,

} from "@heroicons/react/outline";
import { Button } from "primereact/button";
//import AddNewCustomer from "./components/add_new_customer";
//import ViewAllCustomers from "./components/view_all_customers";
import Loader from "../../../../../Common/Components"; 
import { ExpenseSvg } from "./assets";
import CommonLoaderBlue from "../../../../../Components/Loader/LoaderBlue";
export default function ExpenseList({refresh,refreshwhenAddPurchase}) { 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [selectedRows, setSelectedRows] = useState(0);   
  const [loader,setLoader]=useState(true) 
  const [loaderShow,setShowLoader]=useState(false)
  const [viewAllCustomers,setViewAllCustomers]=useState(false)
  //const [addNewCustomerVisibility, setAddNewCustomerVisibility] 
  const [expenseList,setExpenseList]=useState([]) 

    useEffect(()=>{  
      if(!refreshwhenAddPurchase){ 
      setLoader(true)
      }
      Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Expense/List`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
         // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
           setExpenseList(res?.data)  
           setLoader(false)
        })
        .catch(() => {
          //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
        });
    },[refresh])  
    const formatDate = (isoDateString) => {
      const date = new Date(isoDateString);
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    };
  return (
    <div className="overflow-x-auto w-full mt-4">
   {/*   <Button
        onClick={() => {
          setAddNewCustomerVisibility(true);
        }}
        className="bg-submit-button text-white top-[30px] absolute  right-[20px] font-poppins border-none mt-[59px] pl-4 pr-4 pt-2 pb-2"
      >
        <PlusIcon className="w-[15px] inline mt-[-2px] mr-[5px]" />
        Add New Customer
      </Button> 
      */}
      <DataTable
        value={expenseList}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        size="small"
       
        resizableColumns
        paginator
       rows={10}
        rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Expenses Not Found"}
         
        style={{ backgroundColor: 'white' ,minWidth:"950px"}}
        className=" relative w-[99%] pl-10 pr-4 pt-10 ml-2 rounded-lg border border-[#E6E9EE] shadow-sm   font-poppins  shadow-md"
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column headerClassName="border-b-[2px]  bg-white border-main-color" header={<span className="text-black font-semibold text-[20px]">Expense List</span>} body={(rowdata)=>{ 
          return( 
               <div className="flex flex-wrap flex-row items-center justify-left">  
                
            
                <div className="rounded-[50%] bg-custom-gradient overflow-hidden w-[29px] h-[29px] self-center border border-[#E6E9EE] shadow-sm"> 
                  <div className="ml-[7px] mt-[4px]">
                <ExpenseSvg/>  
                </div> 
                </div>
              <p className="ml-5 font-medium">{rowdata.expenseCategory.title}</p> 
              </div>
          )
        }} field="name" className="border-r border-[#D6D7DB]"></Column>
        <Column headerClassName="text-main-color  bg-white border-b-[2px] border-main-color font-medium" header="Branch" className="border-r border-[#D6D7DB]"  field="branch.name"></Column>
        <Column  headerClassName="text-main-color   bg-white border-b-[2px] border-main-color font-medium"  header="Date" className="border-r border-[#D6D7DB]" field="id" body={(rowData)=>{ 
           return( 
            <p>{formatDate(rowData.createdAt)}</p>
           )
        }}></Column>
        <Column  headerClassName="text-main-color   bg-white border-b-[2px] border-main-color font-medium"  header="Amount" className="border-r border-[#D6D7DB]" field="amount"></Column>
        <Column headerClassName="text-main-color  bg-white border-b-[2px] border-main-color font-medium"  header="Description" field="description" className="border-r border-[#D6D7DB]"></Column>  
         <Column headerClassName="text-main-color bg-white border-b-[2px] border-main-color font-medium" className="border-r border-[#D6D7DB]"  field="action" body={tableActions}></Column>
      </DataTable>
   
    </div>
  );
}

const tableActions = (rowData) => {
  return (
    <div className="w-full  flex flex-wrap flex-row justify-around">
    
      <div c>
        <EyeIcon className="w-[16px] cursor-pointer  " />
        
        </div> 
        <div >
         <PencilIcon className="w-[16px] cursor-pointer  " />
         
        </div> 
        <div >
         <TrashIcon className="w-[16px] cursor-pointer   " />
        </div>
        <div > 
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[16px] cursor-pointer">
  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
</svg>

        </div>
      
    </div>
  );
};
