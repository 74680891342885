import React from "react";
import { ArrowUpIcon } from "@heroicons/react/solid";
import { ArrowDownIcon } from "@heroicons/react/solid";

export default function Stats({dashboardData}) { 
  const statstoshow = [
    {
      label: "Total Investment",
      amount: `${dashboardData?.investment ? parseFloat(dashboardData?.investment).toFixed(2):"0"} PKR`,
      background: "#00B3FF",
      bgdecrease: "#FFCDCF",
      decreaseColor: "#E74A51",
      buttonbg: "#D8FFEC",
      buttoncolor: "#2DD683",
    },
    {
      label: "Total Earning",
      amount: `${dashboardData?.earning ? parseFloat(dashboardData?.earning).toFixed(2):"0"} PKR`,
      background: "#2DD683",
      buttonbg: "#D8FFEC",
      buttoncolor: "#2DD683",
      bgdecrease: "#FFCDCF",
      decreaseColor: "#E74A51",
    },
    {
      label: "Total Liability",
      amount:`${dashboardData?.liablity ? parseFloat(dashboardData?.liablity).toFixed(2):"0"} PKR`,
      background: "#FED142",
      buttonbg: "#D8FFEC",
      buttoncolor: "#2DD683",
      bgdecrease: "#FFCDCF",
      decreaseColor: "#E74A51", 
      mt:9
    },
    {
      label: "Total Rent",
      amount: `${dashboardData?.rent ? parseFloat(dashboardData?.rent).toFixed(2):"0"} PKR`, 
      mt:9,
      background: "#FA8B3A",
      buttonbg: "#D8FFEC",
      buttoncolor: "#2DD683",
      bgdecrease: "#FFCDCF",
      decreaseColor: "#E74A51",
    },
  ];
  return (
    <div className="flex flex-wrap flex-row   justify-between   w-[100%]">
      {statstoshow.map((item) => (
        <div
          className={`mt-2 w-[49%] h-[110px] items-center relative shadow-md rounded-lg flex flex-wrap ${
            item.buttoncolor ? "justify-left" : "justify-left"
          } flex-row ${item?.mt?`mt-[13px]`:""}`}
        >
          <div
            style={{ background: `${item.background}` }}
            className={`bg-[${item.background}]  w-[10px] h-[100%] rounded-l-lg`}
          ></div>
          <div className="p-4 font-poppins font-medium w-[calc(100%-10px)]  ">
            <p className="text-stat-color">{item.label}</p>
            <h1 className="font-semibold text-[20px]">{item.amount}</h1>
          </div>
         
        </div>
      ))}
    </div>
  );
}
