import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import html2pdf from "html2pdf.js";
import * as Yup from "yup";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Pdf, Printer } from "../../../Svg";
import CommonLoader from "../../../../Components/Loader/Loader";  
import moment from "moment";
export default function CustomerForm({
  setFormToOpen,
  setRefreshUserFromAdd,
  setRefreshUser,
}) {
  const toast = useRef();
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  const [imgUrl, setImageUrl] = useState(null);
  let formref = useRef();
  const [cnicFrontUrl, setCnicFront] = useState(null);
  const [cnicBackUrl, setCnicBack] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [citiesOption, setCitiesOptions] = useState([]);
  const [heirCitiesOption, setHierCitiesOptions] = useState([]);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [showCofirmation, setConfirmation] = useState(false);
  const [referenceCitiesOption, setReferenceCitiesOptions] = useState([]);
  let token = parseLoginRes.token;
  const [receipt, setReceipt] = useState(null);

  const validationSchema = Yup.object().shape({
    Id: Yup.string().required("Customer ID Is Required"),
    Date: Yup.string().required("Date Is Required"),

    CountryId: Yup.string().required("Country Is Required"),
    CityId: Yup.string().required("City Is Required"),
    Address: Yup.string().required("Address Is Required"),
    Name: Yup.string().required("Name Is Required"),
    FatherName: Yup.string().required("Father Name Is Required"),
    BankAccountNumber: Yup.string().required("Account No Is Required"),
    Phone: Yup.string().required("Contact Is Required"),
    WhatsappNumber: Yup.string().required("Whatsapp Is Required"),
    ReferenceId: Yup.string().required("ID Is Required"),
    Plan: Yup.string().required("Plan Is Required"),
    Qty: Yup.string().required("Qty Is Required"),
    Amount: Yup.string().required("Amount Is Required"),
    BankName: Yup.string().required("Bank Name Is Required"), 
    EndTime: Yup.string().required("End Time Is Required"), 
  });
  const formik = useFormik({
    initialValues: {
      Id: "",
      Image: "",
      Cnic: "",
      Date: new Date(),
      BankName: "",
      CountryId: "",
      CityId: "",
      Address: "",
      Name: "",
      FatherName: "",
      BankAccountNumber: "",
      Email: "",
      Phone: "",
      WhatsappNumber: "",
      Dob: "",
      Receipt: "",
      IdCardBack: "",
      IdCardFront: "",
      Cnic: "",
      HeirCountryId: "",
      HeirCityId: "",
      HeirAddress: "",
      HeirName: "",
      HeirFatherName: "",
      HeirCnic: "",
      HeirPhone: "",
      HeirWhatsappNumber: "",
      HeirDob: "",
      ReferenceId: "",
      ReferenceName: "",
      ReferencePosition: "",
      ReferenceCityId: "",
      ReferenceCountryId: "",
      Plan: "",
      Qty: 1,
      Amount: "",
      Amount2: "", 
      EndTime:"",
      Liability: "",
    },
    validationSchema,
    onSubmit: (values) => {
      AddCustomer(values);
    },
  });
  const [plansData, setPlansData] = useState([]);
  const [fetchedPlanData, setFetchedPlanData] = useState(null);
  useEffect(()=>{  

    document.body.style.overflow = 'hidden';
  return ()=>{  
  
    document.body.style.overflow = 'auto';
  }
   },[])
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Plans/Get/${formik.values.Plan}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setFetchedPlanData(res?.data);
        if (formik.values.Qty === 1) {
          formik.setFieldValue("Amount2", res?.data?.rent);
            formik.setFieldValue("Amount",res?.data?.amount ? res?.data?.amount :0)
          let startDate = new Date(res?.data?.startTime);
          let endDate = new Date(res?.data?.endTime);
          let totalMonths =
            (endDate.getFullYear() - startDate.getFullYear()) * 12;
          totalMonths -= startDate.getMonth() + 1;
          totalMonths += endDate.getMonth() + 1; 
           formik.setFieldValue("EndTime",res?.data?.endTime ? new Date(res?.data?.endTime):"")
          formik.setFieldValue("Liability", totalMonths * res?.data?.rent);
        } else {
          formik.setFieldValue("Amount",res?.data?.amount ? (res?.data?.amount * formik.values.Qty).toFixed(2) :0)
        
          formik.setFieldValue("EndTime",res?.data?.endTime ? new Date(res?.data?.endTime):"")
         
          formik.setFieldValue("Amount2", (res?.data?.rent * formik.values.Qty).toFixed(2));

          let startDate = new Date(res?.data?.startTime);
          let endDate = new Date(res?.data?.endTime);
          let totalMonths =
            (endDate.getFullYear() - startDate.getFullYear()) * 12;
          totalMonths -= startDate.getMonth() + 1;
          totalMonths += endDate.getMonth() + 1;
          formik.setFieldValue(
            "Liability",
            (totalMonths * res?.data?.rent * formik.values.Qty).toFixed(2)
          );
        }
      })
      .catch(() => {});
  }, [formik.values.Plan]);
  useEffect(() => {
    if (formik.values.Plan !== "") {    
      formik.setFieldValue("Amount2", (fetchedPlanData.rent * formik.values.Qty).toFixed(2));
      formik.setFieldValue("Amount",fetchedPlanData?.amount ? (fetchedPlanData?.amount * formik.values.Qty).toFixed(2) :0)
      let startDate = new Date(fetchedPlanData.startTime);
      let endDate = new Date(formik.values.EndTime); 
      let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      totalMonths -= startDate.getMonth() + 1;
      totalMonths += endDate.getMonth() + 1;
      formik.setFieldValue(
        "Liability",
        (totalMonths * fetchedPlanData?.rent * formik.values.Qty).toFixed(2)
      );
    }
  }, [formik.values.Qty,formik.values.EndTime]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Plans/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        // setPlansData(res?.data);
        let apiResponse = res?.data;
        const modifiedResponse = apiResponse.map((item) => {
          // Destructure the item object and omit the branch property
          const { branch, product, ...rest } = item;
          return rest; // Return the modified object without the branch property
        });
        setPlansData(modifiedResponse);
      })
      .catch(() => {
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, []);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Users/Info/${formik.values.ReferenceId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        formik.setFieldValue("ReferenceCityId", res?.data?.city?.name);
        formik.setFieldValue("ReferenceCountryId", res?.data?.country?.name);
        formik.setFieldValue("ReferenceName", res?.data?.name);
        formik.setFieldValue("ReferencePosition", res?.data?.role?.title);
      })
      .catch(() => {
        formik.setFieldValue("ReferenceCityId", "");
        formik.setFieldValue("ReferenceCountryId", "");
        formik.setFieldValue("ReferenceName", "");
        formik.setFieldValue("ReferencePosition", "");
      });
  }, [formik.values.ReferenceId]);
  function AddCustomer(values) {
    setShowPageLoader(true);
    const formData = new FormData();
    for (const key in values) {
      if (
        (key === "Dob" && values[key] !== "") || (key === "EndTime" && values[key] !== "") ||
        (key === "HeirDob" && values[key] !== "") ||
        (key === "Date" && values[key] !== "")
      ) {
        let currentvalue = values[key];
        let date = new Date(currentvalue);
        let isoformat = date.toISOString();
        formData.append(key, isoformat);
      } else {
        if (
          key === "ReferenceName" ||
          key === "ReferencePosition" ||
          key === "ReferenceCityId" ||
          key === "ReferenceCountryId" ||
          key === "Liability" ||
          key === "Amount2"
        ) {
        } else {
          formData.append(key, values[key]);
        }
      }
    }
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/Users/AddCustomer`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setShowPageLoader(false);
        setConfirmation(true);
        // setCountryOptions(res?.data)
      
        // setUsersData(res?.data)
      })
      .catch((err) => {
        setShowPageLoader(false);
       
      });
  }
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCountryOptions(res?.data);
        
        // setUsersData(res?.data)
      })
      .catch(() => {
       
      });
  }, []);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setCitiesOptions(res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.CountryId]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.HeirCountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setHierCitiesOptions(res?.data);
        //  toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.HeirCountryId]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.ReferenceCountryId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        setReferenceCitiesOptions(res?.data);
        //  toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, [formik.values.ReferenceCountryId]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Account/GetNewId`, {
      params: { IdType: 4 },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        //setManagerCitiesOptions(res?.data)
        formik.setFieldValue("Id", res?.data);
        //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
        // setUsersData(res?.data)
      })
      .catch(() => {
        // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
      });
  }, []);
  return (    
    <div> 
    <div   id="loader">
  <div className="spinner"></div>
</div>
    <div className="all-customers fixed overflow-y-auto  w-[calc(100vw-320px)] border rounded-2xl border-[#E6E9EE] shadow-sm   right-[0px] z-40 h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] bg-white ">
      {showCofirmation ? (
        <div className="fixed h-[230px] w-[400px] border border-[#EEEEEE]  bg-white top-[50%] left-[calc(50%+160px)] rounded-xl z-40 transform translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-wrap mt-[-20px] justify-center ">
            <div className="w-[50px] rounded-full p-3 h-[50px] bg-[#2DD683]">
              <CheckIcon className=" text-white w-[25px] h-[25px]" />
            </div>
          </div>
          <div className="flex cursor-pointer flex-wrap justify-end flex-row mt-[-20px]">
            <XIcon
              onClick={() => {
                setRefreshUserFromAdd(true);
                setConfirmation((prev) => !prev);
                setRefreshUser((prev) => !prev);
                setFormToOpen("");
              }}
              className=" mr-[20px] inline w-[25px] h-[25px]"
            />{" "}
          </div>
          <h1 className="w-full font-poppins text-[20px] text-center font-bold mt-4 ">
            Thank You !
          </h1>
          <p className="w-full font-poppins p-4 pl-10 pr-10 text-[16px] text-center font-medium  ">
            Your Details has been Successfully submitted. Thanks!
          </p>

          <div className="w-full rounded-b-xl h-[50px] mt-5 pt-[2px] bg-[#2DD683] items-center flex flex-wrap flex-row justify-center">
            <div
              onClick={() => {      
                document.querySelector("#loader").style.display="flex"
       
                const form = formref.current;
                const options = {
                  margin: 0.1,
                  filename: 'Customer_Form.pdf',
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 1 },
                  jsPDF: { unit: 'in', format: 'a4', compressPdf: true, orientation: 'portrait' }
              };
              html2pdf()
              .set(options)
              .from(form)
              .save()
              .then((pdfBuffer) => {   
              document.querySelector("#loader").style.display="none"
            
              })
              }}
              className=" cursor-pointer w-[25px] pt-[1px] h-[25px] mr-[10px]"
            >
              <Pdf />
            </div>
            <div onClick={()=>{ 
               document.querySelector("#loader").style.display="flex"
       
          
               
            const form = formref.current;  
            const opt=  {
             margin: .1,
             image: { type: 'jpeg', quality:1 },
             html2canvas: { scale: 1 },
             jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } 
           } 
               html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
               const pdfUrl = URL.createObjectURL(pdfBlob);
               const iframe = document.createElement('iframe');
               iframe.style.display = 'none';
               iframe.src = pdfUrl;
               document.body.appendChild(iframe);
         setTimeout(() => {
                 iframe.contentWindow.print();   
                 document.querySelector("#loader").style.display="none"

               }, 1000)   
             
            })}} className=" cursor-pointer w-[25px] h-[25px] ml-[10px] ">
              <Printer />
            </div>
          </div>
        </div>
      ) : undefined}
      <form ref={formref} onSubmit={formik.handleSubmit} className="pb-[50px]">
        <div className="flex flex-wrap justify-between items-center rounded-t-2xl  flex-row bg-main-color p-4 w-full font-poppins font-semibold text-[20px]">
          <h1>Customer Form</h1>
          <div>
            <XIcon
              onClick={() => {
                setFormToOpen("");
              }}
              className="w-[16px] inline cursor-pointer ml-4 h-[16px]"
            />
          </div>
        </div>
        <div className=" p-10 flex relative bg-form-title flex-wrap items-center w-full flex-row justify-between">
          <div>
            <img
              src="./formlogo.png"
              className={`${showCofirmation ? "w-[130px]" : ""}`}
              alt="formlogo"
            />
          </div>
          <div>
            {showCofirmation ? (
              <div className="flex flex-wrap ml-[-100px] items-center flex-row justify-left">
                <div className="border-l-[5px] border-main-color mt-3  h-[70px] w-[4px]"></div>
                <h1 className="w-[50px] text-white mt-[-15px] font-medium font-poppins ml-[20px] text-[25px]">
                  Customer Form
                </h1>
              </div>
            ) : (
              <h1
                className={`font-poppins border-l-[5px]  border-main-color   w-[200px] font-medium  text-white  ${
                  showCofirmation
                    ? "text-[20px] text-center  w-[50px]   pl-[20px]"
                    : "text-[36px]  pl-[40px] line-height-[40px]  ml-[-200px]"
                }`}
              >
                Customer Form
              </h1>
            )}
          </div>
          <div
            onClick={() => {
              let input = document.createElement("input");
              input.type = "file";
              input.accept = "image/*";
              input.onchange = (e) => {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                  setImageUrl(reader.result);
                };
                formik.setFieldValue("Image", e.target.files[0]);
              };
              input.click();
            }}
          >
            <div
              className={`absolute ${
                showCofirmation
                  ? "mt-10 h-[130px] w-[120px] mt-[-30px] ml-[-110px] "
                  : "mt-[-30px] ml-[-170px] h-[180px] w-[170px] "
              } cursor-pointer  bg-white  justify-center z-10 items-center overflow-hidden flex flex-wrap flex-row  rounded-2xl`}
            >
              {imgUrl === null ? (
                showCofirmation ? (
                  ""
                ) : (
                  <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                    Pic
                  </h1>
                )
              ) : (
                <img src={imgUrl} />
              )}
            </div>
          </div>
        </div>
        <div className="w-full  flex flex-wrap justify-left  flex-row bg-main-color p-4">
          <div className="w-[150px]  font-poppins font-medium">
            <p className="mb-2">Customer ID</p>

            <InputText
              disabled
              name="Id"
              value={formik.values.Id}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Id"
            />
            {formik.touched.Id && formik.errors.Id ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Id}
              </p>
            ) : null}
          </div>

          <div className="ml-5  w-[150px]">
            <p className=" mb-2 font-poppins font-medium ">Date</p>

            <Calendar
              dateFormat="dd/mm/yy"
              name="Date"
              disabled
              value={formik.values.Date}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Date=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px]   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Date"
            />
            {formik.touched.Date && formik.errors.Date ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Date}
              </p>
            ) : null}
          </div>
        </div>

        <div className="mt-2 flex flex-wrap flex-row  p-2 justify-center  text-black font-poppins font-medium text-[31px]">
          <h1
            className={`font-medium text-black ${
              showCofirmation ? "text-[20px] font-medium mt-[-20px]" : ""
            }`}
          >
            Customer Data
          </h1>
        </div>
        <div className="flex flex-wrap flex-row w-full  text-[#AFAFAF] pt-0 p-4 justify-between">
          <div className=" w-[49%]">
            <InputText
              name="Name"
              value={formik.values.Name}
              onChange={formik.handleChange}
              className="  border pl-2 h-[40px]  w-full rounded-lg  mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.Name && formik.errors.Name ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Name}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="FatherName"
              value={formik.values.FatherName}
              onChange={formik.handleChange}
              className="  border pl-2 h-[40px]    w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.FatherName && formik.errors.FatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.FatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputMask
              mask="99999-9999999-9"
              name="Cnic"
              value={formik.values.Cnic}
              onChange={formik.handleChange}
              className=" border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.Cnic && formik.errors.Cnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Cnic}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]  flex   flex-wrap flex-row justify-between">
            <div className="w-[49%]">
              {showCofirmation ? (
                <InputText
                  name="CountryId"
                  value={formik.values.CountryId}
                  onChange={formik.handleChange}
                  className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  placeholder="Country"
                />
              ) : (
                <Dropdown
                  className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md "
                  name="CountryId"
                  placeholder="Country"
                  onChange={formik.handleChange}
                  optionValue="id"
                  optionLabel="name"
                  value={formik.values.CountryId}
                  options={countryOptions}
                />
              )}

              {formik.touched.CountryId && formik.errors.CountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[49%] ">
              {showCofirmation ? (
                <InputText
                  name="CityId"
                  value={formik.values.CityId}
                  onChange={formik.handleChange}
                  className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  placeholder="City"
                />
              ) : (
                <Dropdown
                  className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md "
                  name="CityId"
                  placeholder="City"
                  onChange={formik.handleChange}
                  optionValue="id"
                  optionLabel="name"
                  value={formik.values.CityId}
                  options={citiesOption}
                />
              )}
              {formik.touched.CityId && formik.errors.CityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              className=" border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.Address && formik.errors.Address ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Address}
              </p>
            ) : null}
          </div>
          <div className="w-[27%]">
            <InputText
              name="BankName"
              value={formik.values.BankName}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Bank Name"
            />
            {formik.touched.BankName && formik.errors.BankName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankName}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="BankAccountNumber"
              value={formik.values.BankAccountNumber}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Account No"
            />
            {formik.touched.BankAccountNumber &&
            formik.errors.BankAccountNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankAccountNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="Email"
              value={formik.values.Email}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Email"
            />
            {formik.touched.Email && formik.errors.Email ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Email}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="Phone"
              value={formik.values.Phone}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.Phone && formik.errors.Phone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Phone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="WhatsappNumber"
              value={formik.values.WhatsappNumber}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.WhatsappNumber && formik.errors.WhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar
              dateFormat="dd/mm/yy"
              name="Dob"
              value={formik.values.Dob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Dob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="  border pl-2 h-[40px]  w-full  rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.Dob && formik.errors.Dob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Dob}
              </p>
            ) : null}
          </div>
          <div className="flex flex-row w-full mt-4 flex-wrap justify-center p-2">
            <h1
              className={`font-poppins  text-black font-medium text-[#15223C]  ${
                showCofirmation
                  ? "text-[20px] mt-[-30px]"
                  : "text-[31px] mt-[-10px]"
              }`}
            >
              Customer Heir Data
            </h1>
          </div>
          <div className="w-[49%]">
            <InputText
              name="HeirName"
              value={formik.values.HeirName}
              onChange={formik.handleChange}
              className="  border pl-2 h-[40px] w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.HeirName && formik.errors.HeirName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="HeirFatherName"
              value={formik.values.HeirFatherName}
              onChange={formik.handleChange}
              className="  border pl-2 h-[40px]    w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.HeirFatherName && formik.errors.HeirFatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirFatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputMask
              mask="99999-9999999-9"
              name="HeirCnic"
              value={formik.values.HeirCnic}
              onChange={formik.handleChange}
              className=" border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.HeirCnic && formik.errors.HeirCnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirCnic}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]  flex  flex-wrap flex-row justify-between">
            <div className="w-[49%]">
              {showCofirmation ? (
                <InputText
                  name="HeirCountryId"
                  value={formik.values.HeirCountryId}
                  onChange={formik.handleChange}
                  className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  placeholder="Country"
                />
              ) : (
                <Dropdown
                  className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md "
                  name="HeirCountryId"
                  placeholder="Country"
                  onChange={formik.handleChange}
                  optionValue="id"
                  optionLabel="name"
                  value={formik.values.HeirCountryId}
                  options={countryOptions}
                />
              )}

              {formik.touched.HeirCountryId && formik.errors.HeirCountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.HeirCountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[49%]">
              {showCofirmation ? (
                <InputText
                  name="HeirCityId"
                  value={formik.values.HeirCityId}
                  onChange={formik.handleChange}
                  className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  placeholder="City"
                />
              ) : (
                <Dropdown
                  className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md "
                  name="HeirCityId"
                  placeholder="City"
                  onChange={formik.handleChange}
                  optionValue="id"
                  optionLabel="name"
                  value={formik.values.HeirCityId}
                  options={heirCitiesOption}
                />
              )}

              {formik.touched.HeirCityId && formik.errors.HeirCityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.HeirCityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="HeirAddress"
              value={formik.values.HeirAddress}
              onChange={formik.handleChange}
              className="  border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.HeirAddress && formik.errors.HeirAddress ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirAddress}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="HeirPhone"
              value={formik.values.HeirPhone}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Contact Number"
            />
            {formik.touched.HeirPhone && formik.errors.HeirPhone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirPhone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="HeirWhatsappNumber"
              value={formik.values.HeirWhatsappNumber}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.HeirWhatsappNumber &&
            formik.errors.HeirWhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirWhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar
              dateFormat="dd/mm/yy"
              name="HeirDob"
              value={formik.values.HeirDob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.HeirDob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="  border pl-2 h-[40px]  w-full  rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.HeirDob && formik.errors.HeirDob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.HeirDob}
              </p>
            ) : null}
          </div>
          <div className="flex flex-row w-full mt-4 flex-wrap justify-center p-2">
            <h1
              className={`font-poppins text-black font-medium  ${
                showCofirmation ? "text-[20px] mt-[-30px]" : "text-[31px]"
              } `}
            >
              Reference Data
            </h1>
          </div>
          <div className="w-[25%]">
            <InputText
              name="ReferenceId"
              value={formik.values.ReferenceId}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Ref ID"
            />
            {formik.touched.ReferenceId && formik.errors.ReferenceId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceId}
              </p>
            ) : null}
          </div>
          <div className="w-[18%]">
            <InputText
              name="ReferenceName"
              disabled
              value={formik.values.ReferenceName}
              onChange={formik.handleChange}
              className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.ReferenceName && formik.errors.ReferenceName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceName}
              </p>
            ) : null}
          </div>
          <div className="w-[18%] ">
            <InputText
              className="w-full  pl-2 h-[40px] mt-2 border my-dropdown font-poppins font-medium  rounded-md  "
              disabled
              placeholder="Country"
              onChange={formik.handleChange}
              name="ReferenceCountryId"
              value={formik.values.ReferenceCountryId}
            />

            {formik.touched.ReferenceCountryId &&
            formik.errors.ReferenceCountryId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceCountryId}
              </p>
            ) : null}
          </div>

          <div className="w-[18%] mt-2">
            <InputText
              className="w-full border my-dropdown pl-2 h-[40px]   font-poppins font-medium  rounded-md  "
              disabled
              placeholder="City"
              onChange={formik.handleChange}
              name="ReferenceCityId"
              value={formik.values.ReferenceCityId}
            />

            {formik.touched.ReferenceCityId && formik.errors.ReferenceCityId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceCityId}
              </p>
            ) : null}
          </div>
          <div className="w-[18%] ">
            <InputText
              disabled
              className="w-full border my-dropdown mt-2 pl-2 h-[40px]   font-poppins font-medium  rounded-md  "
              placeholder="Reference Position"
              onChange={formik.handleChange}
              name="ReferencePosition"
              value={formik.values.ReferencePosition}
            />

            {formik.touched.ReferencePosition &&
            formik.errors.ReferencePosition ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferencePosition}
              </p>
            ) : null}

            {formik.touched.ReferencePosition &&
            formik.errors.ReferencePosition ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferencePosition}
              </p>
            ) : null}
          </div>
          <div className="bg-custom-gradient mt-4 pb-4 p-4 w-full flex flex-wrap felx-row justify-between">
            <div className="flex flex-row w-full mt-4 flex-wrap justify-center p-2">
              <h1
                className={`font-poppins text-black font-medium font-medium  ${
                  showCofirmation ? "text-[20px] mt-[-30px]" : "text-[31px]"
                } `}
              >
                Purchase Plan
              </h1>
            </div>
            <div className="w-[18%]   ">
              {!showCofirmation ? (
                <Dropdown
                  className="w-full mt-2 my-dropdown border font-poppins font-medium  rounded-md  "
                  placeholder="Plan"
                  onChange={formik.handleChange}
                  optionValue="id"
                  optionLabel="name"
                  name="Plan"
                  value={formik.values.Plan}
                  options={plansData}
                />
              ) : (
                <InputText
                  className="w-full border my-dropdown mt-2 pl-2 h-[40px]   font-poppins font-medium  rounded-md  "
                  placeholder="Plan"
                  onChange={formik.handleChange}
                  name="Plan"
                  value={formik.values.Plan}
                />
              )}

              {formik.touched.Plan && formik.errors.Plan ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.Plan}
                </p>
              ) : null}
            </div>     
            <div className="  w-[28%]">
          
            
             <Calendar dateFormat="dd/mm/yy"
              name="EndTime" 
              value={formik.values.EndTime}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                //formik.values.EndTime=utcDate.toDate() 
                 formik.setFieldValue("EndTime",utcDate.toDate())
              }}
              showIcon 
              
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px] mt-2   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="End Time"
            />
            {formik.touched.EndTime && formik.errors.EndTime ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.EndTime}
              </p>
            ) : null}
          </div>
            <div className="w-[10%]">
              <InputText
                name="Qty"
                value={formik.values.Qty}
                keyfilter="num"
                onChange={formik.handleChange}
                className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
                placeholder="Qty"
              />
              {formik.touched.Qty && formik.errors.Qty ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.Qty}
                </p>
              ) : null}
            </div> 
           
            <div className="w-[20%]">
              <InputText
                name="Amount" 
                disabled
                value={formik.values.Amount}
                onChange={formik.handleChange}
                keyfilter="num"
                className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
                placeholder="Product Amount"
              />
              {formik.touched.Amount && formik.errors.Amount ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.Amount}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]">
              <InputText
                name="Amount2"
                value={formik.values.Amount2}
                keyfilter="num"
                onChange={formik.handleChange}
                className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
                placeholder="Rent"
                disabled
              />
              {formik.touched.Amount2 && formik.errors.Amount2 ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.Amount2}
                </p>
              ) : null}
            </div>
            <div className="w-[20%]">
              <InputText
                name="Liability"
                value={formik.values.Liability}
                onChange={formik.handleChange}
                className="   border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
                placeholder="Liability"
                disabled
              />
            </div>
          </div>
          <div
            className={`flex ${
              showCofirmation ? "mt-40" : "mt-10"
            }  text-black flex-wrap flex-row justify-left  w-[40%]`}
          >
            <h1
              className={` font-poppins w-[200px]   ${
                showCofirmation
                  ? "text-[18px] font-semibold"
                  : "text-[18px] font-bold"
              }`}
            >
              Marketer Signature:
            </h1>
            <div className="w-[calc(100%-200px)] border-b h-[28px] border-b-[4px]  "></div>
          </div>
          <div
            className={`flex ${
              showCofirmation ? "mt-40" : "mt-10"
            }   flex-wrap flex-row justify-left w-[59%]`}
          >
            <h1
              className={` font-poppins w-[350px] text-black   ${
                showCofirmation
                  ? "text-[18px] font-semibold"
                  : "text-[18px] font-bold"
              } `}
            >
              Nearest Manager Name & Signature:
            </h1>
            <div className="w-[calc(100%-367px)] border-b  border-b-[4px]  "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left  w-[46%]">
            <h1
              className={` text-black w-[170px] font-poppins   ${
                showCofirmation
                  ? "text-[18px] font-semibold"
                  : "text-[18px] font-bold"
              } `}
            >
              Company Stemp:
            </h1>
            <div className="w-[calc(100%-170px)] border-b  h-[28px] border-b-[4px] "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left w-[53%]">
            <h1
              className={`w-[200] font-poppins  text-black  ${
                showCofirmation
                  ? "text-[18px] font-semibold"
                  : "text-[18px] font-bold"
              } `}
            >
              Company Owner Signature:
            </h1>
            <div className=" w-[calc(100%-275px)] border-b  border-b-[4px]  "></div>
          </div>

          <div className="flex flex-row w-full mt-4 flex-wrap justify-center ">
            <h1
              className={`font-poppins font-medium text-black  ${
                showCofirmation ? "text-[20px]  " : "text-[31px] mt-4"
              }`}
            >
              {showCofirmation ? "" : "Upload "}
              <span className="font-medium">Customer</span> ID CARD
            </h1>
          </div>
          <div
            className={`w-full ${
              showCofirmation ? "mt-10" : "mt-10"
            }  flex flex-wrap flex-row justify-evenly`}
          >
            <div className="  flex flex-wrap flex-row justify-between">
              <div
                className={`${
                  showCofirmation
                    ? "w-[300px] h-[150px] "
                    : "w-[400px] h-[200px]"
                }  rounded-lg overflow-hidden bg-[#EEEEEE] flex flex-wrap flex-row justify-center items-center`}
                onClick={() => {
                  let input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = (e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = () => {
                      setCnicFront(reader.result);
                    };
                    formik.setFieldValue("IdCardFront", e.target.files[0]);
                  };
                  input.click();
                }}
              >
                {cnicFrontUrl === null ? (
                  showCofirmation ? (
                    ""
                  ) : (
                    <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                      Front
                    </h1>
                  )
                ) : (
                  <img src={cnicFrontUrl} className="h-[100%] w-auto" />
                )}
              </div>
            </div>
            <div className=" flex flex-wrap flex-row justify-between">
              <div
                className={`${
                  showCofirmation
                    ? "w-[300px] h-[150px] "
                    : "w-[400px] h-[200px]"
                }  rounded-lg bg-[#EEEEEE] flex flex-wrap flex-row overflow-hidden justify-center items-center`}
                onClick={() => {
                  let input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = (e) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = () => {
                      setCnicBack(reader.result);
                    };
                    formik.setFieldValue("IdCardBack", e.target.files[0]);
                  };
                  input.click();
                }}
              >
                {cnicBackUrl === null ? (
                  showCofirmation ? (
                    ""
                  ) : (
                    <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                      Back
                    </h1>
                  )
                ) : (
                  <img src={cnicBackUrl} className="h-[100%] w-auto" />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mt-4 flex-row justify-center items-center w-full">
            <div className="flex flex-row w-full mt-4 flex-wrap justify-center ">
              <h1
                className={`font-poppins font-medium text-black  ${
                  showCofirmation ? "text-[20px] mt-[-10px]" : "text-[31px]"
                }`}
              >
                <span className="font-medium">Transaction</span> Receipt
              </h1>
            </div>

            <div className="w-full cursor-pointer  flex flex-wrap flex-row justify-center">
              <div
                className={`  ${
                  showCofirmation
                    ? "w-[300px] h-[150px] mt-10"
                    : "w-[400px] h-[200px]"
                } `}
              >
                <div
                  className={`  ${
                    showCofirmation
                      ? "w-[300px] h-[150px] mt-10"
                      : "w-[400px] h-[200px] mt-8"
                  } rounded-lg bg-[#EEEEEE] flex flex-wrap overflow-hidden flex-row justify-center items-center`}
                  onClick={() => {
                    let input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = (e) => {
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onload = () => {
                        setReceipt(reader.result);
                      };
                      formik.setFieldValue("Receipt", e.target.files[0]);
                    };
                    input.click();
                  }}
                >
                  {receipt === null ? (
                    showCofirmation ? (
                      ""
                    ) : (
                      <h1 className="text-[31px] font-poppins  font-medium ">
                        Upload
                      </h1>
                    )
                  ) : (
                    <img src={receipt} className="h-[100%] w-auto" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {!showCofirmation ? (
            <div className="flex font-poppins mt-20  font-medium flex-row w-full flex-wrap justify-center ">
              <Button
                type="submit"
                disabled={showPageLoader}
                className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
                label={`${showPageLoader ? "" : "Submit"}`}
                iconPos="center"
                icon={showPageLoader ? <CommonLoader /> : ""}
              />

             {/* <Button
                label="Draft"
                className="bg-[#D6D7DB] text-black rounded-xl ml-5 w-[100px]"
              />   */}
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
      <Toast className="z-50 mt-[80px]" ref={toast} />
    </div>   
     </div>
  );
}
