import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
import Loader from "../../Utils";
import { Button } from "primereact/button"; 
import PageLoader from "../../Cities/Components/PageLoader";
import { Dialog } from "primereact/dialog";
import AddFaqs from "./Add_Faqs/Add_Faqs";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function Faqs() {
  const [loaderShow, setLoaderShow] = useState(true);
  const [faqsList, setFaqsList] = useState([]);
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false); 
  const [deleteDialog,setDeleteDialog]=useState(false)  
  const [renderHere,setRenderHere]=useState(1)
  const [currentFaq,setCurrentFaq]=useState()
  useEffect(() => { 
    if(renderHere === 1){ 
    setLoaderShow(true); 
    }
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Faqs/List`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { Search: searchValue },
    })
      .then((res) => { 
         setRenderHere(prev=>{ 
          prev=prev+1
         })
        //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
        setFaqsList(res?.data);
        setLoaderShow(false);
      })
      .catch(() => {
        setLoaderShow(false);
        //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
      });
  }, [searchValue, refresh]);

  return (
    <div className="w-full relative bg-[#EEEEEE] p-2  max-h-[calc(100vh-200px)] overflow-y-auto profilesidebar rounded-md flex text-black  font-poppins font-normal flex-wrap flex-row justify-around">
      <h1 className="text-black font-semibold text-[22px] mt-4">
        Frequently Asked Question
      </h1>
      <AddFaqs setRefresh={setRefresh} />
      <div className="w-[calc(100%-20px)] mt-4 ml-2 mr-2 flex flex-wrap flex-row justify-between">
        <InputText
          className="pl-2 h-[40px] w-[calc(100%-120px)] rounded-l-full"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          placeholder="Search"
        />
        <div className="h-[40px]">
          <i className="pi pi-search w-[120px] h-[40px] pt-3  bg-custom-gradient rounded-r-full text-white pl-[50px] p-2"></i>
        </div>
      </div>
      {loaderShow ? (
        <div className="flex flex-wrap flex-row mt-10 items-center justify-center"><CommonLoaderBlue/></div>
      ) : (
        <div className="flex flex-wrap w-full flex-row  mt-4">
          {faqsList?.map((item) => {
            return (
              <div className="flex bg-[#F1AA21] p-2 w-full  mt-2 rounded-lg flex-wrap flex-row justify-between items-center ">
                <div className="w-[50px] h-[50px] border border-[4px] border-solid border-[black] rounded-full  flex flex-wrap flex-row justify-center items-center">
                  <i className="pi pi-question fill-[black] text-[31px] text-[black] font-bold"></i>
                </div>
                <div className="w-[calc(100%-140px)]">
                  <h1 className="font-semibold">{item.question}</h1>
                  <h1>{item.answer}</h1>
                </div>
                <div>
                  <i
                    onClick={() => { 
                       setCurrentFaq(item.id)
                       setDeleteDialog(prev=>!prev)
                    }}
                    className="pi pi-trash cursor-pointer"
                  ></i>
                </div>
              </div>
            );
          })}
        </div>
      )} 
         <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setDeleteDialog((prev) => !prev);
          }}
          visible={deleteDialog}
        >
          <div className="font-poppins text-medium text-[#AFAFAF]">
            {loaderShow ? <PageLoader /> : undefined}
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Delete Plan? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button
                label="Yes"
                onClick={() => {
                 // setLoaderShow(true);
                  Axios.delete(
                    `${process.env.REACT_APP_BASE_URL}/api/Faqs/Delete`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                      params: { id: currentFaq },
                    }
                  )
                    .then((res) => {
                      //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
                     setDeleteDialog(false)
                      setRefresh((prev) => !prev);
                    })
                    .catch(() => { 
                      setDeleteDialog(false)
                      setRefresh((prev) => !prev);
                      //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
                    });
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setDeleteDialog((prev) => !prev);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>
          </div>
        </Dialog>
    </div>  
  );
}
