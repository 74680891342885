import Wallet from "./components/mainside/wallet/wallet";  
import Axios from "axios"; 
import { useState } from "react";
import UpcomingRetailSelles from "./components/mainside/upcoming-retail-selles/upcoming-retail-selles";
import Quicktransfer from "./components/mainside/quick-transfer/quick-transfer";
import "./styles.css"    
import { useEffect } from "react";
import Stats from "./components/maindetail/stats/stats";
import BusinessFlow from "./components/maindetail/business_flow/business_flow"; 
import TransactionHistory from "./components/maindetail/transaction_history/transaction_history";
function MarketerDashboard() {     
  const [dashboardData,setDashboardData]=useState("")  
  const [loader,setShowLoader]=useState(true)
  let parseLoginRes=JSON.parse(localStorage.getItem("userData")) 
  let token=parseLoginRes.token  
  useEffect(()=>{     
   Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Dashboards/Marketer`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
          
     setDashboardData(res?.data) 
     setShowLoader(false)
   }).catch(()=>{ 
     
   })
  },[]) 
  return (
    <div className="maindashboard mt-[0px] mt-[160px] width716:mt-[90px] width910:mt-[70px]  width1240:mt-[20px]"> 
            <div className="flex flex-wrap flex-row justify-around ">  
            {/*
                 <div className="sidemenue  width910:w-[35%] width500:w-[100%]">      
                    <div className="mainside pt-4 width910:w-[90%]  width500:w-[90%]"> 
                    <Wallet/> 
                     <UpcomingRetailSelles/> 
                      <Quicktransfer/>
                     </div>
                   </div> */}
                  <div className="detailmenu  width910:w-[100%] width500:w-[100%] width500:mt-[220px]  width910:mt-[0px]  "> 
                        <div className="maindetail pt-4">   
                          <div className="flex flex-row  flex-wrap justify-left"> 
                            <div className="w-[100%]   pl-5 width1280:pl-0  width1280:w-[34%] ml-3">
                            <Wallet  dashboardData={dashboardData}/> 
                            </div> 
                             <div className="w-[63%]">
                         <Stats dashboardData={dashboardData} />    
                         </div>  
                          </div>
                          <div className="flex flex-wrap justify-left pl-3 pr-3 mt-4 gap-2 flex-row ">  
                           <TransactionHistory loader={loader} dashboardData={dashboardData}/>
                             
                            
                             <UpcomingRetailSelles loader={loader} dashboardData={dashboardData}/>  
                            
                              
                              
                            <BusinessFlow/> 
                          </div>
                          </div>
                  </div>

            </div>
    </div>
  );
}

export default MarketerDashboard;
