import React from "react";
import "./css/expense_list.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import Axios  from "axios";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,

} from "@heroicons/react/outline";
import { Button } from "primereact/button";
//import AddNewCustomer from "./components/add_new_customer";
//import ViewAllCustomers from "./components/view_all_customers"; 
import Loader from "../../Utils";
import { ExpenseSvg } from "./assets";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function ContactUs({refresh}) { 
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [selectedRows, setSelectedRows] = useState(0);   
  const [loader,setLoader]=useState(true) 
  const [viewAllCustomers,setViewAllCustomers]=useState(false)
  //const [addNewCustomerVisibility, setAddNewCustomerVisibility] 
  const [questionList,setQuestionsList]=useState([]) 
      
    useEffect(()=>{    
      setQuestionsList([])
      setLoader(true)
      Axios.get(`${process.env.REACT_APP_BASE_URL}/api/ContactUs/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
        setQuestionsList(res?.data)  
        setLoader(false)
       //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
         // setUsersData(res?.data)
      }).catch(()=>{ 
       // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
     
      })
     },[refresh])

  return (
    <div className="overflow-x-hidden w-full  bg-[#EEEEEE] mt-0 max-h-[calc(100vh-200px)] rounded-2xl overflow-y-auto profilesidebar">
   {/*   <Button
        onClick={() => {
          setAddNewCustomerVisibility(true);
        }}
        className="bg-submit-button text-white top-[30px] absolute  right-[20px] font-poppins border-none mt-[59px] pl-4 pr-4 pt-2 pb-2"
      >
        <PlusIcon className="w-[15px] inline mt-[-2px] mr-[5px]" />
        Add New Customer
      </Button> 
      */}
      <DataTable
        value={questionList}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        size="small"
       
        resizableColumns
        paginator
       rows={10}
        rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Question Not Found"}
        style={{ backgroundColor: 'white'}}
        className=" relative w-[99%] p-4  profilesidebar mt-4 ml-1 rounded-md     font-poppins  "
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column headerClassName="border-b-[2px]  bg-white border-main-color" header={<span className="text-black font-semibold text-[20px]">Questions List</span>}  field="name" className="border-r border-[#D6D7DB]"></Column>
      <Column headerClassName="text-main-color  bg-white border-b-[2px] border-main-color font-medium" header="Email" className="border-r border-[#D6D7DB]"  field="email"></Column>
      
        <Column  headerClassName="text-main-color   bg-white border-b-[2px] border-main-color font-medium"  header="Message" className="border-r border-[#D6D7DB]" field="message"></Column>
        <Column headerClassName="text-main-color  bg-white border-b-[2px] border-main-color font-medium" className="border-r border-[#D6D7DB]"  field="action" body={tableActions}></Column>
      </DataTable>
     {/* <Dialog
        header="Add New Customer"
        className="font-poppins"
        visible={addNewCustomerVisibility}
        style={{ width: "80vw" }}
        onHide={() => setAddNewCustomerVisibility(false)}
      >   
        <AddNewCustomer />
      </Dialog> 
      
      <div> 
        {
        viewAllCustomers ? <ViewAllCustomers setViewAllCustomers={setViewAllCustomers} allcustomers={customers}/>:undefined }</div> 
    */}
    </div>
  );
}
const statusBody = (rowData) => {
  return (
    <Button className="bg-[#FF9770] text-white font-poppins pt-2 pb-2 pl-4 pr-4 rounded">
      {rowData.status}
    </Button>
  );
};
const tableActions = (rowData) => {
  return (
    <div className="w-full  flex flex-wrap flex-row justify-around">
    
   
        
        <div >
         <TrashIcon className="w-[16px] cursor-pointer   " />
        </div>
       
      
    </div>
  );
};
