import React, { useState ,useRef,useEffect} from "react";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar"; 
import { Toast } from "primereact/toast";
import * as Yup from "yup";  
import moment from "moment";
import html2pdf from 'html2pdf.js';
import {
  XIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Pdf, Printer } from "../../../Svg";
import Axios  from "axios";
import CommonLoader from "../../../../Components/Loader/Loader";
export default function MarketerForm({ setFormToOpen,setRefreshUser,setRefreshUserFromAdd }) {
  const [showCofirmation, setConfirmation] = useState(false); 
  const toast=useRef()    
  let formref=useRef()
  let parseLoginRes=JSON.parse(localStorage.getItem("userData"))   
  let token=parseLoginRes.token 
  const [imgUrl,setImageUrl]=useState(null)     
  const [showImageError,setShowImageError]=useState(false)  
  const [showCnicFrontError,setShowCnicFrontError]=useState(false) 
  const [showCnicBackError,setShowCnicBackError]=useState(false)  
  const [cnicFrontUrl,setCnicFront]=useState(null)  
  const [cnicBackUrl,setCnicBack]=useState(null) 
   const [countryOptions,setCountryOptions]=useState([]) 
   const [citiesOption,setCitiesOptions]=useState([])  
   const [referenceCitiesOption,setReferenceCitiesOptions]=useState([])   
   const [showPageLoader,setShowPageLoader]=useState(false)
  
   useEffect(()=>{  

    document.body.style.overflow = 'hidden';
  return ()=>{  
  
    document.body.style.overflow = 'auto';
  }
   },[])
  const validationSchema = Yup.object().shape({
    Id: Yup.string().required("Branch ID Is Required"),
    Date: Yup.string().required("Date Is Required"),

    CountryId: Yup.string().required("Country Is Required"),
    CityId: Yup.string().required("City Is Required"),
    Address: Yup.string().required("Address Is Required"),
    Name: Yup.string().required("Name Is Required"),
    
    FatherName: Yup.string().required("Father Name Is Required"),
    BankAccountNumber: Yup.string().required("Account No Is Required"),
     BankName:Yup.string().required("Account No Is Required"),
    Email: Yup.string().required("Email Is Required"),
    Phone: Yup.string().required("Phone Is Required"),
    WhatsappNumber: Yup.string().required("WhatsappNumber Is Required"),
    Dob: Yup.string().required("Dob Is Required"),
    Cnic: Yup.string().required("Cnic Is Required"),
    ReferenceName: Yup.string().required("Name Is Required"),
    ReferencePhone: Yup.string().required("Phone Is Required"),
    ReferenceCityId: Yup.string().required("City Is Required"),
    ReferenceCountryId: Yup.string().required("Country Is Required"),
    ReferenceAddress: Yup.string().required("Address Is Required"),
    ReferenceOccupation: Yup.string().required("Occupation Is Required"),
    ReferenceRelationYears: Yup.string().required(
      "Relation Year Is Required"
    ),
    Password: Yup.string().required(
      "Password Is Required"
    ).min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one special character'
    )
    .test(
      'password',
      'Password must contain at least one special character',
      (value) => {
        return /[!@#$%^&*(),.?":{}|<>]/.test(value);
      }
    )
  });  

  const formik = useFormik({
    initialValues: {
      Id: "",
      Date: new Date(),
      Name: "",  
      Password:"",
      BankName:"", 
      Image:"", 
      IdCardBack:"", 
      IdCardFront:"",
      FatherName: "",
      CountryId: "",
      CityId: "",
      Address: "",
      BankAccountNumber: "",
      Email: "",
      Phone: "",
      WhatsappNumber: "",
      Dob: "",
      Cnic: "",
      ReferenceName: "",
      ReferencePhone: "",
      ReferenceCityId: "",

      ReferenceCountryId: "",
      ReferenceAddress: "",
      ReferenceOccupation: "",
      ReferenceRelationYears: "",
    },
    validationSchema,
    onSubmit: (values) => {
      //setConfirmation((prev) => !prev); 
       
          //setConfirmation(prev=>!prev)  
          if(formik.values.Image !== ""){ 
            if(formik.values.IdCardBack !== ""){ 
              if(formik.values.IdCardFront !== ""){   
                
                
                 AddMarketer(values)
          
              } 
              else{  
                setShowCnicFrontError(true)
                
              
              }
            } 
            else{  
              setShowCnicBackError(true)
              if(formik.values.IdCardFront === ""){ 
                setShowCnicFrontError(true)
               }  
              
            }
          }  
          else{ 
            setShowImageError(true) 
             if(formik.values.IdCardBack === ""){ 
              setShowCnicBackError(true)
             } 
             if(formik.values.IdCardFront === ""){ 
              setShowCnicFrontError(true)
             }  
          }
    },
  });  
  function AddMarketer(values){ 
    setShowPageLoader(true)
    const formData=new FormData() 
    for (const key in values) { 
       if(key === "Dob" || key === "Date"){  
         let currentvalue=values[key] 
          let date=new Date(currentvalue) 
           let isoformat=date.toISOString() 
        formData.append(key, isoformat); 
       } 
       else{ 
      formData.append(key, values[key]); 
       }
    }
  
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Users/AddMarketer`,formData,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
      setShowPageLoader(false) 
      setConfirmation(true)
        // setCountryOptions(res?.data)
       // setUsersData(res?.data)
    }).catch((err)=>{  
      setShowPageLoader(false) 
    })
  }  
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
     
         setCountryOptions(res?.data)
       // setUsersData(res?.data)
    }).catch(()=>{ 
   
    })
   },[])   
   useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.CountryId}`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         setCitiesOptions(res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[formik.values.CountryId]) 
   useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.ReferenceCountryId}`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         setReferenceCitiesOptions(res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[formik.values.ReferenceCountryId]) 
   useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Account/GetNewId`,{params:{IdType:6}, headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         //setManagerCitiesOptions(res?.data) 
   formik.setFieldValue("Id",res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[])
  return (    
    <div> 
    <div   id="loader">
  <div className="spinner"></div>
</div>
    <div className="all-customers fixed overflow-y-auto  w-[calc(100vw-320px)] border rounded-2xl border-[#E6E9EE] shadow-sm   right-[0px] z-40 h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] bg-white ">
       
      {showCofirmation ? (
        <div className="fixed h-[230px] w-[400px] border border-[#EEEEEE]  bg-white top-[50%] left-[calc(50%+160px)] rounded-xl z-40 transform translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-wrap mt-[-20px] justify-center ">
            <div className="w-[50px] rounded-full p-3 h-[50px] bg-[#2DD683]">
              <CheckIcon className=" text-white w-[25px] h-[25px]" />
            </div>
          </div>
          <div className="flex cursor-pointer flex-wrap justify-end flex-row mt-[-20px]">
            <XIcon
              onClick={() => { 
                setRefreshUserFromAdd(true)
                setConfirmation((prev) => !prev);   
                setRefreshUser(prev=>!prev) 
                setFormToOpen("")
              }}
              className=" mr-[20px] inline w-[25px] h-[25px]"
            />{" "}
          </div>
          <h1 className="w-full font-poppins text-[20px] text-center font-bold  ">
            Thank You !
          </h1>
          <p className="w-full font-poppins p-4 pl-10 pr-10 text-[16px] text-center font-medium  ">
            Your Details has been Successfully submitted. Thanks!
          </p>

          <div className="w-full rounded-b-xl h-[50px] mt-5 pt-[2px] bg-[#2DD683] items-center flex flex-wrap flex-row justify-center">
            <div   onClick={() => {      
                document.querySelector("#loader").style.display="flex"
       
                const form = formref.current;
                const options = {
                  margin: 0.1,
                  filename: 'Customer_Form.pdf',
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 1 },
                  jsPDF: { unit: 'in', format: 'a4', compressPdf: true, orientation: 'portrait' }
              };
              html2pdf()
              .set(options)
              .from(form)
              .save()
              .then((pdfBuffer) => {   
              document.querySelector("#loader").style.display="none"
            
              })
              }}  
             className="w-[25px]  cursor-pointer pt-[1px] h-[25px] mr-[10px]">
              <Pdf />
            </div>
            <div onClick={()=>{ 
               document.querySelector("#loader").style.display="flex"
       
          
               
            const form = formref.current;  
            const opt=  {
             margin: .1,
             image: { type: 'jpeg', quality:1 },
             html2canvas: { scale: 1 },
             jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } 
           } 
               html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
               const pdfUrl = URL.createObjectURL(pdfBlob);
               const iframe = document.createElement('iframe');
               iframe.style.display = 'none';
               iframe.src = pdfUrl;
               document.body.appendChild(iframe);
         setTimeout(() => {
                 iframe.contentWindow.print();   
                 document.querySelector("#loader").style.display="none"

               }, 1000)   
             
            })}} className="w-[25px] cursor-pointer h-[25px] ml-[10px] ">
              <Printer />
            </div>
          </div>
        </div>
      ) : undefined}
      <form ref={formref} onSubmit={formik.handleSubmit} className="pb-[50px]">
        <div className="flex flex-wrap justify-between items-center rounded-t-2xl  flex-row bg-main-color p-4 w-full font-poppins font-semibold text-[20px]">
          <h1>Marketer Form</h1>
          <div>
            
            <XIcon
              onClick={() => {
                setFormToOpen("");
              }}
              className="w-[16px] inline cursor-pointer ml-4 h-[16px]"
            />
          </div>
        </div>
        <div className=" p-10 flex relative bg-form-title flex-wrap items-center w-full flex-row justify-between">
          <div>
            <img src="./formlogo.png" className={`${showCofirmation ? "w-[130px]":""}`} alt="formlogo" />
          </div>
          <div>
           { 
               showCofirmation ?    <div className="flex flex-wrap ml-[-100px] items-center flex-row justify-left">   
                   <div className="border-l-[5px] border-main-color mt-3  h-[70px] w-[4px]"></div>
                   <h1 className="w-[50px] text-white mt-[-15px] font-medium font-poppins ml-[20px] text-[25px]">Marketer Form</h1>
                </div> :    <h1 className={`font-poppins border-l-[5px]  border-main-color   w-[200px] font-medium  text-white  ${showCofirmation ? "text-[20px] text-center  w-[50px]   pl-[20px]":"text-[36px]  pl-[40px] line-height-[40px]  ml-[-200px]"}`}>Marketer Form</h1>
         
             }
             </div>
          <div    onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setImageUrl(reader.result)
                }
              formik.setFieldValue("Image",e.target.files[0])  
              setShowImageError(false)
              }  
              input.click()
          }}>
             <div className={`absolute ${showCofirmation ? "mt-10 h-[130px] w-[120px] mt-[-30px] ml-[-110px] ":"mt-[-30px] ml-[-170px] h-[180px] w-[170px] "} cursor-pointer  bg-white  justify-center z-10 items-center overflow-hidden flex flex-wrap flex-row  rounded-2xl`}>
             
             {imgUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Pic 
              </h1>  
              :<img src={imgUrl}/>
}    
            </div> 
            { showImageError ? (
              <p className={`font-poppins block font-medium mt-[155px] absolute ml-[-165px] text-red-500`}>
                Image Is Required
              </p>
            ) : null}
          </div>
        </div>
        <div className="w-full  flex flex-wrap justify-left  flex-row bg-main-color p-4">
        <div className="w-[150px]  font-poppins font-medium">
            <p className="mb-2">Marketer ID</p>
         
              <InputText
              disabled
              name="Id"
              value={formik.values.Id}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Id"
            />
            {formik.touched.Id && formik.errors.Id ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Id}
              </p>
            ) : null}
          </div>   
          
          <div className="ml-5  w-[150px]">
            <p className=" mb-2 font-poppins font-medium ">Date</p>
          
            
             <Calendar dateFormat="dd/mm/yy"
              name="Date"  
              disabled
              value={formik.values.Date}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Date=utcDate.toDate()
              }}
              showIcon 
              
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px]   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Date"
            />
            {formik.touched.Date && formik.errors.Date ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Date}
              </p>
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap text-[#AFAFAF] flex-row w-full p-4 justify-between">
          <div className="w-[49%]">
            <InputText
              name="Name"
              value={formik.values.Name}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px] mt-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.Name && formik.errors.Name ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Name}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="FatherName"
              value={formik.values.FatherName}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.FatherName && formik.errors.FatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.FatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputMask
              mask="99999-9999999-9"
              name="Cnic"
              value={formik.values.Cnic}
              onChange={formik.handleChange}
              className=" text-[#AFAFAF] mt-2 border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Cnic"
            />
            {formik.touched.Cnic && formik.errors.Cnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Cnic}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]  flex   flex-wrap flex-row justify-between">
            <div className="w-[49%]">
               
                {  
            showCofirmation ?  
            <InputText
            name="CountryId"
            value={formik.values.CountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="CountryId"  placeholder="Country" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.CountryId} options={countryOptions}/>
         
   }

              {formik.touched.CountryId && formik.errors.CountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[49%] ">
            {  
            showCofirmation ?  
            <InputText
            name="CityId"
            value={formik.values.CityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="City"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="CityId"  placeholder="City" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.CityId} options={citiesOption}/>
         
   }
              {formik.touched.CityId && formik.errors.CityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.CityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%] flex flex flex-row justify-between">
             <div className="w-[49%]">
            <InputText
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              className=" text-[#AFAFAF] border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.Address && formik.errors.Address ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Address}
              </p>
            ) : null}   
              </div> 
              <div className="w-[49%]">
            <InputText
              name="Password"
              value={formik.values.Password}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px] mt-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Password"
            />
            {formik.touched.Password && formik.errors.Password ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Password}
              </p>
            ) : null}
          </div>
          </div> 
          <div className="w-[28%]">
            <InputText
              name="BankName"
              value={formik.values.BankName}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Bank Name"
            />
            {formik.touched.BankName && formik.errors.BankName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankName}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="BankAccountNumber"
              value={formik.values.BankAccountNumber}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Account No"
            />
            {formik.touched.BankAccountNumber && formik.errors.BankAccountNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BankAccountNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="Email"
              value={formik.values.Email}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Email"
            />
            {formik.touched.Email && formik.errors.Email ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Email}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="Phone"
              value={formik.values.Phone}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Phone Number"
            />
            {formik.touched.Phone && formik.errors.Phone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Phone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="WhatsappNumber"
              value={formik.values.WhatsappNumber}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="WhatsappNumber Number"
            />
            {formik.touched.WhatsappNumber && formik.errors.WhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.WhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar dateFormat="dd/mm/yy"
              name="Dob"
              value={formik.values.Dob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.Dob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full  rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Dob"
            />
            {formik.touched.Dob && formik.errors.Dob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.Dob}
              </p>
            ) : null}
          </div>
          <div className={`flex flex-wrap w-full mt-8 ${showCofirmation ? "mb-4":""}  items-center justify-evenly flex-row `}>
            <div>
              <h1 className={`font-poppins  font-medium text-[26px] ${showCofirmation ? "mt-[-50px]":""} text-[#595A5C]`}>
                References
              </h1>
            </div>    
            {   
            !showCofirmation ? 
            <h1 className={`font-poppins font-medium text-[16px] text-[#595A5C] pl-[40px]  border-l w-[60%]`}>
              List below One person who have knowledge of your work performance
              within the last 02 years. Please include professional references
              only.
            </h1> :  
             <div className="flex flex-wrap flex-row mt-[-15px] justify-center w-[60%] ">   
              <div className="border-l-[2px] w-[10px] h-[100px] text-[#595A5C]"> </div> 
              <h1 className={`font-poppins font-medium text-[16px] text-[#595A5C] pl-[40px] w-[95%]  `}>
              List below One person who have knowledge of your work performance
              within the last 02 years. Please include professional references
              only.
            </h1>
              </div>

}
          </div>

          <div className="w-[30%]">
            <InputText
              name="ReferenceName"
              value={formik.values.ReferenceName}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px] mt-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.ReferenceName && formik.errors.ReferenceName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceName}
              </p>
            ) : null}
          </div>
          <div className="w-[22%]">
            <InputText
              name="ReferencePhone"
              value={formik.values.ReferencePhone}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px] mt-2 w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Mobile No"
            />
            {formik.touched.ReferencePhone && formik.errors.ReferencePhone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferencePhone}
              </p>
            ) : null}
          </div>
            <div className="w-[22%]">
               
                {  
            showCofirmation ?  
            <InputText
            name="CountryId"
            value={formik.values.CountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="ReferenceCountryId"  placeholder="Country" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.ReferenceCountryId} options={countryOptions}/>
         
   }

              {formik.touched.ReferenceCountryId && formik.errors.ReferenceCountryId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.ReferenceCountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[22%] ">
            {  
            showCofirmation ?  
            <InputText
            name="ReferenceCityId"
            value={formik.values.ReferenceCityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2  mt-2  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="City"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] mt-2 border font-poppins font-medium  rounded-md " name="ReferenceCityId"  placeholder="City" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.referenceCitiesOption} options={referenceCitiesOption}/>
         
   }
              {formik.touched.ReferenceCityId && formik.errors.ReferenceCityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.ReferenceCityId}
                </p>
              ) : null}
            </div>
          <div className="w-[49%]">
            <InputText
              name="ReferenceAddress"
              value={formik.values.ReferenceAddress}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.ReferenceAddress && formik.errors.ReferenceAddress ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceAddress}
              </p>
            ) : null}
          </div>     
          <div className="w-[25%]">
            <InputText
              name="ReferenceOccupation"
              value={formik.values.ReferenceOccupation}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Occupation"
            />
            {formik.touched.ReferenceOccupation && formik.errors.ReferenceOccupation ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceOccupation}
              </p>
            ) : null}
          </div> 
          <div className="w-[25%]">
            <InputText
              name="ReferenceRelationYears"
              value={formik.values.ReferenceRelationYears}
              onChange={formik.handleChange}
              className="  text-[#AFAFAF] border pl-2 h-[40px]  mt-2  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Relation Years"
            />
            {formik.touched.ReferenceRelationYears && formik.errors.ReferenceRelationYears ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ReferenceRelationYears}
              </p>
            ) : null}
          </div>
          <div className="flex flex-row w-full mt-4 flex-wrap justify-center ">
            <h1 className={`font-poppins font-medium text-black  ${showCofirmation ? "text-[20px]  ":"text-[31px] mt-4"}`}>
             {showCofirmation ? "":"Upload "}<span className="font-medium">Marketer</span> ID CARD
            </h1>
          </div>
          <div className={`w-full ${showCofirmation ? "mt-10":"mt-10"}  flex flex-wrap flex-row justify-evenly`}>
          <div className="  flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[300px] h-[150px] ":"w-[400px] h-[200px]"}  rounded-lg overflow-hidden bg-[#EEEEEE] flex flex-wrap flex-row justify-center items-center`}   onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicFront(reader.result)
                }
              formik.setFieldValue("IdCardFront",e.target.files[0])         
               setShowCnicFrontError(false)
              }  
               input.click()
          }}>
             
              {cnicFrontUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Front
              </h1> 
              :<img src={cnicFrontUrl} className="h-[100%] w-auto"/>   
        }      
         </div>
           { showCnicFrontError ? (
              <p className="font-poppins block w-full font-medium  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
            
             </div>
            <div className=" flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[300px] h-[150px] ":"w-[400px] h-[200px]"}  rounded-lg bg-[#EEEEEE] flex flex-wrap flex-row overflow-hidden justify-center items-center`} onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicBack(reader.result)
                }  
              formik.setFieldValue("IdCardBack",e.target.files[0])  
              setShowCnicBackError(false)
              }  
              input.click()
          }}>
               {cnicBackUrl === null ?   <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Back
              </h1> 
              :<img src={cnicBackUrl} className="h-[100%] w-auto"/>   
        }   
            </div> 
            { showCnicBackError ? (
              <p className="font-poppins w-full block font-medium  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
          </div> 
          </div>
          <div className="flex mt-10  text-black flex-wrap flex-row justify-left  w-[40%]">
            <h1 className={` font-poppins w-[200px]   ${showCofirmation ? "text-[18px] font-semibold":"text-[18px] font-bold"}`}>
              Marketer Signature:
            </h1>
            <div className="w-[calc(100%-200px)] border-b h-[28px] border-b-[4px]  "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left w-[59%]">
            <h1 className={` font-poppins w-[350px] text-black   ${showCofirmation ? "text-[18px] font-semibold":"text-[18px] font-bold"} `}>
              Nearest Manager Name & Signature:
            </h1>
            <div className="w-[calc(100%-367px)] border-b  border-b-[4px]  "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left  w-[46%]">
            <h1 className={` text-black w-[170px] font-poppins   ${showCofirmation ? "text-[18px] font-semibold":"text-[18px] font-bold"} `} >
              Company Stemp:
            </h1>
            <div className="w-[calc(100%-170px)] border-b  h-[28px] border-b-[4px] "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left w-[53%]">
            <h1 className={`w-[200] font-poppins  text-black  ${showCofirmation ? "text-[18px] font-semibold":"text-[18px] font-bold"} `}>
              Company Owner Signature:
            </h1>
            <div className=" w-[calc(100%-275px)] border-b  border-b-[4px]  "></div>
          </div>
         
         { !showCofirmation ? <div className="flex font-poppins mt-10  font-medium flex-row w-full flex-wrap justify-center ">
            
            <Button  
             onClick={()=>{ 
              if(formik.values.Image !== ""){ 
                if(formik.values.IdCardBack !== ""){ 
                  if(formik.values.IdCardFront !== ""){   
                    formik.handleSubmit() 
                  
                  } 
                  else{  
                    setShowCnicFrontError(true)
                    
                     
                     formik.handleSubmit()
                  }
                } 
                else{  
                  setShowCnicBackError(true)
                  if(formik.values.IdCardFront === ""){ 
                    setShowCnicFrontError(true)
                   }  
                  
                   formik.handleSubmit()
                }
              }  
              else{ 
                setShowImageError(true) 
                 if(formik.values.IdCardBack === ""){ 
                  setShowCnicBackError(true)
                 } 
                 if(formik.values.IdCardFront === ""){ 
                  setShowCnicFrontError(true)
                 }  
                 
                 formik.handleSubmit()
              }
            }}
            type="button"
            disabled={showPageLoader}
            className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${showPageLoader ? "":"Submit"}`}   
            iconPos="center"
            icon={showPageLoader? <CommonLoader/>:""}
          /> 
           {/* <Button
              label="Draft"
              className="bg-[#D6D7DB] text-black rounded-xl ml-5 w-[100px]"
            />  
         */}
          </div>  
            :""}
        </div>
      </form>  
      
      <Toast className="z-50 mt-[80px]" ref={toast}/>
    </div>    
    </div>
  );
}
