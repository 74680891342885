import Axios from "axios";
import { Dropdown } from "primereact/dropdown"; 
import html2pdf from 'html2pdf.js';
import React, { useState,useEffect, useRef } from "react"; 
import moment from "moment";  
import { DataTable } from "primereact/datatable"; 
import { Column } from "primereact/column";     
import { Printer,Copy } from "../Svgs";
import { Button } from "primereact/button";  
import { Dialog } from "primereact/dialog"; 
import { Toast } from "primereact/toast";
import CommonLoaderBlue from "../../../../Components/Loader/LoaderBlue";
export default function Rent(){    
   const monthOptions = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
];
function generateYearObjects() {
  const currentYear = new Date().getFullYear();
  const years = [];

  // Loop from current year to 2010
  for (let year = currentYear; year >= 2010; year--) {
      years.push({ label: year.toString(), value: year });
  }

  return years;
}    
  const toast=useRef() 
   const dataRef=useRef()
    let parseLoginRes = JSON.parse(localStorage.getItem("userData")); 
     const [payingDialog,setPayingDialog]=useState(false)  
     const [currentSelected,setCurrentSelected]=useState()
    let token = parseLoginRes.token;      
    const [plansData,setplansData]=useState([])      
    const [payingLoader,setPayingLoader]=useState(false)   
    const [renderHere,setRenderHere]=useState(1) 
     const [yearloader,setYearLoader]=useState(false) 
     const [monthloader,setMonthLoader]=useState(false)  
    const [rentList,setRentList]=useState([])   
    const [selectedRow,setSelectedRow]=useState()
    const [loader,setLoader]=useState(true)         
    const [currentYear,setCurrentYear]=useState((new Date()).getFullYear())   
     const [currentMonth,setCurrentMonth]=useState((new Date()).getMonth()+1)  
      const [currentPlan,setcurrentPlan]=useState(0)   

      const   [payApiLoader,setPayApiLoader]=useState(false)  
      const [pdfSelect,setPdfSelect]=useState(false) 
      const [printSelect,setPrintSelect]=useState(false)
     
      const tableActions = (rowData) => {
        return (
          <div className="w-full  flex flex-wrap items-center flex-row justify-around">
             <Button  label={`${rowData?.isPaid ? "PAYED":"PAY"}`}  onClick={()=>{  
              if(rowData?.isPaid){ 

              } 
              else{
              setSelectedRow(rowData)
               setPayingDialog(prev=>!prev) 
                
              }
             }} className={`${rowData.isPaid ? "bg-[#D6D7DB] text-[#ffffff] cursor":"bg-[#2DD683] text-white cursor-pointer"} rounded-full pl-2 pr-2`}
            
          />
            <div className="cursor-pointer" onClick={()=>{   
            
                 setCurrentSelected(rowData)   
                
                 if (!pdfSelect) {  
                  document.querySelector("#loader").style.display="flex"
         
                    setPdfSelect(prev => !prev);
            
                    const form = dataRef.current;
                    const options = {
                        margin: 0.1,
                        filename: `${rowData?.customer?.name}_Invoice.pdf`,
                        image: { type: 'jpeg', quality: 1 },
                        html2canvas: { scale: 2 },
                        pagebreak: { mode: 'avoid-all', before: '#page2el' },
                        jsPDF: { unit: 'px', format: [300,570], compressPdf: true, orientation: 'portrait' }
                    };
                    html2pdf()
                    .set(options)
                    .from(form)
                    .save()
                    .then((pdfBuffer) => { 
                   //await generatePDFInWorker(form, options);
                    setPdfSelect(prev => !prev);   
                    //document.querySelector("#loader").style.display="none"
                    document.querySelector("#loader").style.display="none"
       
                    })
                }
              }}>
              <Copy  />
              
              </div> 
              <div  className="cursor-pointer" onClick={()=>{
                 setCurrentSelected(rowData)   
                
                if(!printSelect){  
                 // document.querySelector("#loader").style.display="flex"
                 document.querySelector("#loader").style.display="flex"
       
                  setPrintSelect(prev=>!prev)
                  
               const form = dataRef.current;  
               const opt=  {
                margin: .1,
                image: { type: 'jpeg', quality:1 },
                
                filename: `${rowData?.customer?.name}_Invoice.pdf`,
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'px', format: [300,570], orientation: 'portrait' } 
              } 
                  html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
                  const pdfUrl = URL.createObjectURL(pdfBlob);
                  const iframe = document.createElement('iframe');
                  iframe.style.display = 'none';
                  iframe.src = pdfUrl;
                  document.body.appendChild(iframe);
            setTimeout(() => {
                    iframe.contentWindow.print();   
                    //document.querySelector("#loader").style.display="none"
                    document.querySelector("#loader").style.display="none"
         
                  }, 1000);  
                 setPrintSelect(prev=>!prev)});   
                }
              }} >
               <Printer />
               
              </div> 
              
            
          </div>
        );
      };
   
  
    useEffect(() => {
    
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Plans/List`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
           // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            
               
            setplansData(res?.data);  
          })
          .catch(() => {
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
      
     
           
      }, []);  
      useEffect(()=>{    
           if(renderHere === 1){
        setLoader(true)  
           }
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Rents/List`, {    
          
          headers: { Authorization: `Bearer ${token}` }, 
          params:{ 
              plan:currentPlan, 
              Month:currentMonth, 
              Year:currentYear
          }
        })
          .then((res) => {  
             setMonthLoader(false) 
             setYearLoader(false)
            setRenderHere(prev=>{ 
              prev=prev+1 
          
            })
           // toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
            
               
            setRentList(res?.data);   
            setLoader(false)
          })
          .catch(() => { 
            setLoader(false)
            //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
          });
       },[currentPlan,currentMonth,currentYear,payApiLoader])
     return(  
      <div> 
      <div   id="loader">
    <div className="spinner"></div>
  </div>
        <div className=" w-full  font-poppins text-[#AFAFAF] bg-[#ffffff] font-medium border-[1px] border-[#EEEEEE] rounded-lg overflow-hidden">
         <div className="bg-black pb-2 pt-2  flex items-center flex-wrap flex-row justify-between">
         <h1  className=" pl-[2px]  text-[19px] text-white font-semibold ">Customers Rent</h1>  
         <div className="flex items-center gap-1  flex-wrap flex-row justify-between"> <Dropdown  optionLabel="label"
              
              optionValue="value"  onChange={(e)=>{ 
                setCurrentYear(e.value)
              }} options={generateYearObjects()} value={currentYear}  className=" h-[40px] pl-1 pr-1  w-[110px] my-dropdown bg-white text-black " placeholder="month"/> 
        
           <Dropdown  optionLabel="label"
              optionValue="value"  onChange={(e)=>{ 
                setCurrentMonth(e.value)
              }} options={monthOptions} value={currentMonth}  className=" h-[40px] pl-1 pr-1  w-[100px] my-dropdown bg-white text-black " placeholder="month"/> 
          
        <Dropdown  optionLabel="name"
              optionValue="id"  onChange={(e)=>{    
                
                setcurrentPlan(e.value)
              }} options={plansData} value={currentPlan}  className=" h-[40px] pl-[1px] pr-[1px] text-[9px] w-[110px]  my-dropdown bg-white " placeholder="Plan"/>    
           </div>
          </div>  
           <div className="overflow-x-auto">
           <DataTable
        value={rentList}
        onSelectionChange={(e) => { /*setSelectedRows(e.value) */ }}
        size="small"
       
        resizableColumns
        //paginator
      //  rows={10}
        //rowsPerPageOptions={[25, 50]} 
        
        emptyMessage={loader ? <div className="w-full flex flex-wrap flex-row justify-center items-center mt-[14px]"><CommonLoaderBlue/></div>:"Customers Rent Not Found"}
         
        style={{ backgroundColor: '#EEEEEE' }} 

        className=" relative w-full mt-4 rounded-lg   bg-[#ffffff] font-poppins  "
      >
        {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
        <Column body={(rowdata) => {
            return (
              <div className="flex w-[300px] flex-wrap flex-row items-center justify-left">
                <div className="rounded-[50%] overflow-hidden bg-seller-avatar w-[40px] h-[35px] self-center border border-[#E6E9EE] shadow-sm">  
                <img   onLoad={(event)=>{ 
          const { naturalWidth, naturalHeight } = event.target; 
           if(naturalWidth > naturalHeight){ 
            event.target.style="width:auto;height:100%"  
             
           } 
           else{ 
            event.target.style="width:100%;height:auto"  
         
           }

         }}  className="transform ml-[50%] mt-[50%] translate-x-[-50%] translate-y-[-50%]" src={rowdata?.customer?.image}   alt="branch image"/>
            
                 </div>
                <p className="ml-5 font-medium">{rowdata?.customer?.name}</p>
              </div>
            );
          }}  headerClassName="text-main-color min-w-[170px] bg-[#ffffff]  border-b-[1px]  border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium" header="Name"  field="Name" className="  bg-[#ffffff]  border-r border-[#D6D7DB]"></Column>
        <Column headerClassName="text-main-color   bg-[#ffffff]   border-b-[1px] border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium" header="Rent" className="  bg-[#ffffff] border-r border-[#D6D7DB]"  field="rent"></Column>
      
        <Column  headerClassName="text-main-color   bg-[#ffffff]    border-b-[1px] border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium"  header="Marketer" className="  bg-[#ffffff]  border-r border-[#D6D7DB]" field="marketer.name"></Column>
        <Column headerClassName="text-main-color min-w-[150px]  bg-[#ffffff]  border-b-[1px] border-t-[1px] border-main-color border-r border-r-[#D6D7DB] font-medium" className="  bg-[#ffffff]  border-r border-[#D6D7DB]" header="Actions" field="action" body={tableActions}></Column>
      </DataTable>
           </div> 
           <Dialog
          headerClassName="font-poppins font-medium"
          header="Confirmation"
          onHide={() => {
            setPayingDialog(false);
          }}
          visible={payingDialog}
        >   
    
          <div className="font-poppins text-medium text-[#AFAFAF]">
          
            <div className="flex flex-wrap flex-row justify-around">
              <h1>Are You Sure You Want To Pay the Rent? </h1>
            </div>
            <div className="flex flex-wrap mt-4  flex-row justify-around">
              <Button 
               loading={payingLoader} 
               disabled={payApiLoader}
                label="Yes"
                onClick={() => {   
                  setPayingLoader(true)
                  Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Rent/Pay`,{id:selectedRow?.id}, {
                    headers: { Authorization: `Bearer ${token}` },
                  }).then(()=>{   
                
              setPayApiLoader(prev=>!prev) 
              setPayingLoader(true)
               setPayingDialog(prev=>!prev) 
                  }).catch(err=>{ 
            
               setPayingLoader(false)
                  }) 
                }}
                className="border border-[#AFAFAF] pl-2 pr-2 "
              />
              <Button
                label="No"
                onClick={() => {
                  setPayingDialog(false);
                }}
                className="border border-[#AFAFAF] pl-3 pr-3 "
              />
            </div>  

          </div>  
        </Dialog>  
        <Toast ref={toast}/> 
        <div ref={dataRef} className={`bg-white font-poppins font-medium w-[300px] rounded-md ${pdfSelect || printSelect ? "block":"hidden"}`}> 
             <div  className="w-full flex flex-wrap flex-row justify-center p-1 items-center">    
             <div className="rounded-full flex flex-wrap flex-row justify-center items-center w-[70px] h-[70px] bg-[#23A26D1F]"> 
              <div className="rounded-full flex flex-wrap flex-row justify-center items-center w-[50px] h-[50px] bg-[#23A26D]"> 
              <i className="text-white text-[20px] mt-[-15px] pi pi-check"/>
              </div>
             </div>
             </div>
             <h1 className="text-center text-[#AFAFAF]">Monthly Rent Payment Success !</h1>  
             <h1 className="text-center text-[24px] font-bold text-black pb-4">PKR {currentSelected?.rent}</h1>   
             <hr/>
              <div className="flex flex-wrap p-4 flex-row items-center justify-between"> 
                 <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                <h1  className="text-[#AFAFAF]">Month</h1> 
                 <h1 className="w-[70px] text-right text-black">{moment().month(currentSelected?.month - 1).format('MMMM')}</h1>  
                 </div> 
                  <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                 <h1 className="text-[#AFAFAF]">Year</h1> 
                 <h1 className="w-[70px] text-right text-black">{currentSelected?.year}</h1>  
                  </div>
              </div>   
               <h1 className="pl-5 font-semibold text-black">Customer Info :</h1>     
               <div className="flex flex-wrap p-4 flex-row items-center justify-between"> 
                  
               <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                <h1  className="text-[#AFAFAF]">Customer ID</h1> 
                 <h1 className=" text-right text-black">{currentSelected?.customer?.userId}</h1>  
                 </div> 
                  <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                 <h1  className="text-[#AFAFAF]">Name</h1> 
                 <h1 className=" text-right text-black">{currentSelected?.customer?.name}</h1>  
                  </div>
                  <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                 <h1  className="text-[#AFAFAF]">Phone</h1> 
                 <h1 className=" text-right text-black">{currentSelected?.customer?.phone}</h1>  
                  </div>
                </div>
                
               <h1 className="pl-5 font-semibold text-black">Marketer Info :</h1>  
                <div className="flex flex-wrap p-4 flex-row items-center justify-between"> 
                  
                  <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                   <h1  className="text-[#AFAFAF]">Marketer ID</h1> 
                    <h1 className=" text-right text-black">{currentSelected?.marketer?.userId}</h1>  
                    </div> 
                     <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                    <h1  className="text-[#AFAFAF]">Name</h1> 
                    <h1 className=" text-right text-black">{currentSelected?.customer?.name}</h1>  
                     </div>
                     <div className="w-full flex flex-wrap p-1 flex-row items-center justify-between">
                    <h1  className="text-[#AFAFAF]">Phone</h1> 
                    <h1 className=" text-right text-black">{currentSelected?.customer?.phone}</h1>  
                     </div>
                   </div>
           </div>
          </div> 
          
   </div>
     )
} 

  