  import React, { useState,useRef } from "react";
import * as Yup from "yup"; 
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button"; 
import {Toast} from "primereact/toast" 
import Axios from "axios";  
import { useFormik } from "formik";   
import PageLoader from "../../../Cities/Components/PageLoader";
import { InputTextarea } from "primereact/inputtextarea";
import CommonLoader from "../../../../../Components/Loader/Loader";
export default function AddFaqs({setRefresh}){  
  let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
  let token = parseLoginRes.token;  
  const [loaderShow,setShowLoader]=useState(false)  
  const toast=useRef(null)    
  
    const validationSchema = Yup.object().shape({
        question:Yup.string().required("Question Is Required"),
         answer:Yup.string().required("City Name Is Required")
         
     
     
       })     
     
   const formik = useFormik({
       initialValues: {
         question:"", 
         answer:"", 
     
         },
       validationSchema,
       onSubmit: (values,{resetForm}) => {   
       setShowLoader(true) 
         Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Faqs/Create`,values,{
          headers: { Authorization: `Bearer ${token}` },
        }).then(res=>{  
          
              setShowLoader(false)   
                resetForm()
         setRefresh(prev=>!prev)
       }).catch(err=>{  
          setShowLoader(false) 
        })  
     
       }})  
    
     return( 
         <div className="font-poppins w-full mt-2 p-2 ">  
       
            <form onSubmit={formik.handleSubmit}>  
               <div className=" border-[1px]  border-[#E6E9EE] rounded-2xl p-2 flex flex-wrap flex-wrap justify-between items-center"> 
                 <h1 className="font-semibold">Add Faqs</h1> 
                   
                 <div className="w-[74%] ">
                 <InputText
              value={formik.values.name}
              name="question"
              onChange={formik.handleChange}
              placeholder="Question"
              className="mt-2 border p-2 border-[#EEEEEE] text-[#AFAFAF]   rounded-md w-full"
            />
            {formik.touched.question && formik.errors.question ? (
              <p className="text-red-500 text-[12px]">{formik.errors.question}</p>
            ) : undefined}
          </div>   
        
          <div className="w-[100%] ">
            <InputTextarea
              value={formik.values.name}
              name="answer"
              onChange={formik.handleChange}
              placeholder="Answer"
              className="mt-2 border p-2 border-[#EEEEEE] text-[#AFAFAF]   rounded-md w-full"
            />
            {formik.touched.answer && formik.errors.answer ? (
              <p className="text-red-500 text-[12px]">{formik.errors.answer}</p>
            ) : undefined}
          </div>  
             
          <Button 
            type="submit"
            disabled={loaderShow}
            className="w-[22%] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${loaderShow ? "":"Submit"}`}   
            iconPos="center"
            icon={loaderShow ? <CommonLoader/>:""}
          />
               </div>
            
            </form> 
             <Toast ref={toast}/>
         </div>
     )
 }