import React, { useEffect, useState } from "react";
import * as Yup from "yup"; 

import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";  
import { Button } from "primereact/button"; 
import {InputTextarea} from "primereact/inputtextarea"
import Axios from "axios";  
import PageLoader from "../../Utils/PageLoader";
export default function ContactUs(){   
    const [loaderShow,setLoaderShow]=useState(false) 
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    let token = parseLoginRes.token;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name Is Required"),
    email: Yup.string().required("Email Is Required"),
    message: Yup.string().required("Message Is Required"),
  });
  const formik = useFormik({
    initialValues: {
    name:"", 
    email:"", 
    message:""
    },
    validationSchema,  
     onSubmit:(values ,{resetForm}) => { 
        setLoaderShow(true)
        Axios.post(`${process.env.REACT_APP_BASE_URL}/api/ContactUs/Create`,values, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              //toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Data</h1> });
           setLoaderShow(false) 
            resetForm()
            })
            .catch(() => { 
                setLoaderShow(false)
              //toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Enter Password Or Email Is Incorrect</h1> });
            });
     }  
    })
     return(  
        <form className="w-[49%]" onSubmit={formik.handleSubmit}>
   <div className="w-full bg-[#EEEEEE] p-2 rounded-md flex text-[#AFAFAF] font-poppins font-medium flex-wrap flex-row justify-around max-h-[calc(100vh-200px)] rounded-2xl overflow-y-auto profilesidebar"> 
       
        
   
       <h1 className="text-black font-semibold text-[22px] mt-4">Ask Question</h1>
       <div className="w-[100%]">
            <InputText
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              placeholder="Name"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-[12px] text-red-500">{formik.errors.name}</p>
            ) : undefined}
          </div> 
          <div className="w-[100%]">
            <InputText
              value={formik.values.email}
              name="email"
              onChange={formik.handleChange}
              placeholder="Email"
              className="mt-2 border p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-[12px] text-red-500">{formik.errors.email}</p>
            ) : undefined}
          </div>  
          <div className="w-[100%]">
            <InputTextarea
              value={formik.values.message}
              name="message"
              onChange={formik.handleChange}
              placeholder="Message"
              className="mt-2 border h-[200px] p-2 border-[#EEEEEE]  rounded-md w-full"
            />
            {formik.touched.message && formik.errors.message ? (
              <p className="text-[12px] text-red-500">{formik.errors.message}</p>
            ) : undefined}
          </div>  
           <Button label="Submit" type="submit"  loading={loaderShow} className="w-[100%] p-2 text-white bg-custom-gradient rounded-md" />
      
   </div> 
   </form>
     )
}