import React, { useState,useRef,useEffect} from "react"; 
import { Toast } from "primereact/toast"; 
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar"; 
import moment from "moment";
import * as Yup from "yup"; 
import Axios from "axios";  
import html2pdf from 'html2pdf.js';
import {
  XIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext"; 
import { Dropdown } from "primereact/dropdown";
import { Pdf, Printer } from "../../../Svg";
import PageLoader from "../../../Components/PageLoader";
import CommonLoader from "../../../../Components/Loader/Loader";
export default function BranchForm({ setFormToOpen,setRefreshUserFromAdd ,setRefreshUser}) {
  const [showCofirmation, setConfirmation] = useState(false);  
  const toast=useRef()  
  let formref=useRef()
  let parseLoginRes=JSON.parse(localStorage.getItem("userData"))  
  const [imgUrl,setImageUrl]=useState(null)     
  const [showImageError,setShowImageError]=useState(false)  
  const [showCnicFrontError,setShowCnicFrontError]=useState(false) 
  const [showCnicBackError,setShowCnicBackError]=useState(false)  
  const [cnicFrontUrl,setCnicFront]=useState(null)  
  const [cnicBackUrl,setCnicBack]=useState(null) 
   const [countryOptions,setCountryOptions]=useState([]) 
   const [citiesOption,setCitiesOptions]=useState([])  
   const [managerCitiesOption,setManagerCitiesOptions]=useState([])    
   const [showPageLoader,setShowPageLoader]=useState(false)
   let token=parseLoginRes.token 
  const validationSchema = Yup.object().shape({
    BranchId: Yup.string().required("ID Is Required"),
    BranchDate: Yup.string().required("Date Is Required"),
    BranchName: Yup.string().required("Name Is Required"),
    BranchCountryId: Yup.string().required("Country Is Required"),
    BranchCityId: Yup.string().required("City Is Required"),
    BranchAddress: Yup.string().required("Address Is Required"),

    ManagerCountryId: Yup.string().required("Country Is Required"),
    ManagerCityId: Yup.string().required("City Is Required"),
    ManagerAddress: Yup.string().required("Address Is Required"),
    ManagerName: Yup.string().required("Name Is Required"),

    ManagerFatherName: Yup.string().required("Father Name Is Required"),
    ManagerBankAccountNumber: Yup.string().required("Account No Is Required"),
    ManagerEmail: Yup.string().required("Email Is Required"),
    ManagerPhone: Yup.string().required("Phone Is Required"),
    ManagerWhatsappNumber: Yup.string().required("Whatsapp Number Is Required"),
    ManagerDob: Yup.string().required("DOB Is Required"),
    ManagerBankName:Yup.string().required("Bank Name Is Required"), 
    ManagerCnic: Yup.string().required("Cnic Is Required"),   
  });
  const formik = useFormik({
    initialValues: {
      BranchId: "",
      BranchDate: new Date(),
      BranchName: "",
      BranchCountryId: "",
      BranchCityId: "", 
      BranchImage:"", 
      ManagerIdCardFront:"", 
      ManagerIdCardBack:"",
      BranchAddress: "",
      address: "",
      ManagerName: "",
      ManagerFatherName: "",
      ManagerCountryId: "",
      ManagerCityId: "",
      ManagerAddress: "",
      ManagerBankAccountNumber: "",
      ManagerEmail: "",
      ManagerPhone: "",
      ManagerWhatsappNumber: "",
      ManagerDob: "", 
      ManagerBankName:"",
      ManagerCnic: "",
    },
    validationSchema,
    onSubmit: (values,{resetForm}) => { 
      
          //setConfirmation(prev=>!prev)  
       if(formik.values.BranchImage !== ""){ 
        if(formik.values.ManagerIdCardBack !== ""){ 
          if(formik.values.ManagerIdCardFront !== ""){   
            
            
             AddBranch(values,resetForm)
      
          } 
          else{  
            setShowCnicFrontError(true)
            
          
          }
        } 
        else{  
          setShowCnicBackError(true)
          if(formik.values.ManagerIdCardFront === ""){ 
            setShowCnicFrontError(true)
           }  
          
        }
      }  
      else{ 
        setShowImageError(true) 
         if(formik.values.ManagerIdCardBack === ""){ 
          setShowCnicBackError(true)
         } 
         if(formik.values.ManagerIdCardFront === ""){ 
          setShowCnicFrontError(true)
         }  
      }        
    },
  });     
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.BranchCountryId}`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         setCitiesOptions(res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[formik.values.BranchCountryId])   
   useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Cities/List/${formik.values.ManagerCountryId}`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         setManagerCitiesOptions(res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[formik.values.ManagerCountryId])
  function AddBranch(values,resetForm){ 
   setShowPageLoader(true)
    const formData=new FormData() 
    for (const key in values) { 
       if(key === "ManagerDob" || key === "BranchDate"){  
         let currentvalue=values[key] 
          let date=new Date(currentvalue) 
           let isoformat=date.toISOString() 
        formData.append(key, isoformat); 
       } 
       else{ 
      formData.append(key, values[key]); 
       }
    }  
   
  
    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/Branches/Add`,formData,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
     
      setShowPageLoader(false) 
      setConfirmation(true)
        // setCountryOptions(res?.data)
       // setUsersData(res?.data)
    }).catch((err)=>{   
      setShowPageLoader(false)
     })
  }   
  useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Countries/List`,{headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{  
     
         setCountryOptions(res?.data)
       // setUsersData(res?.data)
    }).catch(()=>{ 
     
    })
   },[]) 
   useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/Account/GetNewId`,{params:{IdType:1}, headers:{'Authorization':`Bearer ${token}`}}).then((res)=>{ 
         //setManagerCitiesOptions(res?.data) 
   formik.setFieldValue("BranchId",res?.data)
     //   toast.current.show({ severity: "success", summary: "Info", detail: <h1 className="font-poppins ">Successfully Fetched Cities Data</h1> });
       // setUsersData(res?.data)
    }).catch(()=>{ 
     // toast.current.show({ severity: "error", summary: "Info", detail: <h1 className="font-poppins ">Error Fetched Cities Data</h1> });
   
    })
   },[])
   useEffect(()=>{  

    document.body.style.overflow = 'hidden';
  return ()=>{  
  
    document.body.style.overflow = 'auto';
  }
   },[])
  return ( 
    <div> 
    <div   id="loader">
  <div className="spinner"></div>
</div>
    <div className="all-customers fixed overflow-y-auto  w-[calc(100vw-320px)] border rounded-2xl border-[#E6E9EE] shadow-sm   right-[0px] z-40 h-[calc(100vh-75px)] width716:h-[calc(100vh-75px)] width910:h-[calc(100vh-140px)]  top-[160px] width716:top-[170px] width910:top-[160px]  width1240:top-[100px] width1240:h-[calc(100vh-75px)] bg-white ">
       
      {showCofirmation ? (
        <div className="fixed h-[230px] w-[400px] border border-[#EEEEEE]  z-40 bg-white top-[50%] left-[calc(50%+160px)] rounded-xl z-40 transform translate-x-[-50%] translate-y-[-50%]">
          <div className="flex flex-wrap mt-[-20px] justify-center ">
            <div className="w-[50px] rounded-full p-3 h-[50px] bg-[#2DD683]">
              <CheckIcon className=" text-white w-[25px] h-[25px]" />
            </div>
          </div>
          <div className="flex cursor-pointer flex-wrap justify-end flex-row mt-[-20px]">
            <XIcon
              onClick={() => { 
                setRefreshUserFromAdd(true)
                setConfirmation(prev=>!prev)   
                setRefreshUser(prev=>!prev) 
                setFormToOpen("")
              }}
              className=" mr-[20px] inline w-[25px] h-[25px]"
            />{" "}
          </div>
          <h1 className="w-full font-poppins text-[20px] text-center font-bold mt-4 ">
            Thank You !
          </h1>
          <p className="w-full font-poppins p-4 pl-10 pr-10 text-[16px] text-center font-medium  ">
            Your Details has been Successfully submitted. Thanks!
          </p>

          <div className="w-full rounded-b-xl h-[50px] mt-5 pt-[2px] bg-[#2DD683] items-center flex flex-wrap flex-row justify-center">
            <div onClick={() => {      
                document.querySelector("#loader").style.display="flex"
       
                const form = formref.current;
                const options = {
                  margin: 0.1,
                  filename: 'Customer_Form.pdf',
                  image: { type: 'jpeg', quality: 1 },
                  html2canvas: { scale: 1 },
                  jsPDF: { unit: 'in', format: 'a4', compressPdf: true, orientation: 'portrait' }
              };
              html2pdf()
              .set(options)
              .from(form)
              .save()
              .then((pdfBuffer) => {   
              document.querySelector("#loader").style.display="none"
            
              })
              }}  className="cursor-pointer w-[25px] pt-[1px] h-[25px] mr-[10px]">
              <Pdf />
            </div>
            <div onClick={()=>{ 
               document.querySelector("#loader").style.display="flex"
       
          
               
            const form = formref.current;  
            const opt=  {
             margin: .1,
             image: { type: 'jpeg', quality:1 },
             html2canvas: { scale: 1 },
             jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } 
           } 
               html2pdf().set(opt).from(form).outputPdf('blob').then(pdfBlob => {
               const pdfUrl = URL.createObjectURL(pdfBlob);
               const iframe = document.createElement('iframe');
               iframe.style.display = 'none';
               iframe.src = pdfUrl;
               document.body.appendChild(iframe);
         setTimeout(() => {
                 iframe.contentWindow.print();   
                 document.querySelector("#loader").style.display="none"

               }, 1000)   
             
            })}} className=" cursor-pointer w-[25px] h-[25px] ml-[10px] ">
              <Printer />
            </div>
          </div>
        </div>
      ) : undefined}
      <form ref={formref} onSubmit={formik.handleSubmit} className="w-full pb-[50px]">
        <div className="flex flex-wrap justify-between items-center rounded-t-2xl  flex-row bg-main-color p-4 w-full font-poppins font-semibold text-[20px]">
          <h1>Branch Form</h1>
          <div>
           
            <XIcon
              onClick={() => {
                setFormToOpen("");
              }}
              className="w-[16px] inline cursor-pointer ml-4 h-[16px]"
            />
          </div>
        </div>
        <div className=" p-10 flex relative bg-form-title flex-wrap items-center w-full flex-row justify-between">
        <div>
            <img src="./formlogo.png" className={`${showCofirmation ? "w-[130px]":""}`} alt="formlogo" />
          </div>
          <div>
           { 
               showCofirmation ?    <div className="flex flex-wrap ml-[-100px] items-center flex-row justify-left">   
                   <div className="border-l-[5px] border-main-color mt-3  h-[70px] w-[4px]"></div>
                   <h1 className="w-[50px] text-white mt-[-15px] font-medium font-poppins ml-[20px] text-[25px]">Branch Form</h1>
                </div> :    <h1 className={`font-poppins border-l-[5px]  border-main-color   w-[200px] font-medium  text-white  ${showCofirmation ? "text-[20px] text-center  w-[50px]   pl-[20px]":"text-[36px]  pl-[40px] line-height-[40px]  ml-[-200px]"}`}>Branch Form</h1>
         
             }
             </div>
          <div    onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setImageUrl(reader.result)
                }
              formik.setFieldValue("BranchImage",e.target.files[0])  
              setShowImageError(false)
              }  
              input.click()
          }}>
             <div className={`absolute ${showCofirmation ? "mt-10 h-[130px] w-[120px] mt-[-30px] ml-[-110px] ":"mt-[-30px] ml-[-170px] h-[180px] w-[170px] "} cursor-pointer  bg-white  justify-center z-10 items-center overflow-hidden flex flex-wrap flex-row  rounded-2xl`}>
             
             {imgUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Pic 
              </h1>  
              :<img src={imgUrl}/>
}
            </div> 
            { showImageError ? (
              <p className={`font-poppins block font-medium mt-[155px] absolute ml-[-165px] text-red-500`}>
                Image Is Required
              </p>
            ) : null}
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-left   flex-row bg-main-color p-4">
          <div className="w-[150px]  font-poppins font-medium">
            <p className="mb-2">Branch ID</p>
         
              <InputText 
              disabled
              name="BranchId"
              value={formik.values.BranchId}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Branch"
            />
            {formik.touched.BranchId && formik.errors.BranchId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BranchId}
              </p>
            ) : null}
          </div>
          <div className="ml-5  w-[150px]">
            <p className=" mb-2 font-poppins font-medium ">Date</p>
          
            
             <Calendar disabled dateFormat="dd/mm/yy"
              name="BranchDate"
              value={formik.values.BranchDate}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.BranchDate=utcDate.toDate()
              }}
              showIcon 
              
              iconPos="left"
              className=" text-[#AFAFAF] border pl-2 h-[40px]   bg-white   w-full  rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Date"
            />
            {formik.touched.BranchDate && formik.errors.BranchDate ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BranchDate}
              </p>
            ) : null}
          </div>
          
        </div>
        <div className="flex flex-wrap w-full mt-2  text-[#AFAFAF] justify-left flex-row ">
          <div className="w-[200px] mt-2 ml-5">
            <InputText
              placeholder="Branch Name"
              name="BranchName"
              onChange={formik.handleChange}
              value={formik.values.BranchName}   
              className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
                  />
            {formik.touched.BranchName && formik.errors.BranchName ? (
              <p className="font-poppins font-medium mt-2 h-[40px] ml-1 text-red-500">
                {formik.errors.BranchName}
              </p>
            ) : null}
          </div>
          <div className="w-[200px] mt-2 ml-5">  {  
            showCofirmation ?  
            <InputText
            name="BranchCountryId"
            value={formik.values.BranchCountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />
             :
          <Dropdown style={{height:"40px",paddingTop:"-4px"}} className="w-full border h-[40px] my-dropdown font-poppins font-medium  rounded-md  "  placeholder="Country" onChange={formik.handleChange} optionValue="id" optionLabel="name" name="BranchCountryId" value={formik.values.BranchCountryId} options={countryOptions}/>
          }
            {formik.touched.BranchCountryId && formik.errors.BranchCountryId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BranchCountryId}
              </p>
            ) : null}
          </div>
          <div className="w-[200px] h-[40px] mt-2 ml-5 ">
         {  
            showCofirmation ?  
            <InputText
            name="BranchCityId"
            value={formik.values.BranchCityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="City"
          />
             :
             <Dropdown className="w-full  border h-[40px] p-0 m-0  my-dropdown font-poppins font-medium  rounded-md " name="BranchCityId"  placeholder="City" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.BranchCityId} options={citiesOption}/>
        
        }
            {formik.touched.BranchCityId && formik.errors.BranchCityId ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BranchCityId}
              </p>
            ) : null}
          </div>
          <div className="w-[300px] mt-2 ml-5 ">
            <InputText
              name="BranchAddress"
              value={formik.values.BranchAddress}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.BranchAddress && formik.errors.BranchAddress ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.BranchAddress}
              </p>
            ) : null}
          </div>
        </div>
        <div className={`mt-4 flex flex-wrap flex-row  text-[#AFAFAF] ${showCofirmation ? "p-4":"p-2"} justify-center bg-[black] text-white font-poppins font-semibold text-[31px]`}>
          <h1 className={`text-white font-medium ${showCofirmation ? "mt-[-20px] text-[20px]":""}`}>Branch Manager Data</h1>
        </div>
        <div className="flex flex-wrap flex-row w-full p-4 justify-between">
          <div className="w-[49%] mt-4">
            <InputText
              name="ManagerName"
              value={formik.values.ManagerName}
              onChange={formik.handleChange}
              className="text-[#AFAFAF]  border pl-2 h-[40px]  w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
              placeholder="Name"
            />
            {formik.touched.ManagerName && formik.errors.ManagerName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputText
              name="ManagerFatherName"
              value={formik.values.ManagerFatherName}
              onChange={formik.handleChange}
              className="text-[#AFAFAF] mt-4 border pl-2 h-[40px]    w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Father Name"
            />

            {formik.touched.ManagerFatherName && formik.errors.ManagerFatherName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerFatherName}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]">
            <InputMask
              mask="99999-9999999-9"
              name="ManagerCnic"
              value={formik.values.ManagerCnic}
              onChange={formik.handleChange}
              className="mt-4 text-[#AFAFAF] border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="ManagerCnic"
            />
            {formik.touched.ManagerCnic && formik.errors.ManagerCnic ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerCnic}
              </p>
            ) : null}
          </div>
          <div className="w-[49%]  flex  flex-wrap flex-row justify-between">
            <div className="w-[49%] mt-4 ">
            {  
            showCofirmation ?  
            <InputText
            name="ManagerCountryId"
            value={formik.values.ManagerCountryId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="Country"
          />
             :
             <Dropdown className="w-full  my-dropdown h-[40px] border font-poppins font-medium  rounded-md " name="ManagerCountryId"  placeholder="Country" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.ManagerCountryId} options={countryOptions}/>
         
   }
              {formik.touched.ManagerCountryId && formik.errors.ManagerCountryId ? (
                <p className="font-poppins font-medium mt-4 ml-1 text-red-500">
                  {formik.errors.ManagerCountryId}
                </p>
              ) : null}
            </div>
            <div className="w-[49%]  mt-4">
           {  
            showCofirmation ?  
            <InputText
            name="ManagerCityId"
            value={formik.values.ManagerCityId}
            onChange={formik.handleChange}
            className="text-[#AFAFAF] border h-[40px] pl-2   w-full rounded-lg  border-[#D6D7DB] font-poppins font-medium"
            placeholder="City"
          />
             :
             <Dropdown className="w-full  my-dropdown border h-[40px] font-poppins font-medium  rounded-md " name="ManagerCityId"  placeholder="City" onChange={formik.handleChange} optionValue="id" optionLabel="name" value={formik.values.ManagerCityId} options={managerCitiesOption}/>
          
     }
              {formik.touched.ManagerCityId && formik.errors.ManagerCityId ? (
                <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                  {formik.errors.ManagerCityId}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-[100%]">
            <InputText
              name="ManagerAddress"
              value={formik.values.ManagerAddress}
              onChange={formik.handleChange}
              className="mt-4 text-[#AFAFAF] border pl-2 h-[40px]  w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Address"
            />
            {formik.touched.ManagerAddress && formik.errors.ManagerAddress ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerAddress}
              </p>
            ) : null}
          </div>  
          <div className="w-[28%]">
            <InputText
              name="ManagerBankName"
              value={formik.values.ManagerBankName}
              onChange={formik.handleChange}
              className=" mt-4 text-[#AFAFAF] border pl-2 h-[40px]   w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Bank Name"
            />
            {formik.touched.ManagerBankName && formik.errors.ManagerBankName ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerBankName}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="ManagerBankAccountNumber"
              value={formik.values.ManagerBankAccountNumber}
              onChange={formik.handleChange}
              className=" mt-4 text-[#AFAFAF] border pl-2 h-[40px]   w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Account No"
            />
            {formik.touched.ManagerBankAccountNumber && formik.errors.ManagerBankAccountNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerBankAccountNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[35%]">
            <InputText
              name="ManagerEmail"
              value={formik.values.ManagerEmail}
              onChange={formik.handleChange}
              className=" mt-4 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Manager Email"
            />
            {formik.touched.ManagerEmail && formik.errors.ManagerEmail ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerEmail}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="ManagerPhone"
              value={formik.values.ManagerPhone}
              onChange={formik.handleChange}
              className=" mt-4 text-[#AFAFAF] border pl-2 h-[40px]   w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="ManagerPhone Number"
            />
            {formik.touched.ManagerPhone && formik.errors.ManagerPhone ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerPhone}
              </p>
            ) : null}
          </div>
          <div className="w-[35%]">
            <InputText
              name="ManagerWhatsappNumber"
              value={formik.values.ManagerWhatsappNumber}
              onChange={formik.handleChange}
              className=" mt-4 text-[#AFAFAF] border pl-2 h-[40px]    w-full rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="Whatsapp Number"
            />
            {formik.touched.ManagerWhatsappNumber && formik.errors.ManagerWhatsappNumber ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerWhatsappNumber}
              </p>
            ) : null}
          </div>

          <div className="w-[25%]">
            <Calendar dateFormat="dd/mm/yy"
              name="ManagerDob"
              value={formik.values.ManagerDob}
              onChange={(e)=>{ 
                const selectedDate = moment(e.value).set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
                // Update the state with the formatted date
             
            const utcDate = selectedDate.utc(); 
                 
                formik.values.ManagerDob=utcDate.toDate()
              }}
              showIcon
              iconPos="left"
              className="mt-4 text-[#AFAFAF] border pl-2 h-[40px]    w-full  rounded-lg mt-2 border-[#D6D7DB] font-poppins font-medium"
              placeholder="DOB"
            />
            {formik.touched.ManagerDob && formik.errors.ManagerDob ? (
              <p className="font-poppins font-medium mt-2 ml-1 text-red-500">
                {formik.errors.ManagerDob}
              </p>
            ) : null}
          </div>

          <div className="flex flex-row w-full mt-4 flex-wrap justify-center ">
            <h1 className={`font-poppins font-medium  ${showCofirmation ? "text-[20px] ":"text-[31px] mt-4"}`}>
             {showCofirmation ? "":"Upload "}<span className="font-semibold">Manager</span> ID CARD
            </h1>
          </div>
          <div className="w-full mt-10  flex flex-wrap flex-row justify-evenly">
          <div className="  flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[200px] h-[150px] ":"w-[300px] h-[200px]"}  rounded-lg overflow-hidden bg-[#EEEEEE] flex flex-wrap flex-row justify-center items-center`}   onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicFront(reader.result)
                }
              formik.setFieldValue("ManagerIdCardFront",e.target.files[0])         
               setShowCnicFrontError(false)
              }  
               input.click()
          }}>
             
              {cnicFrontUrl === null ? <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Front
              </h1> 
              :<img src={cnicFrontUrl} className="h-[100%] w-auto"/>   
        }      
         </div>
           { showCnicFrontError ? (
              <p className="font-poppins block w-full font-medium  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
            
             </div>
            <div className=" flex flex-wrap flex-row justify-between">
           
            <div className={`${showCofirmation ? "w-[200px] h-[150px] ":"w-[300px] h-[200px]"}  rounded-lg bg-[#EEEEEE] flex flex-wrap flex-row overflow-hidden justify-center items-center`} onClick={()=>{ 
              let input=document.createElement("input") 
              input.type="file"  
              input.accept="image/*"
              input.onchange=(e)=>{   
                let reader=new FileReader() 
                reader.readAsDataURL(e.target.files[0]) 
                reader.onload=()=>{ 
                  setCnicBack(reader.result)
                }  
              formik.setFieldValue("ManagerIdCardBack",e.target.files[0])  
              setShowCnicBackError(false)
              }  
              input.click()
          }}>
               {cnicBackUrl === null ?   <h1 className="text-[31px] font-poppins text-[#D6D7DB]  font-medium ">
                Back
              </h1> 
              :<img src={cnicBackUrl} className="h-[100%] w-auto"/>   
        }   
            </div> 
            { showCnicBackError ? (
              <p className="font-poppins block font-medium w-full  ml-1 text-red-500">
                Image Is Required
              </p>
            ) : null} 
          </div> 
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left w-full">
            <h1 className={`font-poppins  ${showCofirmation ? "text-[16px] font-semibold":"font-bold"} `}>
              Manager Signature:
            </h1>
            <div className={` w-[200px] border-b   ml-5 ${showCofirmation ? "font-semibold mt-6 h-[10px] border-b-[2px]":"font-bold border-b-[4px]"}`}></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-end w-full">
            <h1 className={`font-poppins text-[#AFAFAF]  font-bold  ${showCofirmation ? "font-semibold":"font-bold"}`}>
              Company Stemp:
            </h1>
            <div className=" w-[200px]  ml-5 "></div>
          </div>
          <div className="flex mt-10  flex-wrap flex-row justify-left w-full">
            <h1 className={` font-poppins   ${showCofirmation ? "text-[16px] font-semibold":"font-bold"}`}>
              Company Owner Signature:
            </h1>
            <div className={` w-[200px] border-b   ml-5 ${showCofirmation ? "font-semibold mt-6  h-[10px] border-b-[2px]":"font-bold border-b-[4px]"}`}></div>
          </div>  
           { !showCofirmation ?
          <div className="flex font-poppins mt-10  font-medium flex-row w-full flex-wrap justify-center ">
             <Button  
              onClick={()=>{ 
                if(formik.values.BranchImage !== ""){ 
                  if(formik.values.ManagerIdCardBack !== ""){ 
                    if(formik.values.ManagerIdCardFront !== ""){   
                      formik.handleSubmit() 
                    
                    } 
                    else{  
                      setShowCnicFrontError(true)
                      
                       
                       formik.handleSubmit()
                    }
                  } 
                  else{  
                    setShowCnicBackError(true)
                    if(formik.values.ManagerIdCardFront === ""){ 
                      setShowCnicFrontError(true)
                     }  
                    
                     formik.handleSubmit()
                  }
                }  
                else{ 
                  setShowImageError(true) 
                   if(formik.values.ManagerIdCardBack === ""){ 
                    setShowCnicBackError(true)
                   } 
                   if(formik.values.ManagerIdCardFront === ""){ 
                    setShowCnicFrontError(true)
                   }  
                   
                   formik.handleSubmit()
                }
              }}
            type="button"
            disabled={showPageLoader}
            className="w-[100px] text-white bg-custom-gradient mt-2 p-2 rounded-md"
            label={`${showPageLoader ? "":"Submit"}`}   
            iconPos="center"
            icon={showPageLoader? <CommonLoader/>:""}
          />
          
         { /*
         !showCofirmation ?   <Button
              label="Draft" 
              className="bg-[#D6D7DB] rounded-xl ml-5  w-[100px]"
            />    :  
             <button className="bg-[#D6D7DB] rounded-xl ml-5 relative w-[100px] "><span className="ml-[-20px]  mt-[-20px] absolute">Draft</span></button>
        */ }     
          </div>
          :""
        }   
        </div>   
      </form> 
      
      <Toast className="z-50 mt-[80px]" ref={toast}/>
    </div> 
     </div>
  );
}
